import React, { useState, useRef, useEffect } from "react";
import { Libraries, LoadScript } from "@react-google-maps/api";
import { GOOGLE_MAP_API_KEY } from "../../../constant/environment";
import { airportAddressFieldTypes, BookingDetails } from "../../../types";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { newCloseIcon } from "../../../constant";
import styles from "./input.module.scss";

const libraries: Libraries = ["places"];

interface AutocompleteAddressProps {
  clearInputValues: () => void;
  onPlaceSelected: (address: airportAddressFieldTypes) => void;
  address: string;
  setAddress: (address: string) => void;
  setInputAddress: React.Dispatch<
    React.SetStateAction<airportAddressFieldTypes>
  >;
}

const AutoCompleteAirportAddress: React.FC<AutocompleteAddressProps> = ({
  onPlaceSelected,
  address,
  setAddress,
  setInputAddress,
  clearInputValues,
}) => {
  const state = React.useContext(StateContext);
  const { loginCreateAccountState, isMobile }: iGlobalContext = state;

  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const autocompleteServiceRef =
    useRef<google.maps.places.AutocompleteService | null>(null);
  const placesServiceRef = useRef<google.maps.places.PlacesService | null>(
    null
  );
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (scriptLoaded && !autocompleteServiceRef.current) {
      autocompleteServiceRef.current =
        new window.google.maps.places.AutocompleteService();
      placesServiceRef.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
  }, [scriptLoaded]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAddress(value);
    if (value && autocompleteServiceRef.current) {
      autocompleteServiceRef.current.getPlacePredictions(
        { input: value },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setPredictions(predictions);
          } else {
            setPredictions([]);
          }
        }
      );
    } else {
      setPredictions([]);
    }
  };

  const handleSelect = (placeId: string) => {
    if (placesServiceRef.current) {
      placesServiceRef.current.getDetails({ placeId }, (place, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          place
        ) {
          setAddress(place.formatted_address || "");
          const addressComponents = place.address_components || [];
          let city = "";
          let state = "";
          let country = "";
          let countryCode = "";
          addressComponents.forEach((component) => {
            const types = component.types;
            if (types.includes("locality")) {
              city = component.long_name;
            }
            if (types.includes("administrative_area_level_1")) {
              state = component.long_name;
            }
            if (types.includes("country")) {
              country = component.long_name;
              countryCode = component.short_name;
            }
          });

          // Extract latitude and longitude
          const latitude = place.geometry?.location?.lat();
          const longitude = place.geometry?.location?.lng();
          const inputCompleteAddress = {
            address: place.formatted_address || "",
            city,
            state,
            country,
            countryCode,
            latitude: latitude ?? 0.0,
            longitude: longitude ?? 0.0,
          };
          setInputAddress((prevData) => ({
            ...prevData,
            ...inputCompleteAddress,
          }));

          setPredictions([]);
          onPlaceSelected(inputCompleteAddress);
        }
      });
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <LoadScript
          googleMapsApiKey={GOOGLE_MAP_API_KEY}
          libraries={libraries}
          onLoad={() => setScriptLoaded(true)}
        />
      </div>

      {scriptLoaded && (
        <div className={styles.inputBox}>
          <div className={styles.inputContainer}>
            {isMobile && loginCreateAccountState.flag === true ? (
              <div className={styles.inputWrapper}>
                <input
                  type="text"
                  value={address}
                  onChange={handleChange}
                  placeholder="Enter city name"
                  className={styles.inputField1}
                />
                {address && (
                  <img
                    src={newCloseIcon}
                    alt="Close"
                    className={styles.closeIcon}
                    onClick={() => {
                      setAddress("");
                      clearInputValues();
                    }}
                  />
                )}
              </div>
            ) : (
              <div className={styles.inputWrapper}>
                <input
                  type="text"
                  value={address}
                  onChange={handleChange}
                  placeholder="Enter city name"
                  className={styles.inputField}
                />
                {address && (
                  <img
                    src={newCloseIcon}
                    alt="Close"
                    className={styles.closeIcon}
                    onClick={() => {
                      setAddress("");
                      clearInputValues();
                    }}
                  />
                )}
              </div>
            )}
          </div>

          {predictions.length > 0 && (
            <div className={styles.card}>
              {isMobile && loginCreateAccountState.flag === true ? (
                <div className={styles.listCard1}>
                  <ul className={styles.ulList}>
                    {predictions.map((prediction) => (
                      <li
                        key={prediction.place_id}
                        onClick={() => handleSelect(prediction.place_id)}
                        className={styles.lists}>
                        {prediction.description}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className={styles.listCard}>
                  <ul className={styles.ulList}>
                    {predictions.map((prediction) => (
                      <li
                        key={prediction.place_id}
                        onClick={() => handleSelect(prediction.place_id)}
                        className={styles.lists}>
                        {prediction.description}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AutoCompleteAirportAddress;
