import React from "react";
import styles from "./btn.module.scss";

interface ButtonProps {
  children: React.ReactNode;
  type?: "button" | "submit" | "reset";
  size?: "sm" | "md" | "lg";
  variant?: "default" | "cancel" | "success" | "disabled";
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

const AppButton: React.FC<ButtonProps> = ({
  children,
  type = "button",
  size = "md",
  disabled = false,
  onClick,
  className,
  variant = "default",
}) => {
  const computedClassName = `${className} ${styles.btn} ${styles[`btn-${size}`]} ${
    disabled ? styles["disabled"] : styles[`btn-${variant}`]
  }`;
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={computedClassName}>
      {children}
    </button>
  );
};

export default AppButton;
