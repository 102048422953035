import React, { useState, useEffect, useRef } from "react";
import styles from "./manage.module.scss";
import { expandIcon } from "../../../constant";

// Define the type htmlFor the dropdown options
export interface DropdownOption {
  value: string | number;
  label: string;
  icon: string;
}

export interface DropdownProps {
  options: DropdownOption[];
  onChange: (selectedValue: string | number) => void;
  value: string | number;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
}

const ManageFlights: React.FC<DropdownProps> = ({
  options,
  onChange,
  value,
  placeholder = "MANAGE FLIGHTS",
  disabled = false,
  className = "",
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`${disabled ? styles.disable : styles.main}`}
      ref={dropdownRef}>
      <div className={styles.dropdownWrapper}>
        <section
          className={styles.dropDown}
          onClick={() => setDropdownOpen(!dropdownOpen)}>
          <span className={styles.selectedValue}>
            {options.find((opt) => opt.value === value)?.label ||
              "MANAGE FLIGHTS"}
          </span>
          <img
            src={expandIcon}
            alt="Dropdown Icon"
            className={`${dropdownOpen ? styles.dropIcon : styles.upIcon}`}
          />
        </section>

        {dropdownOpen && (
          <ul className={styles.optionsList}>
            {options.map((option) => (
              <li
                key={option.value}
                className={styles.optionList}
                onClick={() => {
                  onChange(option.value);
                  setDropdownOpen(false);
                }}>
                <div
                  className={`${option.value === value ? styles.listActiveCard : styles.listCard}`}>
                  <p
                    className={`${option.value === "CANCEL" ? styles.cancelLabel : styles.label}`}>
                    {option.label}
                  </p>

                  {option.icon && (
                    <img
                      src={option.icon}
                      alt="icon"
                      className={styles.optionIcon}
                    />
                  )}
                </div>
                {option.value === "CHANGE SEATS" && (
                  <div className={styles.dividerLine} />
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ManageFlights;
