import { useMemo } from "react";

const useFormatDuration = (duration: string) => {
  return useMemo(() => {
    if (!duration) return "";
    return duration.includes(":")
      ? duration.replace(":", "h ") + "min"
      : duration + "in";
  }, [duration]);
};

export default useFormatDuration;
