import React from "react";
import styles from "./propertyDetails.module.scss";
import DialogPopup from "../../../reusableComponent/dialogPopup/dialogPopup";
import { Stack } from "@mui/material";
import { outlinedCircleCloseIcon } from "../../../../constant";
import ImageSlider from "../imageSlider/imageSlider";
// import { PROPERTY_DETAILS } from "./propertyDetailsConstants";
import Amenities from "../amenities/amenities";
import { roomAmenitiesIcons } from "../roomDetailsModal/roomDetailsConstants";
import { amenitiesIcons } from "../../hotelDetailsConstants";

interface iProps {
  isOpen: boolean;
  onClose: () => void;
  propertyDetails: any;
}

function PropertyDetailsModal(props: iProps): JSX.Element {
  const { isOpen = false, onClose, propertyDetails } = props;

  // const {
  // hotelImages,
  // address,
  // hotelName,
  // place,
  // landMark,
  // phoneNumber,
  // email,
  // amenities = [],
  // } = PROPERTY_DETAILS;
  const {
    // stars: hotelStar,
    name: hotelName,
    hotelImages = [],
    address,
    city: place,
    // hotelMainImage,
    // rating,
    // reviewCount,
    landMark = "",
    phoneNumber,
    email,
    // reviewHeading = "",
    hotelAmenities: amenities = [],
    // aboutProperty = [],
  } = propertyDetails || {};
  return (
    <DialogPopup isOpen={isOpen}>
      <Stack
        sx={{
          // minHeight: "100vh",
          mt: { sm: 0, xs: 2 },
          // justifyContent: { sm: "center", md: "center", lg: "center" },
        }}
        spacing={6}
        alignItems="center"
        justifyContent="center"
      >
        <Stack
          sx={{
            width: { xs: "100vw" },
            maxWidth: { md: "450px" },
          }}
        >
          <Stack
            sx={{ width: "100%" }}
            px={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }}
          >
            <Stack alignSelf="end" mb={2} mr={2.6}>
              <img
                onClick={onClose}
                style={{ width: "25px", cursor: "pointer" }}
                src={outlinedCircleCloseIcon}
                alt="cross"
              />
            </Stack>

            <Stack
              sx={{
                width: "100%",
                // marginBottom: "-20px",
                maxHeight: "80vh",
                overflow: "auto",
                bgcolor: "#f8f8f8",
                borderRadius: "8px",
              }}
            >
              <Stack pt={4} pb={3.5} pl={0} pr={0} spacing={3}>
                <div className={styles.roomDetailsContainer}>
                  <div className={styles.header}>Property Amenities</div>
                  {hotelImages.length > 0 && (
                    <ImageSlider
                      className={styles.imgSlider}
                      noPaddSwipeBtn
                      imagesArr={[
                        // hotelMainImage,
                        ...hotelImages.map((item: any) => item.url),
                      ]}
                    />
                  )}
                  <div className={styles.roomDetailsInfoContainer}>
                    {/* <div className={styles.roomName}>{roomName}</div>
                    <div className={styles.sleepAndAreaContainer}>
                      <div className={styles.sleepsContainer}>
                        <img
                          src={personsIcon}
                          alt="Sleeps"
                          className={styles.sleepsIcon}
                        />
                        <span className={styles.sleepText}>
                          SLEEPS {sleeps}
                        </span>
                      </div>
                      <div className={styles.sleepsContainer}>
                        <img
                          src={areaIcon}
                          alt="Area"
                          className={styles.sleepsIcon}
                        />
                        <span className={styles.sleepText}>
                          {area} SQ METERS
                        </span>
                      </div>
                    </div> */}
                    <div className={styles.hotelDescriptionContainer}>
                      <section className={styles.contentCard}>
                        <p className={styles.hotelName}>{hotelName}</p>
                        <p className={styles.location}>
                          {place} &nbsp; | &nbsp; {address}
                        </p>
                        <p className={`${styles.location} ${styles.lastChild}`}>
                          {phoneNumber ? phoneNumber : ""} &nbsp;{" "}
                          {email ? "|" : ""} &nbsp;{" "}
                          {email ? <span>{email}</span> : ""}
                        </p>
                        {landMark && (
                          <p className={styles.landMark}>{landMark}</p>
                        )}
                      </section>
                      <Amenities
                        className={styles.amenitiesClass}
                        amenityItemClassName={styles.amenityItemClassName}
                        amenityListConainerClass={
                          styles.amenityListConainerClass
                        }
                        aminitiesArr={amenities}
                        showViewPropAmenities={false}
                        amenitiesIconsArr={[
                          ...roomAmenitiesIcons,
                          ...amenitiesIcons,
                        ]}
                      />
                    </div>

                    {/* <div className={styles.amenitiesHeader}>Amenities</div>
                    <div className={styles.amenitiesContainer}>
                      <Amenities
                        className={styles.amenitiesClass}
                        amenityItemClassName={styles.amenityItemClassName}
                        aminitiesArr={amenities}
                        showViewPropAmenities={false}
                        amenitiesIconsArr={roomAmenitiesIcons}
                      />
                    </div> */}
                  </div>
                </div>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </DialogPopup>
  );
}

export default PropertyDetailsModal;
