import React, { ReactNode, useMemo } from "react";
import "react-calendar/dist/Calendar.css";
import CustomPopover from "../../../reusableComponent/customPopver/CustomPopover";
import Calendar from "react-calendar";
import { expandIcon } from "../../../../constant";
import styles from "./datePopover.module.scss";

interface DatePickerPopoverProps {
  popoverTrigger: ReactNode;
  value: any;
  setValue: any;
  isPopoverOpen: boolean;
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleApplyButton: () => void;
}

export default function DatePickerPopover({
  popoverTrigger,
  value,
  setValue,
  isPopoverOpen,
  setIsPopoverOpen,
  handleApplyButton,
}: Readonly<DatePickerPopoverProps>) {
  const onApply = useMemo(
    () => () => {
      handleApplyButton();
      setIsPopoverOpen(false);
    },
    [handleApplyButton, setIsPopoverOpen]
  );

  return (
    <CustomPopover
      popoverTrigger={popoverTrigger}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      popoverContent={
        <div className={styles.mainContainer}>
          <Calendar
            minDate={new Date()}
            onChange={setValue}
            value={value instanceof Date ? value : new Date(value)}
            prevLabel={
              <img
                className={styles.prevIcon}
                src={expandIcon}
                alt="Previous"
              />
            }
            nextLabel={
              <img className={styles.nextIcon} src={expandIcon} alt="Next" />
            }
          />
        </div>
      }
      handleApplyButton={onApply}
    />
  );
}
