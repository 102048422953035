import React, { useEffect, useState } from "react";
import styles from "./destinationCardWithImages.module.scss";
import { iCityDetailsResult } from "../../../types";
import { arrowNextIcon, multiCityIcon, selectedIcon } from "../../../constant";
import { Skeleton } from "@mui/material";
import { currencyToNumber, getFormattedCurrency } from "../../../utils";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";
import { TripTypes } from "../../../interface";

export default function DestinationCardWithImages({
  destinationList,
  showArrowIcon = true,
  itineraryVersion = 0,
  estimatedPrice = "0",
  multiCity = false,
}: {
  destinationList: iCityDetailsResult[];
  showArrowIcon?: boolean;
  itineraryVersion?: string | number;
  estimatedPrice?: string;
  multiCity: boolean;
}) {
  const [timeOutFlag, setTimeOutFlag] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setTimeOutFlag(false);
    }, 2000);
    // console.log("YES", destinationList);
  }, [destinationList]);
  const selected = itineraryVersion
    ? (destinationList[0]?.checkedFlag && Number(itineraryVersion) > 0) || false
    : true;

  function getName(data: any) {
    let name = data?.reduce(
      (acc: any, ele: iCityDetailsResult) =>
        acc +
        (ele?.destination_name ||
          ele?.destination_city ||
          ele?.city_name ||
          ele?.cityName?.split(",")[0] ||
          "") +
        ", ",
      ""
    );
    name = name.trim();
    return name.length > 25
      ? name.substring(0, 25) + "..."
      : name.substring(0, name.length - 1);
  }

  return (
    <div className={styles.mainContainer}>
      {
        // destinationList.length > 1
        multiCity && (
          <div className={styles.multiCityIconContainer}>
            <img src={multiCityIcon} alt="" />
            <h1>MULTI-CITY</h1>
          </div>
        )
      }
      <div
        className={styles.imageContainer}
        onClick={() => {
          triggerGAEvent(GA_LABEL_ENUMS.top_destination_image_card_click);
        }}
      >
        {destinationList[destinationList.length - 1]?.imageURL ? (
          multiCity ? (
            destinationList.map((ele: iCityDetailsResult) => (
              <img
                src={ele?.imageURL ? ele?.imageURL : "https://error.error"}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/destinationPlaceholder.png";
                  currentTarget.style.objectFit = "cover";
                  currentTarget.style.height = "100%";
                  currentTarget.style.width = `${100 / destinationList.length}%`;
                }}
                style={{ width: `${100 / destinationList.length}%` }}
                className={styles.img}
                // src={ele.imageURL}
                alt="city Image"
              />
            ))
          ) : (
            <img
              src={
                destinationList[0]?.imageURL
                  ? destinationList[0]?.imageURL
                  : "https://error.error"
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/destinationPlaceholder.png";
                currentTarget.style.objectFit = "cover";
                currentTarget.style.height = "100%";
                currentTarget.style.width = `${100}%`;
              }}
              style={{ width: `${100}%` }}
              className={styles.img}
              // src={ele.imageURL}
              alt="city Image"
            />
          )
        ) : (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"100%"}
            height={"100%"}
          />
        )}
      </div>
      <div
        style={{
          backgroundColor: destinationList[destinationList.length - 1]?.imageURL
            ? "rgba(0, 0, 0, 0.7)"
            : "transparent",
        }}
        className={`
        ${styles.placeHeadingContainer}
        ${selected && styles.placeHeadingContainerSelected}
        `}
      >
        <div className={styles.textContainer}>
          <div
            className={`${styles.midContainer}
             ${selected && styles.midContainerSelected}`}
          >
            {selected && Number(itineraryVersion) > 0 ? (
              <div className={styles.versionContainer}>
                <p>{itineraryVersion}</p>
              </div>
            ) : (
                <></>
              //   <Skeleton
              //   variant="circular"
              //   sx={{
              //     width: "50px",
              //     height: "50px",
              //     marginBottom: "5px",
              //   }}
              // />
            )}

            <div
              className={`
              ${
                selected
                  ? styles.cityCountryPriceContainerSelected
                  : styles.cityCountryPriceContainer
              }
              `}
              style={{ marginRight: showArrowIcon ? "30px" : "0px" }}
            >
              <div className={styles.cityNameContainer}>
                {destinationList.length > 1 ? (
                  getName(destinationList) ? (
                    <h1
                      className={styles.placeHeadingMob}
                      style={{ marginTop: "6px" }}
                    >
                      {getName(destinationList)}
                    </h1>
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        borderRadius: "2px",
                        width: "50px",
                        height: "13px",
                        marginBottom: "5px",
                      }}
                    />
                  )
                ) : (
                  <div className={styles.placeHeadingMobContainer}>
                    {destinationList[0]?.destination_name ||
                    destinationList[0]?.destination_city ||
                    destinationList[0]?.city_name ? (
                      <h1 className={styles.placeHeadingMob}>
                        {destinationList[0]?.destination_name ||
                          destinationList[0]?.destination_city ||
                          destinationList[0]?.city_name}
                      </h1>
                    ) : (
                      <Skeleton
                        variant="rectangular"
                        sx={{
                          borderRadius: "2px",
                          width: "50px",
                          height: "13px",
                          marginBottom: "5px",
                        }}
                      />
                    )}
                    {destinationList[0]?.destination_country ||
                    destinationList[0]?.city_country ? (
                      <h2>
                        {destinationList[0]?.destination_country ||
                          destinationList[0]?.city_country}
                      </h2>
                    ) : (
                      <Skeleton
                        variant="rectangular"
                        sx={{
                          borderRadius: "2px",
                          width: "80px",
                          height: "13px",
                          marginBottom: "5px",
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
              <PriceHelperComponent price={Number(estimatedPrice)} />
            </div>
          </div>

          <div style={{ display: showArrowIcon ? "flex" : "none" }}>
            {selected ? (
              <img
                className={styles.selectedIcon}
                // src={selectedIcon}
                src={arrowNextIcon}
                alt="selected"
              />
            ) : (
              <img
                className={styles.nextIcon}
                src={arrowNextIcon}
                alt="arrowNextIcon"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function PriceHelperComponent({ price = 0 }: { price: number }) {
  if (typeof price !== "number" || price === 0)
    return <div className={styles.hidden}>null</div>;
  return (
    <h1
      className={`
    ${styles.priceText}
    `}
    >
      from ${price.toFixed(0)}
    </h1>
  );
}
