import React, { useCallback } from "react";
import NextBreadcrumbBtn from "../component/nextBreadcrumbBtn/NextBreadcrumbBtn";
import styles from "./paymentCrumbsDetails.module.scss";
import {
  bedFontLight,
  hotelRoomIcon,
  outgoingFlightIcon,
} from "../../../constant";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

interface PaymentCrumbsDetailsProps {
  readonly handleContinue: any;
  readonly data: any;
}

const stripePromise = loadStripe("pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3");


export default function PaymentCrumbsDetails({
  handleContinue,
  data,
}: PaymentCrumbsDetailsProps) {
  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    return fetch("/create-checkout-session", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, []);
  const options = {fetchClientSecret};

  if (!data?.flight || !data?.hotel) return <></>;
  return (
    <div className={styles.mainContainer}>
      <div className={styles.summaryContainer}>
        <img src={outgoingFlightIcon} alt="flight" />
        <div className={styles.detailsColumn}>
          <div className={styles.headingContainer}>
            <h1>{data?.flight?.heading}</h1>
          </div>
          {data?.flight?.list?.map((ele: any, index: number) => (
            <div key={index} className={styles.rowContainer}>
              <h2>{ele?.key}</h2>
              <p className={styles.blackFont}>{ele?.value}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.summaryContainer}>
        <img src={bedFontLight} alt="hotel" />
        <div className={styles.detailsColumn}>
          <div className={styles.headingContainer}>
            <h1>{data?.hotel?.heading}</h1>
          </div>
          {data?.flight?.list?.map((ele: any, index: number) => (
            <div key={index} className={styles.rowContainer}>
              <h2>{ele?.key}</h2>
              <p>{ele?.value}</p>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.summaryContainer}>
        <img src={bedFontLight} className={styles.hide} alt="hotel" />
        <div className={styles.detailsColumn}>
          <div className={`${styles.rowContainer} ${styles.discount}`}>
            <h2>Discount</h2>
            <p>-$64.20</p>
          </div>
          <div className={styles.rowContainer}>
            <h3>Due now:</h3>
            <h4>$1,582.80</h4>
          </div>
        </div>
      </div>

      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
      <NextBreadcrumbBtn handleNext={handleContinue} btnText="Pay" />
    </div>
  );
}
