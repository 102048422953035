import React from "react";
import styles from "./flightTabsAccordion.module.scss";
import { getDayOfWeek, getMonthDate } from "../../../../utils/dateUtils";
import { arrowRightSharpLight, planeEnginesLight } from "../../../../constant";
import NewFlightStepperElement from "../NewFlightStepperElement/NewFlightStepperElement";
import CustomAccordion from "../../../reusableComponent/customAccordion/CustomAccordion";

export default function NewFlightTabsAccordion({
  flightData,
  defaultExpanded,
}: {
  flightData?: any;
  defaultExpanded: boolean;
}) {
  const [showAllSteps, setShowAllSteps] = React.useState(true);
  const handleViewDetails = () => {
    setShowAllSteps(!showAllSteps);
  };


  return (
    <div className={styles.main}>
      <div className={styles.flightAccord}>
        <CustomAccordion
          className={styles.customFlightStyles}
          summaryClass={styles.summaryFlightClass}
          accordianDetailsClass={styles.accordFlightDetailsClass}
          defaultExpanded={defaultExpanded}
          hideExpandIcon={false}
          onChange={() => {}}
          // onChange={(_e, isExpanded) => setShowAllSteps(isExpanded)}
          headingComponent={
            <div className={styles.headingFlightComp}>
              <div className={styles.accordTitleCard}>
                <p className={styles.dayAndCity}>
                  {getDayOfWeek(flightData?.departure?.date)}{" "}
                  {getMonthDate(flightData?.departure?.date)}
                </p>

                <p className={styles.accordDesc}>
                  {`${flightData?.departure?.cityName} (${flightData?.departure?.airport})`}{" "}
                  <img
                    src={arrowRightSharpLight}
                    alt="arrow"
                    className={styles.rightArrow}
                  />
                  {`${flightData?.arrival?.cityName} (${flightData?.arrival?.airport})`}
                </p>
              </div>
              {flightData.sectorStatus === "CHANGE REQUESTED" ||
              flightData.sectorStatus === "CANCEL REQUESTED" ? (
                <div className={styles.statusCard}>
                  <p className={styles.inProgress}>In Progress</p>
                </div>
              ) : (
                <div className={styles.statusCard}>
                  {flightData.sectorflowType === "OUTBOUND" && (
                    <img
                      src={planeEnginesLight}
                      alt="plane"
                      className={styles.planeDepartureIcon}
                    />
                  )}
                  {flightData.sectorflowType === "INBOUND" && (
                    <img
                      src={planeEnginesLight}
                      alt="plane"
                      className={styles.planeArrivalIcon}
                    />
                  )}
                </div>
              )}
            </div>
          }
          bodyComponent={
            <div className={styles.stepper}>
              <section className={styles.stepperDetails}>
                {/* <Stepper
                  // steps={i.stepsData}
                  // initialStep={0}
                  // finalStep={i.stepsData.length - 1}
                  steps={flightData?.segments}
                  stops={flightData?.stops}
                  initialStep={0}
                  finalStep={flightData?.length - 1}
                  flightImage={flightImage}
                /> */}

                <NewFlightStepperElement
                  flightData={flightData?.departure}
                  showAllSteps={true}
                  initialStep={true}
                  handleViewDetails={handleViewDetails}
                  airline={flightData?.airline}
                  flightType="Departure"
                  date={flightData?.departure?.date}
                  airportCode={flightData?.departure?.airport}
                  airportName={flightData?.departure?.airportName}
                  pnrNo={flightData?.airlinePnr}
                  flightOperatorData={flightData?.operatingAirline}
                  equipmentType={flightData?.departure?.equipmentType}
                  cabinClass={flightData?.cabinClass}
                />
                {showAllSteps &&
                  flightData?.stops &&
                  flightData?.stops?.map((ele: any, index: number) => (
                    <div key={index} className={styles.stopsDetails}>
                      <NewFlightStepperElement
                        flightData={ele}
                        showAllSteps={showAllSteps}
                        initialStep={false}
                        airline={ele?.airline}
                        flightType="Layover"
                        departureDate={
                          index === 0
                            ? flightData?.departure?.date
                            : flightData?.stops[index - 1]?.departureDate
                        }
                        date={ele?.arrivalDate}
                        airportCode={ele?.airport}
                        airportName={ele?.airportName}
                        equipmentType={ele?.equipmentType}
                        cabinClass={ele?.cabinClass}
                      />
                      <NewFlightStepperElement
                        flightData={ele}
                        showAllSteps={showAllSteps}
                        initialStep={false}
                        airline={ele?.airline}
                        flightType="Departure"
                        date={ele?.departureDate}
                        airportCode={ele?.airport}
                        airportName={ele?.airportName}
                        pnrNo={flightData?.airlinePnr}
                        cabinClass={ele?.cabinClass}
                        flightOperatorData={ele?.operatingAirline}
                        equipmentType={ele?.equipmentType}
                      />
                    </div>
                  ))}
                <NewFlightStepperElement
                  flightData={flightData?.arrival}
                  showAllSteps={showAllSteps}
                  initialStep={false}
                  airline={flightData?.airline}
                  flightType="Arrival"
                  lastStop={true}
                  departureDate={
                    flightData?.stops?.length > 0
                      ? flightData?.stops[flightData?.stops?.length - 1]
                          ?.departureDate
                      : ""
                  }
                  date={flightData?.arrival?.date}
                  airportCode={flightData?.arrival?.airport}
                  airportName={flightData?.arrival?.airportName}
                  equipmentType={flightData?.arrival?.equipmentType}
                  cabinClass={flightData?.cabinClass}
                />
              </section>
              <div className={styles.divider} />
            </div>
          }
          zeroMarginOnExpand
          borderBottom
        />
      </div>
    </div>
  );
}
