import React, { useCallback, useContext } from "react";
import LocationAndDatePicker from "./locationAndDatePicker/locationAndDatePicker";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import NewTravelers from "../../../../component/booking/inputFields/newTravelers/newTravelers";
import AppButton from "../../../../component/button/button";
import styles from "./hotel.module.scss";

interface HotelInputFieldsProps {
  className?: string;
  handleUpdateTravelData: () => {};
  searchHotels: () => void;
}

const NewHotelInputFields: React.FC<HotelInputFieldsProps> = ({
  className,
  handleUpdateTravelData,
  searchHotels,
}) => {
  const state = useContext(StateContext);
  const { bookingDetails, setBookingDetails }: iGlobalContext = state;

  const handleSearch = useCallback(async () => {
    try {
      await handleUpdateTravelData();
      searchHotels();
    } catch (error) {
      console.error("An error occurred during the search process:", error);
    }
  }, [handleUpdateTravelData, searchHotels]);


  return (
    <div className={`${className} ${styles.main}`}>
      {bookingDetails.hotels.length > 1 && (
        <div className={styles.travelCard}>
          <NewTravelers
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            showRoomAndTravellers={false}
            showOnlyRoom={false}
            showInstrcutions={true}
          />
          <NewTravelers
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            showRoomAndTravellers={false}
            showOnlyRoom={true}
            showInstrcutions={false}
          />
        </div>
      )}
      <div className={styles.location}>
        <LocationAndDatePicker />
      </div>
      {bookingDetails.hotels.length === 1 && (
        <div className={styles.travelCard}>
          <NewTravelers
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            showRoomAndTravellers={false}
            showOnlyRoom={false}
            showInstrcutions={true}
          />
          <NewTravelers
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            showRoomAndTravellers={false}
            showOnlyRoom={true}
            showInstrcutions={false}
          />
        </div>
      )}

      <div className={styles.searchBtn}>
        <AppButton size="md" onClick={handleSearch} disabled={false}>
          Search
        </AppButton>
      </div>
    </div>
  );
};

export default NewHotelInputFields;
