import { useEffect, useState } from "react";
import { BookingDetails, TripOption } from "../../../../types";
import styles from "./route.module.scss";
import RouteClassPopover from "../../bookingPopover/routeClassPopover/RouteClassPopover";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../constant/google-analytics/GAEvents";

interface iProps {
  className?: string;
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
  tripType: number;
}
function RouteAndCabinInput({
  className,
  bookingDetails,
  setBookingDetails,
  tripType,
}: iProps): JSX.Element {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const data: TripOption[] = [
    { id: 1, value: "One-Way", checkedFlag: false },
    { id: 2, value: "Round-Trip", checkedFlag: false },
    { id: 3, value: "Multi-City", checkedFlag: false },
  ];

  const filterByTripType = (
    tripType: number,
    data: TripOption[]
  ): TripOption[] => {
    if (tripType === 3) {
      return data.filter((item) => item.id === 3);
    } else if (tripType === 1 || tripType === 2) {
      return data.filter((item) => item.id === 1 || item.id === 2);
    }
    return [];
  };

  const filteredData = filterByTripType(tripType, data);

  function updateTripType(tripType: number, data: TripOption[]): TripOption[] {
    return data.map((item) => ({
      ...item,
      checkedFlag: item?.id === tripType,
    }));
  }
  const updatedData = updateTripType(tripType, filteredData);

  const [routeData, setRouteData] = useState(updatedData);

  const [classData, setClassData] = useState([
    {
      id: 2,
      value: "Economy",
      checkedFlag: true,
    },
    {
      id: 3,
      value: "Premium Plus",
      checkedFlag: false,
    },
    {
      id: 4,
      value: "Business",
      checkedFlag: false,
    },
    {
      id: 5,
      value: "First Class",
      checkedFlag: false,
    },
  ]);

  const selectedValues = classData
    .filter((item) => item.checkedFlag)
    .map((item) => item.value)
    .join(", ");
  const selectedRouteValues = routeData
    .filter((item) => item.checkedFlag)
    .map((item) => item.value)
    .join(", ");

  useEffect(() => {
    if (bookingDetails) {
      setBookingDetails((prevData) => ({
        ...prevData,
        tripType: tripType,
      }));
    }
  }, [tripType]);

  useEffect(() => {
    if (bookingDetails.flightClass) {
      const mappedClassData = classData.map((ele: any) => {
        let match = false;
        switch (bookingDetails.flightClass) {
          case "E":
            match = ele.value === "Economy";
            break;
          case "P":
            match = ele.value === "Premium Plus";
            break;
          case "B":
            match = ele.value === "Business";
            break;
          case "F":
            match = ele.value === "First Class";
            break;
          default:
            match = false;
        }
        return { ...ele, checkedFlag: match };
      });

      setClassData(mappedClassData);
    }
  }, [bookingDetails.flightClass]);

  return (
    <div className={`${className} ${styles.main}`}>
      {bookingDetails && (
        <RouteClassPopover
          route={routeData}
          setRoute={setRouteData}
          classData={classData}
          setClass={setClassData}
          bookingDetails={bookingDetails}
          setBookingDetails={setBookingDetails}
          isPopoverOpen={isPopoverOpen}
          setIsPopoverOpen={setIsPopoverOpen}
          popoverTrigger={
            <div className={styles.container}>
              <p className={styles.label}>Route & Class</p>
              <div
                className={`${isPopoverOpen ? styles.activeTravelBtn : styles.travelBtn}`}
                onClick={() => {
                  triggerGAEvent(GA_LABEL_ENUMS.booking_route_and_class_click);
                }}>
                <div className={styles.travelersCard}>
                  <div className={styles.travelerBtn}>
                    <span className={styles.totalTravelers}>
                      {selectedRouteValues}
                    </span>{" "}
                    <span className={styles.travelerLabel}>
                      {selectedValues}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
}

export default RouteAndCabinInput;
