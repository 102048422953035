import React, { useCallback, useContext, useState } from "react";
import styles from "./RoomWithImages.module.scss";
import ImageSlider from "../../imageSlider/imageSlider";
import {
  circleCheckIcon,
  circleInfoLight,
  expandIcon,
  eyeLight,
  greenOutlinedTickMark,
  newBlueCheckIcon,
} from "../../../../../constant";
import {
  iGlobalContext,
  iSelectedHotelRoom,
} from "../../../../../context/globalContext/interface";
import AppButton from "../../../../button/button";
import { HOTEL_CARD_TYPES } from "../../../../../enums";
import { roundValue } from "../../../../../utils";
import { StateContext } from "../../../../../context/globalContext/context";

interface iProps {
  room: any;
  setShowRoomDetails: (value: React.SetStateAction<boolean>) => void;
  setRoomDetailsData: (value: any) => void;
  selectedHotelRoom: iSelectedHotelRoom | null | undefined;
  isChecking: boolean;
  hotelCardType?: HOTEL_CARD_TYPES;
  checkPostHotelAvailability?: (
    hotelUniqueId: string,
    sessionId: string,
    amountDue: number
  ) => Promise<void>;
  checkHotelAvailability?: (
    hotelUniqueId: string,
    sessionId: string,
    type: string,
    price: number,
    actualPrice: number,
    feesIncluded: boolean,
    taxIncluded: boolean,
    roomName: string,
    bedInformation: string
  ) => Promise<void>;
  sessionId: string;
  hotelDetails?: any;
}

function RoomWithImages(props: iProps): JSX.Element {
  const {
    room,
    setShowRoomDetails,
    setRoomDetailsData,
    selectedHotelRoom,
    checkHotelAvailability,
    checkPostHotelAvailability,
    isChecking,
    sessionId,
    hotelCardType = HOTEL_CARD_TYPES.ADDRESSS,
    hotelDetails,
  } = props || {};
  const globalContext = useContext(StateContext);
  const { setIsCancPolModalOpen, setCancellationData }: iGlobalContext =
    globalContext || {};
  const {
    roomImages = [],
    roomName = "",
    roomPrices: roomTypes = [],
    roomId = "",
    bedInformation = [],
  } = room || {};

  const [roomPricesLength, setRoomPricesLength] = useState(1);

  const handleCancellationPolicy = useCallback(
    (cancellationPolicy: string) => {
      if (cancellationPolicy) {
        setCancellationData(cancellationPolicy);
        setIsCancPolModalOpen(true);
      }
    },
    [setCancellationData, setIsCancPolModalOpen]
  );

  return (
    <div key={roomId} className={styles.mainRoomContainer}>
      {roomImages.length > 0 && (
        <ImageSlider
          className={styles.imageClass}
          noPaddSwipeBtn
          imagesArr={roomImages.map((img: any) => img.url)}
        />
      )}

      <div className={styles.viewRoomDetailsContainer}>
        <p className={styles.roomType}>
          {roomName}{" "}
          {bedInformation?.reduce(
            (acc: string, item: any) =>
              acc + `, ${item.quantity} ${item.bedType}`,
            ""
          )}
        </p>
        <div
          className={styles.amenitiesFooter}
          // onClick={() => {}}
          onClick={() => {
            // setViewRoomDetailsModal(true);
            setShowRoomDetails(true);
            setRoomDetailsData(room);
          }}
        >
          VIEW ROOM DETAILS
          <img src={eyeLight} alt="icon" className={styles.amenityFooterIcon} />
        </div>
      </div>

      {roomTypes.slice(0, roomPricesLength).map((item: any) => {
        const {
          rateType: type,
          // includesArr = [],
          sellingPrice: price,
          cancellationPolicy: discountValidity = "",
          suggestedSellingPrice: actualPrice = "",
          taxIncluded = "",
          feesIncluded = "",
          uniqueId = "",
          roomOnly = false,
          amountDue,
        } = item || {};

        const discountPercentage = Math.round(
          ((actualPrice - price) * 100) / actualPrice
        );
        return (
          <div key={uniqueId} className={styles.main}>
            <div className={styles.container}>
              <div className={styles.contentCard}>
                <div className={styles.hotelListCard}>
                  <div className={styles.card1}>
                    <div
                      className={`${styles.typeName} ${type === "Non-refundable" ? "" : styles.refundableType}`}
                      onClick={() => handleCancellationPolicy(discountValidity)}
                    >
                      {type}
                      {type !== "Non-refundable" && (
                        <img
                          src={circleInfoLight}
                          className={styles.tickMarkIcon}
                          alt="green tick mark"
                        />
                      )}
                    </div>
                    {!roomOnly && (
                      <div className={styles.discountAndPriviliges}>
                        {/* {discountValidity && (
                        <div className={styles.discountValidity}>
                          {discountValidity}
                        </div>
                      )} */}
                        {/* {!roomOnly && ( */}
                        <div className={styles.includesArrContainer}>
                          {/* {includesArr.map((eachItem: any, idx: any) => {
                            return ( */}
                          <div className={styles.eachIncludes}>
                            <img
                              src={circleCheckIcon}
                              className={styles.tickMarkIcon}
                              alt="green tick mark"
                            />
                            Breakfast included
                          </div>
                          {/* );
                          })} */}
                        </div>
                      </div>
                    )}
                  </div>
                  {hotelCardType === HOTEL_CARD_TYPES.ROOM ? (
                    <div className={styles.card3Andcard4Container}>
                      {uniqueId !== hotelDetails?.selectedHotelIds?.uniqueId ? (
                        <AppButton
                          className={styles.bookBtn}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (isChecking) return;
                            if (checkPostHotelAvailability)
                              checkPostHotelAvailability(
                                uniqueId,
                                sessionId,
                                amountDue
                              );
                            if (checkHotelAvailability)
                              checkHotelAvailability(
                                uniqueId,
                                sessionId,
                                type,
                                price,
                                actualPrice,
                                feesIncluded,
                                taxIncluded,
                                roomName,
                                `${bedInformation?.reduce(
                                  (acc: string, item: any, idx: number) =>
                                    acc +
                                    `${item.quantity} ${item.bedType}${idx === bedInformation?.length - 1 ? "" : ","} `,
                                  ""
                                )}`
                              );
                          }}
                        >
                          {`${amountDue > 0 ? "+" : ""} ${amountDue < 0 ? "-" : ""}$${Math.round(Math.abs(amountDue))}`}
                        </AppButton>
                      ) : (
                        <>
                          <div className={styles.verticalDivider} />
                          <div
                            className={`${styles.selectedHotelPriceContainer}`}
                          >
                            <img
                              src={newBlueCheckIcon}
                              alt="check icon"
                              className={styles.circleCheckIcon}
                            />
                            <div className={styles.selectedPriceContainer}>
                              <p className={styles.hotelPrice}>
                                {amountDue ? `$${roundValue(amountDue)}` : ""}
                              </p>
                              <p className={styles.taxAndFeesContainer}>
                                {taxIncluded === "yes" &&
                                  feesIncluded &&
                                  "incl taxes & fees"}
                                {taxIncluded === "yes" &&
                                  !feesIncluded &&
                                  "incl taxes & excl fees"}
                                {taxIncluded !== "yes" &&
                                  feesIncluded &&
                                  "excl taxes & incl fees"}
                                {taxIncluded !== "yes" &&
                                  !feesIncluded &&
                                  "excl taxes & fees"}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className={styles.card3Andcard4Container}>
                      {uniqueId !== selectedHotelRoom?.selectedRoomId ? (
                        <AppButton
                          className={styles.bookBtn}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (isChecking) return;
                            if (checkPostHotelAvailability)
                              checkPostHotelAvailability(
                                uniqueId,
                                sessionId,
                                amountDue
                              );
                            if (checkHotelAvailability)
                              checkHotelAvailability(
                                uniqueId,
                                sessionId,
                                type,
                                price,
                                actualPrice,
                                feesIncluded,
                                taxIncluded,
                                roomName,
                                `${bedInformation?.reduce(
                                  (acc: string, item: any, idx: number) =>
                                    acc +
                                    `${item.quantity} ${item.bedType}${idx === bedInformation?.length - 1 ? "" : ","} `,
                                  ""
                                )}`
                              );
                          }}
                        >
                          {`$${Math.round(price)}`}
                        </AppButton>
                      ) : (
                        <>
                          <div className={styles.verticalDivider} />
                          <div
                            className={`${styles.selectedHotelPriceContainer}`}
                          >
                            <img
                              src={newBlueCheckIcon}
                              alt="check icon"
                              className={styles.circleCheckIcon}
                            />
                            <div className={styles.selectedPriceContainer}>
                              <p className={styles.hotelPrice}>
                                {selectedHotelRoom?.price
                                  ? `$${roundValue(selectedHotelRoom?.price)}`
                                  : ""}
                              </p>
                              <p className={styles.taxAndFeesContainer}>
                                {selectedHotelRoom?.taxIncluded === "yes" &&
                                  selectedHotelRoom?.feesIncluded &&
                                  "incl taxes & fees"}
                                {selectedHotelRoom?.taxIncluded === "yes" &&
                                  !selectedHotelRoom?.feesIncluded &&
                                  "incl taxes & excl fees"}
                                {selectedHotelRoom?.taxIncluded !== "yes" &&
                                  selectedHotelRoom?.feesIncluded &&
                                  "excl taxes & incl fees"}
                                {selectedHotelRoom?.taxIncluded !== "yes" &&
                                  !selectedHotelRoom?.feesIncluded &&
                                  "excl taxes & fees"}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {roomTypes.length > 1 && roomPricesLength < roomTypes.length && (
        <p
          className={styles.showMoreContainer}
          onClick={() =>
            setRoomPricesLength(
              roomTypes.length - roomPricesLength > 3
                ? roomPricesLength + 3
                : roomTypes.length
            )
          }
        >
          Show{" "}
          {roomTypes.length - roomPricesLength > 3
            ? "3"
            : roomTypes.length - roomPricesLength}{" "}
          more {roomTypes.length - roomPricesLength !== 1 ? "rates" : "rate"}
          <img
            src={expandIcon}
            className={styles.expandIcon}
            alt="expand-icon"
          />
        </p>
      )}
      {roomTypes.length > 1 && roomPricesLength === roomTypes.length && (
        <p
          className={styles.showMoreContainer}
          onClick={() => setRoomPricesLength(1)}
        >
          Show less
          <img
            src={expandIcon}
            className={styles.rotateExpandIcon}
            alt="expand-icon"
          />
        </p>
      )}
    </div>
  );
}

export default RoomWithImages;
