import React, { useCallback, useContext, useRef, useState } from "react";
import CustomAccordion from "../../../reusableComponent/customAccordion/CustomAccordion";
import {
  formatToYYYYMMDD,
  getDayOfWeek,
  getMonthDate,
} from "../../../../utils/dateUtils";
import { PathIcon, planeEnginesLight, ROUTES } from "../../../../constant";
import AppButton from "../../../button/button";
import FlightDetails from "../flightDetails/flightDetail";
import { Transaction } from "../../../../types";
import CustomNewCheckBox from "../../../reusableComponent/customNewCheckBox/customNewCheckBox";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import { getAirports } from "../../../../api/booking/booking";
import { ScreenTypes } from "../../../../enums";
import styles from "./modify.module.scss";
import { handleTripType } from "../../../../utils/booking/tripTypeHandler";
import { handleFlightClass } from "../../../../utils/booking/flight/handleFlightClass";
import { cancelFlight } from "../../../../api/booking/postBooking";
import useCustomErrorHandler from "../../../../hooks/useCustomErrorHandler";
import { toastMessage } from "../../../../utils/ToastProvider";
import { TOAST_MESSAGES } from "../../../../constant/messages/toastMessages";
import DiscardChanges from "../../../dialog/discardChanges/discardChanges";
import {
  CityInfo,
  ModifyPostBookingAirportPayload,
} from "../../../../utils/postBooking/modifyPostBookingAirportPayload";
interface FlightDataProps {
  readonly flightDetails: Transaction[];
  readonly selectedValue: string | number;
}
function ModifyFlights(props: FlightDataProps): JSX.Element {
  const { flightDetails, selectedValue } = props;
  const navigate = useNavigate();
  const state = useContext(StateContext);
  const {
    setBookingDetails,
    setSelectedFlightOptions,
    setAirPorts,
    selectedChatData,
    isMobile,
    setScreen,
    itineraryId,
    bookingDetails,
    userInfo,
    setFlightChange,
    setModifyFlightStatus,
    setIsFetchingAirports,
    tripOrItinearyId,
  }: iGlobalContext = state;
  const [selectedFlights, setSelectedFlights] = useState<Transaction[]>([]);
  const EnableModifyButton = selectedFlights.length > 0;
  const [open, setOpen] = useState(false);
  let fetchAirportController: any = useRef(null);
  const { handleCustomError } = useCustomErrorHandler();
  const handleSelection = useCallback((flightData: Transaction) => {
    setSelectedFlights((prevSelected) => {
      const isAlreadySelected = prevSelected.some(
        (selected) => selected.transactionId === flightData.transactionId
      );
      if (isAlreadySelected) {
        // Remove flight from the selected list
        return prevSelected.filter(
          (selected) => selected.transactionId !== flightData.transactionId
        );
      } else {
        // Add flight to the selected list
        return [...prevSelected, flightData];
      }
    });
  }, []);

  const getAirportData = useCallback(
    async (city: CityInfo[][], selectionType: string) => {
      setIsFetchingAirports(true);
      const processCity = (cityData: CityInfo[][]): CityInfo[] => {
        return cityData.flat().map((item) => ({
          ...item,
          latitude: item.latitude?.slice(0, 7) ?? "",
          longitude: item.longitude?.slice(0, 7) ?? "",
          name: item.name ?? "",
          countryCode: item.countryCode ?? "",
        }));
      };
      const cityData = processCity(city);
      if (!cityData || cityData?.length === 0) {
        setBookingDetails((prevData) => ({
          ...prevData,
          hideFlights: true,
        }));
        return;
      }

      const requestBody = {
        cities: cityData,
        radius: "100 miles",
      };
      fetchAirportController.current = new AbortController();
      try {
        const data = await getAirports(
          requestBody,
          fetchAirportController.current
        );
        if (fetchAirportController.current.signal.aborted) {
          return null;
        }
        setAirPorts(data);
        setIsFetchingAirports(false);
        if (selectionType === "MODIFY") {
          if (isMobile) {
            navigate(ROUTES.ModifyFlights);
          } else {
            setScreen(ScreenTypes.modifyFlight);
          }
        }
      } catch (err) {
        setAirPorts([]);
        setIsFetchingAirports(false);
        console.error("Failed to fetch flight data");
        return [];
      }
    },
    [
      isMobile,
      navigate,
      setAirPorts,
      setBookingDetails,
      setIsFetchingAirports,
      setScreen,
    ]
  );

  const handleModifyButton = useCallback(
    async (selectionType: string) => {
      const transformedAirPortData = selectedFlights.map((flight) => ({
        transactionId: flight?.transactionId,
        pnr: flight?.airlinePnr,
        arrival: flight?.arrival?.airport,
        departure: flight?.departure?.airport,
        fromCity: flight?.departure?.cityName ?? "",
        destinationCity: flight?.arrival?.cityName ?? "",
        departureDate: formatToYYYYMMDD(flight?.departure?.date ?? ""),
        returnDate: "",
        departureAirportFullName: flight.departure.airportName,
        arrivalAirportFullName: flight.arrival.airportName,
        arrivalCityLatitude: flight?.arrival?.latitude ?? "",
        arrivalCityLongitude: flight?.arrival?.longitude ?? "",
        departureCityLatitude: flight?.departure?.latitude ?? "",
        departureCityLongitude: flight?.departure?.longitude ?? "",
        noOfDays: 0,
        arrivalCityDetails: flight?.arrival?.cityName ?? "",
        departureCityDetails: flight?.departure?.cityName ?? "",
        arrivalCountryCode: flight?.arrival?.countryCode ?? "",
        arrivalCountryName: flight?.arrival?.countryName ?? "",
        departureCountryCode: flight?.departure.countryCode ?? "",
        departureCountryName: flight?.departure.countryName ?? "",
      }));
      const cityData = ModifyPostBookingAirportPayload(transformedAirPortData);
      setSelectedFlightOptions((prevData) => ({
        ...prevData,
        flights: true,
        hotels: false,
      }));
      setBookingDetails((prevData) => ({
        ...prevData,
        tripType: handleTripType(selectedChatData?.trip_type),
        flightClass: handleFlightClass(selectedFlights[0]?.tripType ?? ""),
        postBooking: transformedAirPortData,
      }));
      if (selectionType === "CANCEL") {
        setOpen(true);
        return;
      }
      getAirportData(cityData, selectionType);
    },
    [
      selectedFlights,
      setSelectedFlightOptions,
      setBookingDetails,
      getAirportData,
      selectedChatData?.trip_type,
    ]
  );

  const handleCancelFlight = useCallback(async () => {
    if (!itineraryId) {
      console.error("Itinerary ID provided.");
      return;
    }

    const segments = bookingDetails.postBooking
      .map((airport) => {
        if (!airport?.departureDate || !airport?.transactionId) {
          console.error("Missing departure date or transaction ID.");
          return null;
        }
        return {
          transactionId: airport.transactionId,
          pnr: airport?.pnr,
        };
      })
      .filter(Boolean);

    if (segments.length === 0) {
      console.error("No valid segments to modify.");
      return;
    }
    setIsFetchingAirports(true);
    setOpen(false);
    if (!tripOrItinearyId || !userInfo?.id || !itineraryId) {
      setIsFetchingAirports(false);
      return;
    }

    const requestBody = {
      id: tripOrItinearyId,
      userId: userInfo?.id,
      itineraryId: itineraryId,
      segments,
    };

    try {
      const flightCancel = await cancelFlight(requestBody);
      if (flightCancel?.statusCode !== 200) {
        setOpen(false);
        handleCustomError(flightCancel?.statusCode, "cancel.flight");
        toastMessage.error(
          TOAST_MESSAGES.ApiBreakdown.messageTitle,
          TOAST_MESSAGES.ApiBreakdown.message
        );
        return null;
      }
      toastMessage.newSuccess(
        TOAST_MESSAGES.PostBooking.flightCancelRequest.messageTitle,
        TOAST_MESSAGES.PostBooking.flightCancelRequest.message
      );
      setModifyFlightStatus("CANCEL REQUESTED");
      setFlightChange({ flightCancel: true, flightChange: false });
      if (isMobile) {
        navigate(ROUTES.TripDetails);
        setScreen(ScreenTypes.TripDetails);
      }
      setScreen(ScreenTypes.TripDetails);
      console.log(flightCancel);
      setFlightChange({ flightCancel: true, flightChange: false });

      setIsFetchingAirports(false);
    } catch (error) {
      console.error("Error modifying flight:", error);
      setOpen(false);
      setIsFetchingAirports(false);
    }
  }, [
    itineraryId,
    bookingDetails.postBooking,
    setIsFetchingAirports,
    userInfo?.id,
    setModifyFlightStatus,
    setFlightChange,
    isMobile,
    setScreen,
    handleCustomError,
    navigate,
  ]);
  console.log("flightDetails", flightDetails);

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <DiscardChanges
          open={open}
          onClose={() => setOpen(false)}
          handleDiscard={handleCancelFlight}
          hideDialogTitle={true}
          cancelButtonTitle="No"
          discardButtonLabel="Yes"
          message="Cancel flights?"
          cancelFLight={true}
        />
        {flightDetails.map((flightData, index) => (
          <CustomAccordion
            key={index}
            className={styles.customFlightStyles}
            summaryClass={styles.summaryFlightClass}
            accordianDetailsClass={styles.accordFlightDetailsClass}
            defaultExpanded={true}
            hideExpandIcon={true}
            onChange={() => {}}
            headingComponent={
              <div className={styles.headingFlightComp}>
                <div className={styles.accordTitleCard}>
                  <p className={styles.dayAndCity}>
                    {getDayOfWeek(flightData?.departure?.date)}{" "}
                    {getMonthDate(flightData?.departure?.date)}
                  </p>

                  <p className={styles.accordDesc}>
                    {`${flightData?.departure?.cityName} (${flightData?.departure?.airport})`}{" "}
                    -{" "}
                    {`${flightData?.arrival?.cityName} (${flightData?.arrival?.airport})`}
                  </p>
                </div>
                {flightData?.sectorflowType === "OUTBOUND" && (
                  <img
                    src={planeEnginesLight}
                    alt="plane"
                    className={styles.planeDepartureIcon}
                  />
                )}
                {flightData?.sectorflowType === "INBOUND" && (
                  <img
                    src={planeEnginesLight}
                    alt="plane"
                    className={styles.planeArrivalIcon}
                  />
                )}
              </div>
            }
            bodyComponent={
              <div className={styles.flightDetails}>
                {(flightData?.sectorStatus === "CANCEL REQUESTED" ||
                  flightData?.sectorStatus === "CHANGE REQUESTED") ? <div className={styles.hideCheck}></div>: (
                    <CustomNewCheckBox
                      name={flightData?.transactionId}
                      checked={selectedFlights?.some(
                        (selected) =>
                          selected?.transactionId === flightData?.transactionId
                      )}
                      onChange={() => {
                        handleSelection(flightData);
                      }}
                    />
                  )}
                <div
                  className={`${
                    selectedFlights.some(
                      (selected) =>
                        selected.transactionId === flightData.transactionId
                    )
                      ? styles.flightCard
                      : styles.inActive
                  }`}>
                  <div className={styles.flightSubCard}>
                    <FlightDetails
                      airportCode={flightData?.departure?.airport}
                      airportName={flightData?.departure?.airportName}
                      date={flightData?.departure?.date}
                      flightType="departure"
                      cabinClass={flightData?.cabinClass}
                    />
                  </div>
                  {flightData?.stops.map((airport, index) => (
                    <div key={index} className={styles.flightCard2}>
                      <img src={PathIcon} alt="" className={styles.backIcon} />
                      <FlightDetails
                        airportCode={airport.airport}
                        airportName={airport.airportName}
                        date={airport.departureDate}
                        flightType="arrival"
                      />
                    </div>
                  ))}
                  <div className={styles.flightCard2}>
                    <img src={PathIcon} alt="" className={styles.backIcon} />
                    <FlightDetails
                      airportCode={flightData?.arrival?.airport}
                      airportName={flightData?.arrival?.airportName}
                      date={flightData?.arrival?.date}
                      flightType="arrival"
                    />
                  </div>
                </div>
              </div>
            }
            zeroMarginOnExpand
            borderBottom
          />
        ))}
        <div className={styles.modifyCard}>
          <p className={styles.info}>
            {EnableModifyButton
              ? ""
              : "Tap above to select all flights to modify"}
          </p>

          {selectedValue === "CANCEL" ? (
            <AppButton
              disabled={!EnableModifyButton}
              onClick={() => handleModifyButton("CANCEL")}
              variant={!EnableModifyButton ? "disabled" : "cancel"}>
              Cancel Selected
            </AppButton>
          ) : (
            <AppButton
              disabled={!EnableModifyButton}
              onClick={() => handleModifyButton("MODIFY")}>
              Modify Selected
            </AppButton>
          )}
          <p className={styles.subInfo}>and ignore unselected flights</p>
        </div>
      </div>
    </div>
  );
}

export default ModifyFlights;
