import React, { useCallback, useContext, useState } from "react";
import styles from "./hotels.module.scss";
import {
  bedFontLight,
  expandIcon,
  ROUTES,
  personWalkingLuggage,
} from "../../../constant";
// import { useNavigate } from "react-router-dom";
import BookedHotelAccordion from "../../reusableComponent/bookedHotelCardList/BookedHotelAccordion";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";
import CustomAccordion from "../../reusableComponent/customAccordion/CustomAccordion";
import Popover from "./components/popover/popover";
import { MANAGE_HOTEL_CONSTANTS } from "./hotelsConstants";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import AppButton from "../../button/button";
import { useNavigate } from "react-router-dom";
import { BOOKING_STATUS, ScreenTypes } from "../../../enums";
import PaymentDetails from "./components/paymentDetails/PaymentDetails";
import TravelerDetails from "../travelerDetails/travelerDetails";
import { getPassengerCount } from "../../../utils";

interface HotelsTabsProps {
  readonly hotelSummary?: any;
  readonly hotelPayment?: any;
  readonly cardDetails: any;
  readonly travellersInfo: any;
}

export default function HotelsTabs({
  hotelSummary,
  hotelPayment,
  cardDetails,
  travellersInfo,
}: HotelsTabsProps) {
  const globalContext = useContext(StateContext);
  const {
    manageHotelsArr,
    setManageHotelsArr,
    isMobile,
    setScreen,
    setShowCancelHotelsModal,
  }: iGlobalContext = globalContext;
  const [modifyHotels, setModifyHotels] = useState(false);
  const [cancelHotels, setCancelHotels] = useState(false);
  const navigate = useNavigate();

  // const navigate = useNavigate();
  // console.log("response?.data.hotel", hotelPayment);

  const handleManageHotels = useCallback((name: string) => {
    if (name === "MODIFY") {
      setModifyHotels(true);
      setCancelHotels(false);
    }
    if (name === "CANCEL") {
      setModifyHotels(false);
      setCancelHotels(true);
    }
  }, []);

  const handleCheckboxChange = (hotel: any): any => {
    const { hotelBookId, bookingStatus } = hotel || {};
    if (!modifyHotels && !cancelHotels) return;
    if (bookingStatus === BOOKING_STATUS.Cancelled) return;
    if (
      manageHotelsArr
        .map((item: any) => item?.hotelBookId)
        .includes(hotelBookId)
    ) {
      setManageHotelsArr((prev: any) =>
        prev.filter((ele: any) => ele?.hotelBookId !== hotelBookId)
      );
    } else {
      setManageHotelsArr((prev: any) => [...prev, hotel]);
    }
  };

  const ifAnyOfTheHotelsSelected =
    Array.isArray(manageHotelsArr) &&
    manageHotelsArr.length > 0 &&
    manageHotelsArr
      .map((item) => item?.hotelBookId)
      .every((hotelItemId: any) =>
        hotelSummary.some((item: any) => item.hotelBookId === hotelItemId)
      );

  const handleModifyHotels = useCallback(() => {
    if (modifyHotels) {
      if (isMobile) navigate(ROUTES.ModifyHotelReservation);
      if (!isMobile) setScreen(ScreenTypes.modifyHotels);
    }
    if (cancelHotels) {
      setModifyHotels(false);
      setCancelHotels(false);
      setShowCancelHotelsModal(true);
    }
  }, [
    cancelHotels,
    isMobile,
    modifyHotels,
    navigate,
    setScreen,
    setShowCancelHotelsModal,
  ]);

  return (
    <>
      <header className={styles.header}>
        <div className={styles.headerBtnContainer}>
          <img
            src={bedFontLight}
            alt="plane"
            className={styles.planeDepartureIcon}
          />
          <p className={styles.headerBtn}>Hotels</p>
          {(modifyHotels || cancelHotels) && (
            <p className={styles.modfiyHeader}>
              SELECT HOTELS TO {modifyHotels ? "MODIFY" : "CANCEL"}
            </p>
          )}
        </div>

        <Popover
          popOverElement={
            <div className={styles.constContainer}>
              {MANAGE_HOTEL_CONSTANTS.map((ele) => {
                return (
                  <div
                    key={ele.id}
                    onClick={() => handleManageHotels(ele?.name)}
                    className={styles.dropDownRowContainer}
                  >
                    <p className={styles.titleName}>{ele?.name}</p>{" "}
                    <img src={ele?.icon} alt="" className={styles.iconStyle} />
                  </div>
                );
              })}
            </div>
          }
        >
          {" "}
          <div className={styles.viewBtn}>
            <p className={styles.viewTitle}>MANAGE HOTELS</p>{" "}
            <img src={expandIcon} alt="" className={styles.expIcon} />
          </div>
        </Popover>
        {/* <Button
          className={styles.headerBtn}
          onClick={() =>
            triggerGAEvent(
              GA_LABEL_ENUMS.trip_details_hotel_cancel_option_click
            )
          }
          endIcon={
            <img src={redBedIcon} alt="img" className={styles.flightIcon} />
          }
        >
          Cancel Options
        </Button>
        <Button
          onClick={() =>
            triggerGAEvent(GA_LABEL_ENUMS.trip_details_hotel_change_hotel_click)
          }
          className={styles.headerBtn}
          endIcon={
            <img src={modifyEditIcon} alt="img" className={styles.editIcon} />
          }
        >
          Change Hotel
        </Button> */}
      </header>
      <div className={styles.flightAccord}>
        {hotelSummary?.length > 0 ? (
          hotelSummary?.map((hotel: any, index: number) => {
            // const {
            //   checkIn = "",
            //   checkOut = "",
            //   city = "",
            //   hotelInformation = {},
            //   roomInfo = {},
            // } = ele;
            // const { stars, name, hotelMainImage, address, hotelId } =
            //   hotelInformation;
            // const { noOfRooms, bedInformation = [] } = roomInfo;
            const {
              // hotelMainImage,
              // stars,
              // name,
              // address,
              hotelInformation,
              amenities,
              totalPrice,
              cancellationPolicy,
              // hotelBookId,
              checkIn = "",
              checkOut = "",
              city = "",
            } = hotel;
            const {
              stars,
              name,
              hotelMainImage,
              address,
              hotelId,
              rating,
              reviewCount,
              reviewHeading,
            } = hotelInformation;
            return (
              <BookedHotelAccordion
                key={index}
                checkIn={checkIn}
                checkOut={checkOut}
                isHotelBeingModifiedOrCancelled={modifyHotels || cancelHotels}
                // stars={stars}
                // name={name}
                // hotelMainImage={hotelMainImage}
                // address={address}
                // noOfRooms={noOfRooms}
                // bedInformation={bedInformation}
                // city={city}
                // hotelId={hotelId}
                id={index}
                hotelMainImage={hotelMainImage}
                star={stars}
                hotelName={name}
                hotelLocation={address}
                hotelRating={rating}
                reviewCount={reviewCount}
                cost={totalPrice?.price}
                cancellation={cancellationPolicy}
                Amenity={amenities}
                hotelList={true}
                discount={""}
                discountCost={""}
                taxesIncluded={totalPrice?.taxesIncluded}
                reviewHeading={reviewHeading}
                hotelId={hotelId}
                hotelDetails={hotel}
                city={city}
                handleCheckboxChange={() => handleCheckboxChange(hotel)}
                hotelAccordionClick={() =>
                  triggerGAEvent(
                    GA_LABEL_ENUMS.trip_details_hotel_accordion_click
                  )
                }
                hotelViewDetailsClick={() =>
                  triggerGAEvent(
                    GA_LABEL_ENUMS.trip_details_hotel_view_deatils_accordion_click
                  )
                }
              />
            );
          })
        ) : (
          <p className={styles.noHotel}>No hotel booked</p>
        )}

        {(modifyHotels || cancelHotels) && (
          <div className={styles.footerSection}>
            <p className={styles.tapAboveToSelect}>
              Tap above to select hotels to modify
            </p>
            <AppButton
              className={`${styles.hoteltBtn} ${!ifAnyOfTheHotelsSelected && styles.notSelected}`}
              disabled={!ifAnyOfTheHotelsSelected}
              onClick={handleModifyHotels}
            >
              {modifyHotels ? "Modify" : "Cancel"} Selected
            </AppButton>
            <p className={styles.ignoreStyles}>and ignore unselected hotels</p>
          </div>
        )}

        {!modifyHotels && !cancelHotels && (
          <CustomAccordion
            className={styles.customFlightStyles}
            summaryClass={styles.summaryFlightClass}
            accordianDetailsClass={styles.accordFlightDetailsClass}
            defaultExpanded={false}
            // variant="active"
            headingComponent={
              <div
                className={styles.headingFlightComp}
                // key={index}
                onClick={() =>
                  triggerGAEvent(
                    GA_LABEL_ENUMS.trip_details_flight_traveler_accordion_click
                  )
                }
              >
                <div className={styles.accordTitleCard}>
                  <p className={styles.accordDesc}>Travelers</p>
                  {/* {i.passengers && ( */}
                  <p className={styles.traveler}>
                    {/* {getPassengerCount(
                      travellersInfo?.adultsCount
                        ? Number(travellersInfo?.adultsCount)
                        : 0,
                      travellersInfo?.childrenCount
                        ? Number(travellersInfo?.childrenCount)
                        : 0,
                      travellersInfo?.seniorsCount
                        ? Number(travellersInfo?.seniorsCount)
                        : 0
                    )} */}
                  </p>
                  {/* )} */}
                </div>
                {/* {i.accordType && ( */}
                <img
                  src={
                    // i.accordType === "flight"
                    //   ? planeDepartureRegular
                    // :
                    personWalkingLuggage
                  }
                  alt="plane"
                  className={styles.planeDepartureIcon}
                />
                {/* )} */}
              </div>
            }
            bodyComponent={
              <React.Fragment>
                {travellersInfo &&
                  travellersInfo?.length &&
                  travellersInfo?.map((ele: any, index: number) => (
                    <TravelerDetails
                      key={index}
                      count={index}
                      name={ele?.firstName +  (ele?.middleName ? " " + ele?.middleName : "") + ", " + ele?.lastName}
                      phone={ele?.phoneNumber ? ele?.phoneNumber : " "}
                      countryCode={ele?.countryCode ? ele?.countryCode : " "}
                      gender={ele?.title === "Mr" ? "Male" : "Female"}
                      meal={ele?.meals[0]}
                      email={ele.email}
                      skyMiles={ele.FF}
                      specialNeeds={
                        // Object?.keys(ele?.specialAssistance)?.filter(
                        //   (key) => ele?.selectedOptions[key]
                        // ) || []
                        // ele?.operationalReference?.specialAssistance || []
                        ele?.operationalReference?.specialAssistance
                      }
                    />
                  ))}
              </React.Fragment>
            }
            zeroMarginOnExpand
            borderBottom
          />
        )}

        {/* Payment Details */}
        {hotelPayment &&
          hotelPayment?.length > 0 &&
          !modifyHotels &&
          !cancelHotels && (
            <PaymentDetails
              hotelPayment={hotelPayment}
              cardDetails={cardDetails}
            />
          )}
      </div>
    </>
  );
}
