import React, { useContext } from "react";
import updateCloseIcon from "../../../assets/updateCloseIcon.svg";
import { StateContext } from "../../../context/globalContext/context";
import styles from "./baggage.module.scss";
import { newBlueCheckIcon, seatArlineRegular } from "../../../constant";
import CloseIcon from "@mui/icons-material/Close";

export default function BaggageAndFares(): JSX.Element {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Context
  const state = useContext(StateContext);
  const {
    loginCreateAccountState,
    setLoginCreateAccountState,
    setSignInClick,
  }: any = state;

  function handleCancel() {
    setSignInClick(false);
    // setSaveScreenState(false)
    setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
    // console.log(loginCreateAccountState);
  }

  return (
    <div className={styles.dialogContainer}>
      <div className={styles.dialogContent}>
        <div className={styles.dialogHeader}>
          <p className={styles.header}>Baggage & Fare Rules</p>
          <img
            onClick={() => handleCancel()}
            src={updateCloseIcon}
            alt="cross"
            className={styles.closeIcon}
          />
        </div>
        <div className={styles.dialogBody}>
          <div className={styles.content}>
            <p className={styles.title}>Economy Basic includes:</p>
            <p className={styles.ruleTitle}>Baggage Rules</p>
            <div className={styles.ruleDetails}>
              <img
                src={newBlueCheckIcon}
                alt="icon"
                className={styles.closeIcon}
              />
              <p className={styles.caryBag}>1 carry-on bag</p>
            </div>
            <div className={styles.ruleDetails}>
              <CloseIcon className={styles.closeIcon} />
              <p className={styles.caryBag}>No checked bags</p>
            </div>
            <p className={styles.ruleTitle}>Fare Rules</p>
            <div className={styles.ruleDetails}>
              <CloseIcon className={styles.closeIcon} />
              <p className={styles.caryBag}>Seat selection for a fee</p>
            </div>
            <div className={styles.ruleDetails}>
              <img
                src={seatArlineRegular}
                alt="icon"
                className={styles.closeIcon}
              />
              <p className={styles.caryBag}>Extra legroom for a fee</p>
            </div>
            <p className={styles.ruleTitle}></p>
            <div className={styles.ruleDetails}>
              <CloseIcon className={styles.closeIcon} />
              <p className={styles.caryBag}>No ticket changes</p>
            </div>

            <div className={styles.ruleDetails}>
              <CloseIcon className={styles.closeIcon} />
              <p className={styles.caryBag}>No refunds</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
