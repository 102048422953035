import React, { useCallback, useContext, useRef, useState } from "react";
import styles from "./flight.module.scss";
import FlightInfo from "./flightInfo/FlightInfo";
import { NewFlightListingProps } from "../../../types/booking/flightListing";
import FlightDetail from "./flightDetail/flightDetail";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import {
  GetFlightDetailsResponse,
  iFlightAvailabilityResponse,
} from "../../../interface";
import {
  getFlightAvailability,
  getFlightDetails,
} from "../../../api/booking/booking";
import useCustomErrorHandler from "../../../hooks/useCustomErrorHandler";
import { toastMessage } from "../../../utils/ToastProvider";
import { TOAST_MESSAGES } from "../../../constant/messages/toastMessages";
import toast from "react-hot-toast";
import { ROUTES } from "../../../constant";
import { ScreenTypes } from "../../../enums";
import { useNavigate } from "react-router-dom";
import FlightCost from "./flightDetail/flightCost/flightCost";

const NewFlightListing: React.FC<NewFlightListingProps> = ({
  outboundFlightAirlineImage,
  outboundFlightDepartureDate,
  outboundFlightDepartureTime,
  outboundFlightArrivalTime,
  outboundFlightArrivalDate,
  outboundFlightStopsCount,
  outboundFlightTotalTime,
  outboundFlightRoute,
  inboundFlightAirlineImage,
  inboundFlightArrivalDate,
  inboundFlightDepartureTime,
  inboundFlightArrivalTime,
  inboundFlightDepartureDate,
  inboundFlightStopsCount,
  inboundFlightTotalTime,
  inboundFlightRoute,
  flightCost,
  uniqueId,
  encryptedKey,
}): JSX.Element => {
  const globalContext = useContext(StateContext);
  const {
    setFlightAvailabilityData,
    isCheckingFlight,
    setIsCheckingFlight,
    setBookingDetails,
    checkFlightDetails,
    flightData,
    setCheckFlightDetails,
    setScreen,
    isMobile,
    bookingDetails,
    expandedFlightId,
    setExpandedFlightId,
  }: iGlobalContext = globalContext;
  const [flightDetails, setFlightDetails] = useState<any>(null);
  const navigate = useNavigate();
  const { handleCustomError } = useCustomErrorHandler();
  const [viewFlightDetails, setViewFlightDetails] = useState<boolean>(false);
  let checkFlightController: any = useRef(null);
  let flightDetailController: any = useRef(null);
  const flightPrice = flightCost;

  const handleShowDetails = useCallback(
    async (encryptedKey: string, uniqueId: string) => {
      setCheckFlightDetails(true);
      setViewFlightDetails(true);
      try {
        flightDetailController.current = new AbortController();
        const currentFlightDetails =
          flightData?.sectors?.find((ele: any) => ele?.uniqueId === uniqueId) ||
          undefined;
        if (currentFlightDetails?.flightDetails) {
          return;
        }
        setFlightDetails(null);

        const data: GetFlightDetailsResponse = await getFlightDetails(
          encryptedKey,
          uniqueId,
          flightDetailController.current
        );
        if (data?.statusCode !== 200) {
          handleCustomError(data?.statusCode, "flight.detail");
          toastMessage.error(
            TOAST_MESSAGES.BOOKING.Search.FlightDetailsFailure.messageTitle,
            TOAST_MESSAGES.BOOKING.Search.FlightDetailsFailure.message
          );
          return;
        }
        setFlightDetails(data);
        setExpandedFlightId(uniqueId);
      } catch (err) {
        console.error(
          "Something went wrong while fetching flight details. Please try again."
        );
      } finally {
        setCheckFlightDetails(false);
      }
    },
    [
      flightData?.sectors,
      handleCustomError,
      setCheckFlightDetails,
      setExpandedFlightId,
    ]
  );

  const handleFlightBook = () => {
    localStorage.removeItem("_traveler_info_array_state");
    localStorage.removeItem("_traveler_info_array");
    isMobile
      ? navigate(ROUTES.TravelerInformation)
      : setScreen(ScreenTypes.TravellerInfo);
  };

  const handleFlightAvailability = useCallback(
    async (encryptedKey: string, uniqueId: string): Promise<boolean> => {
      setIsCheckingFlight(true);
      toast.dismiss();
      setExpandedFlightId(uniqueId);

      try {
        checkFlightController.current = new AbortController();
        const { statusCode, data: flightData }: iFlightAvailabilityResponse =
          await getFlightAvailability(
            encryptedKey,
            uniqueId,
            checkFlightController.current
          );
        if (statusCode !== 200) {
          toastMessage.error(
            TOAST_MESSAGES.BOOKING.Search.FlightAvailabilityFailure
              .messageTitle,
            TOAST_MESSAGES.BOOKING.Search.FlightAvailabilityFailure.message
          );
          handleCustomError(statusCode, "flight.availability");
          return false;
        }
        if (statusCode === 200) {
          setBookingDetails((prevBookingDetails) => ({
            ...prevBookingDetails,
            isFlightAvailable: true,
            selectedFlightId: uniqueId,
          }));
          setViewFlightDetails(false);
          setFlightAvailabilityData({
            ...flightData,
            flightPrice,
            encryptedKey,
            uniqueId,
          });
          return true;
        }
        return false;
      } catch (err) {
        console.error("Failed to check flight availability:", err);
        return false;
      } finally {
        setIsCheckingFlight(false);
      }
    },
    [
      flightPrice,
      handleCustomError,
      setBookingDetails,
      setExpandedFlightId,
      setFlightAvailabilityData,
      setIsCheckingFlight,
    ]
  );
  const outboundData = flightDetails?.data?.outbound;
  const inboundData = flightDetails?.data?.inbound;

  const handleFlightDetailsClick = useCallback(
    (uniqueId: string) => {
      if (expandedFlightId === uniqueId) {
        setExpandedFlightId("");
        return;
      } else if (encryptedKey && uniqueId) {
        handleShowDetails(encryptedKey, uniqueId);
      }
    },
    [expandedFlightId, encryptedKey, handleShowDetails, setExpandedFlightId]
  );

  const handleSelectFlight = useCallback(async () => {
    const isAvailable = await handleFlightAvailability(encryptedKey, uniqueId);
    if (isAvailable) {
      setExpandedFlightId(uniqueId);
      setBookingDetails((prevData) => ({
        ...prevData,
        selectedFlightId: uniqueId,
      }));
    }
  }, [
    encryptedKey,
    handleFlightAvailability,
    setBookingDetails,
    setExpandedFlightId,
    uniqueId,
  ]);
  const isActive =
    bookingDetails.selectedFlightId === uniqueId &&
    bookingDetails.isFlightAvailable;

  return (
    <div className={styles.main}>
      <div
        className={
          expandedFlightId === uniqueId && viewFlightDetails
            ? styles.activeContainer
            : styles.container
        }>
        <div className={styles.newDetailCard}>
          <div className={styles.flightBox}>
            <div
              className={styles.flightSubBox}
              onClick={() => {
                handleFlightDetailsClick(uniqueId);
              }}>
              {/* outbound */}
              <FlightInfo
                flightImage={outboundFlightAirlineImage}
                departureTime={outboundFlightDepartureTime}
                arrivalTime={outboundFlightArrivalTime}
                departureDate={outboundFlightDepartureDate}
                arrivalDate={outboundFlightArrivalDate}
                stops={outboundFlightStopsCount}
                travelTime={outboundFlightTotalTime}
                departureAirport={outboundFlightRoute.split("-")[0]}
                arrivalAirport={outboundFlightRoute.split("-")[1]}
              />
              {/* inbound */}
              {inboundFlightAirlineImage && inboundFlightRoute && (
                <FlightInfo
                  flightImage={inboundFlightAirlineImage}
                  departureTime={inboundFlightDepartureTime!}
                  arrivalTime={inboundFlightArrivalTime!}
                  departureDate={inboundFlightDepartureDate!}
                  arrivalDate={inboundFlightArrivalDate!}
                  stops={inboundFlightStopsCount!}
                  travelTime={inboundFlightTotalTime!}
                  departureAirport={inboundFlightRoute.split("-")[0]}
                  arrivalAirport={inboundFlightRoute.split("-")[1]}
                />
              )}
            </div>

            <div className={styles.verticalLine}></div>
            <FlightCost
              selectedFlightId={bookingDetails.selectedFlightId}
              uniqueId={uniqueId}
              isFlightAvailable={bookingDetails.isFlightAvailable}
              flightCost={flightCost}
              expandedFlightId={expandedFlightId}
              handleSelectFlight={handleSelectFlight}
              viewFlightDetails={viewFlightDetails}
              handleViewFlightDetails={() => {
                handleFlightDetailsClick(uniqueId);
              }}
            />
          </div>
        </div>
        <div
          className={`${
            expandedFlightId === uniqueId && viewFlightDetails
              ? styles.viewFlightDetailsCard
              : styles.hideViewFlightDetails
          }`}>
          <div className={styles.baggageCard}>
            <p className={styles.baggage}>Baggage & Fare Rules</p>
          </div>
          <div className={styles.flightBox}>
            {flightDetails?.data ? (
              <>
                {[...(outboundData || []), ...(inboundData || [])].map(
                  (ele, index) => {
                    // Determine flight type
                    const flightType = outboundData?.includes(ele)
                      ? "outbound"
                      : "inbound";

                    return (
                      <div key={index}>
                        <FlightDetail
                          flightData={ele}
                          flightType={flightType} 
                          flightImage={""}
                        />
                      </div>
                    );
                  }
                )}
              </>
            ) : (
              <p className={styles.noDetails}>No details found</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFlightListing;
