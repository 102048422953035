import React, { useState } from "react";
import NewSingleAirportSelectionPopover from "../../bookingPopover/newSingleAirportSelectionPopover/NewSingleAirportSelectionPopover";
import styles from "./airPort.module.scss";

interface AirPortProps {
  className?: string;
  googleInputAddress: string;
  selectedAddress: string;
  setGoogleInputAddress: (address: string) => void;
  setSelectedAddress: (address: string) => void;
  handleUpdateData: () => void;
  label?: string;
  title?: string;
  airportName?: string;
  value: string;
  handleAirportChange: (flight: {
    airportCode: string;
    airportName: string;
  }) => void;
  selectedAirportCode: string;
  clearInputValues: () => void;
  hideSearchInput?: boolean;
  indexNumber: number;
}

const NewSingleAirportPicker: React.FC<AirPortProps> = ({
  className,
  googleInputAddress,
  selectedAddress,
  setGoogleInputAddress,
  handleUpdateData,
  label,
  title,
  value,
  airportName,
  setSelectedAddress,
  handleAirportChange,
  selectedAirportCode,
  clearInputValues,
  hideSearchInput,
  indexNumber,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const handleAirportSelection = (flight: {
    airportCode: string;
    airportName: string;
  }) => {
    handleAirportChange(flight);
  };
  console.log("value", value);
  console.log("airportName", airportName);

  return (
    <div>
      <NewSingleAirportSelectionPopover
        label={title ?? ""}
        indexNumber={indexNumber}
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        handleApplyButton={() => handleUpdateData()}
        value={selectedAirportCode}
        handleAirportChange={handleAirportSelection}
        googleInputAddress={googleInputAddress}
        setGoogleInputAddress={setGoogleInputAddress}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        clearInputValues={clearInputValues}
        hideSearchInput={hideSearchInput}
        // setBookingDetails={setBookingDetails}
        popoverTrigger={
          <div className={`${className} ${styles.main}`}>
            <p className={styles.label}>{label}</p>
            <div className={styles.airportCard}>
              <div className={styles.card}>
                <p className={value?.trim() ? styles.btnName : styles.selectBtn}>
                  {value?.trim() || "Select"}
                </p>
               
                <p
                  className={`${airportName?.trim() ? styles.city : styles.emptyAirport}`}>
                  {airportName?.trim() || "Departure airport"}
                </p>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default NewSingleAirportPicker;
