import { useCallback, useEffect, useMemo, useState } from "react";
import { calenderRangeLight } from "../../../../constant";
import DatePickerPopover from "../../bookingPopover/datePickerPopover/DatePickerPopover";
import styles from "./input.module.scss";

interface DatePickerProps {
  className?: string;
  handleUpdateData: any;
  label: string;
  dateInput: string | null;
  setDateInput: (newDate: string) => void;
  actualDate?: string;
}

const NewDatePicker: React.FC<DatePickerProps> = ({
  className = "",
  label,
  actualDate = "",
  dateInput = null,
  setDateInput,
  handleUpdateData,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const handleApplyButton = useCallback(() => {
    handleUpdateData();
    // console.log(date, "applyBtnDate");
    setIsPopoverOpen(false);
  }, [handleUpdateData]);

  useEffect(() => {
    if (actualDate && !isPopoverOpen) {
      setDateInput(actualDate);
    }
  }, [actualDate, isPopoverOpen, setDateInput]);

  const displayDate = useMemo(() => {
    return dateInput
      ? new Date(dateInput)
          ?.toLocaleDateString("en-US", {
            weekday: "short",
            month: "short",
            day: "2-digit",
          })
          .replace(/, (.*)/, (_, p1) => `, ${p1.toUpperCase()}`)
      : "Select a date";
  }, [dateInput]);

  return (
    <DatePickerPopover
      value={dateInput ? new Date(dateInput) : null}
      setValue={(date: Date) => {
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
        setDateInput(formattedDate);
      }}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      handleApplyButton={handleApplyButton}
      popoverTrigger={
        <div className={`${className} ${styles.main}`}>
          <p className={styles.label}>{label}</p>
          <div className={styles[isPopoverOpen ? "activeTravelBtn" : "btn"]}>
            <img
              src={calenderRangeLight}
              alt="icon"
              className={styles.calendarIcon}
            />
            <p className={styles.btnName}>{displayDate}</p>
          </div>
        </div>
      }
    />
  );
};

export default NewDatePicker;
