import React, { useContext, useEffect, useRef, useState } from "react";
import CustomPopover from "../../../reusableComponent/customPopver/CustomPopover";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Airport, airportAddressFieldTypes } from "../../../../types";
import Loader from "../../../reusableComponent/loader/Loader";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import { StateContext } from "../../../../context/globalContext/context";
import {
  CityAirports,
  iBookingAirportLoaderTypes,
} from "../../../../interface";
import styles from "./airportSelectionPopover.module.scss";
import { getAirports } from "../../../../api/booking/booking";
import AutoCompleteAirportAddress from "../../../googleMapInputFields/autoCompleteAirportAddress/autoCompleteAirportAddress";
import { PostBookingAirportProps } from "../../../../api/booking/postBooking";

const NewSingleAirportSelectionPopover: React.FC<PostBookingAirportProps> = ({
  popoverTrigger,
  isPopoverOpen,
  setIsPopoverOpen,
  label,
  handleAirportChange,
  value,
  googleInputAddress,
  setGoogleInputAddress,
  setSelectedAddress,
  selectedAddress,
  clearInputValues,
  hideSearchInput,
  indexNumber,
}) => {
  const state = useContext(StateContext);
  const { airPorts, setAirPorts, setAirportAddress }: iGlobalContext = state;
  
  const [loader, setLoader] = useState<iBookingAirportLoaderTypes>({
    arrivalLoader: false,
    departureLoader: false,
  });

  let fetchAirportController: any = useRef(null);

  const handleSearchAirport = async (
    googleAddress: airportAddressFieldTypes
  ) => {
    const addressDetails = [
      googleAddress.city,
      googleAddress.state,
      googleAddress.country,
    ]
      .filter(Boolean)
      .join(", ");
    setSelectedAddress(addressDetails);
    const cities = [
      {
        name: addressDetails,
        latitude: String(googleAddress?.latitude ?? ""),
        longitude: String(googleAddress?.longitude ?? ""),
        countryCode: googleAddress.countryCode ?? "",
      },
    ];
    setLoader((prevData) => ({
      ...prevData,
      departureLoader: true,
      arrivalLoader: false,
    }));
    const requestBody = {
      cities: cities,
      radius: "100 miles",
    };

    fetchAirportController.current = new AbortController();

    try {
      const data = await getAirports(
        requestBody,
        fetchAirportController.current.signal
      );

      if (fetchAirportController.current.signal.aborted) {
        return;
      }
      if (data) {
        if (Array.isArray(data)) {
          setAirPorts((prevData: any) =>
            Array.isArray(prevData) ? [...prevData, ...data] : [...data]
          );
        }
      }
    } catch (err) {
      setAirPorts([]);
      console.error("Failed to fetch flight data", err);
    } finally {
      setLoader((prevData) => ({
        ...prevData,
        departureLoader: false,
        arrivalLoader: false,
      }));
    }
  };

  const [selectedAirport, setSelectedAirport] = useState<{
    airportCode: string;
    airportName: string;
  } | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedAirport = airPorts
      .flatMap((city: CityAirports) => city?.airports)
      .find((airport: Airport) => airport?.code === event.target.value);

    if (selectedAirport) {
      setSelectedAirport({
        airportCode: selectedAirport.code,
        airportName: selectedAirport.fullName,
      });
    }
  };

  useEffect(() => {
    if (value && Array.isArray(airPorts)) {
      const matchingAirport = airPorts
        .flatMap((city: CityAirports) => city.airports)
        .find((airport: Airport) => airport.code === value);

      if (matchingAirport) {
        setSelectedAirport({
          airportCode: matchingAirport.code,
          airportName: matchingAirport.fullName,
        });
      }
    }
  }, [value, airPorts, indexNumber]);

  const handleApplyButton = () => {
    if (selectedAirport) {
      handleAirportChange(selectedAirport);
    }
    setIsPopoverOpen(false);
  };

  return (
    <CustomPopover
      popoverTrigger={popoverTrigger}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      popoverContent={
        <div className={styles.mainContainer}>
          <div className={styles.content}>
            {/* <h1 className={styles.heading}>{label}</h1> */}
            {hideSearchInput ? null : (
              <div className={styles.autoCompleteField}>
                <div className={styles.input}>
                  <AutoCompleteAirportAddress
                    onPlaceSelected={(googleAddress) => {
                      handleSearchAirport(googleAddress);
                    }}
                    address={googleInputAddress}
                    setAddress={setGoogleInputAddress}
                    setInputAddress={setAirportAddress}
                    clearInputValues={() => {
                      setSelectedAirport({
                        airportCode: "",
                        airportName: "",
                      });
                      clearInputValues();
                    }}
                  />
                </div>
              </div>
            )}
            {googleInputAddress?.trim() && (
              <div>
                {false ? (
                  <div className={styles.loader}>
                    <Loader />
                  </div>
                ) : (
                  <>
                    {Array.isArray(airPorts) &&
                    airPorts.reduce(
                      (uniqueCities: CityAirports[], city: CityAirports) => {
                        // Check if the city is already added
                        if (!uniqueCities.some((c) => c?.city === city?.city)) {
                          uniqueCities.push(city);
                        }
                        return uniqueCities;
                      },
                      []
                    ).length === 0 ? (
                      <p className={styles.errorMessage}>
                        {" "}
                        Unable to find an airport.
                        <br /> Try a different location.
                      </p>
                    ) : (
                      <FormControl className={styles.formControlContainer}>
                        <RadioGroup
                          name="radio"
                          onChange={handleChange}
                          value={selectedAirport?.airportCode}>
                          {Array.isArray(airPorts) &&
                            airPorts
                              .reduce(
                                (
                                  uniqueCities: CityAirports[],
                                  city: CityAirports
                                ) => {
                                  if (
                                    !uniqueCities.some(
                                      (c) => c.city === city.city
                                    )
                                  ) {
                                    uniqueCities.push(city);
                                  }
                                  return uniqueCities;
                                },
                                []
                              )
                              .filter((i) => i.city === selectedAddress)
                              .map((city: CityAirports) => (
                                <div key={city.city}>
                                  {city?.airports
                                    ?.slice(0, 5)
                                    .map((airport: Airport) => (
                                      <FormControlLabel
                                        key={airport.code}
                                        value={airport.code}
                                        control={<CustomRadio />}
                                        label={
                                          <p
                                            className={styles.airportLabel}
                                            style={{
                                              color:
                                                airport.code ===
                                                selectedAirport?.airportCode
                                                  ? "#222222"
                                                  : "#696969",
                                            }}>
                                            {airport?.code} -{" "}
                                            {airport?.fullName}
                                          </p>
                                        }
                                      />
                                    ))}
                                </div>
                              ))}
                        </RadioGroup>
                      </FormControl>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      }
      handleApplyButton={handleApplyButton}
    />
  );
};

export default NewSingleAirportSelectionPopover;

export function CustomRadio(props: Readonly<RadioProps>) {
  return (
    <Radio
      // disableRipple
      color="default"
      {...props}
      icon={<span className={styles.icon} />}
      checkedIcon={<span className={styles.checkedIcon} />}
    />
  );
}
