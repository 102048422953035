import { useCallback, useEffect, useMemo, useState } from "react";
import { calenderRangeLight } from "../../../../constant";
import DatePickerPopover from "../../bookingPopover/datePickerPopover/DatePickerPopover";
import styles from "./newDate.module.scss";

interface DatePickerProps {
  className?: string;
  handleUpdateData: any;
  label: string;
  dateInput: string | null;
  actualDate?: string;
  booking?: boolean;
}

const DateNewPicker: React.FC<DatePickerProps> = ({
  className = "",
  label,
  actualDate = "",
  dateInput = null,
  handleUpdateData,
  booking,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(dateInput);

  useEffect(() => {
    if (actualDate && !isPopoverOpen) {
      setSelectedDate(actualDate);
    }
  }, [actualDate, isPopoverOpen]);

  const handleApplyButton = useCallback(() => {
    if (selectedDate) {
      handleUpdateData(selectedDate); // Update global state only when Apply is clicked
    }
    setIsPopoverOpen(false);
  }, [selectedDate, handleUpdateData]);

  const displayDate = useMemo(() => {
    return selectedDate
      ? new Date(selectedDate).toLocaleDateString("en-US", {
          weekday: "short",
          month: "short",
          day: "2-digit",
        })
      : "Select a date";
  }, [selectedDate]);

  return (
    <DatePickerPopover
      value={selectedDate ? new Date(selectedDate) : null}
      setValue={(date: Date) => {
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
        setSelectedDate(formattedDate);
      }}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      handleApplyButton={handleApplyButton}
      popoverTrigger={
        <div className={styles.mainContainer}>
          {booking ? (
            <div className={styles.container}>
              <p className={styles.bookingLabel}>{label}</p>
              <div
                className={
                  styles[
                    isPopoverOpen ? "activeBookTravelBtn" : "travelBookBtn"
                  ]
                }>
                <div className={styles.dateCard}>
                  <div className={styles.dateBtn}>
                    <img
                      src={calenderRangeLight}
                      alt="icon"
                      className={styles.calIcon}
                    />

                    <p className={styles.dateInfo}>{displayDate}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={`${className} ${styles.main}`}>
              <p className={styles.label}>{label}</p>
              <div
                className={styles[isPopoverOpen ? "activeTravelBtn" : "btn"]}>
                <img
                  src={calenderRangeLight}
                  alt="icon"
                  className={styles.calendarIcon}
                />
                <p className={styles.btnName}>{displayDate}</p>
              </div>
            </div>
          )}
        </div>
      }
    />
  );
};

export default DateNewPicker;
