import React from "react";
import CityTabs from "../../itineraryDetails/cityTabs/CityTabs";
import { iCityDetailsResult } from "../../../types";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";
import NextBreadcrumbBtn from "../component/nextBreadcrumbBtn/NextBreadcrumbBtn";
import { checkIfItineraryIsValid } from "../../../utils";

interface ReviewCrumbsDetailsProps {
  readonly handleContinue: any;
  readonly data: any;
}

export default function ReviewCrumbsDetails({
  handleContinue,
  data,
}: ReviewCrumbsDetailsProps) {
  // const globalContext = useContext(StateContext);
  // const { selectedChatData }: iGlobalContext = globalContext;
  const selectedChatData = data;
  console.log(checkIfItineraryIsValid(data));

  const [showEditActivityFlag, setShowEditActivityFlag] = React.useState(false);
  return (
    <div>
      <CityTabs
        showEndCircle
        itineraryDescription={selectedChatData?.complete_itinerary_description}
        itineraryVersion={selectedChatData?.itinerary_version ?? 0}
        destinationList={selectedChatData?.destinationList?.filter(
          (ele: iCityDetailsResult) => ele.checkedFlag
        )}
        //  isThereFlightBooked={flightSummary}
        disableInput={false}
        flexDirection={"column-reverse"}
        startDate={"2025-02-07"}
        showWeatherAdvisory={false}
        showEditActivityFlag={showEditActivityFlag}
        setShowEditActivityFlag={setShowEditActivityFlag}
        // savedStatus={status}
        handleHeadingClick={() =>
          triggerGAEvent(
            GA_LABEL_ENUMS.trip_details_daily_activity_accordion_click
          )
        }
        handleEditGAActivity={() =>
          triggerGAEvent(
            GA_LABEL_ENUMS.trip_details_activities_modify_activities_click
          )
        }
        handleEditActivityGADone={() =>
          triggerGAEvent(GA_LABEL_ENUMS.trip_details_activities_done_click)
        }
      />

      <NextBreadcrumbBtn
        handleNext={handleContinue}
        textInfo={"to Add Traveler Information"}
      />
    </div>
  );
}
