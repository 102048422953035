import CustomAccordion from "../../../reusableComponent/customAccordion/CustomAccordion";
import { getDayOfWeek, getMonthDate } from "../../../../utils/dateUtils";
import { planeEnginesLight } from "../../../../constant";
import FlightDetailStepper from "./stepper/flightDetailStepper";
import styles from "./flightDetail.module.scss";
interface FlightDetailProps {
  readonly flightData?: any;
  readonly flightImage?: any;
  readonly hideExpandIcon?: boolean;
  readonly handleExpandGAEvent?: any;
  readonly handleCloseGAEvent?: any;
  readonly flightType?: string;
}

function FlightDetail({
  flightData,
  flightImage,
  hideExpandIcon = true,
  handleExpandGAEvent,
  handleCloseGAEvent,
  flightType,
}: FlightDetailProps): JSX.Element {
  const departureAirportCode =
    flightData?.segments?.length > 0 && flightData?.segments[0]?.depAirportCode;
  const departureCityName =
    flightData?.segments?.length > 0 &&
    flightData?.segments[0]?.departureCityName;
  const arrivalAirportCode =
    flightData?.segments?.length > 0 &&
    flightData?.segments[flightData?.segments?.length - 1]?.arrAirportCode;
  const arrivalCityName =
    flightData?.segments?.length > 0 &&
    flightData?.segments[flightData?.segments?.length - 1]?.arrivalCityName;
  return (
    <div className={styles.main}>
      <div className={styles.flightAccord}>
        <CustomAccordion
          className={styles.customFlightStyles}
          summaryClass={styles.summaryFlightClass}
          accordianDetailsClass={styles.accordFlightDetailsClass}
          defaultExpanded={true}
          hideExpandIcon={hideExpandIcon}
          onChange={(_e, isExpanded) => {
            if (isExpanded) if (handleExpandGAEvent) handleExpandGAEvent();
            if (!isExpanded) if (handleCloseGAEvent) handleCloseGAEvent();
          }}
          headingComponent={
            <div className={styles.headingFlightComp}>
              <div className={styles.accordTitleCard}>
                <p className={styles.dayAndCity}>
                  {getDayOfWeek(
                    flightData?.segments?.length > 0
                      ? flightData?.segments[0]?.departureDate
                      : ""
                  )}{" "}
                  {getMonthDate(
                    flightData?.segments?.length > 0
                      ? flightData?.segments[0]?.departureDate
                      : ""
                  )}
                </p>

                <p className={styles.accordDesc}>
                  {`${departureCityName} (${departureAirportCode})`} -{" "}
                  {`${arrivalCityName} (${arrivalAirportCode})`}
                </p>
              </div>
              <img
                src={planeEnginesLight}
                alt="plane"
                className={`${flightType === "outbound" ? styles.planeDepartureIcon : styles.planeArrivalIcon}`}
              />
            </div>
          }
          bodyComponent={
            <div className={styles.stepper}>
              <section className={styles.stepperDetails}>
                <FlightDetailStepper
                  steps={flightData?.segments}
                  stops={flightData?.stops}
                  flightImage={flightImage}
                />
              </section>
              <div className={styles.divider} />
            </div>
          }
          zeroMarginOnExpand
          borderBottom
        />
      </div>
    </div>
  );
}

export default FlightDetail;
