import styles from "./arrivalOnDifferentDate.module.scss";
import { redInfoIcon } from "../../../constant";

interface ArrivalOnDifferentDateProps {
  readonly variant?: string;
}

export default function ArrivalOnDifferentDate({
  variant,
}: ArrivalOnDifferentDateProps): JSX.Element {
  return (
    <div className={styles.arrivalOnDifferentDateContainer}>
      <img src={redInfoIcon} alt="info" />
      <p className={styles.defaultText}>ARRIVAL ON A DIFFERENT DATE</p>
    </div>
  );
}
