import { Button } from "@mui/material";
import { eyeLight, ROUTES } from "../../../../constant";
import styles from "./hotel.module.scss";
import HotelCard from "../hotelCard/hotelCard";
import { useNavigate } from "react-router-dom";
import DiscountCard from "../discountCard/discountCard";
import { useCallback, useContext, useState } from "react";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import { HOTEL_CARD_TYPES, ScreenTypes } from "../../../../enums";

interface iProps {
  star: number;
  hotelName: string;
  hotelLocation: string;
  hotelRating: number;
  reviewCount: number;
  cost: number;
  cancellation: string;
  Amenity: string[];
  hotelList: boolean;
  discount?: string;
  discountCost?: string;
  selected?: number | null;
  onSelect?: () => void;
  id?: number | null;
  hotelMainImage?: string;
  taxesIncluded: string;
  reviewHeading?: string;
  hotelId?: string;
  hotelDetails?: any;
  city?: string;
  // setShowSelectRoomModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function HotelListing({
  star,
  hotelName,
  hotelLocation,
  hotelRating,
  reviewCount,
  cost,
  cancellation,
  Amenity,
  hotelList,
  discount,
  discountCost,
  selected,
  onSelect,
  id,
  hotelMainImage,
  taxesIncluded,
  reviewHeading,
  hotelId,
  hotelDetails,
  city,
  // setShowSelectRoomModal,
}: iProps): JSX.Element {
  const navigate = useNavigate();

  const state = useContext(StateContext);
  const {
    setShouldUserNavigateBackTotripDetailsPage,
    setHotelDetailsPageId,
    isMobile,
    setScreen,
    setShowSelectRoomModal,
    // selectedHotelRoom,
    // selectedHotelRoomArr,
  }: iGlobalContext = state;

  const [isThisHotelExpanded, setIsThisHotelExpanded] = useState(false);

  // const { selectedHotelRoom } =
  //   selectedHotelRoomArr?.find(
  //     (eachHotel: any) =>
  //       eachHotel?.checkIn === hotelDetails?.checkIn &&
  //       eachHotel?.checkOut === hotelDetails?.checkOut &&
  //       eachHotel?.city === hotelDetails?.city
  //   ) || {};

  // const isThisHotelSelected =
  //   hotelId === selectedHotelRoom?.selectedHotelId &&
  //   hotelDetails?.checkIn === selectedHotelRoom?.checkIn &&
  //   hotelDetails?.checkOut === selectedHotelRoom?.checkOut;

  const handleViewDetails = () => {
    if (!hotelId) return;
    setHotelDetailsPageId(hotelId);
    if (isMobile) {
      navigate(ROUTES.HotelDetails);
    } else {
      setShouldUserNavigateBackTotripDetailsPage(false);
      setScreen(ScreenTypes.HotelDetails);
    }
  };

  const handleHotelSelect = useCallback(() => {
    if (onSelect) onSelect();
    setShowSelectRoomModal(true);
  }, [onSelect, setShowSelectRoomModal]);

  // console.log(isThisHotelExpanded, "isThisHotelExpanded");

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.contentCard}>
          <div
            className={`${styles.hotelListCard} ${isThisHotelExpanded && styles.selectedHotelListCard}`}
          >
            <HotelCard
              star={star}
              hotelName={hotelName}
              hotelLocation={hotelLocation}
              hotelRating={hotelRating}
              reviewCount={reviewCount}
              Amenity={Amenity?.slice(0, 3)}
              hotelList={hotelList}
              hotelMainImage={hotelMainImage}
              reviewHeading={reviewHeading}
              handleViewDetails={handleViewDetails}
              hotelDetails={hotelDetails}
              city={city}
              handleHotelSelect={handleHotelSelect}
              hotelCardType={HOTEL_CARD_TYPES.BOOKING}
              setIsThisHotelExpanded={setIsThisHotelExpanded}
              isThisHotelExpanded={isThisHotelExpanded}
            />
            {/* <div className={styles.card3}>
              <Button
                className={styles.flightBtn}
                onClick={() => {
                  if (!hotelId) return;
                  setHotelDetailsPageId(hotelId);
                  if (isMobile) {
                    navigate(ROUTES.HotelDetails);
                  }
                  if (!isMobile) {
                    setShouldUserNavigateBackTotripDetailsPage(false);
                    setScreen(ScreenTypes.HotelDetails);
                  }
                }}
              >
                <div className={styles.iconAndText}>
                  {discount && (
                    <DiscountCard cost={discountCost} percentage={discount} />
                  )}
                  <div className={styles.viewDetailsBox}>
                    <img src={eyeLight} alt="icon" className={styles.expIcon} />
                    <span className={styles.viewDetails}>
                      View <br /> Details
                    </span>
                  </div>
                </div>
              </Button>
            </div> */}
            {/* <div className={styles.lastCard}>
              <div
                className={`${styles.card4} ${isThisHotelSelected && styles.selectedHotelRoom}`}
              >
                <p
                  className={`${styles.price} ${isThisHotelSelected && styles.selectedPrice}`}
                >
                  {isThisHotelSelected
                    ? `${selectedHotelRoom?.price ? "$" + Math.round(selectedHotelRoom?.price) : "$0"}`
                    : `${cost ? "$" + Math.round(cost) : "$0"}`}
                </p>
                {!isThisHotelSelected && (
                  <span
                    className={`${styles.flightClass} ${isThisHotelSelected && styles.selectedFlightClass}`}
                  >
                    {taxesIncluded === "yes" ? "inc. Taxes" : "excl. Taxes"} &
                    Fees
                  </span>
                )}
                {isThisHotelSelected && (
                  <span
                    className={`${styles.flightClass} ${isThisHotelSelected && styles.selectedFlightClass}`}
                  >
                    {selectedHotelRoom?.taxIncluded
                      ? "inc. Taxes"
                      : "excl. Taxes"}{" "}
                    {!selectedHotelRoom?.feesIncluded
                      ? `&
                  Fees`
                      : ""}
                  </span>
                )}
                <p
                  className={`${styles.cancellation} ${isThisHotelSelected && styles.selectedCancellation}`}
                >
                  {isThisHotelSelected
                    ? selectedHotelRoom?.type || ""
                    : cancellation || ""}
                </p>

                <Button
                  className={`${isThisHotelSelected ? styles.selectedBtn : styles.bookBtn}`}
                  onClick={() => {
                    if (onSelect) onSelect();
                    setShowSelectRoomModal(true);
                  }}
                >
                  {isThisHotelSelected ? "Selected" : "Select"}
                </Button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HotelListing;
