// import { ChangeEvent } from "react";
// import styles from "./custom.module.scss";
// import StarRateIcon from "@mui/icons-material/StarRate";

// interface iProps {
//   inputName?: string;
//   mandate: boolean;
//   placeHolder?: string;
//   value: string;
//   onChange: (event: ChangeEvent<HTMLInputElement>) => void;
//   width: string;
//   error?: any;
//   name?: any;
//   showRequired?: boolean;
//   showOverlayInput?: boolean;
//   overlayValue?: string;
//   disabled?: boolean;
// }

// function CustomInput({
//   inputName,
//   mandate,
//   placeHolder,
//   value,
//   onChange,
//   width,
//   error = false,
//   name,
//   showRequired = true,
//   showOverlayInput = false,
//   overlayValue = "",
//   disabled = false,
// }: iProps): JSX.Element {
//   return (
//     <div className={styles.main}>
//       <p className={styles.inputCard}>
//         {inputName}
//         {/* {mandate && <StarRateIcon className={styles.mandate} />} */}
//       </p>
//       <div className={styles.inputContainer}>
//         <input
//           name={name}
//           type="text"
//           value={value}
//           className={`${showOverlayInput && styles.hideTextIfOverlay} ${error ? styles.inputErrorField : styles.inputField}`}
//           placeholder={placeHolder}
//           onChange={onChange}
//           style={{ width: `${width}` }}
//           disabled={disabled}
//         />
//         {showOverlayInput && (
//           <input
//             className={`${styles.inputField} ${styles.phoneNumberOverlay}`}
//             style={{ width: `${width}` }}
//             value={overlayValue}
//           />
//         )}
//       </div>
//       {/* <p className={`${error ? styles.errorMessage : styles.hideError}`}>
//         {showRequired ? "Required" : ""}
//       </p> */}
//     </div>
//   );
// }

// export default CustomInput;


/** OLD UI */
import { ChangeEvent } from "react";
import styles from "./custom.module.scss";
import StarRateIcon from "@mui/icons-material/StarRate";

interface iProps {
  inputName?: string;
  mandate: boolean;
  placeHolder?: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  width: string;
  error?: any;
  name?: any;
  showRequired?: boolean;
  showOverlayInput?: boolean;
  overlayValue?: string;
  disabled?: boolean;
}

function CustomInput({
  inputName,
  mandate,
  placeHolder,
  value,
  onChange,
  width,
  error = false,
  name,
  showRequired = true,
  showOverlayInput = false,
  overlayValue = "",
  disabled = false,
}: iProps): JSX.Element {
  return (
    <div className={styles.main}>
      <p className={styles.inputCard}>
        {inputName} {mandate && <StarRateIcon className={styles.mandate} />}
      </p>
      <div className={styles.inputContainer}>
        <input
          name={name}
          type="text"
          value={value}
          className={`${showOverlayInput && styles.hideTextIfOverlay} ${error ? styles.inputErrorField : styles.inputField}`}
          placeholder={placeHolder}
          onChange={onChange}
          style={{ width: `${width}` }}
          disabled={disabled}
        />
        {showOverlayInput && (
          <input
            className={`${styles.inputField} ${styles.phoneNumberOverlay}`}
            style={{ width: `${width}` }}
            value={overlayValue}
          />
        )}
      </div>
      <p className={`${error ? styles.errorMessage : styles.hideError}`}>
        {/* {error} */}
        {showRequired ? "Required" : ""}
      </p>
    </div>
  );
}

export default CustomInput;