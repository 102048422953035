import React, { useCallback, useContext } from "react";
import styles from "./hotelDetails.module.scss";
// import { Button } from "@mui/material";
// import { backIcon, starSharpSolid } from "../../constant";
import { amenitiesIcons } from "./hotelDetailsConstants";
import ImageSlider from "./components/imageSlider/imageSlider";
// import HotelRating from "../booking/hotelBooking/hotelRating/hotelRating";
import CustomAccordion from "../reusableComponent/customAccordion/CustomAccordion";
import Amenities from "./components/amenities/amenities";
import AboutProperty from "./components/aboutProperty/aboutProperty";
// import ReviewsModal from "./components/reviewsModal/reviewsModal";
// import PropertyDetailsModal from "./components/propertyDetailsModal/propertyDetailsModal";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
// import { ScreenTypes } from "../../enums";
// import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import { iHotelDetails } from "../../interface";
import { eyeLight, greenCheckIcon } from "../../constant";

interface iProps {
  hotelDetails: iHotelDetails;
}

function HotelDetails(props: iProps): JSX.Element {
  const { hotelDetails } = props;
  const globalContext = useContext(StateContext);
  const {
    // shouldUserNavigateBacktoTripDetailsPage,
    // setActiveTabIndex,
    // setScreen,
    // setShowReviews,
    setHotelDetailsPageState,
    setShowPropertyDetails,
    // isMobile,
    setShowRoomDetails,
    setRoomDetailsData,
    setIsCancPolModalOpen,
    setCancellationData,
  }: iGlobalContext = globalContext;

  const {
    // stars: hotelStar,
    // name: hotelName,
    hotelImages = [],
    // address,
    // city: place,
    // hotelMainImage,
    // rating,
    // reviewCount,
    // landMark = "",
    // reviewHeading = "",
    amenities: hotelAmenities = [],
    aboutProperty = [],
    hotelAndRoomInfo,
  } = hotelDetails || {};

  const { hotelInformation, roomInfo, city } = hotelAndRoomInfo || {};
  const { cancellationPolicy = "" } = hotelInformation || {};
  const {
    roomName,
    bedInformation = [],
    noOfRooms,
    priceInfo,
    roomOnly = false,
    roomAmenities = [],
  } = roomInfo || {};
  const { isRefundable = false } = priceInfo || {};

  const handleClickPropertyDetails = useCallback(() => {
    if (roomInfo && hotelInformation) {
      setHotelDetailsPageState({
        name: roomName,
        hotelImages,
        address: hotelInformation?.address,
        city,
        hotelAmenities: [...roomAmenities],
      });
    }
    if (!roomInfo)
      setHotelDetailsPageState({
        ...hotelDetails,
        hotelAmenities: [...hotelDetails?.amenities],
      });
    setShowPropertyDetails(true);
  }, [
    city,
    hotelDetails,
    hotelImages,
    hotelInformation,
    roomAmenities,
    roomInfo,
    roomName,
    setHotelDetailsPageState,
    setShowPropertyDetails,
  ]);

  const handleCancellationPolicy = useCallback(() => {
    if (cancellationPolicy) {
      setCancellationData(cancellationPolicy);
      setIsCancPolModalOpen(true);
    }
  }, [cancellationPolicy, setCancellationData, setIsCancPolModalOpen]);

  return (
    <div className={styles.hotelDetailsContainer}>
      {hotelImages.length > 0 && (
        <div className={styles.horizontalScrollContainer}>
          <ImageSlider
            imagesArr={[...hotelImages.map((item: any) => item.url)]}
          />
        </div>
      )}
      {!!roomInfo && !!hotelInformation && (
        <div className={styles.hotelDescriptionContainer}>
          {/* <section className={styles.contentCard}>
          {hotelStar && (
            <div className={styles.starContainer}>
              <img
                className={styles.starPropertyImg}
                src={starSharpSolid}
                alt="star icon"
              />
              <span className={styles.star}>{hotelStar} star property</span>
            </div>
          )}
          <p className={styles.hotelName}>{hotelName}</p>
          <p className={styles.location}>
            {place ? place : ""} &nbsp; {address ? `|` : ""} &nbsp;{" "}
            {address ? `${address}, ` : ""}
            {place ? place : ""}
          </p>
          {landMark && (
            <p
              className={styles.landMark}
              // onClick={() => setShowSelectRoomModal(true)}
            >
              {landMark}
            </p>
          )}
          <HotelRating
            className={styles.ratingsSection}
            ratingCardClass={styles.ratingCardClass}
            ratingClass={styles.ratingClass}
            hotelRating={rating}
            reviewCount={reviewCount}
            reviewHeading={reviewHeading ? reviewHeading : ""}
            contentClass={styles.contentClass}
            reviewClass={styles.reviewClass}
            reviewTitleClass={styles.reviewTitleClass}
            onReviewClick={() => setShowReviews(true)}
          />
        </section> */}
          <section className={styles.roomInfoContainer}>
            <div className={styles.roomtitleAndBedContainer}>
              <p className={styles.titleAndBed}>
                <span>
                  {roomName}{" "}
                  {bedInformation?.reduce(
                    (acc: string, item: any) =>
                      acc + `, ${item.quantity} ${item.bedType}`,
                    ""
                  )}
                </span>
                <span className={styles.bookedRooms}>
                  <img
                    src={greenCheckIcon}
                    alt="check mark"
                    className={styles.checkMark}
                  />
                  {noOfRooms} &nbsp; {Number(noOfRooms) > 1 ? "rooms" : "room"}{" "}
                  booked
                </span>
              </p>
              {(!!isRefundable || !!roomOnly) && (
                <p className={styles.titleAndBed}>
                  <span className={styles.cancellationType}>
                    {!isRefundable ? "Free Cancellation" : ""}
                  </span>
                  <span className={styles.roomOnlyType}>
                    {!roomOnly ? "Breakfast included" : ""}
                  </span>
                </p>
              )}
              {!!cancellationPolicy && (
                <p
                  className={`${styles.cancellationPolicyStyles} ${(!!isRefundable || !!roomOnly) && styles.noTopSpace}`}
                  onClick={handleCancellationPolicy}
                >
                  Cancellation Policy
                </p>
              )}
              <div className={styles.checkInAndCheckOutContainer}>
                <div className={styles.checkInAndCheckOut}>
                  Check-in &nbsp; <span>3pm</span> &nbsp; | &nbsp; Check-out{" "}
                  &nbsp; <span>11am</span>
                </div>
                <div
                  className={styles.amenitiesFooter}
                  // onClick={() => {}}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // setViewRoomDetailsModal(true);
                    setShowRoomDetails(true);
                    setRoomDetailsData(roomInfo);
                  }}
                >
                  VIEW ROOM DETAILS
                  <img
                    src={eyeLight}
                    alt="icon"
                    className={styles.amenityFooterIcon}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      {hotelAmenities.length > 0 && (
        <CustomAccordion
          className={styles.customStyles}
          summaryClass={styles.summaryClass}
          accordianDetailsClass={styles.accordianDetailsClass}
          defaultExpanded={true}
          headingComponent={<span className={styles.heading}>Amenities</span>}
          bodyComponent={
            <Amenities
              aminitiesArr={hotelAmenities.slice(0, 8)}
              showViewPropAmenities
              amenitiesIconsArr={amenitiesIcons}
              onClickPropertyDetails={handleClickPropertyDetails}
            />
          }
          zeroMarginOnExpand
          // borderBottom
          hideDropDownIcon
        />
      )}
      <div className={styles.divider} />
      {aboutProperty.length > 0 && (
        <CustomAccordion
          className={styles.customStyles}
          summaryClass={styles.summaryClass}
          hideDropDownIcon
          defaultExpanded={true}
          headingComponent={
            <span className={styles.heading}>About the Property</span>
          }
          bodyComponent={<AboutProperty aboutPropertyArr={aboutProperty} />}
          // zeroMarginOnExpand
          borderBottom
        />
      )}

      {/* <SelectRoomModal
        isOpen={showSelectRoomModal}
        onClose={() => setShowSelectRoomModal(false)}
      /> */}
    </div>
  );
}

export default HotelDetails;
