// import { chatIcon } from "../../../constant";
import styles from "./loader.module.scss";

function Loader({
  size = "20",
  white = false,
  text,
}: {
  size?: string;
  white?: boolean;
  text?: string;
}): JSX.Element {
  return (
    <div className={styles.loadContainer}>
      {/* <div className={styles.systemChatIcon}>
        <img className={styles.chatIcon} alt="chat logo" src={chatIcon} />
        <h3 className={styles.heading}>ZenVoya</h3>
      </div> */}
      {text && <p className={styles.loaderText}>{text}</p>}
      <div className={styles.loader}>
        <div
          className={`${styles.circle} ${white && styles.whiteCircle}`}
          style={{ width: size + "px", height: size + "px" }}
        ></div>
        <div
          className={`${styles.circle} ${white && styles.whiteCircle}`}
          style={{ width: size + "px", height: size + "px" }}
        ></div>
        <div
          className={`${styles.circle} ${white && styles.whiteCircle}`}
          style={{ width: size + "px", height: size + "px" }}
        ></div>
        <div
          className={`${styles.circle} ${white && styles.whiteCircle}`}
          style={{ width: size + "px", height: size + "px" }}
        ></div>
      </div>
    </div>
  );
}

export default Loader;
