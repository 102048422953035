import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import BackButton from "../../component/backBtn/backBtn";
import CustomAccordion from "../../component/reusableComponent/customAccordion/CustomAccordion";
import { personWalkingLuggage, ROUTES } from "../../constant";
import styles from "./traveler.module.scss";
import TravelerInformationForma from "../../component/booking/travelerInformation/travelerInformationForm";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../../context/globalContext/context";
import {
  flyerProgramType,
  iGlobalContext,
} from "../../context/globalContext/interface";
import { useRefLocalStorage } from "../../hooks/useRefLocalStorage";
import { toastMessage } from "../../helpers/toast/toastMessage";
import toast from "react-hot-toast";
import {
  alphaNumericRegex,
  APP_CONFIG,
  emailRegex,
  isValidDate,
  phoneRegex,
} from "../../constant/common";
import { ScreenTypes } from "../../enums";
import {
  checkHotelRoomAvailable,
  // bookHotelRoomAPI,
} from "../../api/hotelDetailsAPI";
// import { bookFlightAPI } from "../../api/booking/bookFlightAPI";
// import BookedSuccessDialog from "../../component/dialog/bookedSuccessDialog/BookedSuccessDialog";
import { getConfigMessage, getPaxTypeFunc, PAXTYPE } from "../../utils";
// import { getBookedFlightDetailsAPI } from "../../api/booking/getBookedFlightDetailsAPI";
import { saveBookedTransactionDetailsAPI } from "../../api/booking/saveBookedTransactionDetailsAPI";
import { options } from "../../constant/dummyData";
// import axios from "axios";
import { APP_URL } from "../../constant/environment";
import { bookFlightHotelAPI } from "../../api/booking/bookFlightHotelAPI";
import {
  bookHotelAndFlight,
  fetchCountries,
  getFlightAvailability,
  PassengerDetail,
} from "../../api/booking/booking";
import { TOAST_MESSAGES } from "../../constant/messages/toastMessages";
import { iCountryData } from "../../interface/common";
import {
  BookHotelAndFlightResponse,
  iFlightAvailabilityResponse,
} from "../../interface";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import NewLoader from "../../component/reusableComponent/newLoader/NewLoader";

function TravelerInformation(): JSX.Element {
  const state = useContext(StateContext);
  const {
    bookingDetails,
    flightAvailabilityData,
    isMobile,
    setScreen,
    // selectedHotelRoom,
    selectedFlightOptions,
    appConfig,
    selectedChatData,
    // setSelectedChatData,
    selectedHotelRoomArr,
    // setTripOrItinearyId,
    userInfo,
    // setMyTripActiveTab,
    // setFirstScreen,
    setBookedTransIds,
    setIsUserNavigatingFromPaymentGateway,
    setIsUserJustBookedTrip,
    firstScreen,
    setIsMyTripsTheFirstSelectedScreen,
    setBookingDetails,
    setFlightAvailabilityData,
    countriesData,
    setCountriesData,
  }: iGlobalContext = state;
  const navigate = useNavigate();
  const isDeviceMobile = window.matchMedia("(max-width: 768px)").matches;
  const backButtonRef = useRef<HTMLDivElement>(null);
  const handleBackButton = () => {
    if (!isMobile) {
      setScreen(ScreenTypes.Booking);
      return;
    }
    navigate(ROUTES.Booking);
  };

  const [isLoading, setIsloading] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const { adultsCount, childrenCount, seniorsCount } = bookingDetails;

  let travelerCount = adultsCount + childrenCount + seniorsCount;
  const [showErrorMessageFlag, setShowErrorMessageFlag] = useState(false);
  const { handleCustomError } = useCustomErrorHandler();

  let checkFlightController: any = useRef(null);
  let checkingAvailabilityController: any = useRef(null);

  const handleFlightAvailability = useCallback(
    async (encryptedKey: string, uniqueId: string): Promise<boolean> => {
      // setLoading(true);
      // setIsCheckingFlight(true);
      // toast.dismiss();
      try {
        checkFlightController.current = new AbortController();
        const { statusCode, data: flightData }: iFlightAvailabilityResponse =
          await getFlightAvailability(
            encryptedKey,
            uniqueId,
            checkFlightController.current
          );
        if (statusCode !== 200) {
          toast.dismiss();
          toastMessage.error(
            TOAST_MESSAGES.BOOKING.Search.FlightAvailabilityFailure
              .messageTitle,
            TOAST_MESSAGES.BOOKING.Search.FlightAvailabilityFailure.message
          );
          if (isDeviceMobile) navigate(ROUTES.Booking);
          if (!isDeviceMobile) setScreen(ScreenTypes.Booking);
          handleCustomError(statusCode, "flight.availability");
          return false;
        }
        if (statusCode === 200) {
          setBookingDetails((prevBookingDetails) => ({
            ...prevBookingDetails,
            isFlightAvailable: true,
            selectedFlightId: uniqueId,
          }));
          // handleBook();
          setFlightAvailabilityData((prevData: any) => ({
            ...prevData,
            ...flightData,
          }));
          return true;
        }
        return false;
      } catch (err) {
        toast.dismiss();
        toastMessage.error(
          TOAST_MESSAGES.BOOKING.Search.FlightAvailabilityFailure.messageTitle,
          TOAST_MESSAGES.BOOKING.Search.FlightAvailabilityFailure.message
        );
        if (isDeviceMobile) navigate(ROUTES.Booking);
        if (!isDeviceMobile) setScreen(ScreenTypes.Booking);
        console.error("Failed to check flight availability:", err);
        return false;
      }
    },
    [
      handleCustomError,
      isDeviceMobile,
      navigate,
      setBookingDetails,
      setFlightAvailabilityData,
      setScreen,
    ]
  );

  const checkHotelAvailability = useCallback(async () => {
    checkingAvailabilityController.current = new AbortController();
    const response = await checkHotelRoomAvailable(
      {
        hotels: selectedHotelRoomArr.map((item) => ({
          uniqueId: item?.selectedHotelRoom?.responseUniqueId || "",
          sessionId: item?.selectedHotelRoom?.sessionId || "",
        })),
      },
      checkingAvailabilityController.current,
      () => {}
    );

    // // console.log(response, "response2");
    if (Array.isArray(response)) {
      // toastMessage.success(
      //   "Selected hotel room is available, Please proceed with your booking"
      // );
      // setTimeout(toast.dismiss, 2000);
      // let roomData = {
      //   selectedHotelId: getHotelRoomsBody.hotelId,
      //   selectedRoomId: hotelUniqueId,
      //   checkIn,
      //   checkOut,
      //   preBookId: response[0]?.prebookId,
      //   sessionId: response[0]?.sessionId,
      //   responseUniqueId: response[0]?.uniqueId,
      //   type,
      //   price,
      //   actualPrice,
      //   feesIncluded,
      //   taxIncluded,
      //   isBooked: false,
      // };
      // setSelectedHotelRoom(roomData);
      // setSelectedHotelRoomArr(
      //   selectedHotelRoomArr.map((eachItem) => {
      //     if (
      //       eachItem?.checkIn === checkIn &&
      //       eachItem?.checkOut === checkOut &&
      //       eachItem?.city === city
      //     ) {
      //       return { ...eachItem, selectedHotelRoom: roomData };
      //     }
      //     return eachItem;
      //   })
      // );
      return response;
    }
    if (!response) {
      toast.dismiss();
      toastMessage.error(
        TOAST_MESSAGES.BOOKING.Search.HotelAvailabilityFailure.messageTitle,
        TOAST_MESSAGES.BOOKING.Search.HotelAvailabilityFailure.message
      );
      if (isDeviceMobile) navigate(ROUTES.Booking);
      if (!isDeviceMobile) setScreen(ScreenTypes.Booking);
      return null;
    }
  }, [isDeviceMobile, navigate, selectedHotelRoomArr, setScreen]);

  const validateForm = () => {
    travelerInfoArrayDataRef?.current?.forEach(
      (ele: any, index: number, eleArr: any) => {}
    );
    let childrensCountFromTravellersDetails = 0;
    for (let i = 0; i < travelerInfoArrayDataRef?.current?.length; i++) {
      const ele = travelerInfoArrayDataRef?.current[i];
      if (
        getPaxTypeFunc(
          `${ele?.dateOfYear}-${options.find((option) => option.label === ele?.dateOfBirthMonth)?.value}-${ele?.dateOfBirth?.length === 1 ? "0" + ele?.dateOfBirth : ele?.dateOfBirth}`
        ) === PAXTYPE.CHILD
      ) {
        childrensCountFromTravellersDetails++;
      }
      if (ele?.firstName === "" || ele?.firstName?.length < 3) {
        toast.dismiss();
        toastMessage.error(
          `Enter the Traveler ${i + 1} first name with minimum 3 letters`
        );
        setShowErrorMessageFlag(true);
        return false;
      }
      if (ele?.lastName === "" || ele?.lastName?.length < 3) {
        const emptyFieldKey =
          Object.keys(ele).find((key) => ele[key] === "") || "";
        toast.dismiss();
        toastMessage.error(
          `Enter the Traveler ${i + 1} last name with minimum 3 letters`
        );
        setShowErrorMessageFlag(true);
        return false;
      }
      if (
        ele?.dateOfBirthMonth === "" ||
        ele?.dateOfBirth === "" ||
        ele?.dateOfYear === ""
      ) {
        toast.dismiss();
        toastMessage.error(`Enter the Traveler ${i + 1} DOB`);
        setShowErrorMessageFlag(true);
        return false;
      }
      if (
        i === 0 &&
        getPaxTypeFunc(
          `${ele?.dateOfYear}-${options.find((option) => option.label === ele?.dateOfBirthMonth)?.value}-${ele?.dateOfBirth?.length === 1 ? "0" + ele?.dateOfBirth : ele?.dateOfBirth}`
        ) === PAXTYPE.CHILD
      ) {
        toast.dismiss();
        toastMessage.error(`Entered Traveler ${i + 1} can not be a child`);
        setShowErrorMessageFlag(true);
        return false;
      }
      if (Number(ele?.dateOfYear) < Number(new Date().getFullYear() - 150)) {
        toast.dismiss();
        toastMessage.error(
          `Entered the Traveler ${i + 1} DOB year is not valid`
        );
        setShowErrorMessageFlag(true);
        return false;
      }
      if (Number(ele?.dateOfBirth) < 1) {
        toast.dismiss();
        toastMessage.error(
          `Entered the Traveler ${i + 1} DOB date is not valid`
        );
        setShowErrorMessageFlag(true);
        return false;
      }
      if (ele?.gender === "") {
        toast.dismiss();
        toastMessage.error(`Enter the Traveler ${i + 1} gender`);
        setShowErrorMessageFlag(true);
        return false;
      }
      if (ele?.email === "" && i === 0) {
        toast.dismiss();
        toastMessage.error(`Enter the Traveler ${i + 1} email address`);
        setShowErrorMessageFlag(true);
        return false;
      }
      if (!emailRegex.test(ele?.email) && i === 0) {
        toast.dismiss();
        toastMessage.error(`Traveler ${i + 1} email address is not valid`);
        setShowErrorMessageFlag(true);
        return false;
      }
      if (ele?.email !== "" && i !== 0 && !emailRegex.test(ele?.email)) {
        toast.dismiss();
        toastMessage.error(`Traveler ${i + 1} email address is not valid`);
        // setShowErrorMessageFlag(true);
        return false;
      }
      if (!phoneRegex.test(ele?.phoneNumber) && i === 0) {
        toast.dismiss();
        toastMessage.error(`Traveler ${i + 1} phone number is not valid`);
        setShowErrorMessageFlag(true);
        return false;
      }
      if (
        ele?.phoneNumber !== "" &&
        i !== 0 &&
        !phoneRegex.test(ele?.phoneNumber)
      ) {
        toast.dismiss();
        toastMessage.error(`Traveler ${i + 1} phone number is not valid`);
        // setShowErrorMessageFlag(true);
        return false;
      }
      if (
        !isValidDate(
          `${ele?.dateOfYear}-${options.find((option) => option.label === ele?.dateOfBirthMonth)?.value}-${ele?.dateOfBirth?.length === 1 ? "0" + ele?.dateOfBirth : ele?.dateOfBirth}`
        )
      ) {
        toast.dismiss();
        toastMessage.error(`Entered Traveler ${i + 1} DOB date is not valid`);
        setShowErrorMessageFlag(true);
        return false;
      }
      if (
        selectedFlightOptions?.flights &&
        flightAvailabilityData?.international
      ) {
        if (ele?.documentType === "") {
          toast.dismiss();
          toastMessage.error(`Select the Traveler ${i + 1} document type`);
          setShowErrorMessageFlag(true);
          return false;
        }
        if (ele?.documentNumber === "") {
          toast.dismiss();
          toastMessage.error(`Enter the Traveler ${i + 1} document number`);
          setShowErrorMessageFlag(true);
          return false;
        }
        if (
          travelerInfoArrayDataRef?.current?.filter(
            (item: any) => item.documentNumber === ele?.documentNumber
          )?.length > 1
        ) {
          toast.dismiss();
          toastMessage.error(
            `Entered the Traveler ${i + 1} document number is same as other traveller's document number. "Document Number for travellers to be unique`
          );
          setShowErrorMessageFlag(true);
          return false;
        }
        if (
          // !isAlphaNumericString(ele?.documentNumber) ||
          !alphaNumericRegex.test(ele?.documentNumber)
        ) {
          toast.dismiss();
          toastMessage.error(
            `Enter the valid Traveler ${i + 1} document number`
          );
          setShowErrorMessageFlag(true);
          return false;
        }
        const selectedNationality = countriesData.find(
          (item) => item.name === ele?.nationality
        )?.iso;
        const selectedIssuing = countriesData.find(
          (item) => item.name === ele?.issuingCountry
        )?.iso;

        if (ele?.issuingCountry === "" || !selectedIssuing) {
          toast.dismiss();
          toastMessage.error(`Select the Traveler ${i + 1} Issuing country`);
          setShowErrorMessageFlag(true);
          return false;
        }
        if (ele?.nationality === "" || !selectedNationality) {
          toast.dismiss();
          toastMessage.error(`Select the Traveler ${i + 1} Nationality`);
          setShowErrorMessageFlag(true);
          return false;
        }
        if (ele?.docIssueMonth === "") {
          toast.dismiss();
          toastMessage.error(
            `Select the Traveler ${i + 1} document issue month`
          );
          setShowErrorMessageFlag(true);
          return false;
        }
        if (ele?.docIssueDate === "") {
          toast.dismiss();
          toastMessage.error(`Enter the Traveler ${i + 1} document issue date`);
          setShowErrorMessageFlag(true);
          return false;
        }
        if (Number(ele?.docIssueDate) < 1) {
          toast.dismiss();
          toastMessage.error(
            `Entered the Traveler ${i + 1} document issue date is not valid`
          );
          setShowErrorMessageFlag(true);
          return false;
        }
        if (ele?.docIssueYear === "") {
          toast.dismiss();
          toastMessage.error(`Enter the Traveler ${i + 1} document issue year`);
          setShowErrorMessageFlag(true);
          return false;
        }
        if (ele?.docExpiryMonth === "") {
          toast.dismiss();
          toastMessage.error(
            `Select the Traveler ${i + 1} document expiry month`
          );
          setShowErrorMessageFlag(true);
          return false;
        }
        if (ele?.docExpiryDate === "") {
          toast.dismiss();
          toastMessage.error(
            `Enter the Traveler ${i + 1} document expiry date`
          );
          setShowErrorMessageFlag(true);
          return false;
        }
        if (Number(ele?.docExpiryDate) < 1) {
          toast.dismiss();
          toastMessage.error(
            `Entered the Traveler ${i + 1} document expiry date is not valid`
          );
          setShowErrorMessageFlag(true);
          return false;
        }
        if (ele?.docExpiryYear === "") {
          toast.dismiss();
          toastMessage.error(
            `Enter the Traveler ${i + 1} document expiry year`
          );
          setShowErrorMessageFlag(true);
          return false;
        }
        if (Number(ele?.docExpiryYear) < Number(ele?.docIssueYear)) {
          toast.dismiss();
          toastMessage.error(
            `Entered Traveler ${i + 1} document expiry year and issue year are wrong. Expiry date should always be greater than issue year`
          );
          setShowErrorMessageFlag(true);
          return false;
        }
        if (
          new Date(
            `${ele?.docIssueYear}-${options.find((option) => option.label === ele?.docIssueMonth)?.value}-${ele?.docIssueDate?.length === 1 ? "0" + ele?.docIssueDate : ele?.docIssueDate}`
          ).getTime() > new Date().getTime()
        ) {
          toast.dismiss();
          toastMessage.error(
            `Entered Traveler ${i + 1} document issue date is wrong. Issue date should not be greater than present date`
          );
          setShowErrorMessageFlag(true);
          return false;
        }
        if (
          // Number(ele?.docIssueYear) <= Number(ele?.dateOfYear)
          new Date(
            `${ele?.docIssueYear}-${options.find((option) => option.label === ele?.docIssueMonth)?.value}-${ele?.docIssueDate?.length === 1 ? "0" + ele?.docIssueDate : ele?.docIssueDate}`
          ).getTime() <=
          new Date(
            `${ele?.dateOfYear}-${options.find((option) => option.label === ele?.dateOfBirthMonth)?.value}-${ele?.dateOfBirth?.length === 1 ? "0" + ele?.dateOfBirth : ele?.dateOfBirth}`
          ).getTime()
        ) {
          toast.dismiss();
          toastMessage.error(
            `Entered Traveler ${i + 1} document issue year and date of birth are not valid. Issue date should always be greated than birth year`
          );
          setShowErrorMessageFlag(true);
          return false;
        }
        if (
          new Date().getTime() >=
          new Date(
            `${ele?.docExpiryYear}-${options.find((option) => option.label === ele?.docExpiryMonth)?.value}-${ele?.docExpiryDate?.length === 1 ? "0" + ele?.docExpiryDate : ele?.docExpiryDate}`
          ).getTime()
        ) {
          // console.log(
          //   new Date(
          //     `${ele?.docIssueYear}-${options.find((option) => option.label === ele?.docIssueMonth)?.value}-${ele?.docIssueDate?.length === 1 ? "0" + ele?.docIssueDate : ele?.docIssueDate}`
          //   ).getTime() <
          //     new Date(
          //       `${ele?.docExpiryYear}-${options.find((option) => option.label === ele?.docExpiryMonth)?.value}-${ele?.docExpiryDate?.length === 1 ? "0" + ele?.docExpiryDate : ele?.docExpiryDate}`
          //     ).getTime(),
          //   (new Date(
          //     `${ele?.docExpiryYear}-${options.find((option) => option.label === ele?.docExpiryMonth)?.value}-${ele?.docExpiryDate?.length === 1 ? "0" + ele?.docExpiryDate : ele?.docExpiryDate}`
          //   ).getTime() -
          //     new Date(
          //       `${ele?.docIssueYear}-${options.find((option) => option.label === ele?.docIssueMonth)?.value}-${ele?.docIssueDate?.length === 1 ? "0" + ele?.docIssueDate : ele?.docIssueDate}`
          //     ).getTime()) /
          //     (1000 * 60 * 60 * 24)
          // );
          toast.dismiss();
          toastMessage.error(
            `Entered Traveler ${i + 1} document expiry should be greater than the present date`
          );
          setShowErrorMessageFlag(true);
          return false;
        }
        if (
          (new Date(
            `${ele?.docExpiryYear}-${options.find((option) => option.label === ele?.docExpiryMonth)?.value}-${ele?.docExpiryDate?.length === 1 ? "0" + ele?.docExpiryDate : ele?.docExpiryDate}`
          ).getTime() -
            new Date(
              `${ele?.docIssueYear}-${options.find((option) => option.label === ele?.docIssueMonth)?.value}-${ele?.docIssueDate?.length === 1 ? "0" + ele?.docIssueDate : ele?.docIssueDate}`
            ).getTime()) /
            (1000 * 60 * 60 * 24) <
          180
        ) {
          toast.dismiss();
          toastMessage.error(
            `Entered Traveler ${i + 1} document expiry should be greater than the issue date by atleast 6 months`
          );
          setShowErrorMessageFlag(true);
          return false;
        }
        if (
          !isValidDate(
            `${ele?.docIssueYear}-${options.find((option) => option.label === ele?.docIssueMonth)?.value}-${ele?.docIssueDate?.length === 1 ? "0" + ele?.docIssueDate : ele?.docIssueDate}`
          )
        ) {
          toast.dismiss();
          toastMessage.error(
            `Entered Traveler ${i + 1} document issue date is not valid`
          );
          setShowErrorMessageFlag(true);
          return false;
        }
        if (
          !isValidDate(
            `${ele?.docExpiryYear}-${options.find((option) => option.label === ele?.docExpiryMonth)?.value}-${ele?.docExpiryDate?.length === 1 ? "0" + ele?.docExpiryDate : ele?.docExpiryDate}`
          )
        ) {
          toast.dismiss();
          toastMessage.error(
            `Entered Traveler ${i + 1} document expiry date is not valid`
          );
          setShowErrorMessageFlag(true);
          return false;
        }
        if (ele?.programName && ele?.programName !== flyerProgramType.none) {
          if (!ele?.frequentFlyerNumber) {
            toast.dismiss();
            toastMessage.error(`Enter Traveler ${i + 1} frequent flyer number`);
            setShowErrorMessageFlag(true);
            return false;
          }
        }
        if (ele?.frequentFlyerNumber) {
          if (!ele?.programName || ele?.programName === flyerProgramType.none) {
            toast.dismiss();
            toastMessage.error(
              `Select Traveler ${i + 1} frequent flyer programe name`
            );
            setShowErrorMessageFlag(true);
            return false;
          }
        }
        // if(ele?.docIssueMonth)
        // return false;
        // documentType: "",
        //     documentNumber: "",
        //     issuingCountry: "",
        //     nationality: "",
        //     docIssueMonth: "",
        //     docIssueDate: "",
        //     docIssueYear: "",
        //     docExpiryMonth: "",
        //     docExpiryDate: "",
        //     docExpiryYear: "",
      }
    }
    if (childrensCountFromTravellersDetails !== childrenCount) {
      toast.dismiss();
      toastMessage.error(
        `Number of children travellers should be ${childrenCount} and Children should be below 12 years of age`
      );
      setShowErrorMessageFlag(true);
      return false;
    }
    setShowErrorMessageFlag(false);
    return true;
  };

  const parsedTravellerInformation = localStorage.getItem(
    "_traveler_info_array"
  )
    ? JSON.parse(localStorage.getItem("_traveler_info_array") || "")
    : [];

  /**
   * Booking Flight API
   */
  // const [bookedFlightSuccess, setBookedFlightSuccess] = useState({
  //   openDialog: false,
  //   bookedId: "",
  // });

  // const bookFlightHotel = async () => {
  //   const passengerDetails = travelerInfoArrayDataRef?.current?.map(
  //     (ele: any) => {
  //       let localMealPreference = ele?.mealPref !== "" ? [ele?.mealPref] : [];
  //       // console.log("matchedMeals", localMealPreference);
  //       if (localMealPreference.length === 0) {
  //         const matchedMeals: any =
  //           userInfo?.preferences?.foodPreferences?.filter((meal: any) =>
  //             flightAvailabilityData?.extraServiceDetails?.mealsPreference.some(
  //               (item: any) =>
  //                 item.label.toLowerCase().includes(meal.toLowerCase())
  //             )
  //           );
  //         localMealPreference = matchedMeals || [];
  //         // console.log({ matchedMeals }, { localMealPreference });
  //       }
  //       return {
  //         paxType: "ADT",
  //         gender: ele?.gender?.toLowerCase() || "male",
  //         leadPax: true,
  //         title: ele?.gender === "MALE" ? "Mr" : "Ms",
  //         firstName: ele?.firstName,
  //         lastName: ele?.lastName,
  //         email: ele?.email,
  //         countryCode: ele?.countryCode
  //           ? ele?.countryCode.substring(1, ele.countryCode.length)
  //           : "1",
  //         mobileNumber: ele?.phoneNumber,
  //         dateOfBirth: `${ele?.dateOfYear}-${options.find((option) => option.label === ele?.dateOfBirthMonth)?.value}-${ele?.dateOfBirth?.length === 1 ? "0" + ele?.dateOfBirth : ele?.dateOfBirth}`, // YYYY-MM-DD
  //         infantData: {
  //           title: "Mr",
  //           firstName: "Azam",
  //           lastName: "Mohammed",
  //           dateOfBirth: "12-13-2023",
  //           document: {
  //             type: "Passport",
  //             number: "ZN2323111",
  //             nationality: "AF",
  //             issuedCountry: "AF",
  //             issuedDate: "12-13-2023",
  //             expiryDate: "06-06-2024",
  //           },
  //         },
  //         document: {
  //           type: "Passport",
  //           number: "ZNC123433",
  //           nationality: "AF",
  //           issuedCountry: "AF",
  //           issuedDate: "1996-12-13",
  //           expiryDate: "2033-06-06",
  //         },
  //         operationalReference: {
  //           specialAssistance: "",
  //         },
  //         frequentFlyerinfo: {
  //           flyerNumber: "",
  //           frequentFlyerProgram: ele?.programName || null,
  //           airline: "",
  //         },
  //         meals: localMealPreference || "",
  //       };
  //     }
  //   );
  //   let payload = {
  //     clientId: "uuid",
  //     userId: 1012,
  //     paymentDetails: {
  //       successUrl: "https://app.zenvoya.com/success",
  //       cancelUrl: "https://app.zenvoya.com/cancel",
  //       amount: "100.00",
  //       currency: "USD",
  //       remarks: "",
  //     },
  //     flight: flightAvailabilityData.transactionId,
  //     hotel: [
  //       {
  //         prebookId: "x3JEuy0iA",
  //         sessionId: "TQJXBd1vDOl5hFgV",
  //       },
  //       {
  //         prebookId: "eDlSc-Pua",
  //         sessionId: "qdoN0QAfJDBL22Jh",
  //       },
  //     ],
  //     passengerDetails: passengerDetails,
  //   };
  //   const response = await bookFlightHotelAPI(payload);
  //   if (response?.statusCode === 200) {
  //     // setBookedFlightSuccess({
  //     //   ...bookedFlightSuccess,
  //     //   openDialog: true,
  //     //   bookedId: response?.data?.itineraryID || "",
  //     // });
  //     const { itineraryID, transactionId } = response?.data;
  //     // console.log(itineraryID, transactionId);
  //     await saveBookedTransactionDetails({
  //       isFlight: true,
  //       bookedTransactionId: transactionId,
  //       bookedItineraryId: itineraryID,
  //       startDate: bookingDetails.travelFromDate,
  //       endDate: bookingDetails.travelToDate,
  //     });
  //     return true;
  //   }
  // };

  /**
   * Form data
   */
  const [travelerInfoArrayDataRef, setTravelerInfoArrayDataRef] =
    useRefLocalStorage(
      "_traveler_info_array",
      travelerCount === parsedTravellerInformation?.length
        ? parsedTravellerInformation
        : Array(travelerCount).fill({
            firstName: "",
            middleName: "",
            lastName: "",
            dateOfBirthMonth: "",
            dateOfBirth: "",
            dateOfYear: "",
            gender: "",
            email: userInfo?.email || "",
            documentType: "",
            documentNumber: "",
            issuingCountry: "",
            nationality: "",
            docIssueMonth: "",
            docIssueDate: "",
            docIssueYear: "",
            docExpiryMonth: "",
            docExpiryDate: "",
            docExpiryYear: "",
            countryCode: "",
            phoneNumber: userInfo?.phone || userInfo?.phone || "",
            programName: "",
            frequentFlyerNumber: "",
            passportNumber: "",
            mealPref: "",
            selectedOptions: {},
          })
    );

  const handlePreBookingResponse = useCallback(
    (paymentLink: string, itineraryID: string) => {
      if (firstScreen === ScreenTypes.MyTrips)
        setIsMyTripsTheFirstSelectedScreen(true);
      if (paymentLink && itineraryID) {
        setTimeout(() => {
          window.location.replace(paymentLink);
        }, 1000);
        // navigate(paymentLink, { replace: true, state: {} });
      }
      if (!paymentLink || !itineraryID)
        toastMessage.error(
          TOAST_MESSAGES.BOOKING.BOOK.bookFailure.messageTitle,
          TOAST_MESSAGES.BOOKING.BOOK.bookFailure.message
        );
    },
    [firstScreen, setIsMyTripsTheFirstSelectedScreen]
  );

  const bookFlight = async () => {
    // let payload = {
    //   transactionId: flightAvailabilityData.transactionId,
    //   clientId: "uuid",
    //   userId: 1012,
    //   passengerDetails: passengerDetails,
    // };
    // console.log("payload", travelerInfoArrayDataRef?.current);
    // console.log({ payload });
    const passengerDetails: PassengerDetail[] =
      travelerInfoArrayDataRef?.current?.map(
        (ele: any, index: number): PassengerDetail => {
          let localMealPreference = ele?.mealPref !== "" ? [ele?.mealPref] : [];
          // console.log("matchedMeals", localMealPreference);
          if (localMealPreference.length === 0) {
            const matchedMeals: any =
              userInfo?.preferences?.foodPreferences?.filter((meal: any) =>
                flightAvailabilityData?.extraServiceDetails?.mealsPreference.some(
                  (item: any) =>
                    item.label.toLowerCase().includes(meal.toLowerCase())
                )
              );
            localMealPreference = matchedMeals || [];
            // console.log({ matchedMeals }, { localMealPreference });
          }
          const mealPreferencesData =
            flightAvailabilityData?.extraServiceDetails?.mealsPreference?.find(
              (item: any) => {
                return ele?.mealPref === item?.label;
              }
            ) || {};

          const mealPreferencesDataString = mealPreferencesData.label
            ? [`${mealPreferencesData?.label}-${mealPreferencesData.value}`]
            : null;

          const specialServicesData =
            flightAvailabilityData?.extraServiceDetails?.specialAssistance?.find(
              (item: any) => {
                return ele?.selectedOptions[item?.label];
              }
            ) || {};

          const specialServicesDataString = specialServicesData.label
            ? `${specialServicesData?.label}-${specialServicesData.value}`
            : null;
          // console.log(
          //   // ele,
          //   specialServicesDataString,
          //   specialServicesData,
          //   "element"
          // );
          const selectedNationality = countriesData.find(
            (item) => item.name === ele?.nationality
          )?.iso;
          const selectedIssuing = countriesData.find(
            (item) => item.name === ele?.issuingCountry
          )?.iso;
          const selectedPhoneNumberCode = countriesData.find(
            (item) => item.name === ele?.countryCode.split(" ")[0]
          )?.code;
          return {
            countryCode: selectedPhoneNumberCode
              ? selectedPhoneNumberCode
              : "1",
            email: ele?.email,
            middleName: ele?.middleName,
            firstName: ele?.firstName,
            lastName: ele?.lastName,
            leadPax: index === 0 ? true : false,
            mobileNumber: ele?.phoneNumber,
            paxType: getPaxTypeFunc(
              `${ele?.dateOfYear}-${options.find((option) => option.label === ele?.dateOfBirthMonth)?.value}-${ele?.dateOfBirth?.length === 1 ? "0" + ele?.dateOfBirth : ele?.dateOfBirth}`
            ),
            title: ele?.gender === "MALE" ? "Mr" : "Ms",
            dateOfBirth: `${ele?.dateOfYear}-${options.find((option) => option.label === ele?.dateOfBirthMonth)?.value}-${ele?.dateOfBirth?.length === 1 ? "0" + ele?.dateOfBirth : ele?.dateOfBirth}`, // YYYY-MM-DD
            document: {
              type: ele?.documentType || null,
              number: ele?.documentNumber || null,
              nationality: selectedNationality || null,
              issuedCountry: selectedIssuing || null,
              issuedDate: flightAvailabilityData?.international
                ? `${ele?.docIssueYear}-${options.find((option) => option.label === ele?.docIssueMonth)?.value}-${ele?.docIssueDate?.length === 1 ? "0" + ele?.docIssueDate : ele?.docIssueDate}`
                : null, // YYYY-MM-DD,
              expiryDate: flightAvailabilityData?.international
                ? `${ele?.docExpiryYear}-${options.find((option) => option.label === ele?.docExpiryMonth)?.value}-${ele?.docExpiryDate?.length === 1 ? "0" + ele?.docExpiryDate : ele?.docExpiryDate}`
                : null, // YYYY-MM-DD,
            },
            frequentFlyerinfo: {
              flyerNumber: ele?.frequentFlyerNumber || null,
              frequentFlyerProgram:
                (ele?.programName !== flyerProgramType.none &&
                  (ele?.programName || null)) ||
                null,
              airline: "",
            },
            gender: ele?.gender?.toLocaleLowerCase(),
            infantData: null,
            meals: mealPreferencesDataString || null,
            operationalReference: {
              specialAssistance: specialServicesDataString || null,
            },
          };
        }
      );

    const isFlightAvailable = await handleFlightAvailability(
      flightAvailabilityData?.encryptedKey,
      flightAvailabilityData?.uniqueId
    );

    if (isFlightAvailable) {
      const response = await bookHotelAndFlight(
        {
          clientId: "4f9b4522-4810-4718-a1b1-f08322bc0bd1",
          passengerDetails,
          userId: userInfo?.id || "",
          paymentDetails: {
            successUrl: APP_URL.slice(0, -1) + ROUTES.PaymentSuccess,
            cancelUrl: APP_URL.slice(0, -1) + ROUTES.PaymentFailure,
            // successUrl: "http://localhost:3000" + ROUTES.PaymentSuccess,
            // cancelUrl: "http://localhost:3000" + ROUTES.PaymentFailure,
            amount: `${flightAvailabilityData.flightPrice}`,
            currency: "USD",
            remarks: "",
          },
          flight: flightAvailabilityData.transactionId,
        },
        setIsBooking
      );
      // const response = await bookFlightAPI(payload);
      // console.log(response?.data);
      if (response) {
        // setBookedFlightSuccess({
        //   ...bookedFlightSuccess,
        //   openDialog: true,
        //   bookedId: response?.data?.itineraryID || "",
        // });
        const { itineraryID, transactionId, paymentLink = "" } = response;
        // console.log(itineraryID, transactionId, response);
        setBookedTransIds({
          itineraryID,
          flightTransactionId: transactionId,
        });
        handlePreBookingResponse(paymentLink, itineraryID);
        // await saveBookedTransactionDetails({
        //   isFlight: true,
        //   bookedTransactionId: transactionId,
        //   bookedItineraryId: itineraryID,
        //   startDate: bookingDetails.travelFromDate,
        //   endDate: bookingDetails.travelToDate,
        // });
        return true;
      }
      if (!response) {
        toastMessage.error(
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
      }
    }
  };

  /**
   * Booking hotel API
   */
  // const { preBookId = "", sessionId = "" } = selectedHotelRoom || {};
  // const {} = selectedHotelRoomArr || {};

  const fetchCountriesData = useCallback(async () => {
    const response = await fetchCountries();

    if (response.statusCode === 200) {
      setCountriesData(response.data);
    }
  }, [setCountriesData]);

  useEffect(() => {
    if (countriesData?.length === 0) fetchCountriesData();
  }, [countriesData?.length, fetchCountriesData]);

  useEffect(() => {
    setIsUserNavigatingFromPaymentGateway(false);
  }, []);

  const roomTotalPrice = selectedHotelRoomArr.reduce((acc, item) => {
    const { selectedHotelRoom } = item || {};
    const { price } = selectedHotelRoom || {};
    if (price) {
      return Number(acc) + Number(price);
    }
    return acc;
  }, 0);

  const bookHotel = useCallback(async () => {
    // // console.log("Booking hotel");
    // if (!preBookId) return;
    const areAllRoomsSelected = selectedHotelRoomArr.every((eachHotel) => {
      if (!eachHotel?.selectedHotelRoom || !eachHotel?.selectedHotelRoom) {
        // console.log("entered here");
        toast.dismiss();
        toastMessage.error(
          `Please selected any room for the ${eachHotel.city} for the checkIn date ${eachHotel?.checkIn}`
        );
        return false;
      }
      if (
        !eachHotel?.selectedHotelRoom?.preBookId ||
        !eachHotel?.selectedHotelRoom?.sessionId
      ) {
        toastMessage.error(
          `Please selected any room for the ${eachHotel.city} for the checkIn date ${eachHotel?.checkIn}`
        );
        return false;
      }
      return true;
    });
    // console.log(areAllRoomsSelected, "areallrooms");
    const hotelAvailabilityIds = await checkHotelAvailability();
    if (!areAllRoomsSelected) return;
    if (
      Array.isArray(hotelAvailabilityIds) &&
      hotelAvailabilityIds.length > 0
    ) {
      const response = await bookHotelAndFlight({
        clientId: "4f9b4522-4810-4718-a1b1-f08322bc0bd1",
        userId: userInfo?.id || "1",
        passengerDetails: travelerInfoArrayDataRef?.current?.map(
          (traveler: any): PassengerDetail => {
            const {
              email = null,
              firstName = null,
              lastName = null,
              phoneNumber = null,
              gender = null,
              middleName = null,
            } = traveler || {};

            const selectedPhoneNumberCode = countriesData.find(
              (item) => item.name === traveler?.countryCode.split(" ")[0]
            )?.code;
            return {
              countryCode: selectedPhoneNumberCode
                ? selectedPhoneNumberCode
                : "1",
              email,
              firstName,
              lastName,
              middleName,
              leadPax: true,
              paxType: getPaxTypeFunc(
                `${traveler?.dateOfYear}-${options.find((option) => option.label === traveler?.dateOfBirthMonth)?.value}-${traveler?.dateOfBirth?.length === 1 ? "0" + traveler?.dateOfBirth : traveler?.dateOfBirth}`
              ),
              mobileNumber: phoneNumber,
              title: gender === "FEMALE" ? "Mrs" : "Mr",
            };
          }
        ),
        paymentDetails: {
          successUrl: APP_URL.slice(0, -1) + ROUTES.PaymentSuccess,
          cancelUrl: APP_URL.slice(0, -1) + ROUTES.PaymentFailure,
          // successUrl: "http://localhost:3000" + ROUTES.PaymentSuccess,
          // cancelUrl: "http://localhost:3000" + ROUTES.PaymentFailure,
          amount: `${roomTotalPrice}`,
          currency: "USD",
          remarks: "",
        },
        hotel: hotelAvailabilityIds?.map((item) => ({
          prebookId: item?.prebookId,
          sessionId: item?.sessionId,
        })),
      });
      if (response) {
        // // console.log(response, "response from hotel book");
        const {
          itineraryID,
          transactionId,
          paymentLink = "",
          // paymentDetails = "",
        } = response;
        // console.log({ response });
        // setBookedFlightSuccess({
        //   ...bookedFlightSuccess,
        //   openDialog: true,
        //   bookedId: response?.itineraryID || "",
        // });
        // console.log(itineraryID, transactionId, response);
        setBookedTransIds({
          itineraryID,
          hotelTransactionId: transactionId,
        });
        handlePreBookingResponse(paymentLink, itineraryID);
        // await saveBookedTransactionDetails({
        //   isFlight: false,
        //   bookedTransactionId: transactionId,
        //   bookedItineraryId: itineraryID,
        //   startDate: bookingDetails.travelFromDate,
        //   endDate: bookingDetails.travelToDate,
        // });
        // return;
        return true;
      }
      if (!response) {
        toastMessage.error(
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
      }
    }
  }, [
    appConfig,
    checkHotelAvailability,
    countriesData,
    handlePreBookingResponse,
    roomTotalPrice,
    selectedHotelRoomArr,
    setBookedTransIds,
    travelerInfoArrayDataRef,
    userInfo?.id,
  ]);

  const bookHotelAndFlightAPI = useCallback(async () => {
    const areAllRoomsSelected = selectedHotelRoomArr.every((eachHotel) => {
      if (!eachHotel?.selectedHotelRoom || !eachHotel?.selectedHotelRoom) {
        // console.log("entered here");
        toast.dismiss();
        toastMessage.error(
          `Please selected any room for the ${eachHotel.city} for the checkIn date ${eachHotel?.checkIn}`
        );
        return false;
      }
      if (
        !eachHotel?.selectedHotelRoom?.preBookId ||
        !eachHotel?.selectedHotelRoom?.sessionId
      ) {
        toastMessage.error(
          `Please selected any room for the ${eachHotel.city} for the checkIn date ${eachHotel?.checkIn}`
        );
        return false;
      }
      return true;
    });
    // console.log(areAllRoomsSelected, "areallrooms");
    const passengerDetails: PassengerDetail[] =
      travelerInfoArrayDataRef?.current?.map(
        (ele: any, index: number): PassengerDetail => {
          let localMealPreference = ele?.mealPref !== "" ? [ele?.mealPref] : [];
          // console.log("matchedMeals", localMealPreference);
          if (localMealPreference.length === 0) {
            const matchedMeals: any =
              userInfo?.preferences?.foodPreferences?.filter((meal: any) =>
                flightAvailabilityData?.extraServiceDetails?.mealsPreference.some(
                  (item: any) =>
                    item.label.toLowerCase().includes(meal.toLowerCase())
                )
              );
            localMealPreference = matchedMeals || [];
            // console.log({ matchedMeals }, { localMealPreference });
          }

          const specialServicesData =
            flightAvailabilityData?.extraServiceDetails?.specialAssistance?.find(
              (item: any) => {
                return ele?.selectedOptions[item?.label];
              }
            ) || {};

          const mealPreferencesData =
            flightAvailabilityData?.extraServiceDetails?.mealsPreference?.find(
              (item: any) => {
                return ele?.mealPref === item?.label;
              }
            ) || {};

          const mealPreferencesDataString = mealPreferencesData.label
            ? [`${mealPreferencesData?.label}-${mealPreferencesData.value}`]
            : null;

          const specialServicesDataString = specialServicesData.label
            ? `${specialServicesData?.label}-${specialServicesData.value}`
            : null;

          const selectedNationality = countriesData.find(
            (item) => item.name === ele?.nationality
          )?.iso;
          const selectedIssuing = countriesData.find(
            (item) => item.name === ele?.issuingCountry
          )?.iso;
          const selectedPhoneNumberCode = countriesData.find(
            (item) => item.name === ele?.countryCode.split(" ")[0]
          )?.code;
          return {
            countryCode: selectedPhoneNumberCode
              ? selectedPhoneNumberCode
              : "1",
            email: ele?.email,
            middleName: ele?.middleName,
            firstName: ele?.firstName,
            lastName: ele?.lastName,
            leadPax: index === 0 ? true : false,
            mobileNumber: ele?.phoneNumber,
            paxType: getPaxTypeFunc(
              `${ele?.dateOfYear}-${options.find((option) => option.label === ele?.dateOfBirthMonth)?.value}-${ele?.dateOfBirth?.length === 1 ? "0" + ele?.dateOfBirth : ele?.dateOfBirth}`
            ),
            title: ele?.gender === "MALE" ? "Mr" : "Ms",
            dateOfBirth: `${ele?.dateOfYear}-${options.find((option) => option.label === ele?.dateOfBirthMonth)?.value}-${ele?.dateOfBirth?.length === 1 ? "0" + ele?.dateOfBirth : ele?.dateOfBirth}`, // YYYY-MM-DD
            document: {
              type: ele?.documentType || null,
              number: ele?.documentNumber || null,
              nationality: selectedNationality || null,
              issuedCountry: selectedIssuing || null,
              issuedDate: flightAvailabilityData?.international
                ? `${ele?.docIssueYear}-${options.find((option) => option.label === ele?.docIssueMonth)?.value}-${ele?.docIssueDate?.length === 1 ? "0" + ele?.docIssueDate : ele?.docIssueDate}`
                : null, // YYYY-MM-DD,
              expiryDate: flightAvailabilityData?.international
                ? `${ele?.docExpiryYear}-${options.find((option) => option.label === ele?.docExpiryMonth)?.value}-${ele?.docExpiryDate?.length === 1 ? "0" + ele?.docExpiryDate : ele?.docExpiryDate}`
                : null, // YYYY-MM-DD,
            },
            frequentFlyerinfo: {
              flyerNumber: ele?.frequentFlyerNumber,
              frequentFlyerProgram:
                (ele?.programName !== flyerProgramType.none &&
                  (ele?.programName || null)) ||
                null,
              airline: "",
            },
            gender: ele?.gender?.toLocaleLowerCase(),
            infantData: null,
            meals: mealPreferencesDataString || [],
            operationalReference: {
              specialAssistance: specialServicesDataString || null,
            },
            // passengerId: 1234,
            // title: ele?.gender === "MALE" ? "Mr" : "Ms",
            // paxType: "ADT",
            // leadPax: true,
            // firstName: ele?.firstName,
            // lastName: ele?.lastName,
            // email: ele?.email,
            // countryCode: ele?.countryCode
            //   ? ele?.countryCode.substring(1, ele.countryCode.length)
            //   : "1",
            // phoneNumber: ele?.phoneNumber,
            // dateOfBirth: `${ele?.dateOfYear}-${options.find((option) => option.label === ele?.dateOfBirthMonth)?.value}-${ele?.dateOfBirth?.length === 1 ? "0" + ele?.dateOfBirth : ele?.dateOfBirth}`, // YYYY-MM-DD
            // infantData: {},
            // document: {
            //   type: "Passport",
            //   number: "ZNC123433",
            //   nationality: "AF",
            //   issuedCountry: "AF",
            //   issuedDate: "1996-12-13",
            //   expiryDate: "2033-06-06",
            // },
            // // operationalReference: {
            // //   specialAssistance: "",
            // // },
            // operationalReference: {
            //   specialAssistance: null,
            //   // Object.keys(ele?.selectedOptions).filter(
            //   //   (key) => ele?.selectedOptions[key]
            //   // ) || [],
            // },
            // frequentFlyerinfo: {
            // flyerNumber: "",
            // frequentFlyerProgram: ele?.programName || null,
            // airline: "",
            // },
            // baggage: [],
            // meals: localMealPreference || "",
            // seats: [],
          };
        }
      );
    const [isFlightAvailable, hotelAvailabilityIds] = await Promise.all([
      handleFlightAvailability(
        flightAvailabilityData?.encryptedKey,
        flightAvailabilityData?.uniqueId
      ),
      checkHotelAvailability(),
    ]);
    // console.log(
    //   passengerDetails,
    //   flightAvailabilityData?.flightPrice + roomTotalPrice
    // );
    // console.log(hotelAvailabilityIds, isFlightAvailable, "hotelAndFlightIds");
    if (!areAllRoomsSelected) return;
    if (
      isFlightAvailable &&
      Array.isArray(hotelAvailabilityIds) &&
      hotelAvailabilityIds.length > 0
    ) {
      const response = await bookHotelAndFlight(
        {
          clientId: "4f9b4522-4810-4718-a1b1-f08322bc0bd1",
          passengerDetails,
          userId: userInfo?.id || "",
          paymentDetails: {
            successUrl: APP_URL.slice(0, -1) + ROUTES.PaymentSuccess,
            cancelUrl: APP_URL.slice(0, -1) + ROUTES.PaymentFailure,
            // successUrl: "http://localhost:3000" + ROUTES.PaymentSuccess,
            // cancelUrl: "http://localhost:3000" + ROUTES.PaymentFailure,
            amount: `${flightAvailabilityData?.flightPrice + roomTotalPrice}`,
            currency: "USD",
            remarks: "",
          },
          flight: flightAvailabilityData.transactionId,
          // hotel: selectedHotelRoomArr?.map((item): iHotelRoomBookArgs => {
          //   const { preBookId = "", sessionId = "" } =
          //     item?.selectedHotelRoom || {};
          //   return {
          //     prebookId: preBookId,
          //     sessionId,
          //   };
          // }),
          hotel: hotelAvailabilityIds?.map((item) => ({
            prebookId: item?.prebookId,
            sessionId: item?.sessionId,
          })),
        },
        setIsBooking
      );
      if (response) {
        // // console.log(response, "response from hotel book");
        const { itineraryID, flight, hotel, paymentLink = "" } = response;
        // console.log({ response });
        // setBookedFlightSuccess({
        //   ...bookedFlightSuccess,
        //   openDialog: true,
        //   bookedId: response?.itineraryID || "",
        // });
        const { transactionId: flightTransactionId } = flight || {};
        const { transactionId: hotelTransactionId } = hotel || {};
        // console.log(
        //   itineraryID,
        //   flightTransactionId,
        //   hotelTransactionId,
        //   response
        // );
        setBookedTransIds({
          itineraryID,
          flightTransactionId,
          hotelTransactionId,
        });
        handlePreBookingResponse(paymentLink, itineraryID);
        // await saveBookedTransactionDetails({
        //   isFlight: false,
        //   bookedTransactionId: transactionId,
        //   bookedItineraryId: itineraryID,
        //   startDate: bookingDetails.travelFromDate,
        //   endDate: bookingDetails.travelToDate,
        // });
        // return;
        return true;
      }
      if (!response) {
        toastMessage.error(
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
      }
    }
  }, [
    selectedHotelRoomArr,
    travelerInfoArrayDataRef,
    handleFlightAvailability,
    flightAvailabilityData?.encryptedKey,
    flightAvailabilityData?.uniqueId,
    flightAvailabilityData?.extraServiceDetails?.specialAssistance,
    flightAvailabilityData?.extraServiceDetails?.mealsPreference,
    flightAvailabilityData?.international,
    flightAvailabilityData?.flightPrice,
    flightAvailabilityData?.transactionId,
    checkHotelAvailability,
    countriesData,
    userInfo?.preferences?.foodPreferences,
    userInfo?.id,
    roomTotalPrice,
    setBookedTransIds,
    handlePreBookingResponse,
    appConfig,
  ]);

  /**
   * Handling on clicking Continue for Booking Flight or Hotel
   */
  const isThisAMulticityTrip =
    selectedChatData.destinationList &&
    selectedChatData.destinationList.length > 1;

  const handleSubmit = async () => {
    setIsUserJustBookedTrip(false);
    // toast.loading("Booking in progress...");
    setIsloading(true);
    setBookedTransIds(null);
    const isValid = validateForm();
    if (!isValid) {
      setIsloading(false);
      // toastMessage.error(
      //   "SORRY",
      //   TOAST_MESSAGES.BOOKING.TravelerInfo.formValidationFailure.message
      // );
      return;
    }
    if (
      selectedFlightOptions.hotels &&
      !selectedFlightOptions.flights
      // && !isThisAMulticityTrip
    ) {
      const isComplete = await bookHotel();
      // toast.dismiss();

      if (!isComplete)
        //   console.log("SUCCESS", "Hotel is booked");
        // else
        setIsloading(false);
      return;
      // toastMessage.error(
      //   getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      // );
    } else if (!selectedFlightOptions.hotels && selectedFlightOptions.flights) {
      const isComplete = await bookFlight();
      // toast.dismiss();

      if (!isComplete)
        //   console.log("SUCCESS", "Flight is booked");
        // else
        setIsloading(false);
      return;
      // toastMessage.error(
      //   getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      // );
    } else if (selectedFlightOptions.hotels && selectedFlightOptions.flights) {
      const isCompletedHotelAndFlight = await bookHotelAndFlightAPI();
      // const isCompleteFlightBooked = await bookFlight();
      // console.log(isCompletedHotelAndFlight, "completedHotelAndFlight");
      // toast.dismiss();
      if (!isCompletedHotelAndFlight) {
        // toastMessage.error(
        //   getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        // );
        setIsloading(false);
        return;
      }
      // if (isCompletedHotelAndFlight)
      // toastMessage.success("Flight and Hotel are booked");
      // const isCompleteHotelBooked = await bookHotel();
      // toast.dismiss();
      // if (!isCompleteHotelBooked) {
      //   toastMessage.error(
      //     getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      //   );
      //   setIsloading(false);
      //   return;
      // }
      // toast.dismiss();

      // if (isCompleteFlightBooked && isCompleteHotelBooked)
      //   console.log("SUCCESS", "Flight and hotel is booked");
      // else
      //   toastMessage.error(
      //     getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      //   );
    }
    setIsloading(false);
  };

  useEffect(() => {
    backButtonRef.current?.scrollIntoView();
  }, []);

  if (isLoading)
    return (
      <NewLoader
        isOpen={isLoading}
        onClose={() => setIsloading(false)}
        text="Please wait"
        loadFullScreen
        bottomBtnText=""
      />
    );

  return (
    <>
      <div ref={backButtonRef}></div>
      <div className={styles.main}>
        {/* <BookedSuccessDialog
        bookedId={bookedFlightSuccess.bookedId}
        open={bookedFlightSuccess.openDialog}
        onClose={() => {
          // setBookedFlightSuccess({
          //   ...bookedFlightSuccess,
          //   openDialog: false,
          // });
          setTripOrItinearyId(selectedChatData?.id);
          if (isMobile) navigate(ROUTES.MyTrips);
          else {
            setScreen("");
            setFirstScreen(ScreenTypes.MyTrips);
          }
          setMyTripActiveTab("UPCOMING");
          toast.dismiss();
        }}
        handleViewItinerary={() => {
          window.history.pushState({}, "", ROUTES.MyTrips);
          setTripOrItinearyId(selectedChatData?.id);
          if (isMobile) navigate(ROUTES.TripDetails);
          else {
            setFirstScreen(ScreenTypes.TripDetails);
            setScreen("");
          }
          // if (isMobile) navigate(ROUTES.MyTrips);
          // else {
          //   setFirstScreen(ScreenTypes.MyTrips);
          //   setScreen("");
          // }
          toast.dismiss();
          setMyTripActiveTab("UPCOMING");
        }}
      /> */}
        <div className={styles.container}>
          <header className={styles.header}>
            <BackButton onClick={handleBackButton} />
            <p className={styles.title}>Traveler Information</p>
          </header>
          <section className={styles.content}>
            {/* {Array.from({ length: travelerCount }).map((_, index) => ( */}
            {travelerInfoArrayDataRef.current.map((ele: any, index: any) => (
              <CustomAccordion
                className={styles.customStyles}
                summaryClass={styles.summaryClass}
                accordianDetailsClass={styles.accordianDetailsClass}
                defaultExpanded={index === 0}
                key={index}
                headingComponent={
                  <div className={styles.heading}>
                    <p className={styles.accordHeading}>
                      {index !== 0
                        ? `Traveler ${index + 1}`
                        : `Primary Traveler`}
                    </p>
                    <img
                      src={personWalkingLuggage}
                      alt=""
                      className={styles.travelerIcon}
                    />
                  </div>
                }
                bodyComponent={
                  <TravelerInformationForma
                    frequentFlyerProgram={
                      flightAvailabilityData?.extraServiceDetails
                        ?.frequentFlyerProgram
                    }
                    mealsPreference={
                      flightAvailabilityData?.extraServiceDetails
                        ?.mealsPreference
                    }
                    countriesData={countriesData.filter(
                      (item) => item.iso !== ""
                    )}
                    seatsPreference={
                      flightAvailabilityData?.extraServiceDetails
                        ?.seatsPreference
                    }
                    specialAssistance={
                      flightAvailabilityData?.extraServiceDetails
                        ?.specialAssistance
                    }
                    formIndex={index}
                    formData={ele}
                    travelerInfoArrayDataRef={travelerInfoArrayDataRef}
                    setTravelerInfoArrayDataRef={setTravelerInfoArrayDataRef}
                    // ref={formRef}
                    showErrorMessageFlag={showErrorMessageFlag}
                    setShowErrorMessageFlag={setShowErrorMessageFlag}
                  />
                }
                zeroMarginOnExpand
                borderBottom
              />
            ))}
            <div className={styles.btnCard}>
              <Button
                className={styles.continueBtn}
                fullWidth
                // onClick={handleSubmit}
                onClick={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
                disabled={isLoading}
              >
                Continue
              </Button>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default TravelerInformation;
