import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useRef,
} from "react";
import { Route, useNavigate } from "react-router-dom";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { iHistoryContext } from "../../../context/historyContext/interface";
import { useHistoryContext } from "../../../context/historyContext/historyContext";
import useValidateDate from "../../../hooks/useValidateDate";
import useCustomErrorHandler from "../../../hooks/useCustomErrorHandler";
import {
  checkIfItineraryIsValid,
  checkIfThereIsDestination,
  getConfigMessage,
  getItineraryVersion,
  scrollToBottom,
  scrollToTop,
} from "../../../utils";
import { checkIfUserIsLoggedIn } from "../../../utils/loginAndLocalStorageutils";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";
import {
  APP_CONFIG,
  DialogSelected,
  ENVIRONMENT_TYPE,
  INVALID_JSON_PROMPT,
  LLM_RESPONSE_ERROR,
} from "../../../constant/common";
import { ROUTES, SENDING_CHAT_MESSAGE } from "../../../constant";
import { ScreenTypes } from "../../../enums";
import {
  BookingDetails,
  iChatData,
  iCityDetailsResult,
  iHotelStayTypes,
} from "../../../types";
import { getAirports } from "../../../api/booking/booking";
import { getItineraryDetailsAPI } from "../../../api/getItineraryDetails";
import {
  APP_URL,
  environment,
  STREAMING_ENABLE,
} from "../../../constant/environment";
import { toastMessage } from "../../../utils/ToastProvider";
import { TOAST_MESSAGES } from "../../../constant/messages/toastMessages";
import toast from "react-hot-toast";
import { modifyItineraryAPI } from "../../../api/llm/modifyItinerary";
import { SaveItineraryDetailsResponse } from "../../../interface/saveItinarary";
import { saveItineraryDetailsAPI } from "../../../api/saveItineraryDetails";
import ReactGA from "react-ga4";
import { v4 } from "uuid";
import { appendErrorMessageTochat } from "../../../utils/appendErrorMessageToChat";
import { iItineraryDetailsContext } from "./itineraryDetailsInterface";
import { iDataStreamingContext } from "../../../context/dataStreamingContext/dataStreamingContextInterface";
import { useDataStreamingContext } from "../../../context/dataStreamingContext/DataStreamingContext";
import { handleTripType } from "../../../utils/booking/tripTypeHandler";
import { getFlightTravelDetails } from "../../../utils/booking/flight/getFlightTravelDetails";
import { getFlightTravelData } from "../../../utils/booking/flight/getFlightTravelData";
import { modifyItineraryStreaming } from "../../../api/streaming/chatStreaming";
import { iChatStreamingPayload } from "../../../interface/common";
import { socket, SOCKET_EVENTS } from "../../../utils/socket";
import { SELECTED_CHAT_DATA_DUMMY } from "../../../component/tripDetailsPage/activitiesTabs/activitiesConstant";
import { SELECTED_CHAT_DATA } from "../../../constant/dummyData";
// import { handleFlightTime } from "../../../utils/booking/handleFlightTime";
let abortPlanTripDetails = new AbortController();

const ItineraryContext = createContext({} as iItineraryDetailsContext);
export const ItineraryContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const navigate = useNavigate();
  const state = useContext(StateContext);
  const {
    setSelectedHotelRoomArr,
    setFlightAvailabilityData,
    setSignInClick,
    setSaveScreenState,
    setLoginCreateAccountState,
    loginCreateAccountState,
    setNav,
    setMenuOpenState,
    tripForNDays,
    setTripForNDays,
    setExtraMeta,
    extraMeta,
    sharedItineraryId,
    setDataStream,
    query,
    setShareClick,
    setIsItinerarySavedFlag,
    setSelectedChatData,
    chatData,
    selectedChatData,
    scrollContainerRef,
    setScreen,
    isMobile,
    screen,
    setChatData,
    userInfo,
    setUserInfo,
    setModifyItineraryPrompt,
    setBookingDetails,
    setSelectedCityListPrompContext,
    setSelectedFlightOptions,
    selectedFlightOptions,
    setFlightData,
    setHotelData,
    appConfig,
    setShowMore,
    setAirPorts,
    isFetchingAirports,
    setIsFetchingAirports,
    setPrevRoute,
    setHotelPagination,
    prevRoute,
    setBookClickBeforeLoginFlag,
    socketId,
    setFirstScreen,
  }: iGlobalContext = state;
  const { saveHistory, getHistory }: iHistoryContext = useHistoryContext();
  const {
    handleConversationStreamEvents,
    handleFollowUpStreamEvents,
    disableInput,
    setDisableInput,
    chatAbortController,
    chatAbortControllerRef,
    setStreamingInProgressFlag,
  }: iDataStreamingContext = useDataStreamingContext();

  const [showEditActivityFlag, setShowEditActivityFlag] = React.useState(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const validateDate = useValidateDate();
  const { handleCustomError } = useCustomErrorHandler();
  // Axios controller to cancel API call
  const controller = new AbortController();
  const controllerAbortUpdateTrip = new AbortController();
  const abortControllerRef = useRef<AbortController | null>(null);
  const [openModifyItineraryDialog, setOpenModifyItineraryDialog] = useState({
    isOpen: false,
    message: "",
  });
  const isDesktopOrTablet = window.matchMedia("(min-width: 768px)").matches;

  // Disable scroll on focus of input field
  // useEffect(() => {
  //   const handleTouchMove = (e: any) => {
  //     e.preventDefault();
  //   };
  //   function handleResize(event: any) {
  //     const VIEWPORT_VS_CLIENT_HEIGHT_RATIO = 0.75;
  //     if (
  //       (event?.target?.height * event?.target?.scale) / window.screen.height <
  //       VIEWPORT_VS_CLIENT_HEIGHT_RATIO
  //     ) {
  //       //  toastMessage.success("OPEN");
  //       document.body.addEventListener("touchmove", handleTouchMove, {
  //         passive: false,
  //       });
  //       document.documentElement.addEventListener(
  //         "touchmove",
  //         handleTouchMove,
  //         {
  //           passive: false,
  //         }
  //       );
  //     } else {
  //       //  toastMessage.success("CLOSE");
  //       document.body.removeEventListener("touchmove", handleTouchMove);
  //       document.documentElement.removeEventListener(
  //         "touchmove",
  //         handleTouchMove
  //       );
  //     }
  //   }
  //   if ("visualViewport" in window && !loginCreateAccountState?.flag) {
  //     window?.visualViewport?.addEventListener("resize", handleResize);
  //   }
  //   return () => {
  //     window?.visualViewport?.removeEventListener("resize", handleResize);
  //   };
  // }, [loginCreateAccountState]);

  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For other browsers
    }, 200);
    scrollToTop(scrollContainerRef);
    setNav("");
    setTripForNDays([]);
    setDataStream("");
    const localPlanTrip = window.sessionStorage.getItem("_extra_meta");
    if (localPlanTrip !== null) {
      setTripForNDays(JSON.parse(localPlanTrip).planTripArray);
    }
    if (!userInfo?.loggedIn) checkIfUserIsLoggedIn(setUserInfo);
    return () => {
      controllerAbortUpdateTrip.abort();
      controller.abort();
      setDataStream("");
    };
  }, []);
  React.useEffect(() => {
    if (sharedItineraryId !== "") setIsItinerarySavedFlag(true);
    else {
      setIsItinerarySavedFlag(false);
    }
  }, [sharedItineraryId]);

  // Save Itinerary
  function handleSaveItinerary() {
    triggerGAEvent(GA_LABEL_ENUMS.itinerary_details_save_click);

    if (selectedChatData?.isSaved) return;
    setExtraMeta({
      ...extraMeta,
      planTripArray: tripForNDays,
      query: query,
    });

    setMenuOpenState(false);
    setSaveScreenState(true);
    if (userInfo?.loggedIn)
      setLoginCreateAccountState({
        flag: true,
        page: DialogSelected.SAVE_ITINERARY,
      });
    else setLoginCreateAccountState({ flag: true, page: DialogSelected.LOGIN });
  }

  // Onclick function to handle Back button click
  function handleBackButton() {
    triggerGAEvent(GA_LABEL_ENUMS.itinerary_details_back_click);
    if (isMobile) {
      // if (isIPhone()) {
      //   window.history.go(-1);
      // } else {
      //   navigate(prevRoute || ROUTES.TopDestination);
      // }
      if (prevRoute === ROUTES.ItineraryDetails)
        navigate(ROUTES.TopDestination);
      else navigate(prevRoute || ROUTES.TopDestination);
    } else {
      if (screen === ScreenTypes.SingleItinerary) {
        setScreen("");
        return;
      }
      setScreen(ScreenTypes.Reorder);
      setScreen("");
    }
  }

  let fetchAirportController: any = useRef(null);

  const getTravelDetails = (tripData: iCityDetailsResult[]) => {
    if (!tripData || tripData?.length === 0) {
      return [];
    }
    const travelDetails: any[] = [];

    tripData?.forEach((trip) => {
      // Count the number of days in the city
      const numberOfDays = trip?.tripDetails?.length || 0;
      // Collect flight details for the city
      const flights: any = [];
      trip?.tripDetails?.forEach((day) => {
        const dayFlights = day?.itinerary_by_time_of_the_day_data?.filter(
          (activity: any) => activity?.activity_type === "transportation.flight"
        );

        dayFlights?.forEach((flight: any) => {
          flights?.push({
            // departure_city_name:
            //   flight?.departure_city_name && flight?.departure_city_country
            //     ? `${flight.departure_city_name}, ${flight?.departure_city_state}, ${flight.departure_city_country}`
            //     : flight?.departure_city_name ||
            //       flight?.departure_city_country ||
            //       "",
            departure_city_name: [
              flight?.departure_city_name,
              flight?.departure_city_state,
              flight?.departure_city_country,
            ]
              .filter(Boolean)
              .join(", "),
            departure_city_latitude: flight?.departure_city_lattitude || "",
            departure_city_longitude: flight?.departure_city_longitude || "",
            departure_city_country_code:
              flight?.departure_city_country_code || "",
            // arrival_city_name:
            //   flight?.arrival_city_name && flight?.arrival_city_country
            //     ? `${flight.arrival_city_name}, ${flight?.arrival_city_state}, ${flight.arrival_city_country}`
            //     : flight?.arrival_city_name ||
            //       flight?.arrival_city_country ||
            //       "",
            arrival_city_name: [
              flight?.arrival_city_name,
              flight?.arrival_city_state,
              flight?.arrival_city_country,
            ]
              .filter(Boolean)
              .join(", "),
            arrival_city_country_code: flight?.arrival_city_country_code || "",
            arrival_city_latitude: flight?.arrival_city_lattitude || "",
            arrival_city_longitude: flight?.arrival_city_longitude || "",
          });
        });
      });

      // Add the city details with number of days and flights
      travelDetails?.push({
        city_name: trip?.city_name || "",
        number_of_days: numberOfDays,
        flights: flights,
      });
    });

    return travelDetails;
  };

  const flightHistory = getTravelDetails(selectedChatData?.destinationList);
  const extractFlightCities = (data: any) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return [];
    }

    const citiesSet = new Set();

    data.forEach((location: any) => {
      location.flights.forEach((flight: any) => {
        const departureCity = JSON.stringify({
          name: flight?.departure_city_name,
          latitude: flight?.departure_city_latitude,
          longitude: flight?.departure_city_longitude,
          countryCode: flight?.departure_city_country_code,
        });

        const arrivalCity = JSON.stringify({
          name: flight.arrival_city_name,
          latitude: flight.arrival_city_latitude,
          longitude: flight.arrival_city_longitude,
          countryCode: flight.arrival_city_country_code,
        });

        citiesSet.add(departureCity);
        citiesSet.add(arrivalCity);
      });
    });

    return Array.from(citiesSet).map((city: any) => JSON.parse(city));
  };
  const airportPayload = extractFlightCities(flightHistory);
  const getAirportData = async () => {
    const isValidCity = (city: any) =>
      Object.values(city).every(
        (value) => value && !value.toString().includes("undefined")
      );
    const cities = airportPayload?.filter(isValidCity);
    if (!cities || cities.length === 0) {
      setShowLoader(false);
      setBookingDetails((prevData) => ({
        ...prevData,
        hideFlights: true,
      }));
      // console.log("invalid data");
      return;
    }

    const requestBody = {
      cities: cities,
      radius: "100 miles",
    };
    fetchAirportController.current = new AbortController();

    setShowLoader(true);

    try {
      const data = await getAirports(
        requestBody,
        fetchAirportController.current
      );
      if (fetchAirportController.current.signal.aborted) {
        setShowLoader(false);
        return null;
      }
      setAirPorts(data);
      // setAirPorts([]);
      // setShowLoader(false);

      // setIsFetchingAirports(false);
      return data;
    } catch (err) {
      setShowLoader(false);
      setAirPorts([]);
      setIsFetchingAirports(false);
      console.error("Failed to fetch flight data");
      return [];
    }
  };
  const [itineraryInfo, setItineraryInfo] = useState<BookingDetails>();

  const getItineraryDetails = async () => {
    const { id: itineraryId } = selectedChatData;
    if (!itineraryId) return;

    try {
      const response = await getItineraryDetailsAPI(itineraryId);
      if (response?.statusCode !== 200) {
        handleCustomError(response?.statusCode, "itinerary.details");
        return null;
      }

      const metaData = typeof response.data.metaData
        ? JSON.parse(response?.data?.metaData)
        : response.data.metaData;
      const chatData = metaData?.[0]?.selectedChatData;
      if (chatData) setSelectedChatData(chatData);

      const updatedBookingDetails = { ...response.data.bookFilter };
      setItineraryInfo(updatedBookingDetails);
    } catch (error) {
      console.error("Error fetching itinerary details:", error);
    }
  };

  useEffect(() => {
    if (prevRoute !== ROUTES.TopDestination) getItineraryDetails();
  }, []);

  async function handleShare() {
    if (!selectedChatData?.isSaved) {
      setShareClick(true);
      setTimeout(() => {
        handleSaveItinerary();
      }, 1000);
      // toastMessage.info("Please save the Itinerary before sharing!");
    }
  }

  const [noOfCitySelected, setNoOfCitySelected] = useState(1);
  useEffect(() => {
    setNoOfCitySelected(
      selectedChatData?.destinationList?.reduce(
        (acc: number, destListEle: iCityDetailsResult) => {
          if (destListEle.checkedFlag) return acc + 1;
          else return acc;
        },
        0
      )
    );
  }, [selectedChatData]);

  function handleOnShare() {
    ReactGA.event({
      category: "Button Click",
      action: `Click on Share Trip Details`,
      label: `User is sharing the itinerary ${APP_URL}/shared/${selectedChatData?.id}`,
    });
    setTimeout(() => {
      toastMessage.success(
        TOAST_MESSAGES.ShareItinerary.shareSuccess.messageTitle,
        TOAST_MESSAGES.ShareItinerary.shareSuccess.message
      );
    }, 100);
  }

  const [tempSelectedChatData, setTempSelectedChatData] = useState(
    selectedChatData?.destinationList?.filter(
      (ele: iCityDetailsResult) => ele.checkedFlag
    )
  );

  useEffect(() => {
    setTempSelectedChatData(
      selectedChatData?.destinationList?.filter(
        (ele: iCityDetailsResult) => ele.checkedFlag
      )
    );
  }, [selectedChatData]);

  function handleEdit() {
    if (isMobile) navigate(ROUTES.TopDestination);
    setModifyItineraryPrompt(
      `Modify the itinerary for ${selectedChatData.tripName}`
    );
    setChatData((prev: iChatData[]) => [
      ...prev,
      {
        id: v4(),
        message: inputValue,
        isUserMessage: true,
        prompt: selectedChatData?.prompt,
        isLoading: false,
        destinationList: [],
        singleSelectedDestination: false,
      },
      {
        id: v4(),
        message: "",
        prompt: selectedChatData?.prompt,
        isUserMessage: false,
        isLoading: true,
        destinationList: [],
        singleSelectedDestination: false,
      },
    ]);
  }
  function extractStatement(str: string) {
    const match = str.match(/"statement":\s*"([^"]*)"/); // Match statement and handle newlines manually
    return match ? match[1].replace(/\\n/g, "\n") : null; // Replace escaped newlines with actual newlines
  }
  async function handleSendMessageStreaming() {
    setStreamingInProgressFlag(true);
    setSelectedChatData({ ...SELECTED_CHAT_DATA });
    toast.dismiss();
    setModifyItineraryPrompt(
      `Modify the itinerary for ${selectedChatData.tripName}`
    ); //  used to enable auto scroll in Destop
    // setCallSaveHistoryAPIFlag(false);
    // scrollToBottom(desktopScrollContainerRef);
    if (chatData.length > 0 && chatData[chatData.length - 1].isLoading) {
      toastMessage.info(TOAST_MESSAGES.SaveItinerary.inputFieldLoad.message);
      return;
    }
    if (inputValue === "") {
      toastMessage.info(TOAST_MESSAGES.SaveItinerary.inputFieldEnter.message);
      return;
    }
    setChatData((prev: iChatData[]) => [
      ...prev,
      {
        id: v4(),
        message: inputValue,
        isUserMessage: true,
        prompt: [],
        isLoading: false,
        destinationList: [],
        singleSelectedDestination: false,
      },
      {
        id: v4(),
        message: "",
        prompt: [],
        isUserMessage: false,
        isLoading: true,
        destinationList: [],
        singleSelectedDestination: false,
      },
    ]);
    setInputValue("");
    setDisableInput(true);
    // const abortController = new AbortController();
    chatAbortControllerRef.current = chatAbortController;
    // const modifyItineraryPromptResponse = await getPromptAPI(
    //   APP_CONFIG.MODIFY_ITINERARY_PROMPT
    // );
    // const modifyItineraryPrompt = modifyItineraryPromptResponse.filter(
    //   (ele: any) => ele.key === APP_CONFIG.MODIFY_ITINERARY_PROMPT
    // );
    // // console.log({ modifyItineraryPromptResponse }, { modifyItineraryPrompt });
    // const backupPrompt = `Above is the data and the response for single or multiple city, modify it as instructed, and also with the correct number of days mentioned and update the itinerary_version and if there are multiple city, the day wise activity should be overall and also each day should have atleast morning, afternoon and evening activity. Also return the response in the same format without missing data. Note: If there are multiple city do not remove any city from the trip when modifying the number of days`;
    // const promptContext = [
    //   // selectedCityListPrompContext ||
    //   selectedChatData?.prompt[selectedChatData?.prompt.length - 1] ||
    //     selectedChatData?.itineraryPromptContext,
    //   {
    //     role: "user",
    //     // content: selectedChatData?.tripName,
    //     // content: `Above is the data and the response for ${selectedChatData?.tripName}, modify it as instructed and update the itinerary_version`,
    //     content:
    //       modifyItineraryPrompt?.length > 0
    //         ? modifyItineraryPrompt[0]?.value || backupPrompt
    //         : backupPrompt,
    //   },
    // ];

    let payload: iChatStreamingPayload = {
      userId: userInfo?.id || "",
      userInput: inputValue,
      isModify: true,
      currentItineray: selectedChatData?.results || "",
      socketId: socketId || "",
      iteneryInput: null,
    };
    socket.on(
      SOCKET_EVENTS.conversation_stream,
      handleConversationStreamEvents
    );
    let response = await modifyItineraryStreaming(
      payload,
      chatAbortController.signal
    );
    setStreamingInProgressFlag(false);
    // let response = await modifyItineraryAPI(
    //   isMobile,
    //   userInfo?.id || "",
    //   inputValue,
    //   [],
    //   selectedChatData?.results || {},
    //   chatAbortController.signal,
    //   appConfig
    // );

    // let response = await chatInteractionAPI(
    //   userInfo?.id || "",
    //   selectedChatData?.iteneryInput
    //     ? "For " + selectedChatData?.tripName + ". " + inputValue
    //     : inputValue,
    //   selectedChatData?.prompt,
    //   abortController.signal,
    //   // selectedChatData?.iteneryInput || null
    //   null
    // );

    // console.log({ response }, abortController.signal.aborted);
    if (!response && chatAbortController.signal.aborted) {
      socket.off(
        SOCKET_EVENTS.conversation_stream,
        handleConversationStreamEvents
      );
      socket.off(SOCKET_EVENTS.followup_stream, handleFollowUpStreamEvents);
      setChatData((prev: iChatData[]) => {
        const length = prev.length;
        if (prev.length > chatData.length)
          return [...prev.splice(0, length - 2)];
        return prev;
      });
      return;
    }
    // if (
    //   response === null ||
    //   (!response && !chatAbortController.signal.aborted) ||
    //   !response?.data?.result ||
    //   checkIfThereIsDestination(response) === LLM_RESPONSE_ERROR.jsonError.error
    // ) {
    //   response = await modifyItineraryAPI(
    //     isMobile,
    //     userInfo?.id || "",
    //     inputValue + INVALID_JSON_PROMPT,
    //     // selectedChatData?.prompt,
    //     // promptContext,
    //     [],
    //     selectedChatData?.results || {},
    //     chatAbortController.signal,
    //     appConfig
    //     // selectedChatData?.iteneryInput || null
    //   );
    // }

    if (
      response === null ||
      (!response && !chatAbortController.signal.aborted) ||
      !response?.data?.result
    ) {
      socket.off(
        SOCKET_EVENTS.conversation_stream,
        handleConversationStreamEvents
      );
      socket.off(SOCKET_EVENTS.followup_stream, handleFollowUpStreamEvents);
      setDisableInput(false);
      appendErrorMessageTochat(
        chatData,
        setChatData,
        LLM_RESPONSE_ERROR.timeOut.message
      );
      // setChatData((prev: iChatData[]) => {
      //   const length = prev.length;
      //   if (prev.length > chatData.length)
      //     return [...prev.splice(0, length - 1)];
      //   return prev;
      // });
      // toast.dismiss();
      // toastMessage.error(
      //   "OOPS!",
      //   getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      // );
      return;
    }

    if (response?.data?.result) {
      socket.off(
        SOCKET_EVENTS.conversation_stream,
        handleConversationStreamEvents
      );
      socket.off(SOCKET_EVENTS.followup_stream, handleFollowUpStreamEvents);
      setChatData((prevChatData) => {
        if (prevChatData.length > 0) {
          const message = prevChatData[prevChatData.length - 1].message;
          const extractedMessage = extractStatement(message);
          let lastChatData: iChatData = {
            ...prevChatData[prevChatData.length - 1], // Copy the last element
            prompt: response?.data?.messages,
            isLoading: false,
            message: extractedMessage
              ? extractedMessage
              : prevChatData[prevChatData.length - 1].message,
            results: response?.data?.result,
          };
          const itineraryVersion = getItineraryVersion(chatData, lastChatData);
          lastChatData = {
            ...lastChatData,
            itinerary_version: itineraryVersion,
          };
          if (
            lastChatData?.message === "" &&
            lastChatData?.destinationList?.length === 0
          ) {
            if (isMobile) navigate(ROUTES.TopDestination);
            else setScreen("");
            lastChatData = {
              id: v4(),
              message: LLM_RESPONSE_ERROR.timeOut.message,
              isUserMessage: false,
              prompt: [],
              isLoading: false,
              destinationList: [],
              singleSelectedDestination: false,
            };
          }
          setSelectedChatData(lastChatData);
          return [
            ...prevChatData.slice(0, -1), // All elements except the last
            lastChatData,
          ];
        }
        return prevChatData; // If empty, return as is
      });
    }
    // response = checkIfThereIsDestination(response);
    // if (response === LLM_RESPONSE_ERROR.jsonError.error) {
    //   // setChatData((prev: iChatData[]) => {
    //   //   const length = prev.length;
    //   //   if (prev.length > chatData.length)
    //   //     return [...prev.splice(0, length - 1)];
    //   //   return prev;
    //   // });
    //   appendErrorMessageTochat(
    //     chatData,
    //     setChatData,
    //     LLM_RESPONSE_ERROR.jsonError.message
    //   );
    //   setDisableInput(false);
    //   if (environment === ENVIRONMENT_TYPE.PRODUCTION) {
    //     toastMessage.error("OOPS!", "We hit a snag! Please try again later.");
    //   } else {
    //     toastMessage.error(
    //       TOAST_MESSAGES.SaveItinerary.destinationError.messageTitle,
    //       // getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
    //       TOAST_MESSAGES.SaveItinerary.destinationError.message
    //     );
    //   }
    //   return;
    // }
    // let citiesList = [];
    // if (response?.data?.cities) {
    //   citiesList = response?.data?.cities;
    // }
    // processedAndGetDestinationImageURL(citiesList, response, false);
    setDisableInput(false);
    // scrollToBottom(desktopScrollContainerRef);
    // setCallSaveHistoryAPIFlag(true);
    ReactGA.event({
      category: SENDING_CHAT_MESSAGE.CATEGORY,
      action: SENDING_CHAT_MESSAGE.ACTION,
      label: ` ${SENDING_CHAT_MESSAGE.LABEL}  ${inputValue}`,
    });
  }
  async function handleSendMessage() {
    toast.dismiss();
    setModifyItineraryPrompt(
      `Modify the itinerary for ${selectedChatData.tripName}`
    ); //  used to enable auto scroll in Destop
    // setCallSaveHistoryAPIFlag(false);
    // scrollToBottom(desktopScrollContainerRef);
    if (chatData.length > 0 && chatData[chatData.length - 1].isLoading) {
      toastMessage.info(TOAST_MESSAGES.SaveItinerary.inputFieldLoad.message);
      return;
    }
    if (inputValue === "") {
      toastMessage.info(TOAST_MESSAGES.SaveItinerary.inputFieldEnter.message);
      return;
    }
    setChatData((prev: iChatData[]) => [
      ...prev,
      {
        id: v4(),
        message: inputValue,
        isUserMessage: true,
        prompt: [],
        isLoading: false,
        destinationList: [],
        singleSelectedDestination: false,
      },
      {
        id: v4(),
        message: "",
        prompt: [],
        isUserMessage: false,
        isLoading: true,
        destinationList: [],
        singleSelectedDestination: false,
      },
    ]);
    setInputValue("");
    setDisableInput(true);
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    // const modifyItineraryPromptResponse = await getPromptAPI(
    //   APP_CONFIG.MODIFY_ITINERARY_PROMPT
    // );
    // const modifyItineraryPrompt = modifyItineraryPromptResponse.filter(
    //   (ele: any) => ele.key === APP_CONFIG.MODIFY_ITINERARY_PROMPT
    // );
    // // console.log({ modifyItineraryPromptResponse }, { modifyItineraryPrompt });
    // const backupPrompt = `Above is the data and the response for single or multiple city, modify it as instructed, and also with the correct number of days mentioned and update the itinerary_version and if there are multiple city, the day wise activity should be overall and also each day should have atleast morning, afternoon and evening activity. Also return the response in the same format without missing data. Note: If there are multiple city do not remove any city from the trip when modifying the number of days`;
    // const promptContext = [
    //   // selectedCityListPrompContext ||
    //   selectedChatData?.prompt[selectedChatData?.prompt.length - 1] ||
    //     selectedChatData?.itineraryPromptContext,
    //   {
    //     role: "user",
    //     // content: selectedChatData?.tripName,
    //     // content: `Above is the data and the response for ${selectedChatData?.tripName}, modify it as instructed and update the itinerary_version`,
    //     content:
    //       modifyItineraryPrompt?.length > 0
    //         ? modifyItineraryPrompt[0]?.value || backupPrompt
    //         : backupPrompt,
    //   },
    // ];

    let response = await modifyItineraryAPI(
      isMobile,
      userInfo?.id || "",
      inputValue,
      // selectedChatData?.prompt,
      // promptContext,
      [],
      selectedChatData?.results || {},
      abortController.signal,
      appConfig
      // selectedChatData?.iteneryInput || null
    );
    // let response = await chatInteractionAPI(
    //   userInfo?.id || "",
    //   selectedChatData?.iteneryInput
    //     ? "For " + selectedChatData?.tripName + ". " + inputValue
    //     : inputValue,
    //   selectedChatData?.prompt,
    //   abortController.signal,
    //   // selectedChatData?.iteneryInput || null
    //   null
    // );

    // console.log({ response }, abortController.signal.aborted);
    if (!response && abortController.signal.aborted) {
      setChatData((prev: iChatData[]) => {
        const length = prev.length;
        if (prev.length > chatData.length)
          return [...prev.splice(0, length - 2)];
        return prev;
      });
      return;
    }
    if (
      response === null ||
      (!response && !abortController.signal.aborted) ||
      !response?.data?.result ||
      checkIfThereIsDestination(response) === LLM_RESPONSE_ERROR.jsonError.error
    ) {
      response = await modifyItineraryAPI(
        isMobile,
        userInfo?.id || "",
        inputValue + INVALID_JSON_PROMPT,
        // selectedChatData?.prompt,
        // promptContext,
        [],
        selectedChatData?.results || {},
        abortController.signal,
        appConfig
        // selectedChatData?.iteneryInput || null
      );
    }

    if (
      response === null ||
      (!response && !abortController.signal.aborted) ||
      !response?.data?.result
    ) {
      setDisableInput(false);
      appendErrorMessageTochat(
        chatData,
        setChatData,
        LLM_RESPONSE_ERROR.timeOut.message
      );
      // setChatData((prev: iChatData[]) => {
      //   const length = prev.length;
      //   if (prev.length > chatData.length)
      //     return [...prev.splice(0, length - 1)];
      //   return prev;
      // });
      // toast.dismiss();
      // toastMessage.error(
      //   "OOPS!",
      //   getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      // );
      return;
    }
    response = checkIfThereIsDestination(response);
    if (response === LLM_RESPONSE_ERROR.jsonError.error) {
      // setChatData((prev: iChatData[]) => {
      //   const length = prev.length;
      //   if (prev.length > chatData.length)
      //     return [...prev.splice(0, length - 1)];
      //   return prev;
      // });
      appendErrorMessageTochat(
        chatData,
        setChatData,
        LLM_RESPONSE_ERROR.jsonError.message
      );
      setDisableInput(false);
      if (environment === ENVIRONMENT_TYPE.PRODUCTION) {
        toastMessage.error("OOPS!", "We hit a snag! Please try again later.");
      } else {
        toastMessage.error(
          TOAST_MESSAGES.SaveItinerary.destinationError.messageTitle,
          // getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
          TOAST_MESSAGES.SaveItinerary.destinationError.message
        );
      }
      return;
    }
    let citiesList = [];
    if (response?.data?.cities) {
      citiesList = response?.data?.cities;
    }
    processedAndGetDestinationImageURL(citiesList, response, false);
    setDisableInput(false);
    // scrollToBottom(desktopScrollContainerRef);
    // setCallSaveHistoryAPIFlag(true);
    ReactGA.event({
      category: SENDING_CHAT_MESSAGE.CATEGORY,
      action: SENDING_CHAT_MESSAGE.ACTION,
      label: ` ${SENDING_CHAT_MESSAGE.LABEL}  ${inputValue}`,
    });
  }
  async function processedAndGetDestinationImageURL(
    citiesList: iCityDetailsResult[],
    response: any,
    showMore: boolean
  ) {
    if (citiesList.length > 0) {
      setSelectedCityListPrompContext(null);
      const tempResponse = citiesList.map((ele: any, index: number) => ({
        ...ele,
        id: v4(),
        // checkedFlag: citiesList.length === 1 ? true : false,
        // checkedFlag: response?.data?.singleCity,
        checkedFlag: true,
        locations: [],
        imageURL: selectedChatData?.destinationList[index]?.imageURL,
      }));

      const newId = v4();
      const itineraryVersion = getItineraryVersion(
        chatData,
        response?.data?.results
      );
      setSelectedChatData({
        ...response?.data,
        city_wise_itinerary: [],
        cities: [],
        messages: [],
        result: "",
        fromCity: response?.data?.from,
        noOfDays: response?.data?.numberOfdays,
        id: newId,
        message: "",
        preText: response?.data?.preText,
        postText: response?.data?.postText,
        // prompt: response?.data?.messages,
        prompt: [],
        isUserMessage: false,
        isLoading: false,
        singleSelectedDestination: response?.data?.singleCity,
        destinationList: tempResponse,
        itinerary_version: itineraryVersion,
      });
      setChatData((prev: iChatData[]) => [
        ...prev.splice(0, prev.length - 1),
        {
          ...response?.data,
          city_wise_itinerary: [],
          cities: [],
          messages: [],
          result: "",
          fromCity: response?.data?.from,
          noOfDays: response?.data?.numberOfdays,
          id: showMore ? prev[prev.length - 1].id : newId,
          message: "",
          preText: response?.data?.preText,
          postText: response?.data?.postText,
          // prompt: response?.data?.messages,
          prompt: [],
          isUserMessage: false,
          isLoading: false,
          singleSelectedDestination: response?.data?.singleCity,
          destinationList: showMore
            ? [...prev[prev.length - 1].destinationList, ...tempResponse]
            : tempResponse,
          itinerary_version: itineraryVersion,
        },
      ]);
      // const currentItineraryList = tempResponse;
      // for (let i = 0; i < currentItineraryList.length; i++) {
      //   const cityName = currentItineraryList[i].cityName;
      //   let imageRespone: any = await getItineraryImageURL(cityName);
      //   let count = 0;
      //   while (!imageRespone || !imageRespone?.data?.image) {
      //     // Limiting the number of API call to 10 times incase of 409 error
      //     count++;
      //     if (count === 3) break;
      //     // re calling the API
      //     imageRespone = await getItineraryImageURL(cityName);
      //   }
      //   if (imageRespone?.statusCode === 200) {
      //     currentItineraryList[i].imageURL = imageRespone.data.image;
      //   } else {
      //     // console.log("RESPONSE TOP Error");
      //   }
      // }

      ReactGA.event({
        category: "Top Destination",
        action: `User has recieved the itinerary list created`,
        label: `User prompt is ${response?.data?.query}`,
      });
    } else {
      setChatData((prev: iChatData[]) => [
        ...prev.splice(0, prev.length - 1),
        {
          id: v4(),
          message: response?.data?.result,
          // prompt: response?.data?.messages,
          prompt: [],
          isUserMessage: false,
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
      ]);
      // console.log({ response });

      if (isMobile) navigate(ROUTES.TopDestination);
      else setScreen("");
      scrollToBottom(scrollContainerRef);
      // setOpenModifyItineraryDialog({
      //   message: response?.data?.result || "",
      //   isOpen: true,
      // });
    }
  }

  useEffect(() => {
    if (!isFetchingAirports) {
      // fetchAirportController?.current?.abort();
    }
  }, [isFetchingAirports]);
  /**
   * chat input
   */
  const [inputValue, setInputValue] = useState("");

  /**
   * Handling cancelling Send message or
   * open Itinerary details or Show More
   */
  // const [disableInput, setDisableInput] = useState<boolean>(false);

  const [showChatLoaderFlag, setShowChatLoaderFlag] = useState(false);
  function handleCancelStreaming() {
    setShowChatLoaderFlag(false);
    setScreen("");
    if (chatData[chatData.length - 1].prompt.length === 0) {
      const tempChatData = chatData;
      setChatData(tempChatData.slice(0, -2));
    } else {
      setChatData(
        chatData?.map((eleChatData: iChatData) => ({
          ...eleChatData,
          isLoading: false,
        }))
      );
    }
    chatAbortController.abort();
    if (chatAbortControllerRef.current) {
      chatAbortControllerRef.current.abort();
      setDisableInput(false);
    }
  }
  function handleCancel() {
    setShowChatLoaderFlag(false);
    setScreen("");
    if (chatData[chatData.length - 1].prompt.length === 0) {
      const tempChatData = chatData;
      setChatData(tempChatData.slice(0, -2));
    } else {
      setChatData(
        chatData?.map((eleChatData: iChatData) => ({
          ...eleChatData,
          isLoading: false,
        }))
      );
    }
    abortPlanTripDetails.abort();
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setDisableInput(false);
    }
  }

  /**
   * Editing activity
   */
  function handleEditActivity(data: any) {
    // console.log(data);
  }

  //    if (JSON.stringify(selectedChatData) === "{}")
  //      return (
  //        <Stack mt={5}>
  //          <Loader />
  //        </Stack>
  //      );

  const handleNav = () => {
    isMobile ? navigate(ROUTES.AdjustTrip) : setScreen(ScreenTypes.Adjust);
  };

  // Save itinerary
  async function saveItineraryBeforeBooking(userId?: string) {
    // if (selectedChatData?.isSaved) return true;
    setShowLoader(true);
    toast.dismiss();
    const metaData: any = {
      // chatData: chatData,
      selectedChatData: {
        ...selectedChatData,
        isSaved: true,
        // itineraryPromptContext:
        //   selectedChatData?.prompt[selectedChatData?.prompt?.length - 1] || [],
        prompt: [],
        postText: "TEST",
        preText: "TEST",
      },
    };
    const saveItineraryPayload = {
      tripName: selectedChatData?.tripName || "",
      userId: userId || userInfo?.id,
      metaData,
      status: "draft",
      numberOfDaysSelected: Number(selectedChatData.noOfDays),
      year: "",
      selectedMonth: "",
      is_admin_created: userInfo?.role === 1,
      // data: selectedChatData?.results || null,
      data: {},
    };
    // console.log("IS SAVED", selectedChatData?.isSaved);
    const response: SaveItineraryDetailsResponse =
      await saveItineraryDetailsAPI(
        saveItineraryPayload,
        selectedChatData?.isSaved ? "PUT" : "POST",
        selectedChatData?.isSaved ? selectedChatData.id : ""
        // selectedChatData?.tripName || "",
        // selectedChatData.id,
        // userInfo?.id || userInfo?.id,
        // metaData,
        // "draft",
        // Number(selectedChatData.noOfDays),
        // "",
        // "",
        // userInfo?.role === 1,
        // selectedChatData?.results || null
      );
    // console.log("IS SAVED ERROR", response);
    if (response?.statusCode === 200) {
      let checkIfMultipleListFlag = true;
      let tempChatData = chatData?.map((chatDataEle: iChatData) => {
        if (chatDataEle.id === selectedChatData.id) {
          checkIfMultipleListFlag = false;
          const updatedSelectedChatData = {
            ...chatDataEle,
            isSaved: true,
            id: response?.data?.id,
          };
          setSelectedChatData(updatedSelectedChatData);
          return updatedSelectedChatData;
        }
        return chatDataEle;
      });
      if (checkIfMultipleListFlag) {
        // console.log("GOING DEEP");
        tempChatData = chatData?.map((chatDataEle: any) => {
          if (
            !chatDataEle?.singleSelectedDestination &&
            chatDataEle?.destinationList.length > 0
          ) {
            // console.log("GOING DEEP 1", chatDataEle.id);
            return {
              ...chatDataEle,
              destinationList: chatDataEle?.destinationList.map(
                (destEle: iChatData) => {
                  if (destEle.id === selectedChatData.id) {
                    // console.log(
                    //   "GOING DEEP 2",
                    //   destEle.id,
                    //   selectedChatData.id
                    // );

                    const updatedSelectedChatData = {
                      ...destEle,
                      isSaved: true,
                      id: response?.data?.id,
                    };
                    setSelectedChatData(updatedSelectedChatData);
                    return updatedSelectedChatData;
                  }
                  return destEle;
                }
              ),
            };
          }
          return chatDataEle;
        });
      }
      setChatData(tempChatData);

      // setSelectedChatData((prev: any) => ({
      //   ...prev,
      //   isSaved: true,
      //   id: response?.data?.id,
      // }));
      await saveHistory(tempChatData, "PUT");
      await getHistory(true);
      setIsItinerarySavedFlag(true);
      // setShowLoader(false);
      return true;
    } else {
      // console.log("IS SAVED ERROR", selectedChatData?.isSaved);
      toastMessage.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
      setIsFetchingAirports(false);
      // setShowLoader(false);
      return false;
    }
  }
  const selectedClass =
    selectedChatData?.flight_cabin_class?.toLowerCase() ||
    "Economy".toLowerCase();

  const handleFlightClass = (selectedClass: string) => {
    switch (selectedClass) {
      case "Economy":
        return "E";
      case "economy":
        return "E";
      case "premium_economy":
        return "P";
      case "premium economy":
        return "P";
      case "premium":
        return "P";
      case "Business":
        return "B";
      case "business":
        return "B";
      case "First Class":
        return "F";
      case "first":
        return "F";
      default:
        return "E";
    }
  };
  const hotelRatingParse = (input: string): number[] => {
    const regex = /^\d+(,\d+)*$/;

    if (regex.test(input)) {
      return input.split(",").map(Number);
    } else {
      return [3];
    }
  };
  function convertRangeToString(range: any) {
    if (!range || typeof range !== "string") return "";

    const [start, end] = range.split("-").map(Number);
    if (!isNaN(start) && !isNaN(end)) {
      return `${start},${end}`;
    }
    return "";
  }

  const parseAirlineNames = (input: string | null | undefined): string[] => {
    if (typeof input !== "string" || input.trim() === "") {
      return [];
    }
    return input.split(",").map((name) => name.trim());
  };

  const getHotelTravelDetails = (tripData: iHotelStayTypes[]) => {
    if (!tripData || tripData.length === 0) {
      return true;
    }

    for (const trip of tripData) {
      if (trip?.tripDetails?.length > 0) {
        for (const day of trip.tripDetails) {
          const dayHotels = day?.itinerary_by_time_of_the_day_data?.filter(
            (activity) =>
              activity?.activity_type
                ?.toLowerCase()
                .startsWith("accommodation.hotel".toLowerCase())
          );
          if (dayHotels && dayHotels.length > 0) {
            for (const hotel of dayHotels) {
              if (
                !hotel.activity_type ||
                !hotel.accommodation_location_city ||
                !hotel.accommodation_location_country_code
              ) {
                return true;
              }
            }
            return false;
          }
        }
      }
    }

    // Return true if no hotels
    return true;
  };

  const hotelStayCheck = getHotelTravelDetails(
    selectedChatData.destinationList
  );

  function validateTripRequiredFields(selectedChatData: any): {
    isValid: boolean;
    missingFields: string[];
  } {
    if (!selectedChatData) {
      return {
        isValid: false,
        missingFields: [""],
      };
    }
    const missingFields: string[] = [];

    // if (!selectedChatData?.start_date) missingFields.push("start_date");
    // if (!selectedChatData?.number_of_travelers)
    //   missingFields.push("number_of_travelers");
    // if (!selectedChatData?.number_of_days) missingFields.push("number_of_days");
    // if (!selectedChatData?.trip_type) missingFields.push("trip_type");

    selectedChatData?.destinationList?.forEach((destination: any) => {
      destination?.tripDetails?.forEach((tripDetail: any) => {
        tripDetail?.itinerary_by_time_of_the_day_data?.forEach(
          (itinerary: any) => {
            //flight
            if (
              itinerary?.activity_type?.toLowerCase() ===
              "transportation.flight".toLowerCase()
            ) {
              if (!itinerary.departure_city_name)
                missingFields.push(`departure_city_name`);
              if (!itinerary.departure_city_state)
                missingFields.push(`departure_city_state`);
              if (!itinerary.departure_city_country)
                missingFields.push(`departure_city_country`);
              if (!itinerary.departure_city_country_code)
                missingFields.push(`departure_city_country_code`);
              if (!itinerary.departure_city_lattitude)
                missingFields.push(`departure_city_lattitude`);
              if (!itinerary.departure_city_longitude)
                missingFields.push(`departure_city_longitude`);
              if (!itinerary.arrival_city_name)
                missingFields.push(`arrival_city_name`);
              if (!itinerary.arrival_city_state)
                missingFields.push(`arrival_city_state`);
              if (!itinerary.arrival_city_country)
                missingFields.push(`arrival_city_country`);
              if (!itinerary.arrival_city_country_code)
                missingFields.push(`arrival_city_country_code`);
              if (!itinerary.arrival_city_lattitude)
                missingFields.push(`arrival_city_lattitude`);
              if (!itinerary.arrival_city_longitude)
                missingFields.push(`arrival_city_longitude`);
            }

            //hotel
            if (
              itinerary?.activity_type
                ?.toLowerCase()
                ?.startsWith("accommodation.hotel".toLowerCase())
            ) {
              if (!itinerary.accommodation_location_name)
                missingFields.push(`accommodation_location_name`);
              if (!itinerary.accommodation_location_latitude)
                missingFields.push(`accommodation_location_latitude`);
              if (!itinerary.accommodation_location_longitude)
                missingFields.push(`accommodation_location_longitude`);
              if (!itinerary.accommodation_location_city)
                missingFields.push(`accommodation_location_city`);
              if (!itinerary.accommodation_location_country_code)
                missingFields.push(`accommodation_location_country_code`);
            }
          }
        );
      });
    });
    return {
      isValid: missingFields.length === 0,
      missingFields,
    };
  }
  const flightTravelDetailsFromItinerary = getFlightTravelDetails(
    selectedChatData?.destinationList
  );

  const flightTravelData = getFlightTravelData(
    flightTravelDetailsFromItinerary
  );
  const flightTime =
    flightTravelData?.map((i: any) => i?.flightTime?.toLowerCase()) ?? [];
  //handle booking details
  const handleBookingDetails = () => {
    setBookingDetails((prevDetails) => ({
      ...prevDetails,
      adultsCount:
        itineraryInfo?.adultsCount ||
        parseInt(selectedChatData?.number_of_adults?.toLowerCase() as string) ||
        1,
      childrenCount:
        itineraryInfo?.childrenCount ||
        parseInt(
          selectedChatData?.number_of_children?.toLowerCase() as string
        ) ||
        0,
      seniorsCount:
        itineraryInfo?.seniorsCount ||
        parseInt(
          selectedChatData?.number_of_senior_citizens?.toLowerCase() as string
        ) ||
        0,
      travelFromDate:
        validateDate(
          selectedChatData?.start_date?.toLowerCase() ||
            itineraryInfo?.travelFromDate?.toLowerCase()
        ) || "",
      travelToDate: "",
      routeType: "",
      flightClass:
        handleFlightClass(selectedClass) ||
        itineraryInfo?.flightClass?.toLowerCase(),
      checkIn: "",
      checkOut: "",
      numberOfRoomCount:
        Number(selectedChatData?.number_of_rooms?.toLowerCase()) ||
        itineraryInfo?.numberOfRoomCount ||
        1,
      tripType: handleTripType(selectedChatData?.trip_type),
      update: false,
      hideFlights: airportPayload.length === 0 ? true : false,
      hideHotels: hotelStayCheck,
      isThisMultiCityTrip:
        handleTripType(selectedChatData?.trip_type) === 3 ? true : false,
      isFormUpdated: {
        arrivalForm: false,
        departureForm: false,
      },
      isDataModified: {
        flight: false,
        hotel: false,
      },
      selectedFlightId: "",
      isFlightAvailable: false,
      airlines: selectedChatData?.airlines?.toLowerCase() ?? "",
      hotelChains: selectedChatData?.hotels?.toLowerCase() ?? "",
      flightDayPart: selectedChatData?.flight_day_part?.toLowerCase() ?? "",
      numberOfStops:
        convertRangeToString(
          selectedChatData?.number_of_stops?.toLowerCase()
        ) || "",
      flightRefundable:
        selectedChatData?.flight_refundable?.toLowerCase() ?? null,
      noMoreFlights: false,
      hotelUniqueId: "",
      arrCity: "",
      depCity: "",
      numberOfDays: selectedChatData?.number_of_days ?? 1,
      cityInfo: {
        departure: "",
        arrival: "",
      },
      selectedLocation: {
        city: "",
        state: "",
        country: "",
        latitude: 0,
        longitude: 0,
      },
      selectedArrivalLocation: {
        city: "",
        state: "",
        country: "",
        latitude: 0,
        longitude: 0,
      },
      flightPreferences: {
        connections:
          Number(selectedChatData?.number_of_stops?.toLowerCase()) || 0,
        departureTime: flightTime,
        refundable:
          selectedChatData?.flight_refundable &&
          selectedChatData.flight_refundable.trim() !== ""
            ? selectedChatData.flight_refundable.toLowerCase() === "yes"
              ? true
              : false
            : null,
        airlines: parseAirlineNames(selectedChatData?.airlines?.toLowerCase()),
      },
      hotelPreferences: {
        refundable:
          selectedChatData?.hotel_refundable &&
          selectedChatData?.hotel_refundable.trim() !== ""
            ? selectedChatData?.hotel_refundable?.toLowerCase() === "yes"
              ? true
              : false
            : null,

        hotelChains: parseAirlineNames(selectedChatData?.hotels?.toLowerCase()),
      },
      flightHistory: [
        {
          city_name: "",
          flights: [],
          number_of_days: 0,
        },
      ],
      // totalTravelCities: cityStay,
      // totalHotelStays: cityStayInformation,
      hotels: [
        {
          checkIn: "",
          checkOut: "",
          country: "",
          city: "",
          latitude: "",
          longitude: "",
        },
      ],
      airPort: [
        {
          arrivalAddress: "",
          departureGoogleAddress: "",
          departureCityAddress: "",
          arrivalCityAddress: "",
          arrival: "",
          departure: "",
          fromCity: "",
          destinationCity: "",
          departureDate: "",
          returnDate: "",
          departureAirportFullName: "",
          arrivalAirportFullName: "",
          arrivalCityLatitude: "",
          arrivalCityLongitude: "",
          departureCityLatitude: "",
          departureCityLongitude: "",
          noOfDays: 0,
          newArrivalCitySearch: false,
          newDepartureCitySearch: false,
          arrivalCityDetails: "",
          departureCityDetails: "",
          arrivalSelectedLocation: {
            city: "",
            state: "",
            country: "",
            latitude: 0,
            longitude: 0,
          },
          departureSelectedLocation: {
            city: "",
            state: "",
            country: "",
            latitude: 0,
            longitude: 0,
          },
        },
      ],
      travelJourneyData: [
        {
          departureCityAddress: "",
          arrivalCityAddress: "",
          departureDate: "",
          returnDate: "",
          days: [],
          flightCheckIn: "",
          flightCheckOut: "",
          totalDays: 0,
          flightTime: "",
        },
      ],
      visibleList: {
        flight: 0,
        hotel: 4,
        package: 2,
      },
      filters: {
        starRating: selectedChatData?.hotel_star_ratings?.toLowerCase()
          ? hotelRatingParse(
              selectedChatData?.hotel_star_ratings?.toLowerCase()
            )
          : hotelRatingParse(""),
      },
    }));

    // Clear data
    setFlightData(null);
    setHotelData(null);
    setPrevRoute(ROUTES.ItineraryDetails);
    // Set UI state
    setShowMore({ visibleList: { flight: 0, hotel: 4, package: 4 } });
    setHotelPagination([]);
  };

  //Navigation

  const handleNavigateToBooking = () => {
    setTimeout(() => {
      if (isMobile) {
        navigate(ROUTES.Booking);
      } else {
        setScreen(ScreenTypes.Booking);
      }
      setShowLoader(false);
    }, 500);
  };

  // handle book function
  const handleBook = async () => {
    try {
      setFlightAvailabilityData(null);
      setSelectedHotelRoomArr([]);
      setSignInClick(true);
      triggerGAEvent(GA_LABEL_ENUMS.itinerary_details_book_click);
      localStorage.removeItem("_traveler_info_array");
      setShowLoader(true);
      handleBookingDetails();
      if (isDesktopOrTablet) {
        setIsFetchingAirports(true);
      }

      let airportData = null;
      try {
        airportData = await getAirportData();
      } catch (airportError) {
        console.warn("Airport API failed");
      }

      if (!airportData || airportData.length === 0) {
        console.error("No valid airport data found.");
        setSelectedFlightOptions({
          ...selectedFlightOptions,
          flights: flightTravelDetailsFromItinerary[0]?.flights?.length > 0,
          hotels: !hotelStayCheck,
        });

        if (!userInfo?.loggedIn) {
          setBookClickBeforeLoginFlag(true);
          setShowLoader(false);
          setLoginCreateAccountState({
            flag: true,
            page: DialogSelected.LOGIN,
          });
          return false;
        }
        const isValid = await saveItineraryBeforeBooking();
        if (!userInfo?.id || !isValid) {
          setShowLoader(false);
          return;
        }
        handleNavigateToBooking();
        return;
      }
      setSelectedFlightOptions({
        ...selectedFlightOptions,
        flights: flightTravelDetailsFromItinerary[0]?.flights?.length > 0,
        hotels: !hotelStayCheck,
      });

      if (!userInfo?.loggedIn) {
        setBookClickBeforeLoginFlag(true);
        setShowLoader(false);
        setLoginCreateAccountState({ flag: true, page: DialogSelected.LOGIN });
        return false;
      }
      const isValid = await saveItineraryBeforeBooking();
      if (!userInfo?.id || !isValid) {
        setShowLoader(false);
        return;
      }
      handleNavigateToBooking();
    } catch (error) {
      console.error("Error during booking process:", error);
      setShowLoader(false);
    }
  };

  const value = {
    handleNavigateToBooking,
    saveItineraryBeforeBooking,
    showLoader,
    fetchAirportController,
    setShowLoader,
    openModifyItineraryDialog,
    setOpenModifyItineraryDialog,
    handleBackButton,
    noOfCitySelected,
    handleNav,
    handleSaveItinerary,
    handleOnShare,
    handleShare,
    handleBook,
    tempSelectedChatData,
    handleEditActivity,
    disableInput,
    showEditActivityFlag,
    setShowEditActivityFlag,
    handleSendMessage: STREAMING_ENABLE
      ? handleSendMessageStreaming
      : handleSendMessage,
    inputValue,
    setInputValue,
    handleCancel: STREAMING_ENABLE ? handleCancelStreaming : handleCancel,
  };
  return (
    <ItineraryContext.Provider value={value}>
      {children}
    </ItineraryContext.Provider>
  );
};
export const useItineraryContext = () => useContext(ItineraryContext);
