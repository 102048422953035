import React from "react";
import { backIconWithText } from "../../../constant";
import { Button } from "@mui/material";
import styles from "./buttonWithText.module.scss";

export default function ButtonWithText({
  onclick = () => {},
  icon = backIconWithText,
}: {
  onclick?: any;
  icon: any;
}) {
  return (
    <Button className={`${styles.columnAlignButton}`} onClick={onclick}>
      <img src={icon} alt="icon" />
    </Button>
  );
}
