import React, { ChangeEvent, useContext, useState } from "react";
import AppButton from "../../component/button/button";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import InputDatePicker from "../../component/booking/inputFields/inputDatePicker/inputDatePicker";
import RangePicker from "../../component/booking/inputFields/rangePicker/rangePicker";
import CustomCheckbox from "../../component/booking/bookingCheckBox/CustomCheckbox";
import { toastMessage } from "../../utils/ToastProvider";
import { Chip } from "@mui/material";
import { getPreferenceCount } from "../../utils/booking/getPreferenceCount";
import RouteAndClass from "../../component/booking/inputFields/routeAndClassNew/routeAndClass";
import NewTravelers from "../../component/booking/inputFields/newTravelers/newTravelers";
import styles from "./test.module.scss";
import NewDatePicker from "../../component/booking/inputFields/dateNewPicker/newDatePicker";
import DepartureTimes from "../../component/booking/departureTimes/departureTime";

export default function Test() {
  const state = useContext(StateContext);
  const {
    bookingDetails,
    setBookingDetails,
    selectedFlightOptions,
    setSelectedFlightOptions,
    setShowBookingPreferences,
    showBookingPreferences,
  }: iGlobalContext = state;
  const [startDate, setStartDate] = useState<string | null>(null);
  console.log("startDate", startDate);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setSelectedFlightOptions((prevState) => {
      // Ensure at least one option is always selected
      if (!checked && Object.values(prevState).filter(Boolean).length === 1) {
        return prevState;
      }

      return {
        ...prevState,
        [name]: checked,
      };
    });
  };

  const [selectedAirport, setSelectedAirport] = useState({
    airportCode: "",
    airportName: "",
  });
  console.log("selectedAirport", selectedAirport);
  const handleAirportChange = (value: string) => {
    setSelectedAirport((prevState) => ({
      ...prevState,
      airportCode: value,
      airportName: value,
    }));
    // Update bookingDetails or any other state management logic here
  };
  const handleDepartureChange = (selected: string[]) => {
    console.log("Selected departure times:", selected);
  };
  const handlTestFunction = () => {
    toastMessage.success("OOPS!", "We hit a snag. Please try again later.");
  };
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.searchContainer}>
          {/* <SearchBox title="Search Criteria" expand={true} /> */}
          <section className={styles.headerContainer}>
            <div className={styles.checkBox}>
              <CustomCheckbox
                label="FLIGHTS"
                name="FLIGHTS"
                handleCheckboxChange={handleChange}
                selectedOptions={selectedFlightOptions}
                onClick={() => {
                  if (bookingDetails.hideFlights)
                    toastMessage.error(
                      "You do not have flights for this itinerary"
                    );
                }}
                disable={bookingDetails.hideFlights}
              />
              <CustomCheckbox
                label="HOTELS"
                name="HOTELS"
                handleCheckboxChange={handleChange}
                selectedOptions={selectedFlightOptions}
                onClick={() => {
                  if (bookingDetails.hideHotels)
                    toastMessage.error(
                      "You do not have hotels for this itinerary"
                    );
                }}
                disable={bookingDetails.hideHotels}
              />
            </div>

            <div className={styles.showDetailsBtn}>
              <Chip
                label={`Preferences (${getPreferenceCount(
                  bookingDetails.flightPreferences,
                  bookingDetails.hotelPreferences,
                  selectedFlightOptions
                )})`}
                size="medium"
                variant="outlined"
                sx={{
                  height: "20px",
                }}
                onClick={() => {
                  setShowBookingPreferences(true);
                }}
                className={styles.chip}
                style={{
                  backgroundColor: showBookingPreferences ? "#A4D1F0" : "#ffff",
                }}
              />
            </div>
          </section>
          <button onClick={handlTestFunction}>Test</button>
          <div className={styles.calendarCard}>
            <RangePicker
              startDate={bookingDetails.travelFromDate}
              endDate={bookingDetails.travelToDate}
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              handleUpdateData={() => {}}
              label="DEPARTURE"
            />
            <InputDatePicker
              label="RETURN"
              startDate={bookingDetails.travelToDate}
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              handleUpdateData={() => {}}
            />
          </div>
          <div className={styles.travelersCard}>
            <div>
              <DepartureTimes
                onChange={handleDepartureChange}
                initialSelected={["Early Morning", "Morning"]}
              />
            </div>
            <div>
              <NewTravelers
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                showRoomAndTravellers={false}
                showOnlyRoom={false}
                showInstrcutions={true}
              />
            </div>
            <div>
              <RouteAndClass
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                tripType={bookingDetails.tripType}
              />
            </div>
          </div>
          <div className={styles.airportCard}>
            {bookingDetails?.airPort &&
              bookingDetails?.airPort?.length >= 1 &&
              bookingDetails.airPort.map((i, index) => (
                <div className={styles.newInputCard2} key={index}>
                  <div className={styles.card2}>
                    {/* <NewSingleAirportPicker
                      arrival={i.arrival}
                      departure={i.departure}
                      bookingDetails={bookingDetails}
                      departureAirportFullName={i.departureAirportFullName}
                      handleUpdateData={() => {}}
                      value={selectedAirport.airportCode}
                      handleAirportChange={handleAirportChange}
                    /> */}
                  </div>
                </div>
              ))}
            <NewDatePicker
              label="date"
              handleUpdateData={() => {}}
              dateInput={startDate}
              setDateInput={setStartDate}
            />
          </div>
          <div className={styles.searchBtn}>
            <AppButton size="md">Search</AppButton>
          </div>
        </div>
      </div>
      {/* <div className={styles.inputContaie}>
        <InputWithMic
         disable={false}
         onSend={() => {}}
         onStopRecording={() => {}}
         value={value}
 setValue={setValue}
         handleCancel={() => {}}
         disableSendBtn={false}
         disableInput={false}
        />
        <textarea className={styles.input} />
      </div> */}
      {/* <NewFlightListing /> */}
    </div>
  );
}
