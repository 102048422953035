import React, { useContext, useEffect, useRef, useState } from "react";
import CustomAccordion from "../customAccordion/CustomAccordion";
import styles from "./bookedHotelAccordion.module.scss";
import {
  getDayOfWeek,
  getDayOfWeekUTC,
  getMonthDateUTC,
} from "../../../utils/dateUtils";
import { bedFontLight } from "../../../constant";
// import { useNavigate } from "react-router-dom";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { BOOKING_STATUS, HOTEL_CARD_TYPES } from "../../../enums";
import HotelCard from "../../booking/hotelBooking/hotelCard/hotelCard";

interface IProps {
  readonly checkIn?: any;
  readonly checkOut?: any;
  readonly star: Readonly<number>;
  readonly hotelName: Readonly<string>;
  readonly hotelLocation: Readonly<string>;
  readonly hotelRating: Readonly<number>;
  readonly reviewCount: Readonly<number>;
  readonly cost: Readonly<number>;
  readonly cancellation: Readonly<string>;
  readonly Amenity: Readonly<string[]>;
  readonly hotelList: Readonly<boolean>;
  readonly discount?: Readonly<string>;
  readonly discountCost?: Readonly<string>;
  readonly selected?: Readonly<number | null>;
  readonly onSelect?: Readonly<() => void>;
  readonly id?: Readonly<number | null>;
  readonly hotelMainImage?: Readonly<string>;
  readonly taxesIncluded: Readonly<string>;
  readonly reviewHeading?: Readonly<string>;
  readonly hotelId?: Readonly<string>;
  readonly hotelDetails?: Readonly<any>;
  readonly city?: Readonly<string>;
  readonly hotelAccordionClick?: () => void;
  readonly hotelViewDetailsClick?: () => void;
  readonly handleCheckboxChange: () => {};
  readonly isHotelBeingModifiedOrCancelled: Readonly<boolean>;
  // readonly setShowSelectRoomModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function BookedHotelAccordion({
  star,
  hotelName,
  hotelLocation,
  hotelRating,
  reviewCount,
  cost,
  cancellation,
  Amenity,
  hotelList,
  discount,
  discountCost,
  selected,
  onSelect,
  id,
  hotelMainImage,
  taxesIncluded,
  reviewHeading,
  hotelId,
  hotelDetails,
  city,
  checkIn,
  checkOut,
  hotelAccordionClick,
  hotelViewDetailsClick,
  isHotelBeingModifiedOrCancelled,
  handleCheckboxChange,
  // setShowSelectRoomModal,
}: IProps) {
  // const navigate = useNavigate();
  const globalContext = useContext(StateContext);
  const { manageHotelsArr = [] }: iGlobalContext = globalContext;
  // const [hideShowRoomDetails, setHideShowRoomDetails] = useState(false);

  // console.log("HOTEL", checkIn, checkOut);
  // const isAccordClosed = useRef<any>();

  const isThisHotelBeingManaged = manageHotelsArr
    ?.map((item: any) => item?.hotelBookId)
    .includes(hotelDetails?.hotelBookId);

  return (
    <CustomAccordion
      // key={id}
      className={styles.customFlightStyles}
      summaryClass={styles.summaryFlightClass}
      accordianDetailsClass={styles.accordFlightDetailsClass}
      defaultExpanded={isHotelBeingModifiedOrCancelled}
      hideDropDownIcon={isHotelBeingModifiedOrCancelled}
      headingComponent={
        <div
          className={styles.headingFlightComp}
          onClick={() => {
            if (hotelAccordionClick) hotelAccordionClick();
          }}
        >
          <div className={styles.accordTitleCard}>
            {checkIn && checkOut && (
              <p className={styles.dayAndCity}>
                {getDayOfWeekUTC(checkIn)} &nbsp;
                <span>{getMonthDateUTC(checkIn)}</span> - &nbsp;
                {getDayOfWeek(checkOut)} &nbsp;
                <span>{getMonthDateUTC(checkOut)}</span>
              </p>
            )}
            {city && <p className={styles.accordDesc}>{city.split(",")[0]}</p>}
          </div>
          <div className={styles.statusAndBedfontContainer}>
            {hotelDetails?.bookingStatus && (
              <p
                className={`${styles.bookingStatusStyles} ${hotelDetails?.bookingStatus === BOOKING_STATUS.Cancelled && styles.cancelledStatus}`}
              >
                {hotelDetails?.bookingStatus}
              </p>
            )}
            {bedFontLight && (
              <img
                src={bedFontLight}
                alt="hotel"
                className={`${styles.planeDepartureIcon} ${isHotelBeingModifiedOrCancelled && styles.removePadding}`}
              />
            )}
          </div>
        </div>
      }
      bodyComponent={
        // <div className={styles.main}>
        //   <div className={styles.twoSectionsContainer}>
        //     <img
        //       src={hotelMainImage || hotelRoomIcon}
        //       alt="hotel"
        //       className={`${styles.hotelImg}`}
        //     />
        //     <section className={styles.contentCard}>
        //       <div className={styles.starContainer}>
        //         <img
        //           className={styles.starPropertyImg}
        //           src={propertyStarBlue}
        //           alt="star icon"
        //         />
        //         <span className={styles.star}>{stars} star property</span>
        //       </div>
        //       <p className={styles.hotelName}>{name}</p>
        //       <p className={styles.location}>
        //         {/* {place} &nbsp; | &nbsp; */}
        //         {address}
        //       </p>
        //       <div className={styles.roomDetailsContainer}>
        //         <div className={styles.room}>
        //           {noOfRooms} Room -{" "}
        //           {/* {bedCount > 1 ? bedCount : ""} {bedSize}{" "} */}
        //           {bedInformation[0]?.bedType}
        //         </div>
        //       </div>
        //     </section>
        //   </div>
        //   <div className={styles.card3}>
        //     <Button
        //       className={styles.flightBtn}
        //       onClick={() => {
        //         if (!hotelId) return;
        //         setHotelDetailsPageId(hotelId);
        //         if (isMobile) {
        //           navigate(ROUTES.HotelDetails);
        //         }
        //         if (!isMobile) {
        //           setShouldUserNavigateBackTotripDetailsPage(true);
        //           setScreen(ScreenTypes.HotelDetails);
        //         }
        //       }}
        //     >
        //       <div className={styles.iconAndText}>
        //         <img src={eyeLight} alt="icon" className={styles.expIcon} />
        //         <span className={styles.viewDetails}>
        //           VIEW <br /> DETAILS
        //         </span>
        //       </div>
        //     </Button>
        //   </div>
        // </div>
        <div className={styles.main}>
          <div className={styles.container}>
            <div
              className={`${styles.contentCard} ${isHotelBeingModifiedOrCancelled && styles.isHotelBeingModifiedOrCancelled}`}
              onClick={handleCheckboxChange}
            >
              <div
                className={`${styles.hotelListCard} ${!isHotelBeingModifiedOrCancelled && styles.selectedHotelListCard} ${isHotelBeingModifiedOrCancelled && !isThisHotelBeingManaged && styles.selectingState}`}
              >
                <HotelCard
                  star={star}
                  hotelName={hotelName || "Hotel name"}
                  hotelLocation={hotelLocation || "Hotel location"}
                  hotelRating={hotelRating || 0}
                  reviewCount={reviewCount || 0}
                  Amenity={Amenity?.slice(0, 3)}
                  hotelList={hotelList}
                  hotelMainImage={hotelMainImage}
                  reviewHeading={reviewHeading || "No reviews"}
                  // handleViewDetails={handleViewDetails}
                  city={city}
                  hotelDetails={hotelDetails}
                  hotelCardType={
                    isHotelBeingModifiedOrCancelled
                      ? HOTEL_CARD_TYPES.REFUND
                      : HOTEL_CARD_TYPES.ADDRESSS
                  }
                  // isAccordClosed={isAccordClosed}
                />
                {/* <div className={styles.card3}>
                  <Button
                    className={styles.flightBtn}
                    onClick={() => {
                      if (!hotelId) return;
                      setHotelDetailsPageId(hotelId);
                      if (isMobile) {
                        navigate(ROUTES.HotelDetails);
                      }
                      if (!isMobile) {
                        setShouldUserNavigateBackTotripDetailsPage(false);
                        setScreen(ScreenTypes.HotelDetails);
                      }
                    }}
                  >
                    <div className={styles.iconAndText}>
                      {discount && (
                        <DiscountCard
                          cost={discountCost}
                          percentage={discount}
                        />
                      )}
                      <div className={styles.viewDetailsBox}>
                        <img
                          src={eyeLight}
                          alt="icon"
                          className={styles.expIcon}
                        />
                        <span className={styles.viewDetails}>
                          View <br /> Details
                        </span>
                      </div>
                    </div>
                  </Button>
                </div> */}
              </div>
              <div className={styles.checkBoxContainer}>
                {isHotelBeingModifiedOrCancelled && (
                  <section
                    className={`${styles.inputBox} ${hotelDetails?.bookingStatus === BOOKING_STATUS.Cancelled && styles.cancelledCheckBox}`}
                  >
                    <input
                      type="checkbox"
                      checked={isThisHotelBeingManaged}
                      className={`${styles.customCheckbox}`}
                      readOnly
                      // disabled={disable}
                    />
                    <span className={`${styles.checkmark}`}></span>
                  </section>
                )}
              </div>
            </div>
          </div>
        </div>
      }
      zeroMarginOnExpand
      borderBottom
    />
  );
}
