import React, { useEffect } from "react";
import Turnstile, { useTurnstile } from "react-turnstile";
import styles from "./turnStileWidget.module.scss";
import { useMediaQuery } from "@mui/system";
import { CLOUDFARE_VERIFICATION_ID } from "../../../constant/environment";
import { captchaVerificationAPI } from "../../../api/authentication";

interface TurnstileWidgetProps {
  className?: string;
  //   onVerify: (token: string) => void;
  onSuccess: () => void;
}

function TurnstileWidget({ onSuccess, className = "" }: TurnstileWidgetProps) {
  const turnstile = useTurnstile();
  const isItXSScreenSize = useMediaQuery("(max-width: 365px)");

  useEffect(() => {
    if (window.location.hostname === "localhost") onSuccess();
  }, [onSuccess]);

  return (
    <>
      {window.location.hostname !== "localhost" ? (
        <Turnstile
          sitekey={CLOUDFARE_VERIFICATION_ID}
          className={`${className} ${styles.turnStileStyles}`}
          size={isItXSScreenSize ? "flexible" : "flexible"}
          onVerify={(token) => {
            // console.log(token, "token");
            captchaVerificationAPI({ token }).then((response) => {
              console.log(response, "captcha verification");
              if (!response?.data?.isVerified) turnstile.reset();
              if (response.data?.isVerified) onSuccess();
            });
          }}
        />
      ) : (
        <div />
      )}
    </>
    //
  );
}

export default TurnstileWidget;
