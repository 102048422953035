import {
  createContext,
  useContext,
  ReactNode,
  useRef,
  useState,
  useEffect,
} from "react";
import { iTopDestinationContext } from "./topDestinationContextInterface";
import { iGlobalContext } from "../globalContext/interface";
import { StateContext } from "../globalContext/context";
import { useNavigate } from "react-router-dom";
import {
  checkIfThereIsDestination,
  getConfigMessage,
  getItineraryPriceCityList,
  getItineraryVersion,
  getTripName,
  scrollToBottom,
  updateChatDataWithPrice,
} from "../../utils";
import { iChatData, iCityDetailsResult } from "../../types";
import {
  API_FAILURE_MESSAGE,
  ROUTES,
  SEE_MORE,
  SENDING_CHAT_MESSAGE,
  VIEW_ITINERARY_DETAILS,
} from "../../constant";
import {
  APP_CONFIG,
  ENVIRONMENT_TYPE,
  INVALID_JSON_PROMPT,
  LLM_RESPONSE_ERROR,
  PROMPT,
} from "../../constant/common";
import ReactGA from "react-ga4";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { v4 } from "uuid";
import { iHistoryContext } from "../historyContext/interface";
import { useHistoryContext } from "../historyContext/historyContext";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
import toast from "react-hot-toast";
import { useDataStreamingContext } from "../dataStreamingContext/DataStreamingContext";
import { iDataStreamingContext } from "../dataStreamingContext/dataStreamingContextInterface";
import { iChatStreamingPayload } from "../../interface/common";
import { socket, SOCKET_EVENTS } from "../../utils/socket";
import { chatStreaming } from "../../api/streaming/chatStreaming";
import {
  DESTINATION_LIST_DATA_GRID,
  SELECTED_CHAT_DATA,
} from "../../constant/dummyData";
import { getItineraryPriceAPI } from "../../api/itinerary/getItineraryPrice";
import { getItineraryImageURL } from "../../api/getItineraryImageURL";
import { TOAST_MESSAGES } from "../../constant/messages/toastMessages";
import { environment, STREAMING_ENABLE } from "../../constant/environment";
import { appendErrorMessageTochat } from "../../utils/appendErrorMessageToChat";
import { chatInteractionAPI } from "../../api/chatInteractionAPI";
import { planTripDetails } from "../../api/planTripDetails";
import { ChatInteractionResponse } from "../../interface";
import { getPromptAPI } from "../../api/getPrompt";
let abortPlanTripDetails = new AbortController();

const TopDestinationContext = createContext({} as iTopDestinationContext);

export const TopDestinationContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  // Context
  const state = useContext(StateContext);
  const {
    chatInputText,
    setChatInputText,
    chatData,
    setChatData,
    selectedChatData,
    setSelectedChatData,
    setIsItinerarySavedFlag,
    setSharedItineraryId,
    isMobile,
    modifyItineraryPrompt,
    setModifyItineraryPrompt,
    setScreen,
    setPrevRoute,
    setIsUserJustBookedTrip,
    userInfo,
    socketId,
    openLoaderOverlay,
    setOpenLoaderOverlay,
    appConfig,
    setSelectedCityListPrompContext,
  }: iGlobalContext = state;
  const { setCallSaveHistoryAPIFlag }: iHistoryContext = useHistoryContext();

  const {
    handleConversationStreamEvents,
    handleShowmoreEvents,
    handleFollowUpStreamEvents,
    disableInput,
    setDisableInput,
    chatAbortController,
    chatAbortControllerRef,
    setStreamingInProgressFlag,
  }: iDataStreamingContext = useDataStreamingContext();

  const navigate = useNavigate();
  const desktopScrollContainerRef: any = useRef(null);
  const [showChatLoaderFlag, setShowChatLoaderFlag] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  /**
   * Side effect to scroll chat UI to bottom
   */
  useEffect(() => {
    scrollToBottom(desktopScrollContainerRef);
    setModifyItineraryPrompt("");
  }, [modifyItineraryPrompt]);

  /**
   * Opening Trip details for Single or Multi city
   * */
  async function handleViewSingleTripDetails(chatDataArgs: iChatData) {
    setPrevRoute(ROUTES.TopDestination);
    setIsItinerarySavedFlag(false);
    setSharedItineraryId("");
    const tempChatData: iChatData = {
      ...chatDataArgs,
      destinationList: chatDataArgs.destinationList.map(
        (ele: iCityDetailsResult) => ({
          ...ele,
          checkedFlag: isMobile ? true : !ele.checkedFlag,
        })
      ),
    };
    setChatData((prev: iChatData[]) =>
      prev.map((ele: iChatData) => {
        if (ele.id === chatDataArgs.id) return tempChatData;
        else
          return {
            ...ele,
            destinationList: ele.destinationList.map(
              (destListEle: iCityDetailsResult) => ({
                ...destListEle,
                checkedFlag: false,
              })
            ),
          };
      })
    );
    const tempTripName = getTripName(chatDataArgs);
    setSelectedChatData({
      ...tempChatData,
      tripName: tempTripName,
    });
    if (isMobile) {
      navigate(ROUTES.ItineraryDetails);
    } else {
      if (tempChatData.destinationList[0].checkedFlag === false) {
        setScreen("");
      } else {
        setScreen("singleItinerary");
      }
    }
    triggerGAEvent(GA_LABEL_ENUMS.Suggested_Trip_Click, tempTripName);
  }

  async function handleShowMoreStreaming(chatDataArgs: iChatData) {
    toast.dismiss();
    setTimeout(() => {
      setDisableInput(true);
    }, 200);

    setChatData((prev: iChatData[]) =>
      prev.map((chatDataEle: iChatData) => {
        if (chatDataEle.id === chatDataArgs.id) {
          const tempDestinationList: any = chatDataEle.destinationList;
          tempDestinationList.push(DESTINATION_LIST_DATA_GRID);
          tempDestinationList.push(DESTINATION_LIST_DATA_GRID);
          return {
            ...chatDataEle,
            isLoading: true,
            destinationList: tempDestinationList,
          };
        } else return chatDataEle;
      })
    );

    const payload: iChatStreamingPayload = {
      userId: userInfo?.id || "",
      userInput: null,
      messages: chatDataArgs.prompt,
      socketId: socketId || "",
      iteneryInput: null,
      showMore: true,
    };
    socket.on(SOCKET_EVENTS.conversation_stream, handleShowmoreEvents);

    let response = await chatStreaming(payload, chatAbortController.signal);

    if (!response && chatAbortController.signal.aborted) {
      socket.off(SOCKET_EVENTS.conversation_stream, handleShowmoreEvents);
      setChatData((prev: iChatData[]) =>
        prev.map((chatDataEle: iChatData) => {
          if (chatDataEle.isLoading)
            return {
              ...chatDataEle,
              isLoading: false,
            };
          else return chatDataEle;
        })
      );
      return;
    }
    console.log(response?.data?.result);
    if (response?.data?.result) {
      socket.off(SOCKET_EVENTS.conversation_stream, handleShowmoreEvents);
      setChatData((prev: iChatData[]) =>
        prev.map((chatDataEle: iChatData) => {
          // if (chatDataEle.id === chatDataArgs.id)
          if (chatDataEle.isLoading) {
            const currentDestList = chatDataEle.destinationList?.length;
            const filteredList = chatDataEle.destinationList.filter(
              (eleDestinationList: iCityDetailsResult) =>
                eleDestinationList?.destination_name
            );
            if (currentDestList !== filteredList.length) {
              toastMessage.error(
                "OOPS!",
                "Something went wrong. Please try again."
              );
            }
            return {
              ...chatDataEle,
              isLoading: false,
              destinationList: filteredList,
            };
          } else return chatDataEle;
        })
      );
    }
    setDisableInput(false);
    setShowChatLoaderFlag(false);
    ReactGA.event({
      category: SEE_MORE.CATEGORY,
      action: SEE_MORE.ACTION,
      label: ` ${SEE_MORE.LABEL}`,
    });
  }
  async function handleShowMore(chatDataArgs: iChatData) {
    setTimeout(() => {
      setDisableInput(true);
    }, 200);

    setChatData((prev: iChatData[]) =>
      prev.map((chatDataEle: iChatData) => {
        if (chatDataEle.id === chatDataArgs.id)
          return {
            ...chatDataEle,
            isLoading: true,
          };
        else return chatDataEle;
      })
    );

    const showMorePromptResponse = await getPromptAPI(APP_CONFIG.SHOW_MORE);
    const showMorePrompt = showMorePromptResponse?.filter(
      (ele: any) => ele.key === APP_CONFIG.SHOW_MORE
    );
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    let response: ChatInteractionResponse | any = await chatInteractionAPI(
      userInfo?.id || "",
      showMorePrompt?.length > 0 ? showMorePrompt[0]?.value : PROMPT.SHOW_MORE,
      chatDataArgs.prompt,
      abortController.signal,
      null,
      appConfig
    );
    if (!response && !abortPlanTripDetails.signal) {
      toastMessage.info(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
      setDisableInput(false);
      setShowChatLoaderFlag(false);
      return;
    }
    response = checkIfThereIsDestination(response);
    if (response === LLM_RESPONSE_ERROR.jsonError.error) {
      setDisableInput(false);
      setShowChatLoaderFlag(false);
      if (environment !== ENVIRONMENT_TYPE.PRODUCTION) {
        toastMessage.error(
          TOAST_MESSAGES.SaveItinerary.destinationError.messageTitle,
          // getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
          TOAST_MESSAGES.SaveItinerary.destinationError.message
        );
      }
      return;
    }
    setDisableInput(false);

    let citiesList = [];
    if (response?.data?.cities) {
      citiesList = response?.data?.cities;
    }
    const tempResponse = citiesList.map((ele: any) => ({
      ...ele,
      id: v4(),
      checkedFlag: false,
      locations: [],
    }));
    setChatData((prev: iChatData[]) =>
      prev.map((chatDataEle: iChatData) => {
        if (chatDataEle.id === chatDataArgs.id)
          return {
            ...chatDataEle,
            fromCity: response?.data?.from,
            noOfDays: response?.data?.numberOfdays,
            message: "",
            preText: response?.data?.preText,
            postText: response?.data?.postText,
            prompt: response?.data?.messages,
            isUserMessage: false,
            isLoading: false,
            singleSelectedDestination: false,
            destinationList: [...chatDataEle.destinationList, ...tempResponse],
          };
        else return chatDataEle;
      })
    );
    const currentItineraryList = tempResponse;
    for (let i = 0; i < currentItineraryList.length; i++) {
      const cityName = currentItineraryList[i].destination_city;
      let imageRespone = await getItineraryImageURL(cityName);
      let count = 0;
      while (!imageRespone || !imageRespone?.data?.image) {
        // Limiting the number of API call to 10 times incase of 409 error
        count++;
        if (count === 3) break;
        // re calling the API
        imageRespone = await getItineraryImageURL(cityName);
      }
      if (imageRespone?.statusCode === 200) {
        currentItineraryList[i].imageURL = imageRespone.data.image;
      } else {
        // console.log("RESPONSE TOP Error");
        currentItineraryList[i].imageURL = "https://error.error"
      }
    }
    setDisableInput(false);
    setShowChatLoaderFlag(false);
    ReactGA.event({
      category: SEE_MORE.CATEGORY,
      action: SEE_MORE.ACTION,
      label: ` ${SEE_MORE.LABEL}`,
    });
  }

  /**
   * Handling selecting or unselecting
   * card from grid
   */
  const [selectedCheckboxDestinationId, setSelectedCheckboxDestinationId] =
    useState({
      cityDetailsId: "",
      destinationArgsId: "",
    });
  async function handleCheckboxChangeStreaming(
    cityDetails: iCityDetailsResult | any,
    destinationArgs: iChatData
  ) {
    toast.dismiss();
    setPrevRoute(ROUTES.TopDestination);
    setSelectedCheckboxDestinationId({
      cityDetailsId: cityDetails?.id,
      destinationArgsId: destinationArgs?.id,
    });
    let tempDestinationList: iCityDetailsResult[] =
      chatData.find((ele: iChatData) => ele.id === destinationArgs.id)
        ?.destinationList || ([] as iCityDetailsResult[]);

    setIsItinerarySavedFlag(false);
    setSharedItineraryId("");
    setShowChatLoaderFlag(false);
    tempDestinationList = tempDestinationList.map((ele: iCityDetailsResult) => {
      if (cityDetails.id === ele.id)
        return { ...ele, checkedFlag: !ele.checkedFlag };
      return { ...ele, checkedFlag: false };
    });
    setChatData((prev: iChatData[]) =>
      prev.map((ele: iChatData) => {
        if (ele.id === destinationArgs.id)
          return {
            ...ele,
            destinationList: tempDestinationList,
          };
        else
          return {
            ...ele,
            destinationList: ele.destinationList.map(
              (chatDataEle: iCityDetailsResult) => ({
                ...chatDataEle,
                checkedFlag: false,
              })
            ),
          };
      })
    );
    if (
      tempDestinationList.some((ele: iCityDetailsResult) => ele.checkedFlag)
    ) {
      setSelectedChatData({ ...SELECTED_CHAT_DATA });
      setShowChatLoaderFlag(true);

      const tempSelectedChatData = {
        ...destinationArgs,
        destinationList: tempDestinationList,
      };
      const cityList = tempSelectedChatData.destinationList
        .filter((city) => city.checkedFlag)
        .map((city) => city.destination_city);
      setDisableInput(true);
      abortPlanTripDetails.abort();
      abortPlanTripDetails = new AbortController();
      const iteneryInput = {
        user_given_number_of_days: [
          {
            city_name: cityList[0],
            num_days: null,
          },
        ],
      };

      // if (cityDetails?.destinationList?.length > 0) {
      //   setStreamingInProgressFlag(false);
      //   setDisableInput(false);
      //   const temp = destinationArgs?.destinationList?.map((ele: any) => {
      //     if (ele.id === cityDetails.id)
      //       return {
      //         ...ele,
      //         checkedFlag: true,
      //       };
      //     return ele;
      //   });
      //   setSelectedChatData(cityDetails);
      //   /**
      //    * remove the selected checkbox
      //    */
      //   tempDestinationList = destinationArgs?.destinationList?.map(
      //     (ele: iCityDetailsResult) => {
      //       return { ...ele, checkedFlag: false };
      //     }
      //   );
      //   setChatData((prev: iChatData[]) =>
      //     prev.map((ele: iChatData) => {
      //       if (ele.id === destinationArgs.id)
      //         return {
      //           ...ele,
      //           destinationList: tempDestinationList,
      //         };
      //       else
      //         return {
      //           ...ele,
      //           destinationList: ele.destinationList.map(
      //             (chatDataEle: iCityDetailsResult) => ({
      //               ...chatDataEle,
      //               checkedFlag: false,
      //             })
      //           ),
      //         };
      //     })
      //   );
      //   triggerGAEvent(GA_LABEL_ENUMS.Itinrary_click);
      //   if (isMobile) {
      //     navigate(ROUTES.ItineraryDetails);
      //   } else {
      //     setScreen("singleItinerary");
      //   }
      //   return;
      // }
      setOpenLoaderOverlay(true);
      const payload: iChatStreamingPayload = {
        userId: userInfo?.id || "",
        userInput: null,
        messages: tempSelectedChatData.prompt,
        socketId: socketId || "",
        iteneryInput: iteneryInput,
        showMore: false,
      };
      socket.on(
        SOCKET_EVENTS.conversation_stream,
        handleConversationStreamEvents
      );

      let response = await chatStreaming(payload, chatAbortController.signal);
      setStreamingInProgressFlag(false);

      if (!response && chatAbortController.signal.aborted) {
        socket.off(
          SOCKET_EVENTS.conversation_stream,
          handleConversationStreamEvents
        );
        socket.off(
          SOCKET_EVENTS.conversation_stream,
          handleFollowUpStreamEvents
        );
        setChatData((prev: iChatData[]) => {
          const length = prev.length;
          if (prev.length > chatData.length)
            return [...prev.splice(0, length - 2)];
          return prev;
        });
        return;
      }

      let prevChatData = {} as iChatData;
      setSelectedChatData((prev: iChatData) => {
        prevChatData = prev;
        return prev;
      });

      if (response?.data?.result) {
        if (
          prevChatData.destinationList[0]?.tripDetails[0]
            ?.itinerary_by_time_of_the_day?.length === 0
        ) {
          toastMessage.error(
            "OOPS!",
            "Something went wrong. Please try again."
          );
        }
        socket.off(
          SOCKET_EVENTS.conversation_stream,
          handleConversationStreamEvents
        );
        socket.off(
          SOCKET_EVENTS.conversation_stream,
          handleFollowUpStreamEvents
        );
      }

      setDisableInput(false);
      triggerGAEvent(GA_LABEL_ENUMS.Itinrary_click);
    }
    setOpenLoaderOverlay(false);
    setCallSaveHistoryAPIFlag({
      flag: true,
      method: "PUT",
    });
  }

  async function handleCheckboxChange(
    cityDetails: iCityDetailsResult | any,
    destinationArgs: iChatData
  ) {
    setPrevRoute(ROUTES.TopDestination);
    setSelectedCheckboxDestinationId({
      cityDetailsId: cityDetails?.id,
      destinationArgsId: destinationArgs?.id,
    });
    let tempDestinationList: iCityDetailsResult[] =
      chatData.find((ele: iChatData) => ele.id === destinationArgs.id)
        ?.destinationList || ([] as iCityDetailsResult[]);

    setIsItinerarySavedFlag(false);
    setSharedItineraryId("");
    setShowChatLoaderFlag(false);
    tempDestinationList = tempDestinationList.map((ele: iCityDetailsResult) => {
      if (cityDetails.id === ele.id)
        return { ...ele, checkedFlag: !ele.checkedFlag };
      return { ...ele, checkedFlag: false };
    });
    setChatData((prev: iChatData[]) =>
      prev.map((ele: iChatData) => {
        if (ele.id === destinationArgs.id)
          return {
            ...ele,
            destinationList: tempDestinationList,
          };
        else
          return {
            ...ele,
            destinationList: ele.destinationList.map(
              (chatDataEle: iCityDetailsResult) => ({
                ...chatDataEle,
                checkedFlag: false,
              })
            ),
          };
      })
    );
    if (
      tempDestinationList.some((ele: iCityDetailsResult) => ele.checkedFlag)
    ) {
      setSelectedChatData({} as iChatData);
      setShowChatLoaderFlag(true);
      const selectedChatData = {
        ...destinationArgs,
        destinationList: tempDestinationList,
      };
      const cityList = selectedChatData.destinationList
        .filter((city) => city.checkedFlag)
        .map((city) => city.destination_city);
      setDisableInput(true);
      abortPlanTripDetails.abort();
      abortPlanTripDetails = new AbortController();
      const iteneryInput = {
        user_given_number_of_days: [
          {
            city_name: cityList[0],
            num_days: null,
          },
        ],
      };

      if (cityDetails?.destinationList?.length > 0) {
        setDisableInput(false);
        const temp = destinationArgs?.destinationList?.map((ele: any) => {
          if (ele.id === cityDetails.id)
            return {
              ...ele,
              checkedFlag: true,
            };
          return ele;
        });
        setSelectedChatData(cityDetails);
        /**
         * remove the selected checkbox
         */
        tempDestinationList = destinationArgs?.destinationList?.map(
          (ele: iCityDetailsResult) => {
            return { ...ele, checkedFlag: false };
          }
        );
        setChatData((prev: iChatData[]) =>
          prev.map((ele: iChatData) => {
            if (ele.id === destinationArgs.id)
              return {
                ...ele,
                destinationList: tempDestinationList,
              };
            else
              return {
                ...ele,
                destinationList: ele.destinationList.map(
                  (chatDataEle: iCityDetailsResult) => ({
                    ...chatDataEle,
                    checkedFlag: false,
                  })
                ),
              };
          })
        );

        triggerGAEvent(GA_LABEL_ENUMS.Itinrary_click);
        if (isMobile) {
          navigate(ROUTES.ItineraryDetails);
        } else {
          setScreen("singleItinerary");
        }
        return;
      }
      setOpenLoaderOverlay(true);
      let response = await planTripDetails(
        abortPlanTripDetails,
        selectedChatData.prompt,
        iteneryInput
      );
      setDisableInput(false);
      response = checkIfThereIsDestination(response);
      // response = undefined
      if (response === LLM_RESPONSE_ERROR.jsonError.error) {
        setShowChatLoaderFlag(false);
        setOpenLoaderOverlay(false);
        setChatData((prev: iChatData[]) =>
          prev.map((ele: iChatData) => {
            return {
              ...ele,
              destinationList: ele.destinationList.map(
                (chatDataEle: iCityDetailsResult) => ({
                  ...chatDataEle,
                  checkedFlag: false,
                })
              ),
            };
          })
        );
        if (environment !== ENVIRONMENT_TYPE.PRODUCTION) {
          toastMessage.error(
            TOAST_MESSAGES.SaveItinerary.destinationError.messageTitle,
            TOAST_MESSAGES.SaveItinerary.destinationError.message
          );
        }
        return;
      }
      setSelectedCityListPrompContext(
        response?.data?.messages[response?.data?.messages?.length - 1] || {}
      );

      if (response?.statusCode !== 200 || !response) {
        setOpenLoaderOverlay(false);
        if (!abortPlanTripDetails?.signal?.aborted)
          toastMessage.error(
            "OOPS!",
            getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
          );
        console.error(API_FAILURE_MESSAGE);
        return;
      }
      if (!response?.data?.cities || response?.data?.cities?.length === 0) {
        setChatData((prev: any) => [
          ...prev,
          {
            ...response?.data,
            id: v4(),
            message: response?.data?.result,
            isUserMessage: false,
            prompt: [],
            isLoading: false,
            destinationList: [],
            singleSelectedDestination: false,
          },
        ]);
        setOpenLoaderOverlay(false);
        setCallSaveHistoryAPIFlag({
          flag: true,
          method: "PUT",
        });
        scrollToBottom(desktopScrollContainerRef);
        return;
      }
      const tempDestinationList_2 = selectedChatData.destinationList.map(
        (destinationListEle: iCityDetailsResult, index: number) => {
          if (destinationListEle.checkedFlag) {
            const tempObj = {
              ...destinationListEle,
              results: response?.data?.results,
              ...response?.data,
              cities: [],
              city_wise_itinerary: [],
              result: "",
              messages: [],
              fromCity: response?.data?.from,
              noOfDays: response?.data?.numberOfdays,
              id: v4(),
              message: "",
              preText: response?.data?.preText,
              postText: response?.data?.postText,
              prompt: response?.data?.messages,
              isUserMessage: false,
              isLoading: false,
              singleSelectedDestination: response?.data?.singleCity,
              destinationList: response?.data?.cities.map((ele: any) => ({
                ...ele,
                id: v4(),
                checkedFlag: true,
                locations: [],
                imageURL: destinationListEle?.imageURL,
                destination_name: destinationListEle?.destination_name,
              })),
              itinerary_version: 1,
            };
            setSelectedChatData(tempObj);
            return tempObj;
          }
          return destinationListEle;
        }
      );
      const tempTripName = tempDestinationList_2.reduce(
        (acc: string, ele: iCityDetailsResult, index: number) => {
          if (ele.checkedFlag) {
            if (index === selectedChatData?.destinationList.length - 1)
              return acc + ele.destination_city;
            else return acc + ele.destination_city;
          }
          return acc;
        },
        ""
      );
      // console.log({ tempDestinationList_2 });
      setSelectedChatData((prev: any) => ({
        ...prev,
        tripName: tempTripName,
        iteneryInput: iteneryInput,
      }));
      ReactGA.event({
        category: VIEW_ITINERARY_DETAILS.CATEGORY,
        action: VIEW_ITINERARY_DETAILS.ACTION,
        label: ` ${VIEW_ITINERARY_DETAILS.LABEL}  ${tempTripName}`,
      });
      setShowChatLoaderFlag(false);

      /**
       * remove the selected checkbox
       */
      tempDestinationList = tempDestinationList_2.map(
        (ele: iCityDetailsResult) => {
          return { ...ele, checkedFlag: false };
        }
      );
      setChatData((prev: iChatData[]) =>
        prev.map((ele: iChatData) => {
          if (ele.id === destinationArgs.id)
            return {
              ...ele,
              // ...response?.data,
              destinationList: tempDestinationList,
            };
          else
            return {
              ...ele,
              destinationList: ele.destinationList.map(
                (chatDataEle: iCityDetailsResult) => ({
                  ...chatDataEle,
                  checkedFlag: false,
                })
              ),
            };
        })
      );

      /**
       * MOBILE or Desktop
       */
      triggerGAEvent(GA_LABEL_ENUMS.Itinrary_click);
      if (isMobile) {
        navigate(ROUTES.ItineraryDetails);
      } else {
        setScreen("singleItinerary");
      }
    }
    setOpenLoaderOverlay(false);
    setCallSaveHistoryAPIFlag({
      flag: true,
      method: "PUT",
    });
  }

  /**
   * Side effect to cancel or abort api call
   */
  useEffect(() => {
    if (chatData) {
      const ifSelected = chatData?.reduce(
        (acc: number, eleChatData: iChatData) => {
          return (
            acc +
            eleChatData.destinationList.reduce(
              (subEle: number, eleDestList: iCityDetailsResult) => {
                if (eleDestList.checkedFlag) return subEle + 1;
                return subEle;
              },
              0
            )
          );
        },
        0
      );
      if (ifSelected === 0) {
        abortPlanTripDetails.abort();
      }
    }
  }, [chatData]);

  /**
   * Handling sending message
   */
  function extractStatement(str: string) {
    const match = str.match(/"statement":\s*"([^"]*)"/); // Match statement and handle newlines manually
    return match ? match[1].replace(/\\n/g, "\n") : null; // Replace escaped newlines with actual newlines
  }
  async function handleSendMessageStreaming() {
    toast.dismiss();
    // setCallSaveHistoryAPIFlag(false);
    scrollToBottom(desktopScrollContainerRef);
    if (chatData.length > 0 && chatData[chatData.length - 1].isLoading) {
      toastMessage.info("Please wait, we are still fetching the result!");
      return;
    }
    if (chatInputText === "") {
      toastMessage.info("Please type your message!");
      return;
    }
    setChatData((prev: iChatData[]) => [
      ...prev,
      {
        id: v4(),
        message: chatInputText,
        isUserMessage: true,
        prompt: [],
        isLoading: false,
        destinationList: [],
        singleSelectedDestination: false,
      },
      {
        id: v4(),
        message: "",
        prompt: [],
        isUserMessage: false,
        isLoading: true,
        destinationList: [],
        singleSelectedDestination: false,
      },
    ]);
    setChatInputText("");
    setDisableInput(true);
    chatAbortControllerRef.current = chatAbortController;

    const dupChatData = JSON.parse(JSON.stringify(chatData));
    const promptContext: any =
      dupChatData?.reverse()?.find((item: any) => item?.prompt?.length > 0)
        ?.prompt || [];
    const payload: iChatStreamingPayload = {
      userId: userInfo?.id || "",
      userInput: chatInputText,
      messages: promptContext,
      socketId: socketId || "",
      iteneryInput: null,
      showMore: false,
    };
    socket.on(
      SOCKET_EVENTS.conversation_stream,
      handleConversationStreamEvents
    );

    let response = await chatStreaming(payload, chatAbortController.signal);
    setStreamingInProgressFlag(false);
    if (!response && chatAbortController.signal.aborted) {
      socket.off(
        SOCKET_EVENTS.conversation_stream,
        handleConversationStreamEvents
      );
      socket.off(SOCKET_EVENTS.followup_stream, handleFollowUpStreamEvents);
      setChatData((prev: iChatData[]) => {
        const length = prev.length;
        if (prev.length > chatData.length)
          return [...prev.splice(0, length - 2)];
        return prev;
      });
      return;
    }

    if (response?.data?.result) {
      socket.off(
        SOCKET_EVENTS.conversation_stream,
        handleConversationStreamEvents
      );
      socket.off(SOCKET_EVENTS.followup_stream, handleFollowUpStreamEvents);
      // let shouldFetchPrice = false;
      // let tempDestinationList: any = [];
      setChatData((prevChatData) => {
        if (prevChatData.length > 0) {
          const message = prevChatData[prevChatData.length - 1].message;
          const extractedMessage = extractStatement(message);
          let lastChatData: iChatData = {
            ...prevChatData[prevChatData.length - 1], // Copy the last element
            prompt: response?.data?.messages,
            isLoading: false,
            message: extractedMessage
              ? extractedMessage
              : prevChatData[prevChatData.length - 1].message,
            results: response?.data?.result,
          };
          if (
            lastChatData?.message === "" &&
            lastChatData?.destinationList?.length === 0
          ) {
            lastChatData = {
              id: v4(),
              message: LLM_RESPONSE_ERROR.timeOut.message,
              isUserMessage: false,
              prompt: [],
              isLoading: false,
              destinationList: [],
              singleSelectedDestination: false,
            };
          }
          // if (lastChatData?.destinationList?.length) {
          //   shouldFetchPrice = true;
          // }
          // tempDestinationList = lastChatData?.destinationList || [];
          setSelectedChatData(lastChatData);
          return [
            ...prevChatData.slice(0, -1), // All elements except the last
            lastChatData,
          ];
        }
        return prevChatData; // If empty, return as is
      });

      // if (shouldFetchPrice) {
      //   await updateChatDataWithPrice(
      //     setSelectedChatData,
      //     setChatData,
      //     tempDestinationList
      //   );
      // }
    }

    /**
     * Recalling the API if it fails
     */
    // if (
    //   // response === null ||
    //   // (!response && !abortPlanTripDetails.signal.aborted) ||
    //   // !response?.data?.result ||
    //   checkIfThereIsDestination(response) === LLM_RESPONSE_ERROR.jsonError.error
    // ) {
    //   console.log("AGAIN");
    //   response = await chatInteractionAPI(
    //     userInfo?.id || "",
    //     chatInputText + INVALID_JSON_PROMPT,
    //     promptContext,
    //     // chatData.length > 0 ? chatData[chatData.length - 1].prompt : [],
    //     chatAbortController.signal,
    //     null,
    //     appConfig
    //   );
    // }
    setDisableInput(false);
    scrollToBottom(desktopScrollContainerRef);
    ReactGA.event({
      category: SENDING_CHAT_MESSAGE.CATEGORY,
      action: SENDING_CHAT_MESSAGE.ACTION,
      label: ` ${SENDING_CHAT_MESSAGE.LABEL}  ${chatInputText}`,
    });
    setCallSaveHistoryAPIFlag({
      flag: true,
      method: "PUT",
    });
  }

  async function handleSendMessage() {
    toast.dismiss();
    scrollToBottom(desktopScrollContainerRef);
    if (chatData.length > 0 && chatData[chatData.length - 1].isLoading) {
      toastMessage.info("Please wait, we are still fetching the result!");
      return;
    }
    if (chatInputText === "") {
      toastMessage.info("Please type your message!");
      return;
    }
    setChatData((prev: iChatData[]) => [
      ...prev,
      {
        id: v4(),
        message: chatInputText,
        isUserMessage: true,
        prompt: [],
        isLoading: false,
        destinationList: [],
        singleSelectedDestination: false,
      },
      {
        id: v4(),
        message: "",
        prompt: [],
        isUserMessage: false,
        isLoading: true,
        destinationList: [],
        singleSelectedDestination: false,
      },
    ]);
    setChatInputText("");
    setDisableInput(true);
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const dupChatData = JSON.parse(JSON.stringify(chatData));
    const promptContext: any =
      dupChatData?.reverse()?.find((item: any) => item?.prompt?.length > 0)
        ?.prompt || [];
    let response = await chatInteractionAPI(
      userInfo?.id || "",
      chatInputText,
      promptContext,
      abortController.signal,
      null,
      appConfig
    );

    if (!response && abortController.signal.aborted) {
      setChatData((prev: iChatData[]) => {
        const length = prev.length;
        if (prev.length > chatData.length)
          return [...prev.splice(0, length - 2)];
        return prev;
      });
      return;
    }

    /**
     * Recalling the API if it fails
     */
    if (
      checkIfThereIsDestination(response) === LLM_RESPONSE_ERROR.jsonError.error
    ) {
      // console.log("AGAIN");
      response = await chatInteractionAPI(
        userInfo?.id || "",
        chatInputText + INVALID_JSON_PROMPT,
        promptContext,
        // chatData.length > 0 ? chatData[chatData.length - 1].prompt : [],
        abortController.signal,
        null,
        appConfig
      );
    }

    if (
      response === null ||
      (!response && !abortPlanTripDetails.signal.aborted) ||
      !response?.data?.result
    ) {
      setDisableInput(false);
      appendErrorMessageTochat(
        chatData,
        setChatData,
        LLM_RESPONSE_ERROR.timeOut.message
      );
      return;
    }
    response = checkIfThereIsDestination(response);
    if (response === LLM_RESPONSE_ERROR.jsonError.error) {
      setDisableInput(false);

      appendErrorMessageTochat(
        chatData,
        setChatData,
        LLM_RESPONSE_ERROR.jsonError.message
      );
      if (environment !== ENVIRONMENT_TYPE.PRODUCTION) {
        toastMessage.error(
          TOAST_MESSAGES.SaveItinerary.destinationError.messageTitle,
          TOAST_MESSAGES.SaveItinerary.destinationError.message
        );
      }
      return;
    }
    let citiesList = [];
    if (response?.data?.cities) {
      citiesList = response?.data?.cities;
    }
    processedAndGetDestinationImageURL(citiesList, response, false);
    setDisableInput(false);
    scrollToBottom(desktopScrollContainerRef);
    ReactGA.event({
      category: SENDING_CHAT_MESSAGE.CATEGORY,
      action: SENDING_CHAT_MESSAGE.ACTION,
      label: ` ${SENDING_CHAT_MESSAGE.LABEL}  ${chatInputText}`,
    });
    setCallSaveHistoryAPIFlag({
      flag: true,
      method: "PUT",
    });
  }
  async function processedAndGetDestinationImageURL(
    citiesList: iCityDetailsResult[],
    response: any,
    showMore: boolean
  ) {
    if (citiesList.length > 0) {
      const tempResponse = citiesList.map((ele: any) => ({
        ...ele,
        id: v4(),
        checkedFlag: false,
        locations: [],
      }));
      setChatData((prev: iChatData[]) => [
        ...prev.splice(0, prev.length - 1),
        {
          results: response?.data?.results,
          ...response?.data,
          cities: [],
          city_wise_itinerary: [],
          result: "",
          messages: [],
          fromCity: response?.data?.from,
          noOfDays: response?.data?.numberOfdays,
          id: showMore ? prev[prev.length - 1].id : v4(),
          message: "",
          preText: response?.data?.preText,
          postText: response?.data?.postText,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          singleSelectedDestination: response?.data?.singleCity,
          destinationList: showMore
            ? [...prev[prev.length - 1].destinationList, ...tempResponse]
            : tempResponse,
          itinerary_version: getItineraryVersion(
            prev.splice(0, prev.length - 1),
            response?.data?.results
          ),
        },
      ]);
      const currentItineraryList = tempResponse;
      for (let i = 0; i < currentItineraryList.length; i++) {
        const cityName =
          currentItineraryList[i]?.destination_city ||
          currentItineraryList[i]?.city_name ||
          currentItineraryList[i]?.destination_city ||
          "";
        let imageRespone: any = await getItineraryImageURL(cityName);
        let count = 0;
        while (!imageRespone || !imageRespone?.data?.image) {
          // Limiting the number of API call to 10 times incase of 409 error
          count++;
          if (count === 3) break;
          // re calling the API
          imageRespone = await getItineraryImageURL(cityName);
        }
        if (imageRespone?.statusCode === 200) {
          currentItineraryList[i].imageURL = imageRespone.data.image;
        } else {
          currentItineraryList[i].imageURL = "https://error.error"
        }
      }
      ReactGA.event({
        category: "Top Destination",
        action: `User has recieved the itinerary list created`,
        label: `User prompt is ${response?.data?.query}`,
      });
    } else {
      setChatData((prev: iChatData[]) => [
        ...prev.splice(0, prev.length - 1),
        {
          id: v4(),
          message: response?.data?.result,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
      ]);
    }
  }

  /**
   * Handling cancelling Send message or
   * open Itinerary details or Show More
   */
  function handleCancelStreaming() {
    console.log("CANCEL");
    socket.off(
      SOCKET_EVENTS.conversation_stream,
      handleConversationStreamEvents
    );
    socket.off(SOCKET_EVENTS.conversation_stream, handleFollowUpStreamEvents);
    setShowChatLoaderFlag(false);
    setScreen("");
    // setFirstScreen("");
    if (isMobile && chatData.length === 2) {
      setChatData([]);
      navigate(ROUTES.NewTrip);
    }
    if (chatData[chatData.length - 1].prompt.length === 0) {
      const tempChatData = chatData;
      setChatData(tempChatData.slice(0, -2));
    } else {
      setChatData(
        chatData.map((eleChatData: iChatData) => ({
          ...eleChatData,
          isLoading: false,
        }))
      );
    }
    chatAbortController.abort();
    if (chatAbortControllerRef.current) {
      chatAbortControllerRef.current.abort();
      setDisableInput(false);
    }

    // setSelectedChatData()
    let tempDestinationList: iCityDetailsResult[] =
      chatData.find(
        (ele: iChatData) =>
          ele.id === selectedCheckboxDestinationId.destinationArgsId
      )?.destinationList || ([] as iCityDetailsResult[]);

    setIsItinerarySavedFlag(false);
    setSharedItineraryId("");
    setShowChatLoaderFlag(false);
    tempDestinationList = tempDestinationList.map((ele: iCityDetailsResult) => {
      if (selectedCheckboxDestinationId.cityDetailsId === ele.id)
        return { ...ele, checkedFlag: !ele.checkedFlag };
      return { ...ele, checkedFlag: false };
    });
    setChatData((prev: iChatData[]) =>
      prev.map((ele: iChatData) => {
        if (ele.id === selectedCheckboxDestinationId.destinationArgsId)
          return {
            ...ele,
            destinationList: tempDestinationList,
          };
        else
          return {
            ...ele,
            destinationList: ele.destinationList.map(
              (chatDataEle: iCityDetailsResult) => ({
                ...chatDataEle,
                checkedFlag: false,
              })
            ),
          };
      })
    );
  }
  function handleCancel() {
    setShowChatLoaderFlag(false);
    setScreen("");
    // setFirstScreen("");
    if (chatData[chatData.length - 1].prompt.length === 0) {
      const tempChatData = chatData;
      setChatData(tempChatData.slice(0, -2));
    } else {
      setChatData(
        chatData.map((eleChatData: iChatData) => ({
          ...eleChatData,
          isLoading: false,
        }))
      );
    }
    abortPlanTripDetails.abort();
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setDisableInput(false);
    }

    // setSelectedChatData()
    let tempDestinationList: iCityDetailsResult[] =
      chatData.find(
        (ele: iChatData) =>
          ele.id === selectedCheckboxDestinationId.destinationArgsId
      )?.destinationList || ([] as iCityDetailsResult[]);

    setIsItinerarySavedFlag(false);
    setSharedItineraryId("");
    setShowChatLoaderFlag(false);
    tempDestinationList = tempDestinationList.map((ele: iCityDetailsResult) => {
      if (selectedCheckboxDestinationId.cityDetailsId === ele.id)
        return { ...ele, checkedFlag: !ele.checkedFlag };
      return { ...ele, checkedFlag: false };
    });
    setChatData((prev: iChatData[]) =>
      prev.map((ele: iChatData) => {
        if (ele.id === selectedCheckboxDestinationId.destinationArgsId)
          return {
            ...ele,
            destinationList: tempDestinationList,
          };
        else
          return {
            ...ele,
            destinationList: ele.destinationList.map(
              (chatDataEle: iCityDetailsResult) => ({
                ...chatDataEle,
                checkedFlag: false,
              })
            ),
          };
      })
    );
  }

  /**
   * Value of the Context
   */
  const value = {
    handleCheckboxChange: STREAMING_ENABLE
      ? handleCheckboxChangeStreaming
      : handleCheckboxChange,
    handleShowMore: STREAMING_ENABLE ? handleShowMoreStreaming : handleShowMore,
    handleViewSingleTripDetails,
    showChatLoaderFlag,
    navigate,
    disableInput,
    handleSendMessage: STREAMING_ENABLE
      ? handleSendMessageStreaming
      : handleSendMessage,
    handleCancel: STREAMING_ENABLE ? handleCancelStreaming : handleCancel,
    desktopScrollContainerRef,
    openLoaderOverlay,
  };
  return (
    <TopDestinationContext.Provider value={value}>
      {children}
    </TopDestinationContext.Provider>
  );
};

export const useTopDestinationContext = () => useContext(TopDestinationContext);

/**
 * Template 
 * 
 * import {
    createContext,
    useContext,
    ReactNode,
} from "react";
const TopDestinationContext = createContext({});
export const TopDestinationContextProvider = ({
    children
}: {
    children: ReactNode
}) => {
    const value = {
    }
    return (
        <TopDestinationContext.Provider value={value}>
            {children}
        </TopDestinationContext.Provider>
    );
};
export const useTopDestinationContext = () => useContext(TopDestinationContext);
 */
