import React from "react";
import styles from "./flight.module.scss";
import {
  planeArrivalLight,
  planeDepartureLight,
  seatArlineRegular,
} from "../../../../constant";
import {
  formatNewDepartureDate,
  getTwelveHourTimeFormate,
} from "../../../../utils/dateUtils";

interface FlightDetailsProps {
  airportCode: string;
  airportName: string;
  date: string;
  flightType: string;
  cabinClass?: string;
}

const FlightDetails: React.FC<FlightDetailsProps> = ({
  airportCode,
  airportName,
  date,
  flightType,
  cabinClass,
}) => {
  return (
    <div className={styles.main}>
      <span className={styles.title}>
        <span className={styles.airportCode}>{airportCode || ""}</span> <b>|</b>{" "}
        <span className={styles.airportName}> {airportName || ""}</span>
      </span>
      <section className={styles.descriptionCard}>
        <img
          src={
            flightType === "departure" ? planeDepartureLight : planeArrivalLight
          }
          alt="flight"
          className={styles.flightIcon}
        />
        {date && (
          <p className={styles.dateInfo}>
            {formatNewDepartureDate(date)} |{" "}
            <span className={styles.timeInfo}>
              {getTwelveHourTimeFormate(date)}
            </span>
          </p>
        )}
        {cabinClass && (
          <span className={styles.seatCard}>
            <img
              src={seatArlineRegular}
              alt=""
              className={styles.airlineSeat}
            />
            <p className={styles.seatInfo}>{cabinClass}</p>
          </span>
        )}
      </section>
    </div>
  );
};

export default FlightDetails;
