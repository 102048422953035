import React, { useCallback, useContext, useRef, useState } from "react";
import NextBreadcrumbBtn from "../component/nextBreadcrumbBtn/NextBreadcrumbBtn";
import { StateContext } from "../../../context/globalContext/context";
import {
  iGlobalContext,
  iSelectedHotelRoomArr,
} from "../../../context/globalContext/interface";
import styles from "./hotelsCrumbs.module.scss";
import CustomAccordion from "../../../component/reusableComponent/customAccordion/CustomAccordion";
import FormatHotelListingDateFormat from "../../../utils/formatHotelListingDateFormat";
import FlightHotelSelect from "../../../component/booking/flightHotelSelect/flightHotelSelect";
import {
  calculateNights,
  floorValue,
  isThisHotelRoomSelected,
} from "../../../utils";
import HotelListing from "../../../component/booking/hotelBooking/hotelListing/hotelListing";
import { handleRoomAllocation } from "../../../utils/handleRoomAllocation";
import Loader from "../../../component/reusableComponent/loader/Loader";
import { Button } from "@mui/material";
import { updatedSeeMoreIcon } from "../../../constant";
import NoData from "../../../component/booking/noData/noData";
import { TOAST_MESSAGES } from "../../../constant/messages/toastMessages";
import { iHotelInputPayload } from "../../../types";
import { toastMessage } from "../../../utils/ToastProvider";
import { arrayToString } from "../../../utils/booking/hotel/arrayToString";
import { iHotelSearchResponse } from "../../../interface";
import { searchHotels } from "../../../api/booking/booking";
import useCustomErrorHandler from "../../../hooks/useCustomErrorHandler";
import toast from "react-hot-toast";

interface HotelsCrumbsDetailsProps {
  handleContinue: any;
}

export default function HotelsCrumbsDetails({
  handleContinue,
}: Readonly<HotelsCrumbsDetailsProps>) {
  const state = useContext(StateContext);
  const {
    bookingDetails,
    setBookingDetails,
    setGetHotelRoomsBody,
    hotelData,
    setHotelData,
    selectedHotelRoomArr,
    setSelectedHotelRoomArr,
    showMore,
    setShowMore,
    setIsFetchingHotels,
    setHotelPagination,
    hotelPagination,
    userInfo,
  }: iGlobalContext = state;
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedHotelId, setSelectedHotelId] = useState<number | null>(null);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [loadingCity, setLoadingCity] = useState<string | null>(null);
  const [isModified, setIsModified] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const { handleCustomError } = useCustomErrorHandler();
  let fetchHotelController: any = useRef(null);

  const isEveryCityRoomSelected = selectedHotelRoomArr.every((eachHotel) => {
    if (!eachHotel?.selectedHotelRoom || !eachHotel?.selectedHotelRoom) {
      return false;
    }
    if (
      !eachHotel?.selectedHotelRoom?.preBookId ||
      !eachHotel?.selectedHotelRoom?.sessionId
    ) {
      return false;
    }
    return true;
  });

  const handleNavigate = useCallback(() => {
    const areAllRoomsSelected = selectedHotelRoomArr.every((eachHotel) => {
      if (!eachHotel?.selectedHotelRoom || !eachHotel?.selectedHotelRoom) {
        // console.log("entered here");
        toast.dismiss();
        toastMessage.error(
          `Please selected any room for the ${eachHotel.city} for the checkIn date ${eachHotel?.checkIn}`
        );
        return false;
      }
      if (
        !eachHotel?.selectedHotelRoom?.preBookId ||
        !eachHotel?.selectedHotelRoom?.sessionId
      ) {
        toastMessage.error(
          `Please selected any room for the ${eachHotel.city} for the checkIn date ${eachHotel?.checkIn}`
        );
        return false;
      }
      return true;
    });
    console.log(areAllRoomsSelected, "areAllRoomsSelected");
    if (areAllRoomsSelected) handleContinue();
  }, [handleContinue, selectedHotelRoomArr]);

  const handleToggleAccordion = (index: number) => {
    setBookingDetails((prevDetails) => {
      const expandedAccordions = prevDetails?.expandedAccordions || [];
      const isExpanded = expandedAccordions?.includes(index);
      const newExpanded = isExpanded
        ? expandedAccordions?.filter((i: number) => i !== index)
        : [...expandedAccordions, index];

      return {
        ...prevDetails,
        expandedAccordions: newExpanded,
      };
    });
  };

  const handleHotelSelect = (
    hotelId: number,
    checkIn: string,
    checkOut: string,
    city: string
  ) => {
    const getHotelRoomsBody = {
      hotelId: hotelId,
      checkIn,
      checkOut,
      currency: "USD",
      noOfRooms: bookingDetails.numberOfRoomCount,
      pagination: {
        offset: 0,
        count: 1000,
      },
      city,
      roomInfo: handleRoomAllocation(
        bookingDetails.adultsCount,
        bookingDetails.childrenCount,
        bookingDetails.seniorsCount,
        bookingDetails.numberOfRoomCount
      ),
      // roomInfo: Array.from(
      //   { length: bookingDetails.numberOfRoomCount },
      //   (_obj, idx) => {
      //     return {
      //       roomNo: idx + 1,
      //       adults: bookingDetails.adultsCount,
      //       child: bookingDetails.childrenCount,
      //       seniors: bookingDetails.seniorsCount,
      //     };
      //   }
      // ),
    };
    setGetHotelRoomsBody(getHotelRoomsBody);
    setSelectedHotelId(hotelId);
  };

  const handleShowMoreHotels = (city: string) => {
    setLoading(false);
    setLoadMore(true);
    setLoadingCity(city);
    // offset update
    setHotelPagination((prevPagination) => {
      return prevPagination.map((hotel) => {
        if (hotel.city === city) {
          const currentCityData = hotelData?.data.find(
            (c: any) => c.city === city
          );
          const totalHotels = currentCityData?.hotels.length ?? 0;
          const nextOffset = hotel.offset + 4;
          const hasMore = nextOffset <= totalHotels;
          return {
            ...hotel,
            offset: hotel.offset + 4,
            hasMoreHotels: hasMore,
          };
        }
        return hotel; // other city entries unchanged
      });
    });
  };

  const citiesPayload = bookingDetails.hotels.map(
    ({ cityNotAvailable, ...exceptCityNotAvailable }) => exceptCityNotAvailable
  );

  const filteredBookingDetails = {
    adultsCount: bookingDetails.adultsCount,
    childrenCount: bookingDetails.childrenCount,
    seniorsCount: bookingDetails.seniorsCount,
    numberOfRoomCount: bookingDetails.numberOfRoomCount,
    travelFromDate: bookingDetails.travelFromDate,
    travelToDate: bookingDetails.travelToDate,
    flightClass: bookingDetails.flightClass,
    tripType: bookingDetails.tripType,
    filters: bookingDetails?.filters?.starRating?.map((starRating) => ({
      starRating: starRating,
    })),
    // Flight Preferences
    flightConnections: bookingDetails?.flightPreferences?.connections || 0,
    flightDepartureTime:
      bookingDetails?.flightPreferences?.departureTime.map(
        (departureTime) => departureTime
      ) || [],
    refundableFlights: bookingDetails?.flightPreferences?.refundable || false,
    preferredAirlines: bookingDetails?.flightPreferences?.airlines || [],

    // Hotel Preferences
    refundableHotels: bookingDetails?.hotelPreferences?.refundable || false,
    hotelChains: bookingDetails?.hotelPreferences?.hotelChains || [],
    hotels: bookingDetails.hotels.map((city) => ({
      city,
    })),
    airPort: bookingDetails.airPort.map(({ arrival, departure }) => ({
      arrival,
      departure,
    })),
  };
  const prevBookingDetailsRef = useRef(
    bookingDetails ? filteredBookingDetails : null
  );

  const handleHotelSearch = async (
    count: number,
    cities: iHotelInputPayload[],
    isFetchingFreshly: boolean = false,
    hotelUniqueId?: string
  ) => {
    if (!cities || cities?.length === 0) {
      return toastMessage.error("", "Please select a cities to search");
    }

    setShowDetails(true);
    // const hoteRatings = bookingDetails.filters[0]
    const requestBody = {
      userId: userInfo?.id,
      pagination: {
        offset: count,
        count: 4,
        uniqueId: hotelUniqueId,
      },
      // cities: groupConsecutiveStays(modifiedData),
      cities: cities,
      currency: "USD",
      noOfRooms: bookingDetails.numberOfRoomCount,
      roomInfo: handleRoomAllocation(
        bookingDetails.adultsCount,
        bookingDetails.childrenCount,
        bookingDetails.seniorsCount,
        bookingDetails.numberOfRoomCount
      ),
      filters: {
        hotelChain:
          bookingDetails?.hotelPreferences?.hotelChains?.toString() || null,
        starRating: arrayToString(bookingDetails?.filters?.starRating),
        refundable: bookingDetails?.hotelPreferences?.refundable ?? null,
      },
    };
    prevBookingDetailsRef.current = filteredBookingDetails;
    try {
      if (isFetchingFreshly) setIsFetchingHotels(true);
      fetchHotelController.current = new AbortController();
      const response: iHotelSearchResponse = await searchHotels(
        requestBody,
        fetchHotelController.current
      );
      setIsModified(true);
      if (response.statusCode !== 200 && response?.statusCode !== 404) {
        toastMessage.error(
          TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.messageTitle,
          TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.message
        );
        handleCustomError(response?.statusCode, "hotel.search");
        return null;
      }
      if (response?.data?.pagination) {
        setBookingDetails((prevBookingDetails) => ({
          ...prevBookingDetails,
          hotelUniqueId: response?.data?.pagination.uniqueId || "",
          visibleList: {
            ...showMore.visibleList,
            hotel: response?.data?.pagination.offset,
          },
        }));
      }

      if (response?.data?.data?.length > 0) {
        const updateReceivedData = Array?.isArray(response?.data)
          ? response?.data
          : response?.data?.data;
        setHotelData((prevHotelData: any) => {
          if (!prevHotelData || !prevHotelData.data) {
            // Initialize if no previous data
            return { data: updateReceivedData };
          }

          // Append new data without replacing old data
          const updatedHotelData = [...prevHotelData.data];

          updateReceivedData.forEach((newItemHotel: any) => {
            const existingHotelIndex = updatedHotelData?.findIndex(
              (itemHotel: any) =>
                itemHotel.city === newItemHotel?.city &&
                itemHotel.checkIn === newItemHotel?.checkIn &&
                itemHotel.checkOut === newItemHotel?.checkOut
            );

            if (existingHotelIndex > -1) {
              // If the city and check-in/out match, append the hotels to the existing entry
              updatedHotelData[existingHotelIndex] = {
                ...updatedHotelData[existingHotelIndex],
                hotels: [
                  ...updatedHotelData[existingHotelIndex].hotels,
                  ...newItemHotel.hotels, // Append new hotels
                ],
              };
            } else {
              // If city/check-in/out doesn't exist, add the new entry
              updatedHotelData.push(newItemHotel);
            }
          });

          return {
            ...prevHotelData,
            data: updatedHotelData, // Return updated data with new hotels appended
          };
        });

        // const selectedHotelRoomSet =
        //   selectedHotelRoomArr?.map((item: any) => ({
        //     checkIn: item?.checkIn,
        //     checkOut: item?.checkOut,
        //     city: item?.city,
        //   })) || [];

        // const fetchedHotelArgSet =
        //   response?.data?.map((item: any) => ({
        //     checkIn: item?.checkIn,
        //     checkOut: item?.checkOut,
        //     city: item?.city,
        //   })) || [];
        // // console.log(
        //   selectedHotelRoomArr,
        //   selectedHotelRoomSet,
        //   fetchedHotelArgSet,
        //   areTwoArrObjectsEqual(selectedHotelRoomSet, fetchedHotelArgSet),
        //   "testing"
        // );

        if (
          // !areTwoArrObjectsEqual(selectedHotelRoomSet, fetchedHotelArgSet) &&
          isFetchingFreshly
          // &&          isThisAMulticityTrip
        ) {
          setSelectedHotelRoomArr(
            response?.data?.data?.map(
              (item: any): iSelectedHotelRoomArr => ({
                checkIn: item?.checkIn,
                checkOut: item?.checkOut,
                city: item?.city,
                selectedHotelRoom: null,
              })
            )
          );
          setIsFetchingHotels(false);
        }
        setShowMore((prevShowMore) => ({
          ...prevShowMore,
          visibleList: {
            ...prevShowMore?.visibleList,
            hotel: count,
          },
        }));

        // setLoadMore(false);
      } else {
        if (isFetchingFreshly) setIsFetchingHotels(false);
        console.log("No new hotels found");
        setLoadMore(false);
      }
    } catch (err) {
      if (err instanceof Error) {
        if (
          err?.name !== "AbortError" &&
          !err.message.includes("signal is aborted")
        ) {
          // toastMessage.error(
          //   TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.messageTitle,
          //   TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.message
          // );
          console.error("An api request was cancelled by user", err);
        }
      } else {
        console.error("An unexpected error occurred:", err);
      }
      if (isFetchingFreshly) setIsFetchingHotels(false);
      setHotelData(null);
      // toastMessage.error(
      //   TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.messageTitle,
      //   TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.message
      // );
      setLoadMore(false);
    } finally {
      if (isFetchingFreshly) setIsFetchingHotels(false);
      setLoading(false);
      setLoadMore(false);
    }
  };

  const handleSingleCityShowMoreHotels = () => {
    setLoading(false);
    setLoadMore(true);
    setBookingDetails((prevDetails) => {
      const newHotelCount = prevDetails?.visibleList?.hotel + 4;
      const cities = citiesPayload;
      setTimeout(() => {
        handleHotelSearch(newHotelCount, cities, false);
      }, 10);
      return {
        ...prevDetails,
        visibleList: {
          ...prevDetails.visibleList,
          hotel: newHotelCount,
        },
      };
    });
  };

  const numberOfOfSingleCityNights = calculateNights(
    bookingDetails.hotels[0]?.checkIn,
    bookingDetails.hotels[0]?.checkOut
  );

  return (
    <>
      {hotelData?.data?.length > 0 ? (
        <div className={styles.list}>
          {/* <p>{bookingDetails.hotels.length > 1 ? `` : `Prices for ${bookingDetails.numberOfRoomCount} `}</p> */}
          {bookingDetails.hotels.length > 1 ? (
            <p className={styles.nightsAndRoomsContainer}>
              Total price for{" "}
              <span>
                {bookingDetails.numberOfRoomCount}{" "}
                {bookingDetails?.numberOfRoomCount > 1 ? "rooms" : "room"}
              </span>
            </p>
          ) : (
            <p className={styles.nightsAndRoomsContainer}>
              Prices for{" "}
              <span>
                {bookingDetails.numberOfRoomCount}{" "}
                {bookingDetails?.numberOfRoomCount > 1 ? "rooms" : "room"}
                {" | "}
                {floorValue(numberOfOfSingleCityNights)}{" "}
                {floorValue(numberOfOfSingleCityNights) === 1
                  ? "night"
                  : "nights"}
              </span>
            </p>
          )}
          {!loading && (
            <>
              {bookingDetails.hotels.length > 1 ? (
                <section className={styles.listCard}>
                  {hotelData &&
                    hotelData?.data?.map((cityData: any, cityIndex: number) => {
                      if (!cityData?.hotels?.length) {
                        return (
                          <div
                            key={cityIndex}
                            className={styles.listCardContainer}
                          >
                            <p className={styles.noHotelsMessage}>
                              No hotels found in {cityData?.city}
                            </p>
                          </div>
                        );
                      }

                      return (
                        <div
                          key={cityIndex}
                          className={styles.listCardContainer}
                        >
                          <CustomAccordion
                            className={styles.customFlightStyles}
                            summaryClass={styles.summaryClass}
                            accordianDetailsClass={
                              styles.accordFlightDetailsClass
                            }
                            // defaultExpanded={cityIndex === 0}
                            defaultExpanded={
                              bookingDetails?.expandedAccordions?.includes(
                                cityIndex
                              ) || false
                            }
                            onChange={() => handleToggleAccordion(cityIndex)}
                            hideExpandIcon={false}
                            listing={true}
                            headingComponent={
                              <div className={styles.headingFlightComp}>
                                <div>
                                  <p className={styles.checkInCheckout}>
                                    {FormatHotelListingDateFormat(
                                      cityData?.checkIn
                                    )}{" "}
                                    -{" "}
                                    {FormatHotelListingDateFormat(
                                      cityData?.checkOut
                                    )}
                                  </p>

                                  <p className={styles.city}>
                                    {cityData?.city}
                                  </p>
                                </div>

                                <FlightHotelSelect
                                  flight={false}
                                  selected={isThisHotelRoomSelected(
                                    selectedHotelRoomArr,
                                    cityData
                                  )}
                                />
                              </div>
                            }
                            bodyComponent={
                              <div className={styles.hotelListing}>
                                {cityData?.hotels
                                  ?.map((hotel: any) => ({
                                    ...hotel,
                                    checkIn: cityData?.checkIn,
                                    checkOut: cityData?.checkOut,
                                    city: cityData?.city,
                                  }))
                                  ?.filter(
                                    (hotel: any) =>
                                      hotel?.city === cityData?.city
                                  )
                                  ?.filter(
                                    (hotel: any, index: number, self: any[]) =>
                                      index ===
                                      self?.findIndex(
                                        (h) => h?.hotelId === hotel?.hotelId
                                      )
                                  )
                                  ?.map((hotel: any, hotelIndex: number) => {
                                    if (!hotel) return null;

                                    const {
                                      hotelMainImage,
                                      stars,
                                      name,
                                      address,
                                      rating,
                                      reviewCount,
                                      reviewHeading,
                                      amenities,
                                      totalPrice,
                                      cancellationPolicy,
                                      hotelId,
                                      checkIn = "",
                                      checkOut = "",
                                      city = "",
                                    } = hotel;

                                    return (
                                      <div key={hotelIndex}>
                                        <HotelListing
                                          id={hotelIndex}
                                          hotelMainImage={hotelMainImage}
                                          star={stars}
                                          hotelName={name}
                                          hotelLocation={address}
                                          hotelRating={rating}
                                          reviewCount={reviewCount}
                                          cost={totalPrice.price}
                                          cancellation={cancellationPolicy}
                                          Amenity={amenities}
                                          hotelList={true}
                                          discount={""}
                                          discountCost={""}
                                          taxesIncluded={
                                            totalPrice.taxesIncluded
                                          }
                                          reviewHeading={reviewHeading}
                                          hotelId={hotelId}
                                          hotelDetails={hotel}
                                          onSelect={() =>
                                            handleHotelSelect(
                                              hotelId,
                                              checkIn,
                                              checkOut,
                                              city
                                            )
                                          }
                                          city={city}
                                        />
                                      </div>
                                    );
                                  })}
                                <div className={styles.showMoreBox}>
                                  {loadMore &&
                                  loadingCity === cityData?.city ? (
                                    <Loader />
                                  ) : (
                                    hotelPagination?.find(
                                      (hotel) => hotel?.city === cityData?.city
                                    )?.hasMoreHotels && (
                                      <Button
                                        className={styles.showMoreBtn}
                                        endIcon={
                                          <img
                                            src={updatedSeeMoreIcon}
                                            alt="btn"
                                            className={styles.showMoreIcon}
                                          />
                                        }
                                        onClick={() =>
                                          handleShowMoreHotels(cityData?.city)
                                        }
                                      >
                                        Show More
                                      </Button>
                                    )
                                  )}
                                </div>
                              </div>
                            }
                            zeroMarginOnExpand
                            borderBottom
                          />
                        </div>
                      );
                    })}

                  {hotelData && hotelData?.length === 0 && (
                    <NoData
                      message={
                        TOAST_MESSAGES.BOOKING.Search.NoHotelsFoundScreenMessage
                          .message
                      }
                    />
                  )}
                </section>
              ) : (
                <section className={styles.listCard}>
                  {hotelData &&
                    hotelData?.data
                      ?.flatMap((cityData: any) =>
                        cityData?.hotels?.map((hotel: any) => ({
                          ...hotel,
                          checkIn: cityData?.checkIn,
                          checkOut: cityData?.checkOut,
                          city: cityData?.city,
                        }))
                      )
                      ?.filter(
                        (hotel: any, index: number, self: any[]) =>
                          index ===
                          self?.findIndex((h) => h?.hotelId === hotel?.hotelId)
                      )
                      ?.map((hotel: any, index: number) => {
                        if (!hotel) return null;

                        const {
                          hotelMainImage,
                          stars,
                          name,
                          address,
                          rating,
                          reviewCount,
                          reviewHeading,
                          amenities,
                          totalPrice,
                          cancellationPolicy,
                          hotelId,
                          checkIn = "",
                          checkOut = "",
                          city = "",
                        } = hotel;

                        return (
                          <div key={index}>
                            <HotelListing
                              id={index}
                              hotelMainImage={hotelMainImage}
                              star={stars}
                              hotelName={name}
                              hotelLocation={address}
                              hotelRating={rating}
                              reviewCount={reviewCount}
                              cost={totalPrice.price}
                              cancellation={cancellationPolicy}
                              Amenity={amenities}
                              hotelList={true}
                              discount={""}
                              discountCost={""}
                              taxesIncluded={totalPrice.taxesIncluded}
                              reviewHeading={reviewHeading}
                              hotelId={hotelId}
                              hotelDetails={hotel}
                              onSelect={() =>
                                handleHotelSelect(
                                  hotelId,
                                  checkIn,
                                  checkOut,
                                  city
                                )
                              }
                              city={city}
                            />
                          </div>
                        );
                      })}
                  {hotelData?.data?.flatMap((cityData: any) => cityData?.hotels)
                    ?.length >= showMore?.visibleList?.hotel && (
                    <div className={styles.packageShowMoreCard}>
                      {loadMore ? (
                        <Loader />
                      ) : (
                        <Button
                          className={styles.showMoreBtn}
                          endIcon={
                            <img
                              src={updatedSeeMoreIcon}
                              alt="btn"
                              className={styles.showMoreIcon}
                            />
                          }
                          onClick={handleSingleCityShowMoreHotels}
                        >
                          Show More
                        </Button>
                      )}
                    </div>
                  )}
                  {hotelData && hotelData?.length === 0 && (
                    <NoData
                      message={
                        TOAST_MESSAGES.BOOKING.Search.NoHotelsFoundScreenMessage
                          .message
                      }
                    />
                  )}
                </section>
              )}
            </>
          )}
          {!isEveryCityRoomSelected &&
            bookingDetails?.hotels?.length > 0 &&
            (bookingDetails?.hotels?.length > 1 ? (
              <p className={styles.selectHotelText}>
                Select a hotel for each city of your trip to continue
              </p>
            ) : (
              <p className={styles.selectHotelText}>
                Select a hotel to continue
              </p>
            ))}

          <NextBreadcrumbBtn
            disabled={!isEveryCityRoomSelected || !hotelData?.data?.length}
            handleNext={handleNavigate}
          />
        </div>
      ) : (
        <div />
      )}
    </>
  );
}
