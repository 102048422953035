import React, { useContext } from "react";
import DialogPopup from "../../../reusableComponent/dialogPopup/dialogPopup";
import { Button, Stack } from "@mui/material";
import { outlinedCircleCloseIcon } from "../../../../constant";

import styles from "./cancellationPolicy.module.scss";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";

interface iProps {
  isOpen: boolean;
  onClose: () => void;
}

function CancellationPolicyModal(props: iProps): JSX.Element {
  const { isOpen, onClose } = props;
  const context = useContext(StateContext);
  const { cancellationData = "" }: iGlobalContext = context;
  // const cancellationPolicies = [
  //   {
  //     title: "Refund Window",
  //     description:
  //       "A deadline for canceling a reservation without penalty, usually within 24 to 48 hours of check-in",
  //   },
  //   {
  //     title: "Cancellation fee",
  //     description:
  //       "A fee charged if a reservation is canceled closer to check-in, often equivalent to one night's stay or a percentage of the total cost",
  //   },
  //   {
  //     title: "Non-refundable rates",
  //     description:
  //       "A lower price for a reservation, but no refund is available if the reservation is canceled",
  //   },
  //   {
  //     title: "Flexible rates",
  //     description:
  //       "Reservations that can be changed or canceled with minimal or no penalty, but are usually more expensive",
  //   },
  // ];
  return (
    <DialogPopup isOpen={isOpen}>
      <Stack
        sx={{
          // minHeight: "100vh",
          mt: { sm: 0, xs: 2 },
          // justifyContent: { sm: "center", md: "center", lg: "center" },
        }}
        spacing={6}
        alignItems="center"
        justifyContent="center"
      >
        <Stack
          sx={{
            width: { xs: "100vw" },
            maxWidth: { md: "450px" },
          }}
        >
          <Stack
            sx={{ width: "100%" }}
            px={{ xs: 0.4, sm: 0, md: 0, lg: 0, xl: 0 }}
          >
            <Stack
              alignSelf="start"
              sx={{
                width: "100%",
              }}
            >
              <div className={styles.headerContainer}>
                <div className={styles.header}>Cancellation Policy</div>
                <img
                  style={{ width: "18.69px", cursor: "pointer" }}
                  src={outlinedCircleCloseIcon}
                  alt="cross"
                  onClick={onClose}
                />
              </div>
            </Stack>

            <Stack
              sx={{
                width: "100%",
                // marginBottom: "-20px",
                maxHeight: "80vh",
                overflow: "auto",
                bgcolor: "#f8f8f8",
                borderRadius: "5px",
                padding: "10px 40px",
              }}
            >
              <Stack pt={0.5} pb={2} pl={0} pr={0} spacing={3}>
                <div className={styles.policiesContainer}>
                  {/* {cancellationPolicies.map((item, idx) => {
                    const { title = "", description = "" } = item || {};
                    return (
                      <div className={styles.policyRow} key={idx}>
                        <span className={styles.heading}>{title}</span>
                        <div className={styles.description}>{description}</div>
                      </div>
                    );
                  })} */}
                  {cancellationData && (
                    <div className={styles.policyRow}>
                      <div className={styles.description}>
                        {cancellationData}
                      </div>
                    </div>
                  )}
                </div>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </DialogPopup>
  );
}

export default CancellationPolicyModal;
