import React from "react";
import Dialog from "@mui/material/Dialog";
import styles from "./discard.module.scss";

export interface DialogProps {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly handleDiscard: () => void;
  readonly message?: string;
  readonly cancelButtonTitle?: string;
  readonly discardButtonLabel?: string;
  readonly hideDialogTitle?: boolean;
  readonly cancelFLight?: boolean;
}

function DiscardChanges(props: DialogProps): JSX.Element {
  const {
    onClose,
    open,
    handleDiscard,
    message,
    discardButtonLabel,
    cancelButtonTitle,
    hideDialogTitle,
    cancelFLight,
  } = props;

  return (
    <Dialog
      onClose={onClose}
      open={open}
      className={styles.main}
      BackdropProps={{
        sx: {
          backgroundColor: "rgba(0,0,0,0.85)",
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}>
      <div className={styles.container}>
        {!hideDialogTitle && (
          <span className={styles.title}>Discard Changes?</span>
        )}
        {message ? (
          <span className={styles.subTitle}>{message}</span>
        ) : (
          <span className={styles.subTitle}>
            Your changes will be lost if
            <br /> you do not save them!
          </span>
        )}

        <div
          className={`${cancelFLight ? styles.cancelFLightBtnCard : styles.btnCard}`}>
          <button className={styles.cancelBtn} onClick={onClose}>
            {cancelButtonTitle ?? "Cancel"}
          </button>
          <button className={styles.discBtn} onClick={handleDiscard}>
            {discardButtonLabel ?? " Discard Changes"}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default DiscardChanges;
