import React, { useRef, useState } from "react";
import { Stack, TextField, IconButton } from "@mui/material";
import cross from "../../../assets/white-close-line.svg";
import { StateContext } from "../../../context/globalContext/context";
import { saveItineraryDetailsAPI } from "../../../api/saveItineraryDetails";
import { APP_URL } from "../../../constant/environment";
import { RWebShare } from "react-web-share";
import { iGlobalContext } from "../../../context/globalContext/interface";
import ReactGA from "react-ga4";
import { ROUTES, SAVING_ITINERARY } from "../../../constant";
import toast from "react-hot-toast";
import { toastMessage } from "../../../helpers/toast/toastMessage";
import { getConfigMessage } from "../../../utils";
import { APP_CONFIG } from "../../../constant/common";
import { iChatData } from "../../../types";
import { iHistoryContext } from "../../../context/historyContext/interface";
import { useHistoryContext } from "../../../context/historyContext/historyContext";
import { ScreenTypes } from "../../../enums";
import { useNavigate } from "react-router-dom";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";
import { SaveItineraryDetailsResponse } from "../../../interface/saveItinarary";

export default function SaveItineraryDialog({ props }: any) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { handleCancel } = props;

  const state = React.useContext(StateContext);
  const {
    setLoginCreateAccountState,
    loginCreateAccountState,
    setSharedItineraryId,
    shareClick,
    setShareClick,
    selectedChatData,
    chatData,
    appConfig,
    setTrigerredListItineraryAPIFlag,
    setChatData,
    setSelectedChatData,
    setIsFetchingAirports,
    setScreen,
    setFirstScreen,
    isMobile,
    userInfo,
  }: iGlobalContext = state;
  const { saveHistory, getHistory }: iHistoryContext = useHistoryContext();
  const navigate = useNavigate();

  // Trip name
  const [tripName, setTripName] = React.useState(
    selectedChatData?.tripName || ""
  );

  const [isLoading, setIsLoading] = useState(false);
  async function handleSaveItinerary() {
    triggerGAEvent(GA_LABEL_ENUMS.itinerary_details_save_itinerary_click);
    try {
      if (isMobile) navigate(ROUTES.ItineraryDetails);
      setScreen(ScreenTypes.SingleItinerary);
      setFirstScreen(ScreenTypes.topDestination);
      toast.dismiss();
      if (tripName.length === 0) {
        toastMessage.error("", "Please enter trip name!");
        return;
      }
      if (isLoading) return;
      setIsLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      interface MetaDataType {
        cityName: string;
        averagePackagePriceForExperiencing: string;
        description: string;
        hotelName: string;
        hotelStayCost: string;
        averageCostOfFood: string;
        matchPercentage: string;
        orginatedCity: string;
        selectedMonth: string;
        year: string;
        numberOfDaysSelected: string;
        numberOfExperienceSelected: string;
        planTripArray: any;
      }
      ReactGA.event({
        category: SAVING_ITINERARY.CATEGORY,
        action: SAVING_ITINERARY.ACTION,
        label: ` ${SAVING_ITINERARY.LABEL} ${selectedChatData?.tripName}`,
      });

      const metaData: any = {
        selectedChatData: {
          ...selectedChatData,

          isSaved: true,
          prompt: [],
          postText: "",
          preText: "",
        },
      };

      const saveItineraryPayload = {
        tripName,
        userId: userInfo?.id,
        metaData,
        status: shareClick ? "draft" : "saved",
        numberOfDaysSelected: Number(selectedChatData.noOfDays),
        year: "",
        selectedMonth: "",
        is_admin_created: userInfo?.role === 1,
        // data: selectedChatData?.results || null,
        data: {},
      };
      const response: SaveItineraryDetailsResponse =
        await saveItineraryDetailsAPI(saveItineraryPayload, "POST");
      if (response?.message === "Itineraries has been saved") {
        setIsFetchingAirports(false);

        let checkIfMultipleListFlag = true;
        let tempChatData = chatData?.map((chatDataEle: iChatData) => {
          if (chatDataEle.id === selectedChatData.id) {
            checkIfMultipleListFlag = false;
            const updatedSelectedChatData = {
              ...chatDataEle,
              isSaved: true,
              id: response?.data?.id,
              tripName: tripName,
            };
            setSelectedChatData(updatedSelectedChatData);
            return updatedSelectedChatData;
          }
          return chatDataEle;
        });
        if (checkIfMultipleListFlag) {
          // console.log("GOING DEEP");
          tempChatData = chatData?.map((chatDataEle: any) => {
            if (
              !chatDataEle?.singleSelectedDestination &&
              chatDataEle?.destinationList.length > 0
            ) {
              // console.log("GOING DEEP 1", chatDataEle.id);
              return {
                ...chatDataEle,
                destinationList: chatDataEle?.destinationList.map(
                  (destEle: iChatData) => {
                    if (destEle.id === selectedChatData.id) {
                      // console.log(
                      //   "GOING DEEP 2",
                      //   destEle.id,
                      //   selectedChatData.id
                      // );

                      const updatedSelectedChatData = {
                        ...destEle,
                        isSaved: true,
                        id: response?.data?.id,
                      };
                      setSelectedChatData(updatedSelectedChatData);
                      return updatedSelectedChatData;
                    }
                    return destEle;
                  }
                ),
              };
            }
            return chatDataEle;
          });
        }
        setChatData(tempChatData);

        if (userInfo?.id) {
          await saveHistory(tempChatData, "PUT");
          await getHistory(true);
        }
        if (!shareClick)
          toastMessage.success("Success", "Your itinerary is saved!");
        else {
          setTimeout(() => {
            toastMessage.success("SUCCESS", "Itinerary is shared");
          }, 100);
        }
        setSharedItineraryId(`${response?.data.id}`);
        setTimeout(next, 500);
        setTimeout(() => {
          if (shareRef.current && shareClick) {
            shareRef.current.children[0].click();
            setShareClick(false);
          }
        }, 400);
      } else
        toastMessage.error(
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
      setIsLoading(false);
      setIsFetchingAirports(false);

      setTrigerredListItineraryAPIFlag(true);
    } catch (err: any) {
      toastMessage.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
      setIsLoading(false);
      setIsFetchingAirports(false);
      setTrigerredListItineraryAPIFlag(true);
    } finally {
      setIsLoading(false);
      setIsFetchingAirports(false);
      setTrigerredListItineraryAPIFlag(true);
    }
  }
  function next() {
    setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
    setIsFetchingAirports(false);
  }

  // Create a reference to the second button
  const shareRef: any = useRef(null);

  return (
    <Stack
      zIndex={1}
      sx={{ minHeight: "93vh" }}
      spacing={6}
      alignItems="center"
      justifyContent="center"
    >
      <Stack
        sx={{ maxWidth: "350px", transform: "translateY(-30px)" }}
        justifyContent="center"
        alignItems="center"
      >
        <h1 style={{ visibility: "hidden", transform: "translateY(-100px)" }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem
          laboriosam
        </h1>
        <Stack sx={{ width: "100%" }} px={0.5}>
          <Stack alignSelf="end" mb={1}>
            <IconButton>
              <img
                onClick={() => {
                  handleCancel();
                  triggerGAEvent(
                    GA_LABEL_ENUMS.itinerary_details_save_itinerary_Xclose_click
                  );

                  setScreen(ScreenTypes.SingleItinerary);
                  setFirstScreen(ScreenTypes.topDestination);
                  if (isMobile) navigate(ROUTES.ItineraryDetails);
                }}
                style={{ width: "35px", cursor: "pointer" }}
                src={cross}
                alt="cross"
              />
            </IconButton>
          </Stack>
          <Stack sx={{ width: "100%" }}>
            <Stack
              sx={{ borderRadius: "8px 8px 0 0", width: "100%" }}
              spacing={3}
              justifyContent="center"
              bgcolor="white"
              padding={5}
            >
              <Stack alignSelf="start">
                <h6>Save itinerary</h6>
              </Stack>
              <Stack position="relative" alignSelf='start sx={{width: "100%"}}'>
                <h2
                  style={{
                    fontSize: "11px",
                    marginBottom: "5px",
                    width: "100%",
                  }}
                >
                  TRIP NAME
                </h2>
                <TextField
                  size="small"
                  placeholder="Greek Islands"
                  value={tripName}
                  onChange={(e: any) => {
                    setTripName(e.target.value);
                  }}
                  InputProps={{
                    style: {
                      height: "30px",
                      // width: '280px',
                      width: "100%",
                      fontSize: "12px",
                      color: "#696969",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: "12px",
                    },
                  }}
                />
              </Stack>
            </Stack>
            <Stack
              sx={{ borderRadius: " 0 0 8px 8px", cursor: "pointer" }}
              bgcolor={isLoading ? "#d5d5d5" : "#FCB400"}
              height={60}
              alignItems="center"
              justifyContent="center"
              onClick={handleSaveItinerary}
            >
              <h6 style={{ color: "#333" }}>SAVE ITINERARY</h6>
            </Stack>

            <div ref={shareRef} onClick={() => {}} style={{ display: "none" }}>
              <RWebShare
                data={{
                  url: `${APP_URL}shared/${selectedChatData?.id}`,
                  title: "zenvoya",
                }}
                onClick={() => {
                  ReactGA.event({
                    category: "Button Click",
                    action: `Click on Share Trip Details`,
                    label: `User is sharing the itinerary ${APP_URL}/shared/${selectedChatData?.id}`,
                  });
                }}
              >
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  ref={shareRef}
                >
                  <h3>Share</h3>
                </Stack>
              </RWebShare>
            </div>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
