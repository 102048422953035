import { io } from "socket.io-client";
import { ENDPOINT } from "../constant/environment";

// "undefined" means the URL will be computed from the `window.location` object

export const socket = io(ENDPOINT, {
  reconnection: true,
  reconnectionDelay: 500,
  transports: [
    "websocket", // "polling", "flashsocket"
  ],
  // autoConnect: true,
  upgrade: false,
});

export enum SOCKET_EVENTS {
  conversation_stream = "conversation_stream",
  followup_stream = "followup_stream",
  connect = "connect",
  disconnect = "disconnect",
}
