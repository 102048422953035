import React, { useState, useRef, useEffect } from "react";
import { LoadScript } from "@react-google-maps/api";
import styles from "./hotel.module.scss";
import { GOOGLE_MAP_API_KEY } from "../../../constant/environment";

const HotelLocationInputField: React.FC<{
  placeholder: string;
  address: string;
  onAddressSelect: (addressDetails: {
    locality: string;
    city: string;
    country: string;
    latitude: string;
    longitude: string;
  }) => void;
}> = ({ placeholder, onAddressSelect, address }) => {
  const [inputAddress, setInputAddress] = useState(address);
  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const autocompleteServiceRef =
    useRef<google.maps.places.AutocompleteService | null>(null);
  const placesServiceRef = useRef<google.maps.places.PlacesService | null>(
    null
  );
  const [scriptLoaded, setScriptLoaded] = useState(false);

  // Sync address when parent updates it
  useEffect(() => {
    setInputAddress(address);
  }, [address]);

  useEffect(() => {
    if (scriptLoaded && !autocompleteServiceRef.current) {
      autocompleteServiceRef.current =
        new window.google.maps.places.AutocompleteService();
      placesServiceRef.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
  }, [scriptLoaded]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    console.log(value, "value");

    setInputAddress(value);

    if (value && autocompleteServiceRef.current) {
      autocompleteServiceRef.current.getPlacePredictions(
        { input: value },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setPredictions(predictions);
          } else {
            setPredictions([]);
          }
        }
      );
    } else {
      setPredictions([]);
    }
  };

  const handleSelect = (placeId: string) => {
    if (placesServiceRef.current) {
      placesServiceRef.current.getDetails({ placeId }, (place, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          place
        ) {
          setInputAddress(place.formatted_address ?? "");
          setPredictions([]);
          const locality = place.formatted_address ?? "";
          const city =
            place.address_components?.find((c) => c.types.includes("locality"))
              ?.long_name ?? "";
          const country =
            place.address_components?.find((c) => c.types.includes("country"))
              ?.long_name ?? "";
          const latitude = place.geometry?.location?.lat().toString() ?? "";
          const longitude = place.geometry?.location?.lng().toString() ?? "";
          const truncatedLatitude = latitude
            ? parseFloat(latitude)?.toFixed(4)
            : "";
          const truncatedLongitude = longitude
            ? parseFloat(longitude)?.toFixed(4)
            : "";

          // Pass selected address details to parent component
          onAddressSelect({
            city,
            country,
            latitude: truncatedLatitude,
            longitude: truncatedLongitude,
            locality,
          });
        }
      });
    }
  };

  return (
    <div className={styles.main}>
      <LoadScript
        googleMapsApiKey={GOOGLE_MAP_API_KEY}
        libraries={["places"]}
        onLoad={() => setScriptLoaded(true)}
      />

      {scriptLoaded ? (
        <div className={styles.inputBox}>
          <input
            type="text"
            value={inputAddress}
            onChange={handleChange}
            placeholder={placeholder}
            className={styles.inputField}
          />
          {predictions.length > 0 && (
            <div className={styles.card}>
              <ul className={styles.ulList}>
                {predictions.map((prediction) => (
                  <li
                    key={prediction.place_id}
                    onClick={() => handleSelect(prediction.place_id)}
                    className={styles.lists}>
                    {prediction.description}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.loader}></div>
      )}
    </div>
  );
};

export default HotelLocationInputField;
