import { ChangeEvent, useContext, useEffect, useState } from "react";
import styles from "./travelerForm.module.scss";
import { iCountryData } from "../../../../../interface/common";
import { StateContext } from "../../../../../context/globalContext/context";
import {
  flyerProgramType,
  iGlobalContext,
} from "../../../../../context/globalContext/interface";
import BookedSuccessDialog from "../../../../../component/dialog/bookedSuccessDialog/BookedSuccessDialog";
import CustomInput from "../../../../../component/booking/travelerInformation/inputField/customInput";
import toast from "react-hot-toast";
import BasicSelect from "../../../../../component/booking/travelerInformation/customSelect/customSelect";
import { gender, options } from "../../../../../constant/dummyData";
import { toastMessage } from "../../../../../utils/ToastProvider";
import { TOAST_MESSAGES } from "../../../../../constant/messages/toastMessages";
import { emailRegex } from "../../../../../constant/common";
import PhoneNumberValidationInputField from "../../../../../component/reusableComponent/phoneNumberValidationInputField/PhoneNumberValidationInputField";
import DobSelector from "../../../../../component/booking/travelerInformation/dob-selector/dobSelector";
import CustomCheckbox from "../../../../../component/booking/bookingCheckBox/CustomCheckbox";

const TravelerForm =
  // forwardRef(
  ({
    setShowErrorMessageFlag,
    showErrorMessageFlag,
    frequentFlyerProgram,
    specialAssistance,
    seatsPreference,
    mealsPreference,
    // ref,
    formData,
    travelerInfoArrayDataRef,
    formIndex,
    setTravelerInfoArrayDataRef,
    countriesData,
  }: {
    setShowErrorMessageFlag?: any;
    showErrorMessageFlag?: boolean;
    frequentFlyerProgram: any;
    specialAssistance: any;
    seatsPreference: any;
    mealsPreference: any;
    // ref: any;
    formData: any;
    formIndex: number;
    travelerInfoArrayDataRef: any;
    setTravelerInfoArrayDataRef: any;
    countriesData: iCountryData[];
  }) => {
    const globalContext = useContext(StateContext);
    const {
      selectedFlightOptions,
      selectedChatData,
      flightAvailabilityData,
    }: iGlobalContext = globalContext;

    const { international = false } = flightAvailabilityData || {};
    const [openBookedSuccessDialog, setOpenBookedSuccessDialog] =
      useState(false);
    const [formDataLocal, setFormDataLocal] = useState(formData);

    useEffect(() => {
      setTravelerInfoArrayDataRef(
        travelerInfoArrayDataRef.current.map((ele: any, index: number) => {
          if (index === formIndex) {
            return formDataLocal;
          } else return ele;
        })
      );
    }, [formDataLocal]);
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      // console.log("JUST");
      const { name, checked } = event.target;
      setFormDataLocal((prevState: any) => ({
        ...prevState,
        selectedOptions: {
          [name]: checked,
        },
      }));
    };

    return (
      <div className={styles.main}>
        <BookedSuccessDialog
          open={openBookedSuccessDialog}
          onClose={() => {
            setOpenBookedSuccessDialog(false);
          }}
        />
        <form className={styles.formCard}>
          <div className={styles.container}>
            <div className={styles.heading}>
              <h3>Basic Information</h3>
              <p>REQUIRED</p>
            </div>
            <div className={styles.nameCard}>
              <CustomInput
                value={formDataLocal.firstName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  toast.dismiss();
                  setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                    ...prevFormData,
                    firstName: e.target.value || "",
                  }));
                }}
                inputName="First Name"
                mandate={true}
                width="180px"
                error={formDataLocal.firstName === "" && showErrorMessageFlag}
              />
              <CustomInput
                value={formDataLocal.middleName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  toast.dismiss();
                  setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                    ...prevFormData,
                    middleName: e.target.value || "",
                  }));
                }}
                inputName="Middle Name / Initial"
                mandate={false}
                placeHolder="(if shown on ID)"
                width="95px"
              />
            </div>
            <div className={styles.nameCard}>
              <CustomInput
                value={formDataLocal.lastName}
                onChange={(e) => {
                  toast.dismiss();
                  setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                    ...prevFormData,
                    lastName: e.target.value || "",
                  }));
                }}
                inputName="Last Name"
                mandate={true}
                width="281px"
                error={formDataLocal.lastName === "" && showErrorMessageFlag}
              />
            </div>
            <div className={styles.dateCard}>
              <div className={styles.dobContainer}>
                <BasicSelect
                  isPlaceholderTextColorLight
                  placeholder="Month"
                  options={options}
                  value={formDataLocal.dateOfBirthMonth}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    toast.dismiss();
                    setFormDataLocal((prevFormData: any) => ({
                      ...prevFormData,
                      dateOfBirthMonth: event.target.value,
                    }));
                  }}
                  width="93px"
                  label={"Date of Birth"}
                  mandate={true}
                  error={
                    formDataLocal.dateOfBirthMonth === "" &&
                    showErrorMessageFlag
                  }
                />
                <CustomInput
                  value={formDataLocal.dateOfBirth}
                  onChange={(e) => {
                    toast.dismiss();
                    if (!/^[0-9]*$/.test(e.target.value)) {
                      toastMessage.error(
                        TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate
                          .messageTitle,
                        TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate.message
                      );
                      return;
                    }

                    if (
                      e.target.value.length > 2 ||
                      Number(e.target.value) > 31
                    ) {
                      toastMessage.error(
                        TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate
                          .messageTitle,
                        TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate.message
                      );
                      setFormDataLocal(
                        (prevFormData: typeof formDataLocal) => ({
                          ...prevFormData,
                          dateOfBirth: e.target.value,
                        })
                      );
                      return;
                    }
                    setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                      ...prevFormData,
                      dateOfBirth: e.target.value,
                    }));
                  }}
                  placeHolder="DD"
                  mandate={false}
                  width="40px"
                  showRequired={false}
                  error={
                    formDataLocal.dateOfBirth === "" && showErrorMessageFlag
                  }
                />
                <CustomInput
                  value={formDataLocal.dateOfYear}
                  onChange={(e) => {
                    const value = e.target.value;
                    toast.dismiss();
                    if (!/^\d*$/.test(value) || Number(value) > 9999) {
                      toastMessage.error(
                        TOAST_MESSAGES.BOOKING.TravelerInfo.invalidYear
                          .messageTitle,
                        TOAST_MESSAGES.BOOKING.TravelerInfo.invalidYear.message
                      );
                      return;
                    }
                    setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                      ...prevFormData,
                      dateOfYear: value,
                    }));
                  }}
                  placeHolder="YYYY"
                  mandate={false}
                  width="52px"
                  showRequired={false}
                  error={
                    formDataLocal.dateOfYear === "" && showErrorMessageFlag
                  }
                />
              </div>
              <div className={styles.genderContainer}>
                <BasicSelect
                  isPlaceholderTextColorLight
                  placeholder="Select"
                  options={gender}
                  value={formDataLocal.gender}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    toast.dismiss();
                    setFormDataLocal((prevFormData: any) => ({
                      ...prevFormData,
                      gender: event.target.value,
                    }));
                  }}
                  width="90px"
                  label={"Gender"}
                  mandate={true}
                  error={formDataLocal?.gender === "" && showErrorMessageFlag}
                />
              </div>
            </div>
          </div>

          <div className={styles.container}>
            <div className={styles.heading}>
              <h1>Contact Info</h1>
              <p>EMAIL OR PHONE IS REQUIRED</p>
            </div>
            <CustomInput
              value={formDataLocal.email}
              onChange={(e) => {
                toast.dismiss();
                setShowErrorMessageFlag(false);
                setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                  ...prevFormData,
                  email: e.target.value || "",
                }));
              }}
              placeHolder="email"
              mandate={formIndex === 0 ? true : false}
              inputName="EMAIl"
              width="250px"
              error={
                formIndex === 0 &&
                ((formDataLocal.email === "" && showErrorMessageFlag) ||
                  (!emailRegex.test(formDataLocal.email) &&
                    showErrorMessageFlag))
              }
            />
            <div className={styles.rowMainContainer}>
              <BasicSelect
                showNone={false}
                placeholder="United States (+1)"
                // options={countryCode}
                options={countriesData
                  ?.map(
                    (country) =>
                      `${country?.name} ${country?.code ? `(+${country?.code})` : ""}`
                  )
                  ?.sort()}
                selectOptionClass={styles.phoneInput}
                value={formDataLocal.countryCode}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  toast.dismiss();
                  setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                    ...prevFormData,
                    countryCode: event.target.value,
                  }));
                }}
                width="140px"
                label={"Country Code"}
                mandate={formIndex === 0 ? true : false}
              />
              <PhoneNumberValidationInputField
                countryCode={formDataLocal?.countryCode === "+91" ? "IN" : "US"}
                showOverlayInput={false}
                value={formDataLocal.phoneNumber}
                onChange={(e) => {
                  setFormDataLocal({
                    ...formDataLocal,
                    phoneNumber: e.target.value,
                  });
                }}
                formDataLocal={formDataLocal}
                setFormDataLocal={setFormDataLocal}
                mandate={formIndex === 0 ? true : false}
                inputName="Mobile Phone Number"
              />
            </div>
          </div>
          {selectedFlightOptions?.flights && international && (
            <div className={styles.container}>
              <div className={styles.heading}>
                <h1>Travel Document</h1>
                <p>REQUIRED</p>
              </div>
              <p className={styles.flyTitle}></p>
              <div className={styles.dateCard}>
                <BasicSelect
                  placeholder="Select"
                  options={["Passport"]}
                  selectOptionClass={styles.programName}
                  value={formDataLocal.documentType}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    toast.dismiss();
                    setFormDataLocal((prevFormData: any) => ({
                      ...prevFormData,
                      documentType: event.target.value,
                    }));
                  }}
                  width="140px"
                  label={"Document Type"}
                  mandate={true}
                  error={
                    formDataLocal.documentType === "" && showErrorMessageFlag
                  }
                />
                <CustomInput
                  value={formDataLocal.documentNumber}
                  onChange={(e) => {
                    setFormDataLocal({
                      ...formDataLocal,
                      documentNumber: e.target.value.slice(0, 25),
                    });
                  }}
                  mandate={true}
                  inputName="Document Number"
                  width="170px"
                  error={
                    formDataLocal.documentNumber === "" && showErrorMessageFlag
                  }
                />
              </div>
              <div className={styles.dateCard}>
                <BasicSelect
                  placeholder="Select country"
                  options={countriesData
                    ?.map((country) => country?.name)
                    ?.sort()}
                  selectOptionClass={styles.programName}
                  value={formDataLocal.issuingCountry}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    toast.dismiss();
                    setFormDataLocal((prevFormData: any) => ({
                      ...prevFormData,
                      issuingCountry: event.target.value,
                    }));
                  }}
                  width="140px"
                  label={"Issuing Country"}
                  mandate={true}
                  error={
                    formDataLocal.issuingCountry === "" && showErrorMessageFlag
                  }
                />
                <BasicSelect
                  placeholder="Select nationality"
                  options={countriesData
                    ?.map((country) => country?.name)
                    ?.sort()}
                  selectOptionClass={styles.programName}
                  value={formDataLocal.nationality}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    toast.dismiss();
                    setFormDataLocal((prevFormData: any) => ({
                      ...prevFormData,
                      nationality: event.target.value,
                    }));
                  }}
                  width="140px"
                  label={"Nationality"}
                  mandate={true}
                  error={
                    formDataLocal.nationality === "" && showErrorMessageFlag
                  }
                />
              </div>
              <DobSelector
                dobLabel="Issue Date"
                dateValue={formDataLocal.docIssueDate}
                monthValue={formDataLocal.docIssueMonth}
                yearValue={formDataLocal.docIssueYear}
                onMonthChange={(
                  event: React.ChangeEvent<HTMLSelectElement>
                ) => {
                  toast.dismiss();
                  setFormDataLocal((prevFormData: any) => ({
                    ...prevFormData,
                    docIssueMonth: event.target.value,
                  }));
                }}
                onDateChange={(e) => {
                  toast.dismiss();
                  if (!/^[0-9]*$/.test(e.target.value)) {
                    toastMessage.error(
                      TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate
                        .messageTitle,
                      TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate.message
                    );
                    return;
                  }

                  if (
                    e.target.value.length > 2 ||
                    Number(e.target.value) > 31
                  ) {
                    toastMessage.error(
                      TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate
                        .messageTitle,
                      TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate.message
                    );
                    setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                      ...prevFormData,
                      docIssueDate: e.target.value,
                    }));
                    return;
                  }
                  setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                    ...prevFormData,
                    docIssueDate: e.target.value,
                  }));
                }}
                onYearChange={(e) => {
                  const value = e.target.value;
                  toast.dismiss();
                  if (!/^\d*$/.test(value) || Number(value) > 9999) {
                    toastMessage.error(
                      TOAST_MESSAGES.BOOKING.TravelerInfo.invalidYear
                        .messageTitle,
                      TOAST_MESSAGES.BOOKING.TravelerInfo.invalidYear.message
                    );
                    return;
                  }
                  setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                    ...prevFormData,
                    docIssueYear: value,
                  }));
                }}
                showErrorMessageFlag={showErrorMessageFlag}
              />
              <DobSelector
                dobLabel="Expiration Date"
                dateValue={formDataLocal.docExpiryDate}
                monthValue={formDataLocal.docExpiryMonth}
                yearValue={formDataLocal.docExpiryYear}
                onMonthChange={(
                  event: React.ChangeEvent<HTMLSelectElement>
                ) => {
                  toast.dismiss();
                  setFormDataLocal((prevFormData: any) => ({
                    ...prevFormData,
                    docExpiryMonth: event.target.value,
                  }));
                }}
                onDateChange={(e) => {
                  toast.dismiss();
                  if (!/^[0-9]*$/.test(e.target.value)) {
                    toastMessage.error(
                      TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate
                        .messageTitle,
                      TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate.message
                    );
                    return;
                  }

                  if (
                    e.target.value.length > 2 ||
                    Number(e.target.value) > 31
                  ) {
                    toastMessage.error(
                      TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate
                        .messageTitle,
                      TOAST_MESSAGES.BOOKING.TravelerInfo.invalidDate.message
                    );
                    setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                      ...prevFormData,
                      docExpiryDate: e.target.value,
                    }));
                    return;
                  }
                  setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                    ...prevFormData,
                    docExpiryDate: e.target.value,
                  }));
                }}
                onYearChange={(e) => {
                  const value = e.target.value;
                  toast.dismiss();
                  if (!/^\d*$/.test(value) || Number(value) > 9999) {
                    toastMessage.error(
                      TOAST_MESSAGES.BOOKING.TravelerInfo.invalidYear
                        .messageTitle,
                      TOAST_MESSAGES.BOOKING.TravelerInfo.invalidYear.message
                    );
                    return;
                  }
                  setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                    ...prevFormData,
                    docExpiryYear: value,
                  }));
                }}
                showErrorMessageFlag={showErrorMessageFlag}
              />
            </div>
          )}

          {selectedFlightOptions?.flights && (
            <div className={styles.container}>
              <div className={styles.heading}>
                <h1>Frequent Flyer Program</h1>
                <h2>OPTIONAL</h2>
              </div>

              <div className={styles.dateCard}>
                <BasicSelect
                  placeholder="Select a program"
                  options={
                    frequentFlyerProgram
                      ? [
                          {
                            label: flyerProgramType.none,
                            value: "",
                          },
                          ...frequentFlyerProgram,
                        ]
                      : []
                  }
                  selectOptionClass={styles.programName}
                  value={formDataLocal.programName}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    toast.dismiss();
                    setFormDataLocal((prevFormData: any) => ({
                      ...prevFormData,
                      programName: event.target.value,
                    }));
                  }}
                  width="170px"
                  label={"Program Name"}
                  mandate={false}
                />
                <CustomInput
                  value={formDataLocal.frequentFlyerNumber}
                  onChange={(e) => {
                    setFormDataLocal({
                      ...formDataLocal,
                      frequentFlyerNumber: e.target.value,
                    });
                  }}
                  disabled={
                    formDataLocal.programName === "" ||
                    formDataLocal.programName === flyerProgramType.none
                  }
                  mandate={false}
                  inputName="Frequent Flyer Number"
                  width="160px"
                />
              </div>
            </div>
          )}
          {selectedFlightOptions?.flights && (
            <div className={styles.container}>
              <div className={styles.heading}>
                <h1>TSA PreCheck Number / PASS ID</h1>
                <h2>OPTIONAL</h2>
              </div>
              <CustomInput
                value={formDataLocal.passportNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  toast.dismiss();
                  setFormDataLocal((prevFormData: typeof formDataLocal) => ({
                    ...prevFormData,
                    passportNumber: e.target.value,
                  }));
                }}
                mandate={false}
                inputName="TSA PRECHECK NUMBER / PASS ID"
                width="205px"
              />
            </div>
          )}
          {selectedFlightOptions?.flights && (
            <div className={styles.container}>
              <div className={styles.heading}>
                <h1>Meal Preferences</h1>
                <h2>OPTIONAL</h2>
              </div>
              <div className={styles.dateCard}>
                <BasicSelect
                  placeholder="Select meal type"
                  options={
                    mealsPreference
                      ? [
                          {
                            label: flyerProgramType.none,
                            value: "",
                          },
                          ...mealsPreference,
                        ]
                      : []
                  }
                  selectOptionClass={styles.programName}
                  value={formDataLocal.mealPref}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    toast.dismiss();
                    setFormDataLocal((prevFormData: any) => ({
                      ...prevFormData,
                      mealPref: event.target.value,
                    }));
                  }}
                  width="205px"
                  label={"MEAL TYPE"}
                  mandate={false}
                />
              </div>
            </div>
          )}
          {selectedFlightOptions?.flights && (
            <div className={styles.container}>
              <div className={styles.heading}>
                <h1>Special Travel Needs</h1>
                <h2>OPTIONAL</h2>
              </div>
              <div className={styles.selectCard}>
                {specialAssistance?.map((i: any, index: number) => (
                  <section key={index} className={styles.checkSection}>
                    <CustomCheckbox
                      label={i.label}
                      name={i.label}
                      handleCheckboxChange={handleChange}
                      selectedOptions={formDataLocal.selectedOptions}
                      travelerForm={true}
                    />
                  </section>
                ))}
              </div>
            </div>
          )}
        </form>
      </div>
    );
  };

export default TravelerForm;
