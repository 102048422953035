import React from "react";
import { Button } from "@mui/material";
import { expandIcon } from "../../../../../constant";
import FlightDetails from "../../../flightDetails/flightDetails";
import {
  getDayOfWeek,
  getMonthDate,
  getTwelveHourTimeFormat,
  getYearYYYY,
} from "../../../../../utils/dateUtils";
import ArrivalOnDifferentDate from "../../../../reusableComponent/arrivalOnDifferentDate/ArrivalOnDifferentDate";
import styles from "./step.module.scss";
interface StepperProps {
  steps?: any[];
  stops?: any[];
  initialStep: number;
  finalStep: number;
  flightImage?: any;
  onCloseStepperFunc?: any;
  onOpenStepperFunc?: any;
}

const Stepper: React.FC<StepperProps> = ({
  flightImage = "",
  steps = [],
  stops = [],
  initialStep = 0,
  finalStep = 0,
  onCloseStepperFunc,
  onOpenStepperFunc,
}) => {
  const [showAllSteps, setShowAllSteps] = React.useState(false);

  const handleViewDetails = () => {
    if (showAllSteps && onCloseStepperFunc) onCloseStepperFunc();
    if (!showAllSteps && onOpenStepperFunc) onOpenStepperFunc();
    setShowAllSteps((prev) => !prev);
  };

  function isLayover(booleanData: any) {
    return booleanData;
  }

  function addHHMM(time: string) {
    if (typeof time !== "string" || time.split(":").length < 2) return "";
    const splitTime = time.split(":");
    return splitTime[0] + "h " + splitTime[1] + "m";
  }

  function checkIfArrivalIsNextDate(timestamp1: string, timestamp2: string) {
    if (!timestamp1 || !timestamp2) return false;
    const date1 = timestamp1.split("-");
    const date2 = timestamp2.split("-");
    return !(
      date1[0] === date2[0] &&
      date1[1] === date2[1] &&
      date1[2].substring(0, 2) === date2[2].substring(0, 2)
    );
  }

  if (steps.length === 0) {
    return <div>No data found</div>;
  }

  return (
    <div className={styles.main}>
      <div className={styles.stepper}>
        {steps.map((step: any, index: number) => {
          return (
            <React.Fragment key={index}>
              {/* Departure */}
              {(showAllSteps || index === 0) && (
                <div className={styles.step}>
                  <div className={styles.circle}></div>
                  <div className={styles.stepContent}>
                    <p className={styles.title}>
                      <span className={styles.airportCode}>
                        {step?.depAirportCode}{" "}
                        {step?.depAirportName && (
                          <span className={styles.airportName}>
                            | {step?.depAirportName}
                          </span>
                        )}
                      </span>
                    </p>
                    <p
                      className={`${step.layOver ? styles.delayDescription : styles.description}`}>
                      {step?.departureDate &&
                        getDayOfWeek(step?.departureDate, "long") +
                          ", " +
                          getMonthDate(step?.departureDate) +
                          " " +
                          getYearYYYY(step?.departureDate) +
                          " | " +
                          getTwelveHourTimeFormat(step?.departureDate)}
                    </p>
                    {index === initialStep && (
                      <Button
                        endIcon={
                          <img
                            src={expandIcon}
                            alt=""
                            className={`${showAllSteps ? styles.hideIcon : styles.viewIcon}`}
                          />
                        }
                        className={styles.viewDetailsButton}
                        onClick={handleViewDetails}>
                        {showAllSteps ? "Collapse" : "Details"}
                      </Button>
                    )}
                    {showAllSteps && (
                      <div className={styles.card}>
                        <div className={styles.subCard}>
                          <FlightDetails
                            title={
                              step?.marketingAirlineName ||
                              step?.operatingAirlineName ||
                              step?.marketingAirline
                            }
                            subTitle={`Flight ${step?.operatingAirline}${step?.flightNumber}`}
                            description={""}
                            imgUrl={step?.marketingAirlineImage || flightImage}
                          />
                          <FlightDetails
                            title={"Departure"}
                            subTitle={""}
                            description={`${getTwelveHourTimeFormat(step?.departureDate)} | Flight time: 
                            ${addHHMM(step?.flyDuration) || ""}
                            `}
                            imgUrl={""}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className={`${step.layOver ? styles.dashLine : styles.line}`}></div>
                </div>
              )}

              {/* Arrival */}
              {(showAllSteps || index !== 0 || steps?.length === 1) && (
                <div className={styles.step}>
                  <div className={styles.circle}></div>
                  <div className={styles.stepContent}>
                    <p className={styles.title}>
                      <span className={styles.airportCode}>
                        {step?.arrAirportCode}{" "}
                        {step?.arrAirportName && (
                          <span className={styles.airportName}>
                            | {step?.arrAirportName}
                          </span>
                        )}
                      </span>
                    </p>
                    <div className={styles.arrivalDateContainer}>
                      <p
                        className={`${checkIfArrivalIsNextDate(step?.departureDate, step?.arrivalDate) ? styles.delayDescription : styles.description}`}>
                        {step?.arrivalDate &&
                          getDayOfWeek(step?.arrivalDate, "long") +
                            ", " +
                            getMonthDate(step?.arrivalDate) +
                            " " +
                            getYearYYYY(step?.arrivalDate) +
                            " | " +
                            getTwelveHourTimeFormat(step?.arrivalDate)}
                      </p>
                      {checkIfArrivalIsNextDate(
                        step?.departureDate,
                        step?.arrivalDate
                      ) && <ArrivalOnDifferentDate />}
                    </div>
                    {showAllSteps && (
                      <div className={styles.card}>
                        <div className={styles.subCard}>
                          <FlightDetails
                            title={"Arrival"}
                            subTitle={""}
                            description={getTwelveHourTimeFormat(
                              step?.arrivalDate
                            )}
                            imgUrl={""}
                          />

                          {isLayover(steps?.length - 1 !== index) && (
                            <FlightDetails
                              title={"Layover"}
                              subTitle={""}
                              description={addHHMM(stops[index]?.layover)}
                              imgUrl={""}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {index < steps.length - 1 && (
                    <div
                      className={`${isLayover(steps?.length - 1 !== index) ? styles.dashLine : styles.line}`}></div>
                  )}
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Stepper;
