import { planeEnginesLight, bedFontLight, flightBed } from "../../../constant";
import { BookingDetails, ISelectedOptions } from "../../../types";
import styles from "./selected.module.scss";

interface SelectedOptionsProps {
  readonly selectedOptions: ISelectedOptions;
  readonly selectTitle?: string;
  readonly flightData?: any;
  readonly bookingDetails?: BookingDetails;
  readonly showPrices: boolean;
}

function SelectedOptions({
  selectedOptions,
  selectTitle,
  flightData,
  bookingDetails,
  showPrices,
}: SelectedOptionsProps): JSX.Element {
  const numberOfTravellers =
    (bookingDetails?.adultsCount ?? 0) +
    (bookingDetails?.seniorsCount ?? 0) +
    (bookingDetails?.childrenCount ?? 0);

  return (
    <div className={styles.main}>
      <section className={styles.container}>
        {selectedOptions.flights && !selectedOptions.hotels && (
          <>
            {flightData && (
              <div className={styles.headerCard}>
                <div className={styles.imgCard}>
                  <img
                    src={planeEnginesLight}
                    alt=""
                    className={styles.imgTag}
                  />
                  <p className={styles.title}>Flights</p>
                </div>

                <>
                  {/* {flightData.length > 0 && (
                      <p className={styles.selectPac}>
                        Select a <br />
                        {selectTitle}
                      </p>
                    )} */}
                  {flightData?.length > 0 &&
                    showPrices &&
                    numberOfTravellers >= 1 && (
                      <span className={styles.price}>
                        Prices for{" "}
                        <span className={styles.numberOfTravel}>
                          {numberOfTravellers}{" "}
                          {numberOfTravellers === 1 ? "Traveler" : "Travelers"}
                        </span>
                      </span>
                    )}
                </>
              </div>
            )}
          </>
        )}
        {selectedOptions.hotels && !selectedOptions.flights && (
          <div className={styles.headerCard}>
            <div className={styles.imgCard}>
              <img src={bedFontLight} alt="" className={styles.imgTag} />
              <p className={styles.title}>Hotels</p>
            </div>
            {selectTitle && (
              <h1 className={styles.selectPac}>
                Select a <br />
                {selectTitle}
              </h1>
            )}
          </div>
        )}
        {selectedOptions.flights && selectedOptions.hotels && (
          <div className={styles.headerCard}>
            <div className={styles.imgCard}>
              <img src={flightBed} alt="" className={styles.imgTag} />
              <p className={styles.title}>Packages</p>
            </div>
            {selectTitle && (
              <>
                {flightData?.length > 0 &&
                  showPrices &&
                  numberOfTravellers >= 1 && (
                    <span className={styles.price}>
                      Prices for{" "}
                      <span className={styles.numberOfTravel}>
                        {" "}
                        {numberOfTravellers}{" "}
                        {numberOfTravellers === 1 ? "Traveler" : "Travelers"}
                        Travelers
                      </span>
                    </span>
                  )}
              </>
            )}
          </div>
        )}
      </section>
    </div>
  );
}

export default SelectedOptions;
