import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./bookedHotelDetails.module.scss";
import { iHotelDetails } from "../../../interface";
// import { eyeLight, greenCheckIcon } from "../../../constant";
import ImageSlider from "../components/imageSlider/imageSlider";
import { amenitiesIcons } from "../hotelDetailsConstants";
import CustomAccordion from "../../reusableComponent/customAccordion/CustomAccordion";
import Amenities from "../components/amenities/amenities";
import AboutProperty from "../components/aboutProperty/aboutProperty";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { getHotelDetailsAPI } from "../../../api/hotelDetailsAPI";
import NewLoader from "../../reusableComponent/newLoader/NewLoader";
import Loader from "../../reusableComponent/loader/Loader";

interface iProps {
  hotelId: string;
  isOpen: boolean;
}

function BookedHotelDetails(props: iProps): JSX.Element {
  const { hotelId = "", isOpen = false } = props;
  const globalContext = useContext(StateContext);
  const {
    // shouldUserNavigateBacktoTripDetailsPage,
    // setActiveTabIndex,
    // setScreen,
    // setShowReviews,
    setHotelDetailsPageState,
    setShowPropertyDetails,
    // isMobile,
    // setShowRoomDetails,
    // setRoomDetailsData,
  }: iGlobalContext = globalContext;

  const [hotelDetails, setHotelDetails] = useState<iHotelDetails>();
  const [isLoading, setIsLoading] = useState(false);

  const {
    // stars: hotelStar,
    // name: hotelName,
    hotelImages = [],
    // address,
    // city: place,
    // hotelMainImage,
    // rating,
    // reviewCount,
    // landMark = "",
    // reviewHeading = "",
    hotelAmenities = [],
    aboutProperty = [],
    // hotelAndRoomInfo,
  } = hotelDetails || {};

  const hotelAborController: any = useRef(null);

  const fetchHotelDetails = useCallback(async () => {
    // if (hotelAborController.current) {
    hotelAborController.current = new AbortController();
    // }
    const response = await getHotelDetailsAPI(
      hotelId,
      hotelAborController?.current,
      setIsLoading
    );
    if (response.data) {
      setHotelDetails(response?.data);
    }
  }, [hotelId]);

  useEffect(() => {
    if (isOpen) fetchHotelDetails();
  }, [fetchHotelDetails, isOpen]);

  //   const { hotelInformation, roomInfo, city } = hotelAndRoomInfo || {};
  //   const { cancellationPolicy = "" } = hotelInformation || {};
  //   const {
  //     roomName,
  //     bedInformation = [],
  //     noOfRooms,
  //     priceInfo,
  //     roomOnly = false,
  //     roomAmenities = [],
  //   } = roomInfo || {};
  //   const { isRefundable = false } = priceInfo || {};

  const handleClickPropertyDetails = useCallback(() => {
    // if (!roomInfo)
    setHotelDetailsPageState({
      ...hotelDetails,
      hotelAmenities: [...(hotelAmenities || [])],
    });
    setShowPropertyDetails(true);
  }, [
    hotelAmenities,
    hotelDetails,
    setHotelDetailsPageState,
    setShowPropertyDetails,
  ]);

  if (isLoading)
    return (
      <div className={styles.inLineLoader}>
        <Loader />
      </div>
    );

  return (
    <div className={styles.hotelDetailsContainer}>
      {hotelImages.length > 0 && (
        <div className={styles.horizontalScrollContainer}>
          <ImageSlider
            imagesArr={[...hotelImages.map((item: any) => item.url)]}
          />
        </div>
      )}
      {hotelAmenities.length > 0 && (
        <CustomAccordion
          className={styles.customStyles}
          summaryClass={styles.summaryClass}
          accordianDetailsClass={styles.accordianDetailsClass}
          defaultExpanded={true}
          headingComponent={<span className={styles.heading}>Amenities</span>}
          bodyComponent={
            <Amenities
              aminitiesArr={hotelAmenities.slice(0, 8)}
              showViewPropAmenities
              amenitiesIconsArr={amenitiesIcons}
              onClickPropertyDetails={handleClickPropertyDetails}
            />
          }
          zeroMarginOnExpand
          // borderBottom
          hideDropDownIcon
        />
      )}
      <div className={styles.divider} />
      {aboutProperty.length > 0 && (
        <CustomAccordion
          className={styles.customStyles}
          summaryClass={styles.summaryClass}
          hideDropDownIcon
          defaultExpanded={true}
          headingComponent={
            <span className={styles.heading}>About the Property</span>
          }
          bodyComponent={<AboutProperty aboutPropertyArr={aboutProperty} />}
          // zeroMarginOnExpand
          borderBottom
        />
      )}

      {/* <SelectRoomModal
        isOpen={showSelectRoomModal}
        onClose={() => setShowSelectRoomModal(false)}
      /> */}
    </div>
  );
}

export default BookedHotelDetails;
