import React, { useContext, useEffect, useState } from "react";
import styles from "./editActivity.module.scss";
import { Button, Dialog, Stack } from "@mui/material";
import updateCloseIcon from "../../../assets/updateCloseIcon.svg";
import { getDayOfWeek, getMonthDate } from "../../../utils/dateUtils";
import CustomSelect from "../../reusableComponent/customSelect/CustomSelect";
import DayActivityText from "./component/dayActivityText/DayActivityText";
import DayActivityInput from "./component/dayActivityInput/DayActivityInput";
import { ACTIVITY_TYPE_IDENTIFIER } from "../../../enums";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";
import toast from "react-hot-toast";
import { SaveItineraryDetailsResponse } from "../../../interface";
import { saveItineraryDetailsAPI } from "../../../api/saveItineraryDetails";
import { toastMessage } from "../../../utils/ToastProvider";
import { getConfigMessage } from "../../../utils";
import { APP_CONFIG } from "../../../constant/common";
import { iCityDetailsResult } from "../../../types";
import { TOAST_MESSAGES } from "../../../constant/messages/toastMessages";
import { TIME_OF_DAY_ARR } from "../../../constant/itinerary-constant";
import { v4 } from "uuid";

export default function EditActivityDialog({
  open,
  dayItinerary = [],
  onClose,
  activityDate = "",
  setOpenDiscardChangesDialog,
  savedStatus = "",
}: Readonly<{
  open: boolean;
  dayItinerary?: any;
  setOpenDiscardChangesDialog?: any;
  onClose?: any;
  activityDate?: string;
  savedStatus?: string;
}>) {
  const globalContext = useContext(StateContext);
  const {
    // setChatData,
    appConfig,
    setSelectedChatData,
    selectedChatData,
    userInfo,
  }: iGlobalContext = globalContext;

  const [localDayItinerary, setLocalDayItinerary] = useState(dayItinerary);
  const [selectList, setSelectList] = useState<string[]>([]);
  useEffect(() => {
    setLocalDayItinerary(dayItinerary);
    window.localStorage.setItem(
      "_prev_day_itinerary",
      JSON.stringify(dayItinerary)
    );
  }, [dayItinerary]);

  useEffect(() => {
    const missingTimesOfDay: string[] = TIME_OF_DAY_ARR.filter(
      (timeOfDay: string) =>
        !localDayItinerary.some(
          (localDayItineraryEle: any) =>
            localDayItineraryEle.time_of_the_day.toLowerCase() ===
            timeOfDay.toLowerCase()
        )
    );
    setSelectList(missingTimesOfDay.length ? missingTimesOfDay : []);
    console.log({ localDayItinerary });
  }, [localDayItinerary]);

  async function handleSaveChanges() {
    triggerGAEvent(GA_LABEL_ENUMS.itinerary_details_activities_done_click);
    toast.dismiss();
    console.log({ localDayItinerary });
    const metaData: any = {
      selectedChatData: {
        ...selectedChatData,
        destinationList: selectedChatData?.destinationList.map(
          (destinationListEle: iCityDetailsResult) => ({
            ...destinationListEle,
            tripDetails: destinationListEle?.tripDetails?.map(
              (tripDetailsEle: any) => {
                const filteredLocalDayItinerary = localDayItinerary.filter(
                  (itinerary: any) => itinerary.description !== ""
                );
                console.log(filteredLocalDayItinerary);
                return {
                  ...tripDetailsEle,
                  itinerary_by_time_of_the_day:
                    tripDetailsEle?.itinerary_by_time_of_the_day[0]?.id ===
                    filteredLocalDayItinerary[0]?.id
                      ? filteredLocalDayItinerary
                      : tripDetailsEle.itinerary_by_time_of_the_day,
                };
              }
            ),
          })
        ),
        prompt: [],
        postText: "",
        preText: "",
      },
    };
    if (userInfo?.loggedIn) {
      toast.loading(TOAST_MESSAGES.ModifyingActivity.loading.message);
      const saveItineraryPayload = {
        tripName: selectedChatData?.tripName ?? "",
        userId: userInfo?.id,
        metaData,
        status: savedStatus,
        numberOfDaysSelected: Number(selectedChatData.noOfDays),
        year: "",
        selectedMonth: "",
        is_admin_created: userInfo?.role === 1,
        // data: selectedChatData?.results || null,
        data: {},
      };
      const response: SaveItineraryDetailsResponse =
        await saveItineraryDetailsAPI(
          saveItineraryPayload,
          "PUT",
          selectedChatData?.id
        );
      if (response?.statusCode === 200) {
        toast.dismiss();
        toastMessage.success(
          TOAST_MESSAGES.ModifyingActivity.editSuccessWithUserLogin
            .messageTitle,
          TOAST_MESSAGES.ModifyingActivity.editSuccessWithUserLogin.message
        );
        onClose();
        setTimeout(() => {
          toast.dismiss();
        }, 2000);
      } else {
        toastMessage.error(
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
      }
    } else {
      toast.dismiss();
      toastMessage.success(
        TOAST_MESSAGES.ModifyingActivity.editSuccessWithoutUserLogin
          .messageTitle,
        TOAST_MESSAGES.ModifyingActivity.editSuccessWithoutUserLogin.message
      );
      onClose();
      setTimeout(() => {
        toast.dismiss();
      }, 2000);
    }
    setSelectedChatData(metaData?.selectedChatData);
  }

  function handleInputChange(e: any, dayItineraryEle: any) {
    console.log(dayItineraryEle, localDayItinerary);
    setLocalDayItinerary((prev: any) =>
      prev.map((ele: any) => {
        if (ele?.id === dayItineraryEle?.id)
          return {
            ...ele,
            description: e.target.value,
          };
        return ele;
      })
    );
  }

  function handleClearInput(dayItineraryEle: any) {
    setLocalDayItinerary((prev: any) =>
      prev.map((ele: any) => {
        if (ele?.id === dayItineraryEle?.id)
          return {
            ...ele,
            description: "",
          };
        return ele;
      })
    );
  }

  function handleClose() {
    const prevDayItinerary =
      window.localStorage.getItem("_prev_day_itinerary") ?? "";
    if (
      JSON.stringify(JSON.parse(prevDayItinerary)) ===
      JSON.stringify(localDayItinerary)
    )
      onClose();
    else setOpenDiscardChangesDialog(true);
  }

  function handleSelectClick(e: any) {
    const dataId = e.target.getAttribute("data-id");
    console.log("Data ID:", dataId);
    const tempDayItinerary = [
      ...localDayItinerary,
      {
        id: v4(),
        description: "",
        activity_type: "",
        time_of_the_day: dataId,
      },
    ];
    setLocalDayItinerary(tempDayItinerary);
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      // open={true}
      className={styles.main}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0,0,0,0.85)",
          },
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <div className={styles.mainHeaderContainer}>
        <div className={styles.editActivitiesText}>
          <h1>Edit Activities</h1>
        </div>
        <Stack alignSelf="end" mb={2} mr={1.4}>
          <button
            onClick={handleClose}
            style={{
              background: "none",
              border: "none",
              padding: 0,
              cursor: "pointer",
            }}
          >
            <img style={{ width: "17px" }} src={updateCloseIcon} alt="cross" />
          </button>
        </Stack>
      </div>
      <Stack
        sx={{
          width: "100%",
          maxHeight: {
            sm: "80vh",
            xs: "80vh",
            md: "calc(100% - 5%)",
            lg: "calc(100% - 5%)",
          },
          overflow: "auto",
          bgcolor: "#f8f8f8",
          borderRadius: "8px",
        }}
      >
        {/* Dialog content */}
        <div className={styles.dialogContainer}>
          <div className={styles.headingContainer}>
            {activityDate !== "Invalid Date" && (
              <h1>
                {getDayOfWeek(activityDate)} {","} {getMonthDate(activityDate)}
              </h1>
            )}
          </div>
          <div>
            {localDayItinerary?.length &&
              localDayItinerary?.map((dayItineraryEle: any, index: number) => {
                if (
                  dayItineraryEle?.activity_type ===
                    ACTIVITY_TYPE_IDENTIFIER.Flight ||
                  dayItineraryEle?.activity_type ===
                    ACTIVITY_TYPE_IDENTIFIER.Hotel ||
                  dayItineraryEle?.activity_type ===
                    ACTIVITY_TYPE_IDENTIFIER.LargeHotel
                )
                  return (
                    <DayActivityText
                      key={index + dayItineraryEle?.time_of_the_day}
                      dayItineraryEle={dayItineraryEle}
                    />
                  );
                return (
                  <DayActivityInput
                    key={index + dayItineraryEle?.time_of_the_day}
                    timeOfDay={dayItineraryEle?.time_of_the_day}
                    inputValue={dayItineraryEle?.description}
                    onInputChange={(e: any) =>
                      handleInputChange(e, dayItineraryEle)
                    }
                    handleClearInput={() => handleClearInput(dayItineraryEle)}
                  />
                );
              })}
          </div>
          {selectList?.length ? (
            <div>
              <CustomSelect
                onClick={handleSelectClick}
                selectList={selectList}
              />
            </div>
          ) : (
            <></>
          )}
          <div className={styles.buttonContainer}>
            <Button
              className={`${styles.button} ${styles.saveBtn}`}
              onClick={handleSaveChanges}
            >
              SAVE CHANGES
            </Button>
          </div>
        </div>
      </Stack>
    </Dialog>
  );
}
