import styles from "./search.module.scss";
import SearchIcon from "../../assets/SearchGrayIcon.svg";
import { expandIcon } from "../../constant";

interface SearchBoxProps {
  title: string;
  expand: boolean;
  onClick: () => void;
}

const SearchBox: React.FC<SearchBoxProps> = ({ title, expand, onClick }) => {
  return (
    <div className={styles.main}>
      <section className={styles.searchCard}>
        <img src={SearchIcon} alt="" className={styles.search} />
        <p className={styles.title}>{title}</p>
      </section>
      <button className={styles.hideBtn} onClick={onClick}>
        <p className={styles.expandTitle}>{expand ? "EXPAND " : "HIDE"}</p>

        <img
          src={expandIcon}
          alt="icon"
          className={`${expand ? styles.expandIcon : styles.hideIcon}`}
        />
      </button>
    </div>
  );
};

export default SearchBox;
