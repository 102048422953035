import React from "react";
import styles from "./nextBreadcrumbBtn.module.scss";
import { Button } from "@mui/material";

interface NextBreadcrumbBtnProps {
  handleNext: (e: any) => void;
  textInfo?: string;
  btnText?: string;
  disabled?: boolean;
}

export default function NextBreadcrumbBtn({
  handleNext,
  textInfo = "",
  btnText = "Continue",
  disabled = false,
}: Readonly<NextBreadcrumbBtnProps>) {
  return (
    <div className={styles.continueBtnContainer}>
      <Button
        disabled={disabled}
        className={`${styles.continueBtn} ${!disabled && styles.activeBtn}`}
        onClick={handleNext}
      >
        {btnText}
      </Button>
      {textInfo && <p>to Add Traveler Information</p>}
    </div>
  );
}
