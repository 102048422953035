import React, { useCallback, useContext, useState } from "react";

import { backIcon, bedFontLight, ROUTES } from "../../constant";
import { Button } from "@mui/material";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import styles from "./modifyHotelReservation.module.scss";
import { ScreenTypes } from "../../enums";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
import ModifyingHotelAccordion from "./components/modifyingHotelAccordion/modifyingHotelAccordion";
import CustomButton from "../../component/button/button";
import { useNavigate } from "react-router-dom";
import {
  getHotelRoomsAPI,
  hotelRebookAPI,
} from "../../api/booking/postBooking";
import { APP_URL } from "../../constant/environment";
import { CURRENCY } from "../../interface";
import NewLoader from "../../component/reusableComponent/newLoader/NewLoader";
import { toastMessage } from "../../utils/ToastProvider";
import { TOAST_MESSAGES } from "../../constant/messages/toastMessages";

export default function ModifyHotelReservation() {
  const globalContext = useContext(StateContext);
  const {
    // isMobile,
    setScreen,
    manageHotelsArr,
    myTripsItineraryId = "",
    userInfo,
    setManageHotelsArr,
    setBookedTransIds,
    selectedChatData,
  }: iGlobalContext = globalContext;
  const navigate = useNavigate();
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const [showRoomsLayout, setShowRoomsLayout] = useState(false);
  const [fetchingHotels, setFetchingHotels] = useState(false);
  const [modifyingHotels, setModifyingHotels] = useState(false);

  const handleBackButton = useCallback(() => {
    if (isMobile) {
      if (showRoomsLayout) {
        setShowRoomsLayout(false);
        return;
      }
      window.history.back();
    }
    if (!isMobile) {
      if (showRoomsLayout) {
        setShowRoomsLayout(false);
        return;
      }
      setScreen(ScreenTypes.TripDetails);
    }
  }, [isMobile, setScreen, showRoomsLayout]);

  const fetchHotelRooms = useCallback(async () => {
    setFetchingHotels(true);
    setManageHotelsArr((prev) =>
      prev.map((item: any) => ({ ...item, selectedHotelIds: null }))
    );
    let areCheckInAndCheckOutDatesValid = true;
    manageHotelsArr.forEach((item) => {
      if (
        new Date(item?.checkIn).getTime() > new Date(item?.checkOut).getTime()
      )
        areCheckInAndCheckOutDatesValid = false;
    });
    if (!areCheckInAndCheckOutDatesValid) {
      toastMessage.error(
        TOAST_MESSAGES.PostBooking.hotelSearchDateValidationFailure
          .messageTitle,
        TOAST_MESSAGES.PostBooking.hotelSearchDateValidationFailure.message
      );
      setShowRoomsLayout(false);
      setFetchingHotels(false);
      return;
    }
    const response: any = await getHotelRoomsAPI({
      userId: userInfo?.id || "1234",
      itineraryId: myTripsItineraryId,
      hotel: manageHotelsArr.map((item: any) => ({
        checkIn: item?.checkIn,
        checkOut: item?.checkOut,
        hotelBookId: item?.hotelBookId,
        noOfRooms: item?.roomInfo?.noOfRooms,
      })),
    });
    if (response) {
      // console.log(response.data.data, "response from hotels fetching rooms");
      if (response?.statusCode !== 200) {
        toastMessage.error(
          TOAST_MESSAGES.PostBooking.hotelChangeRequestFailure?.messageTitle,
          TOAST_MESSAGES.PostBooking.hotelChangeRequestFailure?.message
        );
        return;
      }
      setManageHotelsArr((prev: any) =>
        prev.map((item: any) => ({
          ...item,
          rooms: response?.data?.data?.find((responseItem: any) => {
            return responseItem.hotelBookId === item?.hotelBookId;
          })?.rooms,
          sessionId: response?.data?.data?.find((responseItem: any) => {
            return responseItem.hotelBookId === item?.hotelBookId;
          })?.sessionId,
        }))
      );
      setShowRoomsLayout(true);
      setFetchingHotels(false);
    }
  }, [manageHotelsArr, myTripsItineraryId, setManageHotelsArr, userInfo?.id]);

  const totalSelectedHotelRoomsPrice = manageHotelsArr
    .map((item: any) => item?.selectedHotelIds?.amountDue)
    ?.reduce((acc, item) => {
      return (item || 0) + acc;
    }, 0);

  const handleModifyHotels = useCallback(async () => {
    setBookedTransIds(null);
    setModifyingHotels(true);
    if (!selectedChatData?.id) return;
    const response = await hotelRebookAPI({
      id: selectedChatData?.id,
      userId: userInfo?.id || "1234",
      paymentDetails: {
        amount: totalSelectedHotelRoomsPrice,
        cancelUrl: APP_URL,
        successUrl: APP_URL.slice(0, -1) + ROUTES.ModifyHotelsSuccess,
        currency: CURRENCY.USD,
        remarks: "",
      },
      hotels: manageHotelsArr?.map((item: any) => ({
        hotelBookId: item?.selectedHotelIds?.hotelBookId,
        sessionId: item?.selectedHotelIds?.sessionId,
        uniqueId: item?.selectedHotelIds?.uniqueId,
      })),
    });
    if (response) {
      setModifyingHotels(false);
      // console.log(response, "response failed");
      if (response?.statusCode !== 200) {
        toastMessage.error(
          TOAST_MESSAGES.PostBooking.hotelChangeRequestFailure?.messageTitle,
          TOAST_MESSAGES.PostBooking.hotelChangeRequestFailure?.message
        );
        return;
      }
      if (
        response?.data?.paymentLink &&
        response.data?.hotel &&
        response?.data?.itineraryID
      ) {
        setBookedTransIds({
          hotels: response.data.hotel,
          itineraryID: response.data.itineraryID,
        });
        setTimeout(() => {
          window.location.replace(response?.data?.paymentLink);
        }, 1000);
        return;
      }
      // console.log(response, "response after booking");
      if (response.data?.itineraryID) {
        setBookedTransIds(response.data);
        navigate(ROUTES.ModifyHotelsSuccess);
      }
      // console.log(response.data.data, "response from hotel");
    }
  }, [
    manageHotelsArr,
    navigate,
    selectedChatData?.id,
    setBookedTransIds,
    totalSelectedHotelRoomsPrice,
    userInfo?.id,
  ]);

  return (
    <>
      {fetchingHotels && (
        <NewLoader
          loaderIcon1={bedFontLight}
          // loaderIcon2={bedFontLight}
          text="Fetching hotel rooms"
          isOpen={fetchingHotels}
          onClose={() => {
            setFetchingHotels(false);
            // if (checkingAvailabilityController?.current) {
            //   checkingAvailabilityController.current?.abort();
            // }
          }}
          loadFullScreen
        />
      )}
      {modifyingHotels && (
        <NewLoader
          loaderIcon1={bedFontLight}
          // loaderIcon2={bedFontLight}
          text="Please wait"
          isOpen={modifyingHotels}
          onClose={() => {
            // if (checkingAvailabilityController?.current) {
            //   checkingAvailabilityController.current?.abort();
            // }
          }}
          loadFullScreen
        />
      )}
      <div className={styles.mainContainer}>
        <div className={styles.headerContainer}>
          <div
            className={styles.buttonContainer}
            //   style={{ marginBottom: isSharedTrip ? "0px" : "-10px" }}
          >
            <Button
              className={`${styles.columnAlignButton}`}
              onClick={handleBackButton}
            >
              <img src={backIcon} alt="Close" />
              <p>BACK</p>
            </Button>
            <div className={styles.divider} />
            <p className={styles.modifyHotelsHeader}>Modify Reservation</p>
            <p
              className={`${styles.modifyHotelsHeader} ${styles.valueDifference}`}
            >
              ${Math.round(totalSelectedHotelRoomsPrice || 0)}
            </p>
          </div>
        </div>
        <div className={styles.flightAccord}>
          {manageHotelsArr?.length > 0 ? (
            manageHotelsArr?.map((hotel: any, index: number) => {
              // const {
              //   checkIn = "",
              //   checkOut = "",
              //   city = "",
              //   hotelInformation = {},
              //   roomInfo = {},
              // } = ele;
              // const { stars, name, hotelMainImage, address, hotelId } =
              //   hotelInformation;
              // const { noOfRooms, bedInformation = [] } = roomInfo;
              const {
                // hotelMainImage,
                // stars,
                // name,
                // address,
                hotelInformation,
                amenities,
                totalPrice,
                cancellationPolicy,
                // hotelBookId,
                checkIn = "",
                checkOut = "",
                city = "",
              } = hotel;
              const {
                stars,
                name,
                hotelMainImage,
                address,
                hotelId,
                rating,
                reviewCount,
                reviewHeading,
              } = hotelInformation;
              return (
                <ModifyingHotelAccordion
                  showRoomsLayout={showRoomsLayout}
                  key={hotelId}
                  checkIn={checkIn}
                  checkOut={checkOut}
                  id={index}
                  hotelMainImage={hotelMainImage}
                  star={stars}
                  hotelName={name}
                  hotelLocation={address}
                  hotelRating={rating}
                  reviewCount={reviewCount}
                  cost={totalPrice?.price}
                  cancellation={cancellationPolicy}
                  Amenity={amenities}
                  hotelList={true}
                  discount={""}
                  discountCost={""}
                  taxesIncluded={totalPrice?.taxesIncluded}
                  reviewHeading={reviewHeading}
                  hotelId={hotelId}
                  hotelDetails={hotel}
                  city={city}
                  // handleCheckboxChange={() => handleCheckboxChange(hotel)}
                  hotelAccordionClick={() =>
                    triggerGAEvent(
                      GA_LABEL_ENUMS.trip_details_hotel_accordion_click
                    )
                  }
                  hotelViewDetailsClick={() =>
                    triggerGAEvent(
                      GA_LABEL_ENUMS.trip_details_hotel_view_deatils_accordion_click
                    )
                  }
                />
              );
            })
          ) : (
            <p className={styles.noHotel}>No hotel booked</p>
          )}

          <div className={styles.footerSection}>
            {showRoomsLayout ? (
              <CustomButton
                className={`${styles.hoteltBtn}`}
                disabled={
                  !manageHotelsArr.every((item: any) => {
                    if (
                      item?.rooms?.length > 0 &&
                      item?.selectedHotelIds?.uniqueId
                    ) {
                      return true;
                    } else if (item?.rooms?.length === 0) {
                      return true;
                    }
                    return false;
                  })
                }
                onClick={() => {
                  if (showRoomsLayout) {
                    handleModifyHotels();
                  }
                  // setShowRoomsLayout(!showRoomsLayout);
                }}
              >
                Continue
              </CustomButton>
            ) : (
              <CustomButton
                className={`${styles.hoteltBtn}`}
                onClick={() => {
                  if (!showRoomsLayout) {
                    fetchHotelRooms();
                  }
                  // setShowRoomsLayout(!showRoomsLayout);
                }}
              >
                Search
              </CustomButton>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
