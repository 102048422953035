import { AirlineTypes } from "../../../../types";
import NewFlightDetails from "../../../booking/newFlightDetails/newFlightDetails";
import ArrivalOnDifferentDate from "../../../reusableComponent/arrivalOnDifferentDate/ArrivalOnDifferentDate";
import FlightDetails from "../flightDetails/flightDetail";
import styles from "./flightTabs.module.scss";

function NewFlightStepperElement({
  flightData,
  showAllSteps,
  airline,
  flightType,
  lastStop = false,
  departureDate,
  date,
  airportCode,
  airportName,
  pnrNo,
  flightOperatorData,
  equipmentType,
  cabinClass,
}: {
  flightData: any;
  showAllSteps: boolean;
  initialStep: boolean;
  handleViewDetails?: any;
  airline?: any;
  flightType?: string;
  lastStop?: boolean;
  departureFromLayover?: boolean;
  departureDate?: string;
  date?: any;
  airportCode?: string;
  airportName?: string;
  pnrNo?: string;
  flightOperatorData?: AirlineTypes;
  equipmentType?: string;
  cabinClass?: string;
}) {
  function addHHMM(time: string) {
    if (typeof time !== "string" || time.split(":").length < 2) return "";
    const splitTime = time.split(":");
    return splitTime[0] + "h " + splitTime[1] + "m";
  }

  function checkIfArrivalIsNextDate(timestamp1: string, timestamp2: string) {
    if (!timestamp1 || !timestamp2) return false;
    const date1 = timestamp1.split("-");
    const date2 = timestamp2.split("-");
    return !(
      date1[0] === date2[0] &&
      date1[1] === date2[1] &&
      date1[2].substring(0, 2) === date2[2].substring(0, 2)
    );
  }
  return (
    <div className={styles.main}>
      <div className={styles.stepper}>
        <div className={styles.step}>
          <div className={styles.circle}></div>
          <div className={styles.stepContent}>
            <div className={styles.arrivalDateContainer}>
              <FlightDetails
                airportCode={airportCode ?? ""}
                airportName={airportName ?? ""}
                date={date}
                flightType={
                  flightType === "Departure" ? "departure" : "arrival"
                }
                cabinClass={cabinClass}
              />
            </div>
            {checkIfArrivalIsNextDate(departureDate ?? "", date) && (
              <div className={styles.arrivalOnDifferent}>
                <ArrivalOnDifferentDate variant="danger" />
              </div>
            )}
            {/* <p className={styles.title}>
              <span className={styles.airportCode}>
                {airportCode}
                {airportName && (
                  <span className={styles.airportName}>
                    &nbsp;<b>|</b>&nbsp;{airportName}
                  </span>
                )}
              </span>
            </p>
            <div className={styles.arrivalDateContainer}>
              <img
                src={
                  flightType === "Departure"
                    ? planeDepartureRegular
                    : planeArrivalRegular
                }
                alt=""
                className={styles.planeIcon}
              />
              {date && (
                <p
                  className={`${checkIfArrivalIsNextDate(departureDate || "", date) ? styles.delayDescription : styles.description}`}>
                  {date && formatNewDepartureDate(date)} |&nbsp;
                  <span className={styles.timeInfo}>
                    {getTwelveHourTimeFormate(date)}
                  </span>
                </p>
              )}
              {flightType === "Departure" && (
                <span className={styles.seatCard}>
                  <img
                    src={seatArlineRegular}
                    alt=""
                    className={styles.airlineSeat}
                  />
                  <p className={styles.seatInfo}>{cabinClass}</p>
                </span>
              )}
            </div>
            {checkIfArrivalIsNextDate(departureDate ?? "", date) && (
              <div className={styles.arrivalOnDifferent}>
                <ArrivalOnDifferentDate variant="danger" />
              </div>
            )} */}
            {showAllSteps && (
              <div className={styles.card}>
                {flightType === "Departure" && (
                  <div className={styles.subCard}>
                    {flightType === "Departure" && (
                      <div className={styles.airlineImgCard}>
                        <img
                          src={airline?.image}
                          alt="flight"
                          className={styles.airlineImg}
                        />
                      </div>
                    )}
                    <NewFlightDetails
                      title={airline?.name}
                      subTitle={`${airline?.code && flightData?.flightNumber ? "Flight " + airline?.code + flightData?.flightNumber : ""}`}
                      description={pnrNo ? "Booking reference: " + pnrNo : ""}
                      imgUrl={airline?.image}
                      hide={true}
                      flightName={airline?.name}
                      flightNumber={`${airline?.code && flightData?.flightNumber ? " " + airline?.code + flightData?.flightNumber : ""}`}
                      duration={flightData?.flyDuration}
                      manufactureName={equipmentType}
                      operatorName={flightOperatorData?.name}
                    />
                  </div>
                )}
                {flightType === "Layover" && (
                  <div className={styles.subCard}>
                    <NewFlightDetails
                      title={flightType || ""}
                      subTitle={""}
                      description={addHHMM(flightData?.layover) || ""}
                      imgUrl={""}
                      layoverCityName={flightData?.cityName}
                    />
                  </div>
                )}

                {flightType === "Arrival" && (
                  <div className={styles.subCard}>
                    {!lastStop && (
                      <NewFlightDetails
                        title={flightType}
                        subTitle={`${airline?.code && flightData?.flightNumber ? "Flight " + airline?.code + flightData?.flightNumber : ""}`}
                        description=""
                        imgUrl={airline?.image}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>

          {flightType !== "Arrival" && (
            <div
              className={`${flightType === "Layover" ? styles.dashLine : styles.line}`}>
              <div className={`${flightType === "Layover" && styles.square}`} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewFlightStepperElement;
