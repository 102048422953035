import { useCallback, useContext } from "react";
import styles from "./hotelPreferences.module.scss";
import ToggleSwitchComponent from "../components/toggleSwitch/toggleSwitch";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";

function HotelPreferences() {
  const state = useContext(StateContext);
  const { bookingDetails, setBookingDetails }: iGlobalContext = state;

  const splitHotels = (hotelChain: string) => {
    if (hotelChain === "") {
      return [];
    }
    return hotelChain.split(",").map((hotels) => hotels.trim());
  };

  const hotelChains = splitHotels(bookingDetails.hotelChains ?? "");
  const handleRefundFilter = useCallback(
    (event: any, checked: boolean) => {
      setBookingDetails((prevData) => ({
        ...prevData,
        hotelPreferences: {
          ...prevData.hotelPreferences,
          refundable: event.target.checked,
        },
      }));
    },
    [setBookingDetails]
  );

  const HOTEL_PREFERENCES = {
    isRefundable: true,
    hotels: hotelChains,
  };

  const handleCheckboxChange = (hotel: string) => {
    const updatedHotels =
      bookingDetails?.hotelPreferences?.hotelChains?.includes(hotel)
        ? bookingDetails?.hotelPreferences?.hotelChains.filter(
            (item) => item !== hotel
          )
        : [...bookingDetails?.hotelPreferences?.hotelChains, hotel];

    setBookingDetails({
      ...bookingDetails,
      hotelPreferences: {
        ...bookingDetails?.hotelPreferences,
        hotelChains: updatedHotels,
      },
    });
  };

  return (
    <div className={styles.hotelPreferencesContainer}>
      <div className={styles.toggleSwitchContainer}>
        <ToggleSwitchComponent
          handleRefundFilter={handleRefundFilter}
          isSelectedRefundable={bookingDetails?.hotelPreferences?.refundable}
        />
      </div>
      {HOTEL_PREFERENCES?.hotels.length >= 1 && (
        <>
          <div className={styles.header}>Brands</div>
          <div className={styles.selectCard}>
            {HOTEL_PREFERENCES?.hotels?.map((hotel: string, index: number) => (
              <div className={styles.customCheckboxContainer}>
                <section key={index} className={styles.inputBox}>
                  <input
                    type="checkbox"
                    id={hotel}
                    name={hotel}
                    value={hotel}
                    checked={bookingDetails?.hotelPreferences?.hotelChains?.includes(
                      hotel
                    )}
                    onChange={() => handleCheckboxChange(hotel)}
                    className={styles.customCheckbox}
                  />
                  <span className={styles.checkmark}></span>
                </section>
                <label htmlFor={hotel} className={styles.label}>
                  {hotel}
                </label>
                <br />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default HotelPreferences;
