import {
  BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT,
  INITIAL_BREADCRUMBS_LIST,
} from "../../../constant/bookingInProgressConstant";
import { BreadCrumTypes } from "../../../types";

export {};

const afterIndex = {
  checked: false,
  activeCrumbs: false,
};
const beforeIndex = {
  checked: true,
  activeCrumbs: false,
};
export function moveToHotel(setBreadList: any) {
  let afterHotel = false;
  setBreadList((prev: BreadCrumTypes[]) =>
    prev.map((ele: BreadCrumTypes) => {
      if (ele?.value === BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT.Hotels) {
        afterHotel = true;
        return { ...ele, checked: true, activeCrumbs: true };
      } else if (afterHotel) return { ...ele, ...afterIndex };
      return { ...ele, ...beforeIndex };
    })
  );
}
export function moveToFlight(setBreadList: any) {
  let afterFlight = false;
  setBreadList((prev: BreadCrumTypes[]) =>
    prev.map((ele: BreadCrumTypes) => {
      if (ele?.value === BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT.Flights) {
        afterFlight = true;
        return { ...ele, checked: true, activeCrumbs: true };
      } else if (afterFlight) return { ...ele, ...afterIndex };
      return { ...ele, ...beforeIndex };
    })
  );
}

export function flightOrHotelSelect(
  selectedFlightOptions: any,
  setBreadList: any
) {
  if (selectedFlightOptions?.flights && selectedFlightOptions?.hotels) {
    setBreadList(INITIAL_BREADCRUMBS_LIST);
  } else if (selectedFlightOptions?.flights) {
    setBreadList((prev: BreadCrumTypes[]) =>
      prev.filter(
        (ele: BreadCrumTypes) =>
          ele?.value !== BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT.Hotels
      )
    );
    setBreadList((prev: BreadCrumTypes[]) =>
      prev.map((ele: BreadCrumTypes, index: number) => {
        if (index === 0) {
          return { ...ele, checked: true, activeCrumbs: true };
        }
        return { ...ele, checked: false, activeCrumbs: false };
      })
    );
  } else if (selectedFlightOptions?.hotels) {
    setBreadList((prev: BreadCrumTypes[]) =>
      prev.filter(
        (ele: BreadCrumTypes) =>
          ele?.value !== BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT.Flights
      )
    );
    setBreadList((prev: BreadCrumTypes[]) =>
      prev.map((ele: BreadCrumTypes, index: number) => {
        if (index === 0) {
          return { ...ele, checked: true, activeCrumbs: true };
        }
        return { ...ele, checked: false, activeCrumbs: false };
      })
    );
  } else {
    setBreadList(INITIAL_BREADCRUMBS_LIST);
  }
}
