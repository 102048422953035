import React, { useCallback, useContext, useState } from "react";
import DialogPopup from "../../../../component/reusableComponent/dialogPopup/dialogPopup";
import styles from "./cancelHotelsModal.module.scss";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import { StateContext } from "../../../../context/globalContext/context";
import { roundValue } from "../../../../utils";
import { cancelHotelsAPI } from "../../../../api/booking/postBooking";
import { toastMessage } from "../../../../utils/ToastProvider";
import { TOAST_MESSAGES } from "../../../../constant/messages/toastMessages";
import NewLoader from "../../../../component/reusableComponent/newLoader/NewLoader";

interface iProps {
  isOpen: boolean;
  onClose: () => void;
}

function CancelHotelsModal(props: iProps) {
  const { isOpen = false, onClose } = props;
  const globalContext = useContext(StateContext);
  const {
    myTripsItineraryId,
    manageHotelsArr,
    userInfo,
    setManageHotelsArr,
    setFetchItineraryDetails,
    setActiveTripDetailsTab,
    selectedChatData,
  }: iGlobalContext = globalContext;
  const [isCancelling, setIsCancelling] = useState(false);

  const totalSelectedHotelRoomsPrice =
    manageHotelsArr
      .map((item: any) => item?.roomInfo?.priceInfo)
      ?.reduce(
        (acc, item) => (item?.isRefundable ? item?.baseFare + acc : acc),
        0
      ) || 0;

  // console.log(
  //   manageHotelsArr
  //     .map((item: any) => item?.roomInfo?.priceInfo)
  //     ?.reduce(
  //       (acc, item) => (item?.isRefundable ? item?.baseFare + acc : acc),
  //       0
  //     ),
  //   "roomRefundablePrice"
  // );

  const handleCancelHotel = useCallback(async () => {
    if (isCancelling) return;
    setIsCancelling(true);
    if (!selectedChatData?.id) return;
    const response = await cancelHotelsAPI({
      itineraryId: myTripsItineraryId,
      userId: userInfo?.id,
      id: selectedChatData?.id,
      hotel: manageHotelsArr.map((item) => ({
        hotelBookId: item?.hotelBookId,
      })),
    });
    if (response?.statusCode === 200) {
      setIsCancelling(false);
      setFetchItineraryDetails(true);
      setActiveTripDetailsTab("ITINERARY");
      onClose();
    } else {
      setIsCancelling(false);
      setManageHotelsArr([]);
      onClose();
      toastMessage.error(
        TOAST_MESSAGES.PostBooking.hotelCancelRequestFailure?.messageTitle,
        TOAST_MESSAGES.PostBooking.hotelCancelRequestFailure?.message
      );
    }
  }, [
    isCancelling,
    manageHotelsArr,
    myTripsItineraryId,
    onClose,
    selectedChatData?.id,
    setActiveTripDetailsTab,
    setFetchItineraryDetails,
    setManageHotelsArr,
    userInfo?.id,
  ]);

  return (
    <DialogPopup isOpen={isOpen}>
      <>
        {isCancelling && (
          <NewLoader
            isOpen={isCancelling}
            onClose={() => setIsCancelling(false)}
            text="Please wait"
            loadFullScreen
            bottomBtnText=""
          />
        )}
        {!isCancelling && (
          <div className={styles.container}>
            <div className={styles.cancellationPolicyContainer}>
              <p className={styles.header}>CANCEL HOTELS</p>
              {/* <p className={styles.policyContent}>
            Cancel anytime before January 1, 2025 for a full refund. After the
            deadline, a cancellation charge equal to one night will be charged.
          </p> */}
              <p className={styles.refundContainer}>
                <span>Refund amount</span>
                <span>${roundValue(totalSelectedHotelRoomsPrice)}</span>
              </p>
              <p className={`${styles.refundContainerSecond}`}>
                <span>to original form of payment</span>
                {/* <span>$657.80</span> */}
              </p>
            </div>
            <span className={styles.title}>Cancel hotels?</span>
            <div className={styles.btnCard}>
              <button className={styles.cancelBtn} onClick={onClose}>
                No
              </button>
              <button
                className={styles.discBtn}
                onClick={handleCancelHotel}
                disabled={isCancelling}
              >
                YES
              </button>
            </div>
          </div>
        )}
      </>
    </DialogPopup>
  );
}

export default CancelHotelsModal;
