import React, { useState } from "react";
import styles from "./customSelect.module.scss";
import { addNewIcon, expandIcon } from "../../../constant";

export default function CustomSelect({
  onClick,
  selectList = [],
}: {
  onClick: any;
  selectList: any;
}) {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <button
        className={styles.selectBtn}
        onClick={() => setOpen(true)}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            setOpen(true);
          }
        }}
        style={{ backgroundColor: open ? "#D8EFFF" : "#ffffff" }}
      >
        <img src={addNewIcon} alt="Add new" />
        <img className={styles.expand} src={expandIcon} alt="Expand" />
      </button>
      {open && (
        <div className={styles.selectList}>
          {selectList.map((ele: any) => (
            <button
              key={ele}
              onClick={(e) => {
                setOpen(false);
                onClick(e);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  setOpen(false);
                }
              }}
              className={styles.selectItem}
              data-id={ele}
            >
              {ele}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
