import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import DialogPopup from "../../../reusableComponent/dialogPopup/dialogPopup";
import { Button, Stack } from "@mui/material";
import {
  bedFontLight,
  outlinedCircleCloseIcon,
  updatedSeeMoreIcon,
} from "../../../../constant";
import styles from "./selectRoom.module.scss";
import { toastMessage } from "../../../../helpers/toast/toastMessage";
import { getRoomDetailsAPI } from "../../../../api/hotelDetailsAPI";
import Loader from "../../../reusableComponent/loader/Loader";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import RoomDetailsModal from "../roomDetailsModal/roomDetailsModal";
import RoomWithImages from "./components/RoomWithImages";
import { useSelectRoomHook } from "./useSelectRoomHooks/useSelectRoomHooks";
import NewLoader from "../../../reusableComponent/newLoader/NewLoader";
import { GetRoomDetailsResponse } from "../../../../interface";
import { calculateNights, floorValue } from "../../../../utils";

interface iProps {
  isOpen: boolean;
  onClose: () => void;
}

function SelectRoomModal(props: iProps): JSX.Element {
  const { isOpen = false, onClose } = props;
  // const navigate = useNavigate();
  // const [showRoomDetails, setShowRoomDetails] = useState(false);
  // const [viewRoomDetailsModal, setViewRoomDetailsModal] = useState(false);
  const [showRoomDetails, setShowRoomDetails] = useState(false);
  const [roomDetailsData, setRoomDetailsData] = useState<any>(null);
  const [hotelRooms, setHotelRooms] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingHotelRooms, setIsFetchingHotelRooms] = useState(false);
  const [sessionId, setSessionId] = useState("");
  // const [offsetCount, setOffsetCount] = useState(4);
  // const [isChecking, setIsChecking] = useState(false);
  let checkingAvailabilityController: any = useRef(null);
  const { checkHotelAvailability, isChecking } = useSelectRoomHook({
    onClose,
    checkingAvailabilityController,
  });
  const state = useContext(StateContext);
  const {
    getHotelRoomsBody,
    // setSelectedHotelRoom,
    selectedHotelRoomArr,
    // setSelectedHotelRoomArr,
    // selectedHotelRoom,
    hotelData = [],
    setHotelData,
    // selectedFlightOptions,
    // setScreen,
    // isMobile,
    // selectedChatData,
  }: iGlobalContext = state;

  const { hotelId, checkIn, checkOut, city, noOfRooms } =
    getHotelRoomsBody || {};

  const numberOfNights = calculateNights(checkIn, checkOut);

  // console.log(getHotelRoomsBody, "getHotelRoomsBody");
  const { selectedHotelRoom } =
    selectedHotelRoomArr?.find(
      (eachHotel: any) =>
        eachHotel?.checkIn === checkIn &&
        eachHotel?.checkOut === checkOut &&
        eachHotel?.city === city
    ) || {};

  const fetchRoomsController: any = useRef(null);

  const fetchHotelRoomDetails = useCallback(
    async ({
      paginationCount,
      offsetCount,
      isShowMore = false,
    }: {
      paginationCount: number;
      offsetCount: number;
      isShowMore: boolean;
    }) => {
      if (!getHotelRoomsBody) return;
      // // console.log(getHotelRoomsBody, "getHotelRoomsBody");

      const fetchedHotelRooms = hotelData?.data
        ?.flatMap((cityData: any) =>
          cityData?.hotels?.map((hotel: any) => ({
            ...hotel,
            checkIn: cityData?.checkIn,
            checkOut: cityData?.checkOut,
          }))
        )
        ?.find(
          (item: any) =>
            item.hotelId === hotelId &&
            item.checkIn === checkIn &&
            item.checkOut === checkOut
        );
      // // console.log(fetchedHotelRooms, "fetchedHotelRooms");
      if (fetchedHotelRooms?.sessionId && !isShowMore)
        setSessionId(fetchedHotelRooms?.sessionId);
      if (fetchedHotelRooms?.rooms?.length > 0 && !isShowMore) {
        setHotelRooms(fetchedHotelRooms?.rooms);
        return;
      }
      // // console.log(getHotelRoomsBody, "getHotelRoomsBody");
      // const response = await fetch(`https://example.com/hotel/${hotelId}`);
      fetchRoomsController.current = new AbortController();
      if (!isShowMore) setIsFetchingHotelRooms(true);
      const response: GetRoomDetailsResponse | any = await getRoomDetailsAPI(
        {
          ...getHotelRoomsBody,
          pagination: {
            offset: offsetCount,
            count: paginationCount,
          },
        },
        fetchRoomsController.current,
        setIsLoading
      );
      // // console.log(response, "response");
      if (response?.data) {
        // // console.log(response?.data, "response data roomss");
        if (response?.data.rooms) {
          const hotelDetailsWithRooms = hotelData?.data?.map(
            (hotelsData: any) => {
              return {
                ...hotelsData,
                hotels: hotelsData?.hotels?.map((hotel: any) => {
                  if (
                    hotel?.hotelId === getHotelRoomsBody?.hotelId &&
                    hotelsData.checkIn === checkIn &&
                    hotelsData.checkOut === checkOut
                  ) {
                    if (
                      Array.isArray(hotel?.rooms) &&
                      hotel?.rooms?.length > 0
                    ) {
                      return {
                        ...hotel,
                        rooms: [...hotel?.rooms, ...response?.data.rooms],
                        sessionId: response?.data?.sessionId,
                      };
                    }
                    return {
                      ...hotel,
                      rooms: response?.data.rooms,
                      sessionId: response?.data?.sessionId,
                    };
                  }
                  return hotel;
                }),
              };
            }
          );
          setHotelData({ ...hotelData, data: hotelDetailsWithRooms });
          if (response?.data?.sessionId)
            setSessionId(response?.data?.sessionId);
          // // console.log(hotelDetailsWithRooms, hotelData, "hotel Data");
          if (!isShowMore) {
            setHotelRooms(response?.data.rooms);
            setIsFetchingHotelRooms(false);
          }
          if (isShowMore)
            setHotelRooms([...hotelRooms, ...response?.data.rooms]);
          // if (isShowMore) setOffsetCount((prevState) => prevState + 4);
          return;
        }
        toastMessage.error("OOPS!", "Unable to fetch room details");
        if (!isShowMore) onClose();
      }
      if (!response?.length) {
        toastMessage.error("OOPS!", "Fetching room information failed");
        if (!isShowMore) {
          onClose();
          setIsFetchingHotelRooms(false);
        }
        return;
      }
      if (!response) {
        toastMessage.error("OOPS!", "Unable to fetch room details");
        if (!isShowMore) {
          onClose();
          setIsFetchingHotelRooms(false);
        }
      }
    },
    [
      checkIn,
      checkOut,
      getHotelRoomsBody,
      hotelData,
      hotelId,
      hotelRooms,
      onClose,
      setHotelData,
    ]
  );

  useEffect(() => {
    setIsLoading(false);

    if (isOpen) {
      // setOffsetCount(0);
      fetchHotelRoomDetails({
        offsetCount: 0,
        paginationCount: 4,
        isShowMore: false,
      });
    }
    if (!isOpen) setHotelRooms([]);
  }, [isOpen]);

  // // console.log(getHotelRoomsBody, "getHotelRoomsBody");

  return (
    <>
      {isFetchingHotelRooms && (
        <NewLoader
          loaderIcon1={bedFontLight}
          // loaderIcon2={bedFontLight}
          text="Fetching room rates"
          isOpen={isFetchingHotelRooms}
          onClose={() => {
            if (fetchRoomsController?.current)
              fetchRoomsController?.current?.abort();
          }}
          loadFullScreen
        />
      )}
      {isChecking && (
        <NewLoader
          loaderIcon1={bedFontLight}
          // loaderIcon2={bedFontLight}
          text="Checking room availability"
          isOpen={isChecking}
          onClose={() => {
            if (checkingAvailabilityController?.current) {
              checkingAvailabilityController.current?.abort();
            }
          }}
          loadFullScreen
        />
      )}
      {!isFetchingHotelRooms && !isChecking && (
        <DialogPopup isOpen={isOpen}>
          <Stack
            sx={{
              // minHeight: "100vh",
              mt: { sm: 0, xs: 2 },
              // justifyContent: { sm: "center", md: "center", lg: "center" },
            }}
            spacing={6}
            alignItems="center"
            justifyContent="center"
          >
            <Stack
              sx={{
                width: { xs: "100vw" },
                maxWidth: { md: "450px" },
              }}
            >
              <Stack
                sx={{ width: "100%" }}
                px={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }}
              >
                <div className={styles.headerContainer}>
                  <p className={styles.header}>Select a room</p>
                  <p className={styles.roomAndNights}>
                    {floorValue(numberOfNights)}{" "}
                    {floorValue(numberOfNights) === 1 ? "night" : "nights"},{" "}
                    {noOfRooms} {Number(noOfRooms) === 1 ? "room" : "rooms"}
                  </p>
                  <img
                    style={{ width: "18.69px", cursor: "pointer" }}
                    src={outlinedCircleCloseIcon}
                    alt="cross"
                    onClick={onClose}
                  />
                </div>

                <Stack
                  sx={{
                    width: "100%",
                    // marginBottom: "-20px",
                    maxHeight: "80vh",
                    overflow: "auto",
                    bgcolor: "transparent",
                    borderRadius: "5px",
                    overscrollBehavior: "contain",
                  }}
                  // pb={3.5}
                >
                  {/* {!isLoading && ( */}
                  <Stack pl={0} pr={0} spacing={3}>
                    <div className={styles.roomDetailsContainer}>
                      {hotelRooms?.map((room: any, idx: any) => {
                        return (
                          <RoomWithImages
                            key={idx}
                            checkHotelAvailability={checkHotelAvailability}
                            isChecking={isChecking}
                            room={room}
                            selectedHotelRoom={selectedHotelRoom}
                            sessionId={sessionId}
                            setRoomDetailsData={setRoomDetailsData}
                            setShowRoomDetails={setShowRoomDetails}
                          />
                        );
                      })}
                      {Array.isArray(hotelRooms) &&
                        hotelRooms?.length === 0 &&
                        !isLoading && (
                          <>
                            <div className={styles.showCard}>
                              No Rooms found
                            </div>
                            {/* )} */}
                          </>
                        )}
                      <RoomDetailsModal
                        isOpen={showRoomDetails}
                        roomDetailsData={roomDetailsData}
                        onClose={() => {
                          setShowRoomDetails(false);
                        }}
                      />
                    </div>
                  </Stack>

                  {/* {hotelRooms.length > 0 && !isLoading && (
                    <>
                      <div className={styles.showCard}>
                        <Button
                          className={styles.showMoreBtn}
                          endIcon={
                            <img
                              src={updatedSeeMoreIcon}
                              alt="btn"
                              className={styles.showMoreIcon}
                            />
                          }
                          onClick={() =>
                            fetchHotelRoomDetails({
                              paginationCount: 4,
                              offsetCount: hotelRooms.length,
                              isShowMore: true,
                            })
                          }
                        >
                          Show More
                        </Button>
                      </div>
                    </>
                  )} */}
                  {isLoading && (
                    <Stack
                      pt={hotelRooms?.length > 0 ? 2 : 0}
                      pl={0}
                      pr={0}
                      spacing={3}
                    >
                      <div className={styles.loader}>
                        <Loader />
                      </div>
                    </Stack>
                  )}
                  {/* )} */}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </DialogPopup>
      )}
    </>
  );
}

export default SelectRoomModal;
