import { endPoints } from "../../constant/api_constants/url";
import { API_METHODS } from "../../enums";
import { fetchApi } from "../../utils/apiFunction";

export async function getItineraryPriceAPI (requestBody: any){
  return await fetchApi(
    endPoints.itineraryPrice,
    API_METHODS.POST,
    requestBody,
    null
  );
};
