export const handleFlightClass = (selectedClass: string) => {
  switch (selectedClass) {
    case "Economy":
      return "E";
    case "economy":
      return "E";
    case "premium_economy":
      return "P";
    case "premium economy":
      return "P";
    case "premium":
      return "P";
    case "Business":
      return "B";
    case "business":
      return "B";
    case "First Class":
      return "F";
    case "first":
      return "F";
    default:
      return "E";
  }
};
