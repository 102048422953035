import { useContext, useEffect } from "react";
import HotelLocationInputField from "../../../../../component/googleMapInputFields/newHotelLocationSearch/hotelLocationInputField";
import styles from "./location.module.scss";
import { StateContext } from "../../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../../context/globalContext/interface";
import DateNewPicker from "../../../../../component/booking/inputFields/newDatePicker/newDatePicker";
import CustomNewCheckBox from "../../../../../component/reusableComponent/customNewCheckBox/customNewCheckBox";
import { iHotelInputPayload } from "../../../../../types";

function LocationAndDatePicker() {
  const state = useContext(StateContext);
  const {
    bookingDetails,
    setBookingDetails,
    setSelectedHotels,
    selectedHotels,
  }: iGlobalContext = state;

  const handleUpdateDate = (
    index: number,
    key: "checkIn" | "checkOut",
    newDate: string
  ) => {
    setBookingDetails((prevDetails) => {
      const updatedHotels = [...prevDetails.hotels];
      updatedHotels[index] = { ...updatedHotels[index], [key]: newDate };
      return { ...prevDetails, hotels: updatedHotels };
    });
  };

  const handleAddressSelect = (
    index: number,
    addressDetails: {
      city: string;
      country: string;
      latitude: string;
      longitude: string;
      locality: string;
    }
  ) => {
    setBookingDetails((prev) => ({
      ...prev,
      hotels: prev.hotels.map((hotel, i) =>
        i === index
          ? {
              ...hotel,
              locality: addressDetails.locality,
              // country: addressDetails.country,
              latitude: addressDetails.latitude,
              longitude: addressDetails.longitude,
            }
          : hotel
      ),
    }));
  };

  const handleCheckboxChange = (
    checked: boolean,
    hotelData: iHotelInputPayload
  ) => {
    if (checked) {
      setSelectedHotels((prev) => [...prev, hotelData]);
    } else {
      setSelectedHotels((prev) =>
        prev.filter((item) => item.city !== hotelData.city)
      );
    }
  };

  useEffect(() => {
    setSelectedHotels(
      (prevSelected) =>
        bookingDetails?.hotels?.filter((hotel) =>
          prevSelected.some((selected) => selected.city === hotel.city)
        ) || []
    );
  }, [bookingDetails.hotels]);

  useEffect(() => {
    if (bookingDetails?.hotels?.length > 0) {
      setSelectedHotels(bookingDetails.hotels);
    }
  }, [bookingDetails.airPort]);

  return (
    <div className={styles.main}>
      {bookingDetails.hotels.map((hotel, index: number) => (
        <div className={styles.locationBox} key={index}>
          {bookingDetails.hotels.length > 1 && (
            <div className={styles.checkBox}>
              <CustomNewCheckBox
                checked={selectedHotels.some(
                  (item) => item.city === hotel.city
                )}
                onChange={(checked) => handleCheckboxChange(checked, hotel)}
              />
            </div>
          )}

          <div
            className={`${selectedHotels.some((item) => item.city === hotel.city) ? styles.locationCard : styles.disableCard}`}>
            <span className={styles.locationLabel}>
              LOCATION | <span className={styles.location}>{hotel.city}</span>
            </span>
            <div className={styles.inputField}>
              <HotelLocationInputField
                placeholder="Enter address, neighborhood or city"
                onAddressSelect={(addressDetails) => {
                  console.log("addressDetails", addressDetails);
                  handleAddressSelect(index, addressDetails);
                }}
                address={bookingDetails.hotels[index].locality ?? ""}
              />
            </div>
            <div className={styles.dateCard}>
              <DateNewPicker
                label="Check in"
                actualDate={hotel.checkIn}
                dateInput={hotel.checkIn}
                handleUpdateData={(newDate: any) =>
                  handleUpdateDate(index, "checkIn", newDate)
                }
                booking={true}
              />

              <DateNewPicker
                label="Check out"
                actualDate={hotel.checkOut}
                dateInput={hotel.checkOut}
                handleUpdateData={(newDate: any) =>
                  handleUpdateDate(index, "checkOut", newDate)
                }
                booking={true}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default LocationAndDatePicker;
