import { ReactElement } from "react";
import { toast } from "react-hot-toast";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import styles from "./toast.module.scss";
import {
  circleRightIcon,
  infoCircleWhiteIcon,
  outlinedCircleCloseIcon,
} from "../../constant";
const InfoIconComponent = (): ReactElement => (
  <InfoOutlinedIcon sx={{ color: "#FFFFFF" }} />
);

const SuccessIconComponent = (): ReactElement => (
  <CheckCircleOutlineIcon sx={{ color: "#FFFFFF", width: 30, height: 30 }} />
);

export const toastMessage = {
  // success: (message: string, detailedMessage?: string) => {
  //   toast(
  //     <div
  //       style={{
  //         position: "relative",
  //         whiteSpace: "pre-line",
  //         marginLeft: "-10px",
  //         marginRight: "-10px",
  //       }}
  //     >
  //       <div
  //         style={{
  //           fontWeight: "600",
  //           color: "#fffff",
  //           fontSize: "12px",
  //           letterSpacing: "0.3px",
  //           marginLeft: "10px",
  //         }}
  //       >
  //         {message || "SUCCESS"}
  //       </div>
  //       <div
  //         style={{
  //           fontStyle: "500",
  //           color: "#fff",
  //           fontSize: "10px",
  //           letterSpacing: "0.2px",
  //           marginLeft: "10px",
  //         }}
  //       >
  //         {detailedMessage}
  //       </div>
  //     </div>,
  //     {
  //       icon: SuccessIconComponent(),
  //       style: {
  //         background: "#5B8FC8",
  //         color: "#FFFFFF",
  //         boxShadow:
  //           "0px 0px 0px 1.5px white, -4px 4px 10px rgba(0, 0, 0, 0.3)",
  //       },
  //       duration: 2000,
  //     }
  //   );
  // },
  success: (message: string, detailedMessage?: string) => {
    const toastId = toast(
      <div className={styles.successBox}>
        <div className={styles.imgCard}>
          <img src={circleRightIcon} alt="" className={styles.icon} />
        </div>
        <div className={styles.successCard}>
          <div className={styles.message}>{message || "SUCCESS"}</div>
          <div className={styles.detailMsg}>{detailedMessage}</div>
        </div>
      </div>,
      {
        // icon: SuccessIconComponent(),
        style: {
          background: "#5B8FC8",
          color: "#FFFFFF",
          boxShadow: "0px 0px 0px 2px white, 0px 4px 4px rgba(0, 0, 0, 0.5)",
          backgroundImage:
            "linear-gradient(180deg, rgba(124, 190, 234, 1) 0%, rgba(9, 90, 139, 1) 100%)",
          border: "1.1px solid rgba(255, 255, 255, 1)",
          borderRadius: "8px",
        },
        duration: 3000,
      }
    );
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 3000);
  },
  newSuccess: (message: string, detailedMessage?: string) => {
    const toastId = toast(
      <div
        style={{
          position: "relative",
          whiteSpace: "pre-line",
          marginLeft: "-10px",
          marginRight: "-10px",
        }}
      >
        <div
          style={{
            fontWeight: "600",
            color: "#fffff",
            fontSize: "12px",
            letterSpacing: "0.3px",
            marginLeft: "10px",
          }}
        >
          {message || "SUCCESS"}
        </div>
        <div
          style={{
            fontStyle: "500",
            color: "#fff",
            fontSize: "10px",
            letterSpacing: "0.2px",
            marginLeft: "10px",
          }}
        >
          {detailedMessage}
        </div>
      </div>,
      {
        icon: SuccessIconComponent(),
        style: {
          background:
            "linear-gradient(180deg, rgba(124, 190, 234, 1) 0%, rgba(9, 90, 139, 1) 100%)",
          borderRadius: "6px",
          border: "1.1px solid rgba(255, 255, 255, 1)",
          color: "#FFFFFF",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.5)",
        },
        duration: 3000,
      }
    );
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 3000);
  },
  error: (message: string, detailedMessage?: string) => {
    toast(
      <div className={styles.errorBox}>
        <div className={styles.closeIcon}>
          <img
            src={outlinedCircleCloseIcon}
            alt=""
            className={styles.closeIcon}
            onClick={() => toast.dismiss()}
          />
        </div>
        <div className={styles.errorCard}>
          <div className={styles.imgCard}>
            <img src={infoCircleWhiteIcon} alt="" className={styles.icon} />
          </div>
          <div className={styles.errorMessage}>
            <div className={styles.message}>{message || "SUCCESS"}</div>
            <div className={styles.detailMsg}>{detailedMessage}</div>
          </div>
        </div>
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "10px",
            marginTop: "-10px",
          }}
        >
          <div style={{ paddingTop: "5px" }}>
            <InfoOutlinedIcon
              style={{
                transform: "rotate(180deg)",
                width: "26px",
                height: "26px",
              }}
            />
          </div>
          <div>
            <div
              style={{
                fontWeight: "600",
                color: "#fffff",
                fontSize: "12px",
                letterSpacing: "0.3px",
              }}
            >
              {message}
            </div>
            <div
              style={{
                fontStyle: "500",
                color: "#fff",
                fontSize: "10px",
                letterSpacing: "0.2px",
              }}
            >
              {detailedMessage}
            </div>
          </div>
        </div> */}
      </div>,
      {
        duration: 3000,
        // icon: InfoIconComponent(),
        style: {
          minWidth: "90vw !important",
          background: "#D10B00",
          color: "#FFFFFF",
          margin: "0px !important",
          border: "1.1px solid rgba(255, 255, 255, 1)",
          borderRadius: "8px",
          boxShadow: "0px 0px 0px 2px white, 0px 4px 4px rgba(0, 0, 0, 0.5)",
        },
      }
    );
  },
  loading: (message: string) => {
    toastMessage.success(message);
  },
  info: (message: string, detailedMessage?: string) => {
    toast(
      <div
        style={{
          position: "relative",
          whiteSpace: "pre-line",
          marginLeft: "-10px",
          marginRight: "-10px",
        }}
      >
        <div
          style={{
            fontWeight: "600",
            color: "#fffff",
            fontSize: "12px",
            letterSpacing: "0.3px",
            marginLeft: "10px",
          }}
        >
          {message}
        </div>
        <div
          style={{
            fontStyle: "500",
            color: "#fff",
            fontSize: "10px",
            letterSpacing: "0.2px",
            marginLeft: "10px",
          }}
        >
          {detailedMessage}
        </div>
      </div>,
      {
        icon: InfoIconComponent(),
        style: {
          background: "#5B8FC8",
          color: "#FFFFFF",
          boxShadow:
            "0px 0px 0px 1.5px white, -4px 4px 10px rgba(0, 0, 0, 0.3)",
        },
        duration: 3000,
      }
    );
  },
};
