import React, { useEffect, useState } from "react";
import styles from "./customBreadcrumbs.module.scss";
import { breadCrumbsArrowIcon } from "../../../constant";

interface iBreadList {
  id: any;
  value: string;
  checked: boolean;
  activeCrumbs: boolean;
}

export default function CustomBreadcrumbs({
  breadList,
  setBreadList,
}: {
  breadList: iBreadList[];
  setBreadList: any;
}) {
  function onclick(data: iBreadList) {
    const activeCrumbs = breadList?.find((ele: iBreadList) => ele.activeCrumbs);
    if (activeCrumbs?.id < data?.id) return;
    setBreadList((prev: iBreadList[]) =>
      prev.map((ele: iBreadList) => {
        if (data?.id === ele?.id)
          return {
            ...ele,
            checked: true,
            activeCrumbs: true,
          };
        return {
          ...ele,
          checked: false,
          activeCrumbs: false,
        };
      })
    );
    setBreadList((prev: iBreadList[]) => {
      let currentActiveBread = 0;
      for (let i = 0; i < prev?.length; i++) {
        if (prev[i].checked) currentActiveBread = i;
      }
      return prev.map((ele: iBreadList, index: number) => {
        if (index < currentActiveBread + 1)
          return {
            ...ele,
            checked: true,
          };
        return {
          ...ele,
          checked: false,
        };
      });
    });
  }

  useEffect(() => {
    console.log(breadList);
  }, [breadList]);

  return (
    <div className={styles.container}>
      {breadList?.map((ele: iBreadList, index: number) => {
        return (
          <button
            className={`${styles.btn} ${ele?.checked && styles.currentCrumb}`}
            onClick={() => onclick(ele)}
          >
            {ele.value}
            {index !== breadList?.length - 1 && (
              <img src={breadCrumbsArrowIcon} />
            )}
          </button>
        );
      })}
    </div>
  );
}
