export const HomePageNav = {
  LANDING_PAGE: "LANDING_PAGE",
  STARTING_DESTINATION: "STARTING_DESTINATION",
  PREFERENCES: "PREFERENCES",
  SELECTED_PREFERENCES: "SELECTED_PREFERENCES",
} as const;

export const DATA_STREAMING_KEY = {
  STATEMENT: {
    key: "statement",
  },
  INTENT: {
    KEY: "intent",
    RETURN_TYPE: "return_type",
    PRE_TEXT: "pretext",
    POST_TEXT: "posttext",
    DAILY_PLAN: "daily_plan",
    ITINERARY_SUMMARY: "itinerary_summary",
    TRIP_PREFERENCE: "trip_preference",
    FOLLOW_UP: "followup",
    DESTINATION: "destination",
  },
  REUTRN_TYPE: {
    DESTINATIONS: "destinations",
    ITINERARY: "itinerary",
    FOLLOW_UP: "followup",
  },
};

export const ENVIRONMENT_TYPE = {
  LOCAL: "local",
  DEV: "development",
  PRODUCTION: "production",
  STAGING: "staging",
  TEST: "test",
};

export const DialogSelected = {
  LOGIN: "LOGIN",
  CREATE_ACCOUNT: "CREATE_ACCOUNT",
  SAVE_ITINERARY: "SAVE_ITINERARY",
  SAVE_ACTIVITY: "SAVE_ACTIVITY",
  EDIT_PROMPT: "EDIT_PROMPT",
  EDIT_PREFER: "Edit_Prefer",
  EDIT_ACCOUNT: "Edit_Account",
  ADD_PREFER: "Add_Prefer",
  FORGET_PASS: "Forget_Pass",
  EDIT_ACTIVITY: "EDIT_ACTIVITY",
  Baggage_And_Fares: "Baggage_And_Fares",
} as const;

export const APP_CONFIG = {
  HOME_CONSTANT_3: "Home_section_3",
  HOME_CONSTANT_2: "Home_section_2",
  SHOW_MORE: "show_more",
  MODIFY_ITINERARY_PROMPT: "modify_itinerary_prompt",
  MODIFY_DESTINATION_LIST: "want_to_modify_destination_list",
  API_FAILURE_MESSAGE: {
    error: "api_response_bad",
  },
  NO_INTERNET_MESSAGE: {
    info: "no_internet_connection",
  },
  SAVE_ITINERARY_MESSAGE: {
    success: "toast_Itinerary_save",
  },
  LOGIN: "login",
  SIGN_UP: "Sign_up",
  API_RESPONSE_BAD: "api_response_bad",
  NO_INTERNET_CONNECTION: "no_internet_connection",
  TOAST_PROFILE_UPDATE: "toast_profile_update",
  TOAST_PROFILE_ADD: "toast_profile_add",
  TOAST_PREFERENCE_ADD: "toast_preference_add",
  TOAST_PREFERENCE_UPDATE: "toast_preference_update",
  TOAST_RESETPASSWORD_LINK: "toast_resetpassword_link",
  EXPLORE_TRIP_INFO: "explore_trip_info_text",
  ASSISTANCE_INFO_TEXT: "assistance_info_text",
} as const;

export const PROMPT = {
  SHOW_MORE: "Show 2 more cities",
  // SHOW_MORE: "Give only 2 more city or results or destination not more than 2"
} as const;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const alphaNumericRegex = new RegExp(/^[a-z0-9]+$/i);

export const phoneRegex = /^(?:\D*\d){10}\D*$/;

export const replaceNewlineWithSpaceRegex = /\n/g;

export const jsonPatternRegex = /\{.*\}/;

export const LLM_RESPONSE_ERROR = {
  jsonError: {
    error: "JSON_ERROR",
    message:
      "Sorry for the inconvenience, but it seems like we're having trouble processing your request. Please try again.",
  },
  timeOut: {
    error: "TIME_OUT",
    message:
      "Sorry for the inconvenience, but it seems like we're having trouble processing your request. Please try again.",
  },
};

export const INVALID_JSON_PROMPT = ". Please provide a vaild and parseable JSON.";

export function isAlphaNumericString(input: string) {
  return input.split("").some((item: any) => !isNaN(item));
  // &&
  // input.split("").some((item: any) => isNaN(item))
}
// /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
// /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

function daysInMonth(month: number, year: number) {
  return new Date(year, month, 0).getDate();
}

// Validate a simple date string
export function isValidDate(dateString: string) {
  // console.log(
  //   daysInMonth(
  //     Number(dateString.split("-")[1]),
  //     Number(dateString.split("-")[0])
  //   ),
  //   dateString,
  //   "isValid"
  // );
  if (
    // `${formatedDate.getFullYear()}-${`${month + 1}`?.length === 1 ? `0${month + 1}` : `${month + 1}`}-${`${date}`?.length === 1 ? `0${date}` : `${date}`}` ===
    // dateString
    daysInMonth(
      Number(dateString.split("-")[1]),
      Number(dateString.split("-")[0])
    ) >= Number(dateString.split("-")[2])
  )
    return true;
  return false;
  // return !isNaN(Date.parse(dateString));
}

export const WeatherIconCode = {
  CloudBolt: ["1087", "1273"],
  CloudBoltMoonLight: [""],
  CloudBoltSunLight: ["1063"],
  CloudDrizzleLight: ["1189", "1153", "1180", "1183", "1186", "1189"],
  CloudHailLight: ["1204", "1207", "1237"],
  CloudLight: ["1006"],
  CloudShowerHeavyLight: ["1192", "1195", "1276"],
  CloudLigths: ["1003", "1009"],
  HurricaneLight: [""],
  MoonStarsLight: [""],
  RaindropsLight: [""],
  SnowflakeLight: ["1066", "1147", "1210", "1213", "1216", "1219", "1222"],
  SunCloudLight: ["1150"],
  SunLight: ["1000"],
  TemperatureSnowLight: ["1168", "1171", "1282"],
  TemperatureSunLight: [""],
  TornadoLight: [""],
  WindLight: [""],
};

export const API_HEADER = {
  "Content-Type": "application/json;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "*",
};

export const CHAT_BOT_NAME = {
  name: "Zoya",
};
