import { useCallback, useContext } from "react";
import { StateContext } from "../context/globalContext/context";
import { iGlobalContext } from "../context/globalContext/interface";

export function useResetState() {
  const state = useContext(StateContext);
  const {
    setScreen,
    setFlightData,
    setSelectedFlightOptions,
    setHotelData,
    setShowMore,
    setAirPorts,
    setBookingDetails,
    bookingDetails,
    setHotelPagination,
  }: iGlobalContext = state;
  const resetState = useCallback(() => {
    setFlightData(null);
    setHotelData(null);
    setSelectedFlightOptions({ flights: true, hotels: true });
    setShowMore({ visibleList: { flight: 4, hotel: 4, package: 4 } });
    setAirPorts([]);
    setHotelPagination([]);
    setBookingDetails((prevData) => ({
      ...prevData,
      adultsCount: 1,
      childrenCount: 0,
      seniorsCount: 0,
      airportFrom: "",
      airportTo: "",
      travelFromDate: "",
      travelToDate: "",
      routeType: "2",
      flightClass: "E",
      cities: ["Barcelona"],
      checkIn: "",
      checkOut: "",
      numberOfRoomCount: 1,
      tripType: 2,
      update: false,
      hideFlights: false,
      hideHotels: false,
      isThisMultiCityTrip: false,
      isFormUpdated: {
        arrivalForm: false,
        departureForm: false,
      },
      isDataModified: {
        flight: false,
        hotel: false,
      },
      selectedFlightId: "",
      airlines: null,
      hotelChains: null,
      flightDayPart: "",
      numberOfStops: "",
      flightRefundable: "",
      noMoreFlights: false,
      expandedAccordions: [],
      cityInfo: {
        departure: "",
        arrival: "",
      },
      selectedLocation: {
        city: "",
        state: "",
        country: "",
        latitude: 0,
        longitude: 0,
      },
      selectedArrivalLocation: {
        city: "",
        state: "",
        country: "",
        latitude: 0,
        longitude: 0,
      },
      flightPreferences: {
        connections: 0,
        departureTime: [],
        refundable: false,
        airlines: [],
      },
      hotelPreferences: {
        refundable: false,
        hotelChains: [],
      },
      flightHistory: [
        {
          city_name: "",
          flights: [],
          number_of_days: 0,
        },
      ],
      hotels: [
        {
          checkIn: "",
          checkOut: "",
          country: "",
          city: "",
          latitude: "",
          longitude: "",
        },
      ],
      airPort: [
        {
          departureCityAddress: "",
          arrivalCityAddress: "",
          arrivalAddress: "",
          departureGoogleAddress: "",
          arrival: "",
          departure: "",
          fromCity: "",
          destinationCity: "",
          departureDate: "",
          returnDate: "",
          departureAirportFullName: "",
          arrivalAirportFullName: "",
          arrivalCityLatitude: "",
          arrivalCityLongitude: "",
          departureCityLatitude: "",
          departureCityLongitude: "",
          noOfDays: 0,
          newArrivalCitySearch: false,
          newDepartureCitySearch: false,
          arrivalCityDetails: "",
          departureCityDetails: "",
          arrivalSelectedLocation: {
            city: "",
            state: "",
            country: "",
            latitude: 0,
            longitude: 0,
          },
          departureSelectedLocation: {
            city: "",
            state: "",
            country: "",
            latitude: 0,
            longitude: 0,
          },
        },
      ],
      travelJourneyData: [
        {
          departureCityAddress: "",
          arrivalCityAddress: "",
          departureDate: "",
          returnDate: "",
          days: [],
          flightCheckIn: "",
          flightCheckOut: "",
          totalDays: 0,
          flightTime: "",
        },
      ],
      visibleList: {
        flight: 0,
        hotel: 0,
        package: 2,
      },
      filters: {
        starRating: [3],
      },
    }));
  }, [
    setScreen,
    setFlightData,
    setHotelData,
    setSelectedFlightOptions,
    setShowMore,
    setAirPorts,
    setHotelPagination,
    setBookingDetails,
    bookingDetails,
  ]);

  return resetState;
}
