// import * as React from "react";
// import {
//   Box,
//   FormControl,
//   MenuItem,
//   Select,
//   SelectChangeEvent,
// } from "@mui/material";
// import styles from "./custom.module.scss";
// import StarRateIcon from "@mui/icons-material/StarRate";
// import { expandIcon } from "../../../../constant";
// import { TOAST_MESSAGES } from "../../../../constant/messages/toastMessages";

// interface iProps {
//   options: any[];
//   value: string;
//   // onChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
//   onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
//   placeHolder?: string;
//   width: string;
//   label: string;
//   mandate: boolean;
//   error?: any;
//   isPlaceholderTextColorLight?: boolean;
//   showNone?: boolean;
//   placeholder?: string;
//   selectOptionClass?: string;
// }

// interface CustomIconProps {
//   isOpen?: boolean;
//   onClick?: () => void;
// }

// const CustomIcon: React.FC<CustomIconProps> = ({ isOpen, onClick }) => (
//   <img
//     src={expandIcon}
//     alt="icon"
//     className={`${styles.customIcon} ${isOpen ? styles.rotateIcon : ""}`}
//     onClick={onClick}
//   />
// );

// function BasicSelect({
//   options,
//   onChange,
//   value,
//   placeHolder,
//   width,
//   label,
//   mandate,
//   error,
//   isPlaceholderTextColorLight = false,
//   showNone = true,
//   placeholder,
//   selectOptionClass,
// }: iProps): JSX.Element {
//   const [isOpen, setIsOpen] = React.useState(false);
//   return (
//     <div className={styles.main}>
//       <section className={styles.selectCard}>
//         <p className={styles.label}>
//           {label}
//           {/* {mandate && <StarRateIcon className={styles.mandate} />} */}
//         </p>
//         <div
//           className={`${selectOptionClass} ${styles.selectBox}`}
//           onClick={() => setIsOpen(!isOpen)}
//         >
//           <select
//             value={value}
//             // onChange={onChange}

//             onChange={(event) => {
//               onChange(event);
//               // setIsOpen(false);
//             }}
//             // onFocus={() => setIsOpen(true)}
//             // onBlur={() => setIsOpen(false)}
//             className={styles.selectOption}
//             style={{
//               width: width,
//               border: error ? "1px solid red" : "1px solid #cfcfcf",
//               color:
//                 !value && isPlaceholderTextColorLight ? "#979797" : "#272727",
//               cursor: "pointer",
//               // padding: "18px",
//               borderRadius: "2px",
//               appearance: "none",
//             }}
//           >
//             {/* Placeholder option */}
//             {placeholder && (
//               <option
//                 value=""
//                 disabled
//                 hidden
//                 className={styles.placeHolderOption}
//               >
//                 <p className={styles.placeHolder}>{placeholder}
//                 </p>
//               </option>
//             )}
//             {options?.length > 0 ? (
//               options?.map((option, index) => (
//                 <option
//                   value={option?.label || option}
//                   key={index}
//                   className={styles.options}
//                 >
//                   <p className={styles.selectedOption}>
//                     {option?.label || option}
//                   </p>
//                 </option>
//               ))
//             ) : (
//               <option value="" disabled className={styles.options}>
//                 <p className={styles.selectedOption}>
//                   {TOAST_MESSAGES.TravelerInfoInputFiledNoData.message}
//                 </p>
//               </option>
//             )}
//           </select>
//           {/* Custom Icon */}
//           <div
//             style={{
//               position: "absolute",
//               right: "-2px",
//               top: "45%",
//               transform: "translateY(-50%)",
//               pointerEvents: "none",
//             }}
//           >
//             <CustomIcon isOpen={isOpen} />
//           </div>
//         </div>
//       </section>

//       {/* {error && <p className={styles.errorMessage}>Required</p>} */}
//     </div>
//   );
// }

// export default BasicSelect;


/** OLD UI */
import * as React from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import styles from "./custom.module.scss";
import StarRateIcon from "@mui/icons-material/StarRate";
import { expandIcon } from "../../../../constant";
import { TOAST_MESSAGES } from "../../../../constant/messages/toastMessages";

interface iProps {
  options: any[];
  value: string;
  // onChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  placeHolder?: string;
  width: string;
  label: string;
  mandate: boolean;
  error?: any;
  isPlaceholderTextColorLight?: boolean;
  showNone?: boolean;
  placeholder?: string;
  selectOptionClass?: string;
}

interface CustomIconProps {
  isOpen?: boolean;
  onClick?: () => void;
}

const CustomIcon: React.FC<CustomIconProps> = ({ isOpen, onClick }) => (
  <img
    src={expandIcon}
    alt="icon"
    className={`${styles.customIcon} ${isOpen ? styles.rotateIcon : ""}`}
    onClick={onClick}
  />
);

function BasicSelect({
  options,
  onChange,
  value,
  placeHolder,
  width,
  label,
  mandate,
  error,
  isPlaceholderTextColorLight = false,
  showNone = true,
  placeholder,
  selectOptionClass,
}: iProps): JSX.Element {
  const [isOpen, setIsOpen] = React.useState(false);
  // const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

  // const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   console.log("event", event);

  //   if (!event.target) {
  //     console.error("Error: event.target is undefined.");
  //     return;
  //   }

  //   const selectedValue = event.target.value;
  //   if (onChange) {
  //     // Ensure onChange is called only if it's defined
  //     onChange(selectedValue as any, null);
  //   }
  // };
  return (
    // <div className={styles.main}>
    //   <FormControl className={styles.formControl}>
    //     <p className={styles.inputCard}>
    //       {label} {mandate && <StarRateIcon className={styles.mandate} />}
    //     </p>
    //     <Select
    //       native={isIOS}
    //       onOpen={() => setIsOpen(true)}
    //       onClose={() => setIsOpen(false)}
    //       // onTouchStart={() => setIsOpen(true)}
    //       value={value}
    //       onChange={onChange}
    //       // IconComponent={() => <CustomIcon isOpen={isOpen} />}
    //       IconComponent={() => null}
    //       className={styles.selectOption}
    //       style={{
    //         width: `${width}`,
    //         border: error ? "1px solid red" : "none",
    //         color:
    //           !value && isPlaceholderTextColorLight ? "#979797" : "#272727",
    //       }}
    //       sx={{
    //         cursor: "pointer",
    //         "& .MuiSelect-icon": {
    //           position: "absolute",
    //           right: 8,
    //           // pointerEvents: "none",
    //         },
    //         "& .MuiSelect-select": {
    //           paddingRight: "0px !important",
    //           marginLeft: "-5px !important",
    //         },
    //       }}
    //       displayEmpty
    //       renderValue={(selected) => {
    //         return selected || placeholder || "Select";
    //       }}>
    //       {/* {value === "" ? (
    //         <MenuItem value="" disabled>
    //           {placeHolder}
    //         </MenuItem>
    //       ) : (
    //         showNone && (
    //           <MenuItem value="" className={styles.listCard}>
    //             <p className={styles.list}>None</p>
    //           </MenuItem>
    //         )
    //       )} */}
    //       {options?.map((option, index) => (
    //         <MenuItem
    //           value={option?.label || option}
    //           key={index}
    //           className={styles.listCard}>
    //           <p className={styles.list}>{option?.label || option}</p>
    //         </MenuItem>
    //       ))}
    //     </Select>
    //     <Box
    //       sx={{
    //         position: "absolute",
    //         right: 0,
    //         top: "55%",
    //         transform: "translateY(-50%)",
    //         pointerEvents: "none",
    //       }}>
    //       {<CustomIcon isOpen={isOpen} />}
    //     </Box>
    //   </FormControl>
    //   {error && <p className={styles.errorMessage}>Required</p>}
    // </div>
    <div className={styles.main}>
      <section className={styles.selectCard}>
        <p className={styles.label}>
          {label} {mandate && <StarRateIcon className={styles.mandate} />}
        </p>
        <div
          className={`${selectOptionClass} ${styles.selectBox}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <select
            value={value}
            // onChange={onChange}

            onChange={(event) => {
              onChange(event);
              // setIsOpen(false);
            }}
            // onFocus={() => setIsOpen(true)}
            // onBlur={() => setIsOpen(false)}
            className={styles.selectOption}
            style={{
              width: "100%",
              border: error ? "1px solid red" : "1px solid #979797",
              color:
                !value && isPlaceholderTextColorLight ? "#979797" : "#272727",
              cursor: "pointer",
              padding: "18px",
              borderRadius: "2px",
              appearance: "none",
            }}
          >
            {/* Placeholder option */}
            {placeholder && (
              <option
                value=""
                disabled
                hidden
                className={styles.placeHolderOption}
              >
                <p className={styles.placeHolder}>{placeholder}</p>
              </option>
            )}
            {options?.length > 0 ? (
              options?.map((option, index) => (
                <option
                  value={option?.label || option}
                  key={index}
                  className={styles.options}
                >
                  <p className={styles.selectedOption}>
                    {option?.label || option}
                  </p>
                </option>
              ))
            ) : (
              <option value="" disabled className={styles.options}>
                <p className={styles.selectedOption}>
                  {TOAST_MESSAGES.TravelerInfoInputFiledNoData.message}
                </p>
              </option>
            )}
          </select>
          {/* Custom Icon */}
          <div
            style={{
              position: "absolute",
              right: "-2px",
              top: "45%",
              transform: "translateY(-50%)",
              pointerEvents: "none",
            }}
          >
            <CustomIcon isOpen={isOpen} />
          </div>
        </div>
      </section>

      {error && <p className={styles.errorMessage}>Required</p>}
    </div>
  );
}

export default BasicSelect;