import React from "react";
import styles from "./flight.module.scss";
import { FlightInfoProps } from "../../../../types/booking/flightListing";

const FlightInfo: React.FC<FlightInfoProps> = ({
  departureTime,
  arrivalTime,
  departureDate,
  arrivalDate,
  stops,
  travelTime,
  departureAirport,
  arrivalAirport,
  flightImage,
}): JSX.Element => {
  return (
    <div className={styles.flightInfo}>
      <div className={styles.imgCard}>
        <img src={flightImage} alt="flight" className={styles.flightImg} />
      </div>
      <div className={styles.flightTime}>
        <p className={styles.timeInfo}>{departureTime}</p>
        <p className={styles.dateInfo}>{departureDate}</p>
      </div>
      <div className={styles.flightRoute}>
        {stops === 0 ? (
          <p className={styles.stops}>Non-stop</p>
        ) : (
          <p className={styles.stops}>
            {stops} stop{stops > 1 && "s"}
          </p>
        )}
        <div className={styles.line}></div>
        <div className={styles.travelDetails}>
          <p className={styles.airportCode}>{departureAirport}</p>
          <p className={styles.travelTime}>
            {`${Math.floor(parseInt(travelTime?.split(":")[0]) || 0)}h ${parseInt(travelTime?.split(":")[1]) || 0}min`}
          </p>
          <p className={styles.airportCode}>{arrivalAirport}</p>
        </div>
      </div>
      <div className={styles.testCard}>
        <p className={styles.arrivalTimeInfo}>{arrivalTime}</p>
        <p className={styles.arrivalDateInfo}>{arrivalDate} </p>
      </div>
      <div className={styles.flightTime}>
        <p className={styles.dateInfo}>
          {arrivalDate !== departureDate && (
            <span className={styles.nextDay}>+1</span>
          )}
        </p>
      </div>
    </div>
  );
};

export default FlightInfo;
