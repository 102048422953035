import React, { useContext, useEffect, useRef, useState } from "react";
// import ReactGA from "react-ga4";
import styles from "./loginDialog.module.scss";
import AppleSignin from "react-apple-signin-auth";
import {
  Stack,
  TextField,
  Button,
  useMediaQuery,
  IconButton,
  InputAdornment,
} from "@mui/material";
import updateCloseIcon from "../../../assets/updateCloseIcon.svg";
import google from "../../../assets/google.svg";
import next from "../../../assets/arrow-left-line.svg";
import { StateContext } from "../../../context/globalContext/context";

import { checkLogin } from "../../../utils/loginAndLocalStorageutils";
import {
  APP_CONFIG,
  DialogSelected,
  emailRegex,
} from "../../../constant/common";

import { getUserDetailsApi } from "../../../api/getUserDetails";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { toastMessage } from "../../../helpers/toast/toastMessage";
import { getConfigMessage } from "../../../utils";
import { appleIcon } from "../../../constant";
import { iCurrentTripContext } from "../../../context/currentTripContext/currentTripContextInterface";
import { useCurrentTripContext } from "../../../context/currentTripContext/currentTripContext";
import { ROUTES } from "../../../constant";
import { useLocation, useNavigate } from "react-router-dom";
import { ScreenTypes } from "../../../enums";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";
import toast from "react-hot-toast";
import { APP_URL, ENDPOINT } from "../../../constant/environment";
import useCustomErrorHandler from "../../../hooks/useCustomErrorHandler";

import { iHistoryContext } from "../../../context/historyContext/interface";
import { useHistoryContext } from "../../../context/historyContext/historyContext";
import jwtDecode from "jwt-decode";

import axios from "axios";

import { signInWithGooglePopup } from "../../../config/firebaseConfig";
import { SingleSignInLoginButton } from "../../reusableComponent/singleSigninLoginButton/SingleSigninLoginButton";
import { iItineraryDetailsContext } from "../../../routes/itineraryDetails/itineraryDetailsContext/itineraryDetailsInterface";
import { useItineraryContext } from "../../../routes/itineraryDetails/itineraryDetailsContext/ItineraryDetailsContext";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import TurnstileWidget from "../../reusableComponent/turnstile-widget/turnstile";

export default function LoginDialog() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Context
  const state = useContext(StateContext);
  const {
    setLoginCreateAccountState,
    loginCreateAccountState,
    setIsLoggedIn,
    signInClick,
    setSignInClick,
    setUserInfo,
    appConfig,
    setFirstScreen,
    firstScreen,
    screen,
    setIsFetchingAirports,
    bookClickBeforeLoginFlag,
    setBookClickBeforeLoginFlag,
    iosNativeLoginData,
    setIosNativeLoginData,
  }: iGlobalContext = state;
  const {
    saveItineraryBeforeBooking,
    handleNavigateToBooking,
  }: iItineraryDetailsContext = useItineraryContext();
  const loginFailureMessage =
    appConfig.find((item) => item?.key === APP_CONFIG.LOGIN)?.value ??
    "Incorrect Email or Password";
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = useState("");
  const [disableLoginBtn, setDisableLoginBtn] = useState(true);
  // const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const passwordInputRef = useRef<HTMLDivElement>(null);
  const { handleCustomError } = useCustomErrorHandler();
  const handleEmailKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!emailRegex.test(email)) {
        setEmailError("Enter a valid email address");
      } else {
        setEmailError("");
        passwordInputRef.current?.focus();
      }
    }
  };

  function handleCreateAccount() {
    triggerGAEvent(GA_LABEL_ENUMS.Create_new_account_click);
    setLoginCreateAccountState({
      ...loginCreateAccountState,
      page: DialogSelected.CREATE_ACCOUNT,
    });
  }
  function handleCancel() {
    setSignInClick(false);
    setIsFetchingAirports(false);
    setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
    // console.log(loginCreateAccountState);
  }

  async function getUserDetails(userId: string) {
    const response = await getUserDetailsApi(userId);
    if (response) {
      setUserInfo((prevInfo) => ({
        ...prevInfo,
        id: response.id,
        name: response.name,
        email: response.email,
        phone: response.phone,
        profile_pic: response.profile_pic,
        loggedIn: true,
        role: response?.role || 0,
      }));
    }

    if (response?.statusCode !== 200) {
      handleCustomError(response?.statusCode, "user.detail");
      return null;
    }

    if (!response) {
      toastMessage.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
      return;
    }
  }

  const { getHistory }: iHistoryContext = useHistoryContext();
  const { getCurrentTrip }: iCurrentTripContext = useCurrentTripContext();
  const location = useLocation();
  const { pathname } = location;
  const localIsMobile = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  async function handleLogin() {
    if (!emailRegex.test(email)) {
      setEmailError("Enter a valid email address");
      return;
    }
    if (password === "") {
      setPasswordError("Password field cannot be empty.");
      return;
    }

    // Cookies.set("name", "value");
    triggerGAEvent(GA_LABEL_ENUMS.Login_with_email_click);
    setUserInfo((prevDetails) => ({
      ...prevDetails,
      loginType: "Email",
    }));

    const response = await checkLogin(
      email.toLowerCase().trim(),
      "Email",
      "",
      password,
      setUserInfo,
      setIsLoggedIn,
      signInClick,
      setLoginCreateAccountState,
      loginCreateAccountState,
      setSignInClick,
      loginFailureMessage
    );
    if (response) {
      if (!localIsMobile) getHistory(true, true);
      await getUserDetails(response);

      if (bookClickBeforeLoginFlag) {
        // console.log("IS SAVED");
        await saveItineraryBeforeBooking(response);
        await handleNavigateToBooking();
        setBookClickBeforeLoginFlag(false);
      } else {
        const isThereCurrentTrip = await getCurrentTrip(response);
        if (isThereCurrentTrip && localIsMobile) navigate(ROUTES.Today);
        if ((isThereCurrentTrip && pathname === ROUTES.Home) || screen !== "") {
          if (firstScreen !== ScreenTypes.topDestination)
            setFirstScreen(ScreenTypes.CurrentTrip);
        }
      }
    }
  }

  const handlePasswordKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!disableLoginBtn) handleLogin();
    }
  };

  async function checkIfNoPreferences(userId: string) {
    try {
      const response = await axios.get(
        `${ENDPOINT}/app/v1/users/preferences?userId=${userId}`
      );
      const data = response?.data?.data;
      // console.log("PREVIEW RES", response);
      // const response: iGetPreviewPreferenceResponse =
      // await getPreviewPreferenceApi(userId);
      // const { statusCode, data } = response;
      if (data?.length === 0) {
        return true;
      } else return false;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
    }
  }

  async function handleGoogleLogin() {
    triggerGAEvent(GA_LABEL_ENUMS.Google_login_click);
    const nativeElement = document.getElementById(
      "iosNative"
    ) as HTMLInputElement | null;
    const isIOSNativeApp = nativeElement?.value === "true";
    if (isIOSNativeApp) {
      if (window && window.parent) {
        // console.log("Window.parent", window.parent);
        const message = {
          action: "googleLogin",
          data: {},
        };
        // console.log("MESSAGE", message);
        window.parent.postMessage(JSON.stringify(message)); // or specify a specific origin instead of "*"
        console.log(message);
      }
      return;
    }

    const response = await signInWithGooglePopup();
    // console.log("GOOGLE LOG IN", response);
    if (!response?.user?.email) {
      toastMessage.error(
        "OOPS!",
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
      return;
    }
    const email = response?.user?.email || "";
    const name = response?.user?.displayName || "";
    await loginFinalStep(name, email);
  }
  async function loginFinalStep(name: string, email: string) {
    const userId = await checkLogin(
      email.toLowerCase().trim(),
      "Google",
      name,
      "",
      setUserInfo,
      setIsLoggedIn,
      signInClick,
      setLoginCreateAccountState,
      loginCreateAccountState,
      setSignInClick,
      loginFailureMessage
    );
    if (userId) {
      getUserDetails(userId);
      const noPreferencesFlag = await checkIfNoPreferences(userId);
      // handleGetUserDetails(userId);
      if (noPreferencesFlag) {
        setLoginCreateAccountState({
          flag: true,
          page: DialogSelected.ADD_PREFER,
        });
      } else {
        setLoginCreateAccountState({
          ...loginCreateAccountState,
          flag: false,
        });
      }
      setIsLoggedIn(true);
    }

    if (bookClickBeforeLoginFlag) {
      // console.log("IS SAVED");
      await saveItineraryBeforeBooking(userId);
      await handleNavigateToBooking();
      setBookClickBeforeLoginFlag(false);
    } else {
      const isThereCurrentTrip = await getCurrentTrip(userId);
      if (isThereCurrentTrip && localIsMobile) navigate(ROUTES.Today);
      if ((isThereCurrentTrip && pathname === ROUTES.Home) || screen !== "") {
        if (firstScreen !== ScreenTypes.topDestination)
          setFirstScreen(ScreenTypes.CurrentTrip);
      }
    }
    setIosNativeLoginData({ username: "", email: "" });
  }

  useEffect(() => {
    if (iosNativeLoginData?.email)
      loginFinalStep(iosNativeLoginData?.username, iosNativeLoginData?.email);
  }, [iosNativeLoginData?.email]);

  return (
    <Stack
      zIndex={1}
      sx={{
        height: "100dvh",
        // minHeight: "100vh",
        // mt: { sm: 0, xs: 2 },
        justifyContent: { sm: "center", md: "center", lg: "center" },
      }}
      pt={2}
      spacing={0}
      alignItems="center"
      // bgcolor={"#000000"}

    >
      {/* <button
        id="iosNative"
        style={{ visibility: "hidden" }}
        onClick={() => loginFinalStep("", "")}
      ></button> */}
      <Stack
        sx={{
          width: { xs: "100%" },
        }}
        alignItems={"center"}
      >
        <Stack
          sx={{ width: "100%" }}
          px={{ xs: 2, sm: 3, md: 3, lg: 3, xl: 3 }}
          maxWidth={"427px"}
        >
          <Stack direction="row" justifyContent="space-between" px={1.2}>
            <h1 className={styles.loginHeadingText}>Log In</h1>
            <Stack alignSelf="end" mb={2}>
              <img
                onClick={handleCancel}
                style={{ width: "20px", cursor: "pointer" }}
                src={updateCloseIcon}
                alt="cross"
              />
            </Stack>
          </Stack>
          <Stack
            sx={{
              width: "100%",
              maxHeight: {
                sm: "88dvh",
                xs: "88dvh",
                // md: "calc(100% - 15%)",
                lg: "80vh",
              },
              overflow: "auto",
              bgcolor: "#f8f8f8",
              borderRadius: "8px",
            }}
          >
            <div className={styles.loginDialogText}>
              <h1>We’d love to take you further! </h1>
              <p>
                This feature requires a quick login due to our agreements with
                our trusted partners.{" "}
              </p>
              <h2>Log in to continue your journey with us!</h2>
            </div>
            <Stack padding={3}>
              <Stack
                alignItems="center"
                spacing={2}
                sx={{ pl: 2, pr: 2, pt: 2 }}
                pb={3.5}
              >
                {/* <FacebookLogin
                  appId="1477646912639104"
                  onSuccess={(response) => {
                    setUserInfo((prevDetails) => ({
                      ...prevDetails,
                      name: "",
                      email: "",
                    }));
                  }}
                  onFail={(error) => {
                    console.error("Login Failed!", error);
                  }}
                  onProfileSuccess={(response: any) => {
                    login(response.email, response.name);
                    async function login(email: string, name: string) {
                      const userId = await checkLogin(
                        email.toLowerCase().trim(),
                        "Google",
                        name,
                        "",
                        setUserInfo,
                        setIsLoggedIn,
                        signInClick,
                        setLoginCreateAccountState,
                        loginCreateAccountState,
                        setSignInClick,
                        loginFailureMessage
                      );
                      if (userId) getUserDetails(userId);
                    }
                  }}
                  style={{
                    border: "1px solid #979797",
                    borderRadius: "4px",

                    width: "100%",
                  }}
                >
                  <Stack
                    direction="row"
                    height={42}
                    borderRadius={1}
                    alignItems="center"
                    spacing={0}
                    bgcolor="#ffff"
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      triggerGAEvent(GA_LABEL_ENUMS.Facebook_login_click)
                    }
                  >
                    <img style={{ height: "42px" }} src={fb} alt={fb} />
                    <h3
                      style={{
                        width: "100%",
                        color: "#272727",
                        fontSize: "11px",
                        fontFamily: "Lato",
                        letterSpacing: "0.5px",
                        textAlign: "center",
                        alignSelf: "center",
                      }}
                    >
                      LOG IN WITH FACEBOOK
                    </h3>
                  </Stack>
                </FacebookLogin> */}

                <SingleSignInLoginButton
                  onClick={handleGoogleLogin}
                  icon={google}
                  text="LOG IN WITH GOOGLE"
                />
                <AppleSignin
                  /** Auth options passed to AppleID.auth.init() */
                  authOptions={{
                    clientId: "com.zenvoya.auth",
                    scope: "name email",
                    redirectURI: APP_URL,
                    state: "origin:web",
                    nonce: "nonce",
                    usePopup: true,
                  }}
                  onSuccess={(response: any) => {
                    triggerGAEvent(GA_LABEL_ENUMS.Apple_login_click);
                    // console.log(response, "apple sign in response");
                    const decodedUserDetails: any = jwtDecode(
                      response?.authorization?.id_token
                    );
                    if (response?.user) {
                      login(response?.user?.name?.firstName);

                      setLoginCreateAccountState({
                        ...loginCreateAccountState,
                        flag: false,
                      });
                      return;
                    }
                    if (response?.authorization) {
                      login("");
                      setLoginCreateAccountState({
                        ...loginCreateAccountState,
                        flag: false,
                      });
                      return;
                    }
                    async function login(userName: string) {
                      const userId = await checkLogin(
                        decodedUserDetails?.email.toLowerCase().trim(),
                        "Apple",
                        userName,
                        "",
                        setUserInfo,
                        setIsLoggedIn,
                        signInClick,
                        setLoginCreateAccountState,
                        loginCreateAccountState,
                        setSignInClick,
                        loginFailureMessage
                      );
                      if (userId) {
                        getUserDetails(userId);
                        if (bookClickBeforeLoginFlag) {
                          // console.log("IS SAVED");
                          await saveItineraryBeforeBooking(userId);
                          await handleNavigateToBooking();
                          setBookClickBeforeLoginFlag(false);
                        } else {
                          const isThereCurrentTrip =
                            await getCurrentTrip(userId);
                          if (isThereCurrentTrip && localIsMobile)
                            navigate(ROUTES.Today);
                          if (
                            (isThereCurrentTrip && pathname === ROUTES.Home) ||
                            screen !== ""
                          ) {
                            if (firstScreen !== ScreenTypes.topDestination)
                              setFirstScreen(ScreenTypes.CurrentTrip);
                          }
                        }
                      }
                    }
                  }}
                  /** Called upon signin error */
                  onError={(error: any) => console.error(error)}
                  /** General props */
                  uiType="dark"
                  /** className */
                  className="apple-auth-btn"
                  /** Allows to change the button's children, eg: for changing the button text */
                  // buttonExtraChildren="Continue with Apple"
                  render={(renderProps: any) => (
                    <button
                      style={{
                        border: "1px solid #979797",
                        borderRadius: "4px",
                        width: "100%",
                      }}
                      {...renderProps}
                      // onClick={renderProps.onClick}
                      // disabled
                    >
                      <Stack
                        direction="row"
                        height={42}
                        borderRadius={1}
                        alignItems="center"
                        spacing={0}
                        bgcolor=" #ffff"
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          triggerGAEvent(GA_LABEL_ENUMS.Apple_login_click)
                        }
                      >
                        <img
                          style={{ height: "42px" }}
                          src={appleIcon}
                          alt={appleIcon}
                        />
                        <h3
                          style={{
                            marginLeft: "-16px",
                            color: "#272727",
                            fontSize: "14px",
                            fontFamily: "Lato",
                            letterSpacing: "0.5px",
                            width: "100%",
                            fontWeight: "400",
                            // textAlign: "center",
                            // alignSelf: "center",
                          }}
                        >
                          LOG IN WITH APPLE
                        </h3>
                      </Stack>
                    </button>
                  )}
                />
              </Stack>

              <Stack alignSelf="center" pb={3.5}>
                <h5
                  style={{
                    fontFamily: "Lato",
                    color: "#272727",
                    fontWeight: "600",
                    fontSize: "14px",
                    letterSpacing: "0.9px",
                  }}
                >
                  OR
                </h5>
              </Stack>

              <Stack spacing={2} sx={{ width: "100%", pl: 2, pr: 2 }} pb={1}>
                <Stack
                  position="relative"
                  alignSelf="start"
                  sx={{ width: "100%" }}
                >
                  <h2
                    style={{
                      fontSize: "11px",
                      marginBottom: "6px",
                      color: "#5F6A70",
                      fontWeight: "500",
                      fontFamily: "Lato",
                      opacity: "0.9",
                    }}
                  >
                    LOG IN WITH YOUR EMAIL
                  </h2>
                  <TextField
                    type="email"
                    size="small"
                    id="user-text-field"
                    placeholder="enter your email"
                    value={email}
                    onChange={(e: any) => {
                      setEmail(e.target.value);
                      setEmailError("");
                      toast.dismiss();
                    }}
                    InputProps={{
                      style: {
                        height: "40px",
                        width: "100%",
                        fontSize: "12px",
                        backgroundColor: "white",
                        color: "#696969",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    onKeyDown={handleEmailKeyDown}
                    error={!!emailError}
                    helperText={emailError}
                    autoComplete="username"
                  />
                </Stack>
                <Stack
                  position="relative"
                  alignSelf="start"
                  sx={{ width: "100%" }}
                >
                  <h2
                    style={{
                      fontSize: "11px",
                      marginBottom: "6px",
                      color: "#5F6A70",
                      fontWeight: "500",
                      fontFamily: "lato",
                      letterSpacing: "0.1px",
                      opacity: "0.9",
                    }}
                  >
                    PASSWORD
                  </h2>
                  <TextField
                    inputRef={passwordInputRef}
                    type={showPassword ? "text" : "password"}
                    size="small"
                    placeholder="enter password"
                    value={password}
                    onChange={(e: any) => {
                      setPassword(e.target.value);
                      setPasswordError("");
                      toast.dismiss();
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword((prev) => !prev)}
                            edge="end"
                            aria-label={
                              showPassword ? "Hide password" : "Show password"
                            }
                          >
                            {showPassword ? (
                              <Visibility sx={{ height: "3vh" }} />
                            ) : (
                              <VisibilityOff sx={{ height: "3vh" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        height: "40px",
                        width: "100%",
                        fontSize: "12px",
                        backgroundColor: "white",
                        color: "#696969",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    onKeyDown={handlePasswordKeyDown}
                    error={!!passwordError}
                    helperText={passwordError}
                    autoComplete="current-password"
                  />
                </Stack>
              </Stack>
              <Stack alignSelf="center" pb={1}>
                <TurnstileWidget
                  onSuccess={() => {
                    setDisableLoginBtn(false);
                  }}
                />
              </Stack>

              <Stack spacing={2.5} sx={{ width: "100%", pl: 2, pr: 2 }}>
                <h6
                  style={{
                    fontSize: "14px",
                    color: "#8F8F8F",
                    fontFamily: "Lato",
                  }}
                >
                  Forgot your password?{" "}
                  <b
                    onClick={() => {
                      triggerGAEvent(GA_LABEL_ENUMS.Forgot_password_click);
                      setLoginCreateAccountState({
                        ...loginCreateAccountState,
                        page: DialogSelected.FORGET_PASS,
                      });
                    }}
                    style={{
                      color: "#27A9E0",
                      cursor: "pointer",
                      fontWeight: "600",
                      fontFamily: "Lato",
                    }}
                  >
                    Tap here
                  </b>{" "}
                  for help.
                </h6>
                <h6
                  style={{
                    color: "#8F8F8F",
                    fontFamily: "Lato",
                    fontSize: "14px",
                  }}
                >
                  Don't have an account?{" "}
                  <b
                    onClick={handleCreateAccount}
                    style={{ color: "#27A9E0", cursor: "pointer" }}
                  >
                    Create one now!
                  </b>
                </h6>
              </Stack>
            </Stack>

            <Button
              variant="contained"
              endIcon={<img src={next} alt="next" />}
              sx={{
                height: "80px",
                bgcolor: "#FCB400",
                border: "none",
                borderRadius: "0px",
                boxShadow: "none",
                outline: "none",
                "&:hover": {
                  bgcolor: "#FCB400",
                },
              }}
              onClick={handleLogin}
              disabled={disableLoginBtn}
            >
              <h5
                style={{
                  color: "#222222",
                  fontWeight: "600",
                  fontSize: "18px",
                  fontFamily: "lato",
                  padding: "20px 0",
                }}
              >
                LOG IN
              </h5>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
