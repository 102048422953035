import React from "react";
import styles from "./roomDetailsModal.module.scss";
import DialogPopup from "../../../reusableComponent/dialogPopup/dialogPopup";
import { Button, Stack } from "@mui/material";
import {
  areaIcon,
  backIcon,
  leftLineArrowIcon,
  outlinedCircleCloseIcon,
  personsIcon,
} from "../../../../constant";
import ImageSlider from "../imageSlider/imageSlider";
import { roomAmenitiesIcons } from "./roomDetailsConstants";
import Amenities from "../amenities/amenities";

interface iProps {
  isOpen: boolean;
  onClose: () => void;
  roomDetailsData: any;
}

function RoomDetailsModal(props: iProps): JSX.Element {
  const { isOpen = false, onClose, roomDetailsData } = props;
  // const {
  //   // roomImages = [],
  //   // roomName = "",
  //   // sleeps,
  //   // area,
  //   // amenities = [],
  // } = ROOM_DETAILS || {};
  const {
    roomImages = [],
    roomName = "",
    maxOccupancy: sleeps,
    roomDimensions,
    roomAmenities: amenities = [],
    bedInformation,
  } = roomDetailsData || {};
  // console.log(roomDetailsData, "roomDetailsData");
  const { size, unit = "m2" } = roomDimensions || {};
  return (
    <DialogPopup isOpen={isOpen}>
      <Stack
        sx={
          {
            // minHeight: "100vh",
            // mt: { sm: 0, xs: 2 },
            // justifyContent: { sm: "center", md: "center", lg: "center" },
          }
        }
        spacing={6}
        alignItems="center"
        justifyContent="center"
      >
        <Stack
          sx={{
            width: { xs: "100vw" },
            maxWidth: { md: "450px" },
          }}
        >
          <Stack
            sx={{ width: "100%" }}
            px={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }}
          >
            <Stack
              alignSelf="start"
              onClick={onClose}
              sx={{
                opacity: "0.8",
              }}
            >
              <div className={styles.closeBtnContainer}>
                <Button
                  className={`${styles.columnAlignButton}`}
                  onClick={() => {
                    onClose();
                  }}
                >
                  <img src={leftLineArrowIcon} alt="Close" />
                  <p>BACK</p>
                </Button>
              </div>
              <div className={styles.header}>Room Details</div>
              {/* <img
                style={{ width: "18.69px", cursor: "pointer" }}
                src={outlinedCircleCloseIcon}
                alt="cross"
              /> */}
            </Stack>

            <Stack
              sx={{
                width: "100%",
                // marginBottom: "-20px",
                maxHeight: "80vh",
                overflow: "auto",
                bgcolor: "#f8f8f8",
                borderRadius: "5px",
              }}
            >
              <Stack pt={0.5} pb={3.5} pl={0} pr={0} spacing={3}>
                <div className={styles.roomDetailsContainer}>
                  {roomImages.length > 0 && (
                    <ImageSlider
                      className={styles.imgSliderClass}
                      noPaddSwipeBtn
                      imagesArr={roomImages.map((img: any) => img.url)}
                    />
                  )}
                  <div className={styles.roomDetailsInfoContainer}>
                    {roomName && (
                      <div className={styles.roomName}>
                        {roomName}
                        {bedInformation?.reduce(
                          (acc: string, item: any) =>
                            acc + `, ${item.quantity} ${item.bedType}`,
                          ""
                        )}
                      </div>
                    )}
                    <div className={styles.sleepAndAreaContainer}>
                      {sleeps && (
                        <div className={styles.sleepsContainer}>
                          <img
                            src={personsIcon}
                            alt="Sleeps"
                            className={styles.sleepsIcon}
                          />
                          <span className={styles.sleepText}>
                            SLEEPS {sleeps}
                          </span>
                        </div>
                      )}
                      <div className={styles.sleepsContainer}>
                        <img
                          src={areaIcon}
                          alt="Area"
                          className={styles.sleepsIcon}
                        />
                        <span className={styles.sleepText}>
                          {size} {unit === "m2" ? "SQ METERS" : unit}
                        </span>
                      </div>
                    </div>
                    <div className={styles.amenitiesHeader}>Amenities</div>
                    {amenities && (
                      <div className={styles.amenitiesContainer}>
                        <Amenities
                          className={styles.amenitiesClass}
                          amenityItemClassName={styles.amenityItemClassName}
                          aminitiesArr={amenities}
                          showViewPropAmenities={false}
                          amenitiesIconsArr={roomAmenitiesIcons}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </DialogPopup>
  );
}

export default RoomDetailsModal;
