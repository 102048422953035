import * as React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import styles from "./customAccordion.module.scss";
import { expandIcon } from "../../../constant";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";

export default function CustomAccordion({
  onClick = "",
  className = "",
  summaryClass = "",
  headingComponent,
  bodyComponent,
  accordianDetailsClass = "",
  borderBottom = false,
  defaultExpanded = false,
  zeroMarginOnExpand = false,
  hideExpandIcon = false,
  listing = false,
  hideDropDownIcon = false,
  onChange,
}: Readonly<{
  onClick?: any;
  className?: string;
  summaryClass?: string;
  accordianDetailsClass?: string;
  headingComponent: React.ReactNode;
  bodyComponent: React.ReactNode;
  borderBottom?: boolean;
  defaultExpanded?: boolean;
  zeroMarginOnExpand?: boolean;
  hideExpandIcon?: boolean;
  listing?: boolean;
  onChange?: (event: React.SyntheticEvent, isExpanded: boolean) => void;
  hideDropDownIcon?: boolean;
}>) {
  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  }));
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      className={`${styles.mainContainer} ${className}`}
      sx={{
        "&.Mui-expanded": {
          marginTop: `${zeroMarginOnExpand && "0"}`,
        },
      }}
      onChange={onChange}
    >
      <AccordionSummary
        onClick={() => {
          triggerGAEvent(
            GA_LABEL_ENUMS.itinerary_details_flight_details_accordion_click
          );
        }}
        expandIcon={
          hideDropDownIcon ? null : (
            <img
              src={expandIcon}
              className={`${hideExpandIcon ? styles.hideExpandIcon : styles.expandIcon}`}
              alt="expand-icon"
            />
          )
        }
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${styles.summary} ${summaryClass}`}
        sx={{
          // borderBottom: borderBottom ? "1px solid #cacacc" : "none",
          // backgroundColor: borderBottom ? "#F4F4F4" : "transparent",
          backgroundColor: "#F7FCFF !important",
          border: "1px solid #7CBEEA",
          borderRadius: "5px",
          // padding: 0,
          // margin: 0,
          marginBottom: "10px",
          "& .MuiAccordionSummary-content": {
            margin: listing ? "5px 0" : "12px 0",
          },
        }}
      >
        {headingComponent}
      </AccordionSummary>
      <AccordionDetails
        className={`${styles.accordionDetails} ${accordianDetailsClass}`}
        onClick={() => {
          triggerGAEvent(GA_LABEL_ENUMS.itinerary_details_accordion_click);
        }}
      >
        {bodyComponent}
      </AccordionDetails>
    </Accordion>
  );
}
