import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { backIconWithText, ROUTES } from "../../constant";
import ButtonWithText from "../../component/reusableComponent/buttonWithText/ButtonWithText";
import CustomBreadcrumbs from "../../component/reusableComponent/customBreadcrumbs/CustomBreadcrumbs";
import FlightCrumbsDetails from "./flightCrumbsDetails/FlightCrumbsDetails";
import HotelsCrumbsDetails from "./hotelCrumbsDetails/HotelsCrumbsDetails";
import ReviewCrumbsDetails from "./reviewCrumbsDetails/ReviewCrumbsDetails";
import PaymentCrumbsDetails from "./paymentCrumbsDetails/PaymentCrumbsDetails";
import TravelersCrumbsDetails from "./travelersCrumbsDetails/TravelersCrumbsDetails";
import { BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT } from "../../constant/bookingInProgressConstant";
import InputFields from "./component/inputFields/inputFields";
import { StateContext } from "../../context/globalContext/context";
import {
  iGlobalContext,
  iSelectedHotelRoomArr,
} from "../../context/globalContext/interface";
import { useNavigate } from "react-router-dom";
import { ScreenTypes } from "../../enums";
import { getFlightTravelDetails } from "../../utils/booking/flight/getFlightTravelDetails";
import {
  BookingDetails,
  BreadCrumTypes,
  iHotelInputPayload,
} from "../../types";
import { getFlightTravelData } from "../../utils/booking/flight/getFlightTravelData";
import { updateAirportsData } from "../../utils/booking/flight/updateAirportData";
import { roundValue, updateDaywiseTripDateForMetaData } from "../../utils";
import { useResetState } from "../../utils/resetBookingData";
import DiscardChanges from "../../component/dialog/discardChanges/discardChanges";

import NewHotelInputFields from "./component/hotelNewInputFields/hotelInputFields";
import { transformHotelData } from "../../utils/booking/hotel/transformHotelData";
import { getHotelTravelDetails } from "../../utils/booking/hotel/getHotelTravelDetails";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { TOAST_MESSAGES } from "../../constant/messages/toastMessages";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import { handleRoomAllocation } from "../../utils/handleRoomAllocation";
import { arrayToString } from "../../utils/booking/hotel/arrayToString";
import { iHotelSearchResponse } from "../../interface";
import { searchHotels } from "../../api/booking/booking";
import TravelDetails from "../../component/booking/travelDetails/travelDetails";
import SearchBox from "../../component/searchBox/searchBox";
import { Chip } from "@mui/material";
import { getPreferenceCount } from "../../utils/booking/getPreferenceCount";
import CustomCheckbox from "../../component/booking/bookingCheckBox/CustomCheckbox";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
import styles from "./bookingInProgress.module.scss";
import { flightOrHotelSelect } from "../../utils/booking/breadCrumb/breadCrumbUtils";
import BookingNewCheckBox from "../../component/booking/bookingNewCheckBox/bookingCheckBox";

export default function BookingInProgress() {
  const state = useContext(StateContext);
  const {
    flightAvailabilityData,
    setScreen,
    isMobile,
    bookingDetails,
    setBookingDetails,
    flightData,
    hotelData,
    setHotelData,
    setSelectedFlightOptions,
    selectedChatData,
    setSelectedHotelRoomArr,
    selectedHotelRoomArr,
    setSelectedChatData,
    breadList,
    setBreadList,
    selectedFlightOptions,
    setHotelPagination,
    showMore,
    setShowMore,
    userInfo,
    setShowBookingPreferences,
    setIsFetchingHotels,
    showBookingPreferences,
    selectedHotels,
    airPorts,
    activeBreadCrumbs,
    setActiveBreadCrumbs,
  }: iGlobalContext = state;
  const navigate = useNavigate();
  const resetState = useResetState();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [hideDetails, setHideDetails] = useState<boolean>(false);
  const { handleCustomError } = useCustomErrorHandler();
  let fetchHotelController: any = useRef(null);
  let fetchFlightController: any = useRef(null);
  console.log("bookingDetails", bookingDetails);

  // check box select operation
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    triggerGAEvent(GA_LABEL_ENUMS.booking_flight_hotel_checkbox_click);

    const { name, checked } = event.target;

    setSelectedFlightOptions((prevState) => {
      // Ensure at least one option is always selected
      if (!checked && Object.values(prevState).filter(Boolean).length === 1) {
        return prevState;
      }

      return {
        ...prevState,
        [name]: checked,
      };
    });
  };
  useEffect(() => {
    console.log("FLIGHT NOW", flightAvailabilityData);
  }, []);
  const filteredBookingDetails = {
    adultsCount: bookingDetails.adultsCount,
    childrenCount: bookingDetails.childrenCount,
    seniorsCount: bookingDetails.seniorsCount,
    numberOfRoomCount: bookingDetails.numberOfRoomCount,
    travelFromDate: bookingDetails.travelFromDate,
    travelToDate: bookingDetails.travelToDate,
    flightClass: bookingDetails.flightClass,
    tripType: bookingDetails.tripType,
    filters: bookingDetails?.filters?.starRating?.map((starRating) => ({
      starRating: starRating,
    })),
    // Flight Preferences
    flightConnections: bookingDetails?.flightPreferences?.connections || 0,
    flightDepartureTime:
      bookingDetails?.flightPreferences?.departureTime.map(
        (departureTime) => departureTime
      ) || [],
    refundableFlights: bookingDetails?.flightPreferences?.refundable || false,
    preferredAirlines: bookingDetails?.flightPreferences?.airlines || [],

    // Hotel Preferences
    refundableHotels: bookingDetails?.hotelPreferences?.refundable || false,
    hotelChains: bookingDetails?.hotelPreferences?.hotelChains || [],
    hotels: bookingDetails.hotels.map((city) => ({
      city,
    })),
    airPort: bookingDetails.airPort.map(({ arrival, departure }) => ({
      arrival,
      departure,
    })),
  };
  const prevBookingDetailsRef = useRef(
    bookingDetails ? filteredBookingDetails : null
  );

  const [open, setOpen] = useState<boolean>(false);
  // const [activeBreadCrumbs, setActiveBreadCrumbs] = useState(breadList[0]);
  const isContinueDisabled = !flightData || !hotelData;

  useEffect(() => {
    setActiveBreadCrumbs(
      breadList?.filter((ele: BreadCrumTypes) => ele?.activeCrumbs)[0]
    );
  }, [breadList]);

  useEffect(() => {
    flightOrHotelSelect(selectedFlightOptions, setBreadList);
  }, [selectedFlightOptions]);

  function handleContinue() {
    setBreadList((prev: BreadCrumTypes[]) => {
      let currentActiveBread = 0;
      for (let i = 0; i < prev?.length; i++) {
        if (prev[i].checked) currentActiveBread = i;
      }
      return prev.map((ele: BreadCrumTypes, index: number) => {
        if (index === currentActiveBread + 1)
          return {
            ...ele,
            checked: true,
            activeCrumbs: true,
          };
        return {
          ...ele,
          checked: false,
          activeCrumbs: false,
        };
      });
    });
    setBreadList((prev: BreadCrumTypes[]) => {
      let currentActiveBread = 0;
      for (let i = 0; i < prev?.length; i++) {
        if (prev[i].checked) currentActiveBread = i;
      }
      return prev.map((ele: BreadCrumTypes, index: number) => {
        if (index < currentActiveBread + 1)
          return {
            ...ele,
            checked: true,
          };
        return {
          ...ele,
          checked: false,
        };
      });
    });
  }

  const renderSelectedBreadCrumbs = () => {
    switch (activeBreadCrumbs?.value) {
      case BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT.Flights:
        return <FlightCrumbsDetails handleContinue={handleContinue} />;
      case BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT.Hotels:
        return <HotelsCrumbsDetails handleContinue={handleContinue} />;
      case BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT.Review:
        return (
          <ReviewCrumbsDetails
            handleContinue={handleContinue}
            data={activeBreadCrumbs.data}
          />
        );
      case BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT.Travelers:
        return <TravelersCrumbsDetails handleContinue={handleContinue} />;
      case BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT.Payment:
        return (
          <PaymentCrumbsDetails
            handleContinue={handleContinue}
            data={activeBreadCrumbs?.data}
          />
        );
      default:
        return <div></div>;
    }
  };

  // map dates to itinerary
  const [isDateUpdateComplete, setIsDateUpdateComplete] =
    useState<boolean>(false);

  const handleUpdateDatesToItinerary = useCallback(
    (travelDate: string) => {
      setIsDateUpdateComplete(false);
      setSelectedChatData((prevData: any) => {
        const updatedDestinationList = updateDaywiseTripDateForMetaData(
          prevData?.destinationList,
          travelDate
        );

        // const updatedResults = updateDaywiseTripDate(
        //   prevData?.results,
        //   travelDate
        // );

        setIsDateUpdateComplete(true);
        return {
          ...prevData,
          destinationList: updatedDestinationList || prevData?.destinationList,
          // results: updatedResults || prevData?.results,
        };
      });
    },
    [setSelectedChatData]
  );

  const handleBackButton = async () => {
    setScreen(ScreenTypes.ItineraryInfo);
    if (isMobile) {
      navigate(ROUTES.ItineraryDetails);
      setScreen(ScreenTypes.ItineraryInfo);
    }
  };

  //Reset form data
  const handleResetFormDate = async () => {
    await resetState();
    setSelectedHotelRoomArr([]);
    setSelectedFlightOptions({ flights: true, hotels: false });
    handleBackButton();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    bookingDetails.update ? setOpen(true) : handleBackButton();
  };

  // flight related operations

  const mapDatesToTravelJourneyData = useCallback(
    (
      setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>
    ) => {
      try {
        const flightTravelDetailsFromItinerary = getFlightTravelDetails(
          selectedChatData?.destinationList
        );

        const data = getFlightTravelData(flightTravelDetailsFromItinerary);

        if (!Array.isArray(data) || data.length === 0) return;

        const updatedData = data
          .filter((segment, index) => {
            if (
              !segment?.totalDays ||
              typeof segment !== "object" ||
              segment.totalDays < 0
            ) {
              console.warn(`Invalid segment or totalDays at index ${index}`);
              return false;
            }
            return true;
          })
          .map((segment) => {
            const departureDate = new Date(segment.departureDate);
            const returnDate = new Date(departureDate);
            returnDate.setDate(returnDate.getDate() + segment.totalDays - 1);

            return {
              ...segment,
              departureDate: departureDate.toISOString().split("T")[0],
              returnDate: bookingDetails.isThisMultiCityTrip
                ? returnDate.toISOString().split("T")[0]
                : bookingDetails.travelToDate,
            };
          });

        setBookingDetails((prevData: any) => ({
          ...prevData,
          travelJourneyData: updatedData,
          isDataModified: {
            ...prevData.isDataModified,
            flight: false,
            hotel: false,
          },
          flightHistory: flightTravelDetailsFromItinerary,
        }));

        updateAirportsData(
          bookingDetails,
          setBookingDetails,
          bookingDetails.isThisMultiCityTrip,
          updatedData
        );
        setIsDateUpdateComplete(false);
      } catch (error) {
        console.error("Error in mapDates:", error);
        setIsDateUpdateComplete(false);
      }
    },
    [selectedChatData?.destinationList, bookingDetails]
  );

  const handleUpdateTravelData = useCallback(() => {
    mapDatesToTravelJourneyData(setBookingDetails);
  }, [mapDatesToTravelJourneyData, setBookingDetails]);

  useEffect(() => {
    if (isDateUpdateComplete) {
      handleUpdateTravelData();
    }
  }, [handleUpdateTravelData, isDateUpdateComplete]);

  const totalHotelPrice = selectedFlightOptions?.hotels
    ? selectedHotelRoomArr?.reduce(
        (acc, hotel) => acc + Number(hotel?.selectedHotelRoom?.price || 0),
        0
      )
    : 0;

  const totalFlightPrice = selectedFlightOptions?.flights
    ? flightAvailabilityData?.flightPrice ?? 0
    : 0;
  //hotel related operations
  function addOffsetData(data: any) {
    return data.map((city: any) => {
      return {
        ...city,
        offset: 0,
        hasMoreHotels: true,
      };
    });
  }

  const citiesPayload = bookingDetails.hotels.map(
    ({ cityNotAvailable, ...exceptCityNotAvailable }) => exceptCityNotAvailable
  );

  const updateDateWithHotelItinerary = getHotelTravelDetails(
    selectedChatData?.destinationList
  );
  const hotelCityPayload = transformHotelData(
    updateDateWithHotelItinerary,
    bookingDetails?.hotels?.length > 1
  );

  const updateHotelPayloadData = async () => {
    // if (bookingDetails?.isDataModified?.hotel) {
    //   return;
    // }

    const updatedHotelCityPayload = hotelCityPayload.map((hotel) => ({
      ...hotel,
      cityNotAvailable: !hotel?.city,
    }));

    setBookingDetails((prevData) => ({
      ...prevData,
      isDataModified: {
        ...prevData.isDataModified,
        hotel: true,
      },
      hotels: updatedHotelCityPayload,
    }));
  };
  const handleHotelSearch = async (
    count: number,
    cities: iHotelInputPayload[],
    isFetchingFreshly: boolean = false,
    hotelUniqueId?: string
  ) => {
    if (!cities || cities?.length === 0) {
      return toastMessage.error("", "Please select a cities to search");
    }

    setShowDetails(true);
    // const hoteRatings = bookingDetails.filters[0]
    const requestBody = {
      userId: userInfo?.id,
      pagination: {
        offset: count,
        count: 4,
        uniqueId: hotelUniqueId,
      },
      // cities: groupConsecutiveStays(modifiedData),
      cities: cities,
      currency: "USD",
      noOfRooms: bookingDetails.numberOfRoomCount,
      roomInfo: handleRoomAllocation(
        bookingDetails.adultsCount,
        bookingDetails.childrenCount,
        bookingDetails.seniorsCount,
        bookingDetails.numberOfRoomCount
      ),
      filters: {
        hotelChain:
          bookingDetails?.hotelPreferences?.hotelChains?.toString() || null,
        starRating: arrayToString(bookingDetails?.filters?.starRating),
        refundable: bookingDetails?.hotelPreferences?.refundable ?? null,
      },
    };
    prevBookingDetailsRef.current = filteredBookingDetails;
    try {
      if (isFetchingFreshly) setIsFetchingHotels(true);
      fetchHotelController.current = new AbortController();
      const response: iHotelSearchResponse = await searchHotels(
        requestBody,
        fetchHotelController.current
      );
      setIsModified(true);
      if (response.statusCode !== 200 && response?.statusCode !== 404) {
        toastMessage.error(
          TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.messageTitle,
          TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.message
        );
        handleCustomError(response?.statusCode, "hotel.search");
        return null;
      }
      if (response?.data?.pagination) {
        setBookingDetails((prevBookingDetails) => ({
          ...prevBookingDetails,
          hotelUniqueId: response?.data?.pagination.uniqueId || "",
          visibleList: {
            ...showMore.visibleList,
            hotel: response?.data?.pagination.offset,
          },
        }));
      }

      if (response?.data?.data?.length > 0) {
        const updateReceivedData = Array?.isArray(response?.data)
          ? response?.data
          : response?.data?.data;
        setHotelData((prevHotelData: any) => {
          if (!prevHotelData || !prevHotelData.data) {
            // Initialize if no previous data
            return { data: updateReceivedData };
          }

          // Append new data without replacing old data
          const updatedHotelData = [...prevHotelData.data];

          updateReceivedData.forEach((newItemHotel: any) => {
            const existingHotelIndex = updatedHotelData?.findIndex(
              (itemHotel: any) =>
                itemHotel.city === newItemHotel?.city &&
                itemHotel.checkIn === newItemHotel?.checkIn &&
                itemHotel.checkOut === newItemHotel?.checkOut
            );

            if (existingHotelIndex > -1) {
              // If the city and check-in/out match, append the hotels to the existing entry
              updatedHotelData[existingHotelIndex] = {
                ...updatedHotelData[existingHotelIndex],
                hotels: [
                  ...updatedHotelData[existingHotelIndex].hotels,
                  ...newItemHotel.hotels, // Append new hotels
                ],
              };
            } else {
              // If city/check-in/out doesn't exist, add the new entry
              updatedHotelData.push(newItemHotel);
            }
          });

          return {
            ...prevHotelData,
            data: updatedHotelData, // Return updated data with new hotels appended
          };
        });

        // const selectedHotelRoomSet =
        //   selectedHotelRoomArr?.map((item: any) => ({
        //     checkIn: item?.checkIn,
        //     checkOut: item?.checkOut,
        //     city: item?.city,
        //   })) || [];

        // const fetchedHotelArgSet =
        //   response?.data?.map((item: any) => ({
        //     checkIn: item?.checkIn,
        //     checkOut: item?.checkOut,
        //     city: item?.city,
        //   })) || [];
        // // console.log(
        //   selectedHotelRoomArr,
        //   selectedHotelRoomSet,
        //   fetchedHotelArgSet,
        //   areTwoArrObjectsEqual(selectedHotelRoomSet, fetchedHotelArgSet),
        //   "testing"
        // );

        if (
          // !areTwoArrObjectsEqual(selectedHotelRoomSet, fetchedHotelArgSet) &&
          isFetchingFreshly
          // &&          isThisAMulticityTrip
        ) {
          setSelectedHotelRoomArr(
            response?.data?.data?.map(
              (item: any): iSelectedHotelRoomArr => ({
                checkIn: item?.checkIn,
                checkOut: item?.checkOut,
                city: item?.city,
                selectedHotelRoom: null,
              })
            )
          );
          setIsFetchingHotels(false);
        }
        setShowMore((prevShowMore) => ({
          ...prevShowMore,
          visibleList: {
            ...prevShowMore?.visibleList,
            hotel: count,
          },
        }));

        // setLoadMore(false);
      } else {
        if (isFetchingFreshly) setIsFetchingHotels(false);
        console.log("No new hotels found");
        setLoadMore(false);
      }
    } catch (err) {
      if (err instanceof Error) {
        if (
          err?.name !== "AbortError" &&
          !err.message.includes("signal is aborted")
        ) {
          // toastMessage.error(
          //   TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.messageTitle,
          //   TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.message
          // );
          console.error("An api request was cancelled by user", err);
        }
      } else {
        console.error("An unexpected error occurred:", err);
      }
      if (isFetchingFreshly) setIsFetchingHotels(false);
      setHotelData(null);
      // toastMessage.error(
      //   TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.messageTitle,
      //   TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.message
      // );
      setLoadMore(false);
    } finally {
      if (isFetchingFreshly) setIsFetchingHotels(false);
      setLoading(false);
      setLoadMore(false);
    }
  };
  const filterProperties = (current: iHotelInputPayload[]) => {
    return current.map((item) => ({
      checkIn: item.checkIn,
      checkOut: item.checkOut,
      country: item.country,
      city: item.city,
      latitude: item.latitude,
      longitude: item.longitude,
    }));
  };
  const hotelSearch = (isFetchingFreshly: boolean = false) => {
    const cities = filterProperties(
      bookingDetails.isThisMultiCityTrip ? selectedHotels : citiesPayload
    );

    setHotelPagination(addOffsetData(cities));
    setLoading(true);
    setLoadMore(false);
    setTimeout(() => {
      handleHotelSearch(0, cities, isFetchingFreshly);
    }, 10);
  };

  useEffect(() => {
    if (hotelCityPayload[0]?.checkIn) {
      updateHotelPayloadData();
    }
  }, [hotelCityPayload[0]?.checkIn]);

  const handleUpdateHotelTravelData = useCallback(async () => {
    await handleUpdateDatesToItinerary(bookingDetails.travelFromDate);
    setBookingDetails((prevData) => ({
      ...prevData,
      isDataModified: {
        ...prevData.isDataModified,
        flight: false,
        hotel: false,
      },
    }));
    updateHotelPayloadData();
  }, [bookingDetails, setBookingDetails]);

  const handleSearchClick = (isFetchingFreshly: boolean = false) => {
    setHotelData(null);
    setBookingDetails((prevDetails) => {
      return {
        ...prevDetails,
        visibleList: {
          ...prevDetails.visibleList,
          hotel: 4,
        },
      };
    });
    hotelSearch(isFetchingFreshly);
  };

  useEffect(() => {
    setBookingDetails((prev: any) => ({
      ...prev,
      airPort: prev.airPort.map((eleAirport: any) => {
        const currentArrivalAirport = airPorts.find(
          (airport: any) => airport?.city === eleAirport?.arrivalAddress
        );
        const currentDepartureAirport = airPorts.find(
          (airport: any) => airport?.city === eleAirport?.departureCityAddress
        );
        const departureAirportName = airPorts.find(
          (airport: any) => airport?.city === eleAirport?.departureCityAddress
        );
        const arrivalAirportName = airPorts.find(
          (airport: any) => airport?.city === eleAirport?.arrivalAddress
        );
        if (!eleAirport?.departure && !eleAirport?.arrival)
          return {
            ...eleAirport,
            departure: currentDepartureAirport?.airports[0]?.code,
            arrival: currentArrivalAirport?.airports[0]?.code,
            departureAirportFullName:
              departureAirportName?.airports[0]?.fullName,
            arrivalAirportFullName: arrivalAirportName?.airports[0]?.fullName,
          };
        return eleAirport;
      }),
    }));
  }, [bookingDetails.airPort[0]?.arrivalAddress]);

  //Update travel Dates
  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const addDays = (date: Date, days: number): Date => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };
  const handleDateChange = (value: Date | null, days: number) => {
    if (value) {
      const startDate = value;
      const endDate = addDays(startDate, (days ?? 1) - 1);
      const formattedStartDate = formatDate(startDate);

      const formattedEndDate = formatDate(endDate);
      setBookingDetails((prevDetails) => ({
        ...prevDetails,
        travelFromDate: formattedStartDate,
        travelToDate: formattedEndDate,
        update: true,
      }));
    } else {
      console.log("No valid date selected");
    }
  };

  useEffect(() => {
    const updateStartDate = () => {
      const { number_of_days } = selectedChatData || {};
      const days = number_of_days;
      const travelFromDate = bookingDetails?.travelFromDate;
      if (!travelFromDate) {
        const today = new Date();
        const nextMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          today.getDate()
        );

        const formattedNextMonthDate = formatDate(nextMonth);
        setBookingDetails((prevDetails) => ({
          ...prevDetails,
          travelFromDate: formattedNextMonthDate,
          update: true,
        }));

        // If days exist, handle date
        if (days) {
          handleDateChange(
            new Date(`${formattedNextMonthDate}T00:00:00`),
            days
          );
        }
      } else {
        // Ensure consistent ISO format for existing travelFromDate
        const formattedDate =
          new Date(travelFromDate).toISOString().split("T")[0] + "T00:00:00";
        // setDateRangeValues(formattedDate);

        if (days) {
          handleDateChange(new Date(formattedDate), days);
        }
      }
    };
    updateStartDate();
  }, [bookingDetails?.travelFromDate]);

  useEffect(() => {
    if (!bookingDetails.travelToDate) return;

    const { flights, hotels } = selectedFlightOptions;

    if (flights && !hotels) {
      handleUpdateTravelData();
    } else if (!flights && hotels) {
      handleUpdateHotelTravelData();
    } else if (flights && hotels) {
      handleUpdateTravelData();
      handleUpdateHotelTravelData();
    }
  }, [bookingDetails.travelToDate]);

  return (
    <div>
      {/* <button
        onClick={() => {
          moveToHotel(setBreadList);
        }}
      >
        HOTEL
      </button>
      <button
        onClick={() => {
          moveToFlight(setBreadList);
        }}
      >
        FLIght
      </button> */}
      <DiscardChanges
        open={open}
        onClose={handleClose}
        handleDiscard={handleResetFormDate}
        message="Discarding changes will result in losing your modifications"
      />
      <div className={styles.backbtnContainer}>
        <ButtonWithText icon={backIconWithText} onclick={handleClickOpen} />
      </div>

      <div className={styles.headingContainer}>
        {activeBreadCrumbs?.heading && <h1>{activeBreadCrumbs?.heading}</h1>}
        {activeBreadCrumbs?.price ? (
          <p>
            ${roundValue(totalHotelPrice + totalFlightPrice).toLocaleString()}
          </p>
        ) : (
          <p className={styles.hide}>.</p>
        )}
      </div>
      {activeBreadCrumbs?.showSearchFilter && (
        <div className={styles.main}>
          <div className={styles.container}>
            {hideDetails ? (
              <TravelDetails
                handleShowDetails={() => setHideDetails(false)}
                bookingDetails={bookingDetails}
                selectedOptions={selectedFlightOptions}
                showSearchIcon={true}
              />
            ) : (
              <div className={styles.searchContainer}>
                <SearchBox
                  title="Search Criteria"
                  expand={hideDetails}
                  onClick={() => setHideDetails(!hideDetails)}
                />
                <section className={styles.headerContainer}>
                  <section className={styles.checkBox}>
                    <BookingNewCheckBox
                      label="Flights"
                      name="flights"
                      handleCheckboxChange={handleChange}
                      selectedOptions={selectedFlightOptions}
                      onClick={() => {
                        triggerGAEvent(GA_LABEL_ENUMS.booking_back_click);
                        if (bookingDetails.hideFlights)
                          toastMessage.error(
                            "You do not have flights for this itinerary"
                          );
                      }}
                      disable={bookingDetails.hideFlights}
                      labelClassName={styles.checkBoxLabel}
                      travelerForm={true}
                    />
                    <BookingNewCheckBox
                      label="Hotels"
                      name="hotels"
                      handleCheckboxChange={handleChange}
                      selectedOptions={selectedFlightOptions}
                      onClick={() => {
                        triggerGAEvent(
                          GA_LABEL_ENUMS.booking_hotel_checkbox_click
                        );
                        if (bookingDetails.hideHotels)
                          toastMessage.error(
                            "You do not have hotels for this itinerary"
                          );
                      }}
                      disable={bookingDetails.hideHotels}
                      labelClassName={styles.checkBoxLabel}
                      travelerForm={true}
                    />
                  </section>

                  <div className={styles.showDetailsBtn}>
                    <Chip
                      label={`Preferences (${getPreferenceCount(
                        bookingDetails.flightPreferences,
                        bookingDetails.hotelPreferences,
                        selectedFlightOptions
                      )})`}
                      size="medium"
                      variant="outlined"
                      sx={{
                        height: "20px",
                      }}
                      onClick={() => {
                        setShowBookingPreferences(true);
                      }}
                      className={styles.chip}
                      style={{
                        backgroundColor: showBookingPreferences
                          ? "#A4D1F0"
                          : "#ffff",
                      }}
                    />
                  </div>
                </section>
                {activeBreadCrumbs?.value ===
                  BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT.Flights && (
                  <InputFields
                    handleUpdateData={() =>
                      handleUpdateDatesToItinerary(
                        bookingDetails.travelFromDate
                      )
                    }
                    setHideDetails={setHideDetails}
                  />
                )}
                {activeBreadCrumbs?.value ===
                  BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT.Hotels && (
                  <NewHotelInputFields
                    handleUpdateTravelData={handleUpdateHotelTravelData}
                    searchHotels={() => {
                      setSelectedHotelRoomArr([]);
                      handleSearchClick(true);
                    }}
                  />
                )}
                {/* {selectedFlightOptions.flights &&
                  selectedFlightOptions.hotels && (
                    <InputFields
                      handleUpdateData={() =>
                        handleUpdateDatesToItinerary(
                          bookingDetails.travelFromDate
                        )
                      }
                      searchHotels={() => {
                        setSelectedHotelRoomArr([]);
                        handleSearchClick(true);
                      }}
                    />
                  )} */}
              </div>
            )}
          </div>
        </div>
      )}

      <div>
        {(flightData?.sectors?.length > 0 || hotelData?.data?.length > 0) && (
          <CustomBreadcrumbs
            breadList={breadList}
            setBreadList={setBreadList}
          />
        )}
        {selectedFlightOptions.flights &&
          !selectedFlightOptions.hotels &&
          !flightData && <div className={styles.emptyCard} />}
        {!selectedFlightOptions.flights &&
          selectedFlightOptions.hotels &&
          !hotelData && <div className={styles.emptyCard} />}
        {selectedFlightOptions.flights &&
          selectedFlightOptions.hotels &&
          !(flightData) && <div className={styles.emptyCard} />}
        {renderSelectedBreadCrumbs()}
      </div>
      {/* )} */}
    </div>
  );
}
