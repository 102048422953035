import { ToastMessagesType, ToastType } from "../../types";

export const Messages: ToastMessagesType = {
  // "edit.activity": {
  //   message: "",
  //   messageTitle: "",
  // },

  //flight search

  "flight.search.failure.400": {
    message:
      "Sorry, we are unable to search for flights at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "flight.search.failure.401": {
    message:
      "Sorry, we are unable to search for flights at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "flight.search.failure.402": {
    message:
      "Sorry, we are unable to search for flights at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "flight.search.failure.403": {
    message:
      "Sorry, we are unable to search for flights at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "flight.search.failure.404": {
    message:
      "Sorry, we are unable to search for flights at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "flight.search.failure.500": {
    message:
      "Sorry, we are unable to search for flights at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "flight.search.failure.501": {
    message:
      "Sorry, we are unable to search for flights at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "flight.search.failure.502": {
    message:
      "Sorry, we are unable to search for flights at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "flight.search.failure.503": {
    message:
      "Sorry, we are unable to search for flights at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "flight.search.failure.504": {
    message:
      "Sorry, our system is too busy at this time to search for flights. Please try again later.",
    messageTitle: "Gateway Timeout",
  },

  //flight availability

  "flight.availability.failure.400": {
    message:
      "Sorry, we are unable to search for flights at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "flight.availability.failure.401": {
    message:
      "Sorry, we are unable to search for flights at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "flight.availability.failure.402": {
    message:
      "Sorry, we are unable to search for flights at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "flight.availability.failure.403": {
    message:
      "Sorry, we are unable to search for flights at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "flight.availability.failure.404": {
    message:
      "Sorry, we are unable to search for flights at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "flight.availability.failure.500": {
    message:
      "Sorry, we are unable to search for flights at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "flight.availability.failure.501": {
    message:
      "Sorry, we are unable to search for flights at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "flight.availability.failure.502": {
    message:
      "Sorry, we are unable to search for flights at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "flight.availability.failure.503": {
    message:
      "Sorry, we are unable to search for flights at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "flight.availability.failure.504": {
    message:
      "Sorry, our system is too busy at this time to search for flights. Please try again later.",
    messageTitle: "Gateway Timeout",
  },

  // flight details
  "flight.detail.failure.400": {
    message:
      "Sorry, we are unable to display flight details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "flight.detail.failure.401": {
    message:
      "Sorry, we are unable to display flight details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "flight.detail.failure.402": {
    message:
      "Sorry, we are unable to display flight details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "flight.detail.failure.403": {
    message:
      "Sorry, we are unable to display flight details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "flight.detail.failure.404": {
    message:
      "Sorry, we are unable to display flight details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "flight.detail.failure.500": {
    message:
      "Sorry, we are unable to display flight details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "flight.detail.failure.501": {
    message:
      "Sorry, we are unable to display flight details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "flight.detail.failure.502": {
    message:
      "Sorry, we are unable to display flight details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "flight.detail.failure.503": {
    message:
      "Sorry, we are unable to display flight details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "flight.detail.failure.504": {
    message:
      "Sorry, our system is too busy to display flight details. Please try again later.",
    messageTitle: "Gateway Timeout",
  },
  //hotel search

  "hotel.search.failure.400": {
    message:
      "Sorry, we are unable to search for hotels at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "hotel.search.failure.401": {
    message:
      "Sorry, we are unable to search for hotels at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "hotel.search.failure.402": {
    message:
      "Sorry, we are unable to search for hotels at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "hotel.search.failure.403": {
    message:
      "Sorry, we are unable to search for hotels at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "hotel.search.failure.404": {
    message:
      "Sorry, we are unable to search for hotels at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "hotel.search.failure.500": {
    message:
      "Sorry, we are unable to search for hotels at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "hotel.search.failure.501": {
    message:
      "Sorry, we are unable to search for hotels at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "hotel.search.failure.502": {
    message:
      "Sorry, we are unable to search for hotels at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "hotel.search.failure.503": {
    message:
      "Sorry, we are unable to search for hotels at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "hotel.search.failure.504": {
    message:
      "Sorry, our system is too busy at this time to search for hotels. Please try again later.",
    messageTitle: "Gateway Timeout",
  },

  // hotel detail
  "hotel.detail.failure.400": {
    message:
      "Sorry, we are unable to display hotel details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "hotel.detail.failure.401": {
    message:
      "Sorry, we are unable to display hotel details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "hotel.detail.failure.402": {
    message:
      "Sorry, we are unable to display hotel details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "hotel.detail.failure.403": {
    message:
      "Sorry, we are unable to display hotel details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "hotel.detail.failure.404": {
    message:
      "Sorry, we are unable to display hotel details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "hotel.detail.failure.500": {
    message:
      "Sorry, we are unable to display hotel details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "hotel.detail.failure.501": {
    message:
      "Sorry, we are unable to display hotel details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "hotel.detail.failure.502": {
    message:
      "Sorry, we are unable to display hotel details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "hotel.detail.failure.503": {
    message:
      "Sorry, we are unable to display hotel details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "hotel.detail.failure.504": {
    message:
      "Sorry, our system is too busy to display hotel details. Please try again later.",
    messageTitle: "Gateway Timeout",
  },

  //user detail
  "user.detail.failure.400": {
    message:
      "Sorry, we are unable to display user details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "user.detail.failure.401": {
    message:
      "Sorry, we are unable to display user details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "user.detail.failure.402": {
    message:
      "Sorry, we are unable to display user details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "user.detail.failure.403": {
    message:
      "Sorry, we are unable to display user details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "user.detail.failure.404": {
    message:
      "Sorry, we are unable to display user details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "user.detail.failure.500": {
    message:
      "Sorry, we are unable to display user details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "user.detail.failure.501": {
    message:
      "Sorry, we are unable to display user details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "user.detail.failure.502": {
    message:
      "Sorry, we are unable to display user details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "user.detail.failure.503": {
    message:
      "Sorry, we are unable to display user details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "user.detail.failure.504": {
    message:
      "Sorry, our system is too busy to display user details. Please try again later.",
    messageTitle: "Gateway Timeout",
  },

  //update user details
  "user.update.failure.400": {
    message:
      "Sorry, we are unable to update user details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "user.update.failure.401": {
    message:
      "Sorry, we are unable to update user details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "user.update.failure.402": {
    message:
      "Sorry, we are unable to update user details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "user.update.failure.403": {
    message:
      "Sorry, we are unable to update user details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "user.update.failure.404": {
    message:
      "Sorry, we are unable to update user details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "user.update.failure.500": {
    message:
      "Sorry, we are unable to update user details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "user.update.failure.501": {
    message:
      "Sorry, we are unable to update user details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "user.update.failure.502": {
    message:
      "Sorry, we are unable to update user details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "user.update.failure.503": {
    message:
      "Sorry, we are unable to update user details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "user.update.failure.504": {
    message:
      "Sorry, our system is too busy to update user details. Please try again later.",
    messageTitle: "Gateway Timeout",
  },
  // create user

  "user.create.failure.400": {
    message:
      "Sorry, we are unable to create user at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "user.create.failure.401": {
    message:
      "Sorry, we are unable to create user at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "user.create.failure.402": {
    message:
      "Sorry, we are unable to create user at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "user.create.failure.403": {
    message:
      "Sorry, we are unable to create user at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "user.create.failure.404": {
    message:
      "Sorry, we are unable to create user at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "user.create.failure.500": {
    message:
      "Sorry, we are unable to create user at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "user.create.failure.501": {
    message:
      "Sorry, we are unable to create user at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "user.create.failure.502": {
    message:
      "Sorry, we are unable to create user at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "user.create.failure.503": {
    message:
      "Sorry, we are unable to create user at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "user.create.failure.504": {
    message:
      "Sorry, our system is too busy to create user details. Please try again later.",
    messageTitle: "Gateway Timeout",
  },

  // user.login

  "user.login.failure.400": {
    message:
      "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Bad Request",
  },
  "user.login.failure.401": {
    message:
      "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Unauthorized",
  },
  "user.login.failure.402": {
    message:
      "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Access Required!",
  },
  "user.login.failure.403": {
    message:
      "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Forbidden",
  },
  "user.login.failure.404": {
    message:
      "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Not Found!",
  },
  "user.login.failure.500": {
    message: "Sorry, we were unable to log you in. Please try again.",
    messageTitle: "Internal Server Error",
  },
  "user.login.failure.501": {
    message: "Sorry, we were unable to log you in. Please try again.",
    messageTitle: "Not Implemented",
  },
  "user.login.failure.502": {
    message: "Sorry, we were unable to log you in. Please try again.",
    messageTitle: "Bad Gateway",
  },
  "user.login.failure.503": {
    message: "Sorry, we were unable to log you in. Please try again.",
    messageTitle: "Service Unavailable",
  },
  "user.login.failure.504": {
    message:
      "Sorry, our system is too busy to log you in. Please try again later.",
    messageTitle: "Gateway Timeout",
  },

  // user.logout

  "user.logout.failure.400": {
    message: "Sorry, we couldn't log you out. Please try again.",
    messageTitle: "Bad Request",
  },
  "user.logout.failure.401": {
    message: "Sorry, we couldn't log you out. Please try again.",
    messageTitle: "Unauthorized",
  },
  "user.logout.failure.402": {
    message: "Sorry, we couldn't log you out. Please try again.",
    messageTitle: "Access Required!",
  },
  "user.logout.failure.403": {
    message: "Sorry, we couldn't log you out. Please try again.",
    messageTitle: "Forbidden",
  },
  "user.logout.failure.404": {
    message: "Sorry, we couldn't log you out. Please try again.",
    messageTitle: "Not Found!",
  },
  "user.logout.failure.500": {
    message: "Sorry, we couldn't log you out. Please try again.",
    messageTitle: "Internal Server Error",
  },
  "user.logout.failure.501": {
    message: "Sorry, we couldn't log you out. Please try again.",
    messageTitle: "Not Implemented",
  },
  "user.logout.failure.502": {
    message: "Sorry, we couldn't log you out. Please try again.",
    messageTitle: "Bad Gateway",
  },
  "user.logout.failure.503": {
    message: "Sorry, we couldn't log you out. Please try again.",
    messageTitle: "Service Unavailable",
  },
  "user.logout.failure.504": {
    message:
      "Sorry, our system is too busy to log you out. Please try again later.",
    messageTitle: "Gateway Timeout",
  },

  // reset.password

  "reset.password.failure.400": {
    message: "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Bad Request",
  },
  "reset.password.failure.401": {
    message:
      "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Unauthorized",
  },
  "reset.password.failure.402": {
    message: "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Access Required!",
  },
  "reset.password.failure.403": {
    message: "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Forbidden",
  },
  "reset.password.failure.404": {
    message: "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Not Found!",
  },
  "reset.password.failure.500": {
    message: "Sorry, we couldn't reset your password. Please try again later.",
    messageTitle: "Internal Server Error",
  },
  "reset.password.failure.501": {
    message: "Sorry, we couldn't reset your password. Please try again later.",
    messageTitle: "Not Implemented",
  },
  "reset.password.failure.502": {
    message: "Sorry, we couldn't reset your password. Please try again later.",
    messageTitle: "Bad Gateway",
  },
  "reset.password.failure.503": {
    message: "Sorry, we couldn't reset your password. Please try again later.",
    messageTitle: "Service Unavailable",
  },
  "reset.password.failure.504": {
    message: "Sorry, our system is too busy to reset your password. Please try again later.",
    messageTitle: "Gateway Timeout",
  },

  // reset.passwordlink

  "reset.passwordlink.failure.400": {
    message: "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Bad Request",
  },
  "reset.passwordlink.failure.401": {
    message:
      "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Unauthorized",
  },
  "reset.passwordlink.failure.402": {
    message: "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Access Required!",
  },
  "reset.passwordlink.failure.403": {
    message: "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Forbidden",
  },
  "reset.passwordlink.failure.404": {
    message: "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Not Found!",
  },
  "reset.passwordlink.failure.500": {
    message: "Sorry, we couldn't reset your password. Please try again later.",
    messageTitle: "Internal Server Error",
  },
  "reset.passwordlink.failure.501": {
    message: "Sorry, we couldn't reset your password. Please try again later.",
    messageTitle: "Not Implemented",
  },
  "reset.passwordlink.failure.502": {
    message: "Sorry, we couldn't reset your password. Please try again later.",
    messageTitle: "Bad Gateway",
  },
  "reset.passwordlink.failure.503": {
    message: "Sorry, we couldn't reset your password. Please try again later.",
    messageTitle: "Service Unavailable",
  },
  "reset.passwordlink.failure.504": {
    message: "Sorry, our system is too busy to reset your password. Please try again later.",
    messageTitle: "Gateway Timeout",
  },

  //user.sendpasswordlink

  "user.sendpasswordlink.failure.400": {
    message: "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Bad Request",
  },
  "user.sendpasswordlink.failure.401": {
    message:
      "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Unauthorized",
  },
  "user.sendpasswordlink.failure.402": {
    message: "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Access Required!",
  },
  "user.sendpasswordlink.failure.403": {
    message: "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Forbidden",
  },
  "user.sendpasswordlink.failure.404": {
    message: "We couldn't find your account. Please check your information and try again.",
    messageTitle: "Not Found!",
  },
  "user.sendpasswordlink.failure.500": {
    message: "Sorry, we couldn't reset your password. Please try again later.",
    messageTitle: "Internal Server Error",
  },
  "user.sendpasswordlink.failure.501": {
    message: "Sorry, we couldn't reset your password. Please try again later.",
    messageTitle: "Not Implemented",
  },
  "user.sendpasswordlink.failure.502": {
    message: "Sorry, we couldn't reset your password. Please try again later.",
    messageTitle: "Bad Gateway",
  },
  "user.sendpasswordlink.failure.503": {
    message: "Sorry, we couldn't reset your password. Please try again later.",
    messageTitle: "Service Unavailable",
  },
  "user.sendpasswordlink.failure.504": {
    message: "Sorry, our system is too busy to reset your password. Please try again later.",
    messageTitle: "Gateway Timeout",
  },
  //preference

  "preference.details.failure.400": {
    message: "Sorry, we are unable to display your preferences at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "preference.details.failure.401": {
    message:
      "Sorry, we are unable to display your preferences at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "preference.details.failure.402": {
    message: "Sorry, we are unable to display your preferences at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "preference.details.failure.403": {
    message: "Sorry, we are unable to display your preferences at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "preference.details.failure.404": {
    message: "Sorry, we are unable to display your preferences at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "preference.details.failure.500": {
    message: "Sorry, we are unable to display your preferences at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "preference.details.failure.501": {
    message: "Sorry, we are unable to display your preferences at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "preference.details.failure.502": {
    message: "Sorry, we are unable to display your preferences at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "preference.details.failure.503": {
    message: "Sorry, we are unable to display your preferences at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "preference.details.failure.504": {
    message: "Sorry, our system is too busy to view your preferences. Please try again later.",
    messageTitle: "Gateway Timeout",
  },
  // preference update
  "preference.update.failure.400": {
    message: "Sorry, we are unable to update your preferences at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "preference.update.failure.401": {
    message:
      "Sorry, we are unable to update your preferences at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "preference.update.failure.402": {
    message: "Sorry, we are unable to update your preferences at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "preference.update.failure.403": {
    message: "Sorry, we are unable to update your preferences at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "preference.update.failure.404": {
    message: "Sorry, we are unable to update your preferences at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "preference.update.failure.500": {
    message: "Sorry, we are unable to update your preferences at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "preference.update.failure.501": {
    message: "Sorry, we are unable to update your preferences at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "preference.update.failure.502": {
    message: "Sorry, we are unable to update your preferences at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "preference.update.failure.503": {
    message: "Sorry, we are unable to update your preferences at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "preference.update.failure.504": {
    message: "Sorry, our system is too busy to update your preferences. Please try again later.",
    messageTitle: "Gateway Timeout",
  },

  //airport
  "airport.search.failure.400": {
    message: "Sorry, we are unable to search for airports at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "airport.search.failure.401": {
    message:
      "Sorry, we are unable to search for airports at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "airport.search.failure.402": {
    message: "Sorry, we are unable to search for airports at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "airport.search.failure.403": {
    message: "Sorry, we are unable to search for airports at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "airport.search.failure.404": {
    message: "Sorry, we are unable to search for airports at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "airport.search.failure.500": {
    message: "Sorry, we are unable to search for airports at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "airport.search.failure.501": {
    message: "Sorry, we are unable to search for airports at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "airport.search.failure.502": {
    message: "Sorry, we are unable to search for airports at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "airport.search.failure.503": {
    message: "Sorry, we are unable to search for airports at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "airport.search.failure.504": {
    message: "Sorry, our system is too busy at this time to search for airports. Please try again later.",
    messageTitle: "Gateway Timeout",
  },

  //category
  "category.details.failure.400": {
    message: "Sorry, we are unable to access the information you're looking for. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "category.details.failure.401": {
    message:
      "Sorry, we are unable to access the information you're looking for. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "category.details.failure.402": {
    message: "Sorry, we are unable to access the information you're looking for. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "category.details.failure.403": {
    message: "Sorry, we are unable to access the information you're looking for. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "category.details.failure.404": {
    message: "Sorry, we are unable to access the information you're looking for. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "category.details.failure.500": {
    message: "Sorry, we are unable to access the information you're looking for. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "category.details.failure.501": {
    message: "Sorry, we are unable to access the information you're looking for. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "category.details.failure.502": {
    message: "Sorry, we are unable to access the information you're looking for. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "category.details.failure.503": {
    message: "Sorry, we are unable to access the information you're looking for. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "category.details.failure.504": {
    message: "Sorry, our system is too busy at this time to retrieve these details. Please try again later.",
    messageTitle: "Gateway Timeout",
  },
  // config
  "config.details.failure.400": {
    message: "Sorry, we are unable to configure your preferences at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "config.details.failure.401": {
    message:
      "Sorry, we are unable to configure your preferences at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "config.details.failure.402": {
    message: "Sorry, we are unable to configure your preferences at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "config.details.failure.403": {
    message: "Sorry, we are unable to configure your preferences at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "config.details.failure.404": {
    message: "Sorry, we are unable to configure your preferences at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "config.details.failure.500": {
    message: "Sorry, we are unable to configure your preferences at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "config.details.failure.501": {
    message: "Sorry, we are unable to configure your preferences at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "config.details.failure.502": {
    message: "Sorry, we are unable to configure your preferences at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "config.details.failure.503": {
    message: "Sorry, we are unable to configure your preferences at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "config.details.failure.504": {
    message: "Sorry, our system is too busy at this time to configure your preferences. Please try again later.",
    messageTitle: "Gateway Timeout",
  },
  //prompt
  "prompt.details.failure.400": {
    message: "Sorry, we are unable to process your query at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "prompt.details.failure.401": {
    message:
      "Sorry, we are unable to process your query at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "prompt.details.failure.402": {
    message: "Sorry, we are unable to process your query at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "prompt.details.failure.403": {
    message: "Sorry, we are unable to process your query at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "prompt.details.failure.404": {
    message: "Sorry, we are unable to process your query at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "prompt.details.failure.500": {
    message: "Sorry, we are unable to access the information you're looking for. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "prompt.details.failure.501": {
    message: "Sorry, we are unable to access the information you're looking for. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "prompt.details.failure.502": {
    message: "Sorry, we are unable to access the information you're looking for. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "prompt.details.failure.503": {
    message: "Sorry, we are unable to access the information you're looking for. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "prompt.details.failure.504": {
    message: "Sorry, our system is too busy at this time to retrieve these details. Please try again later.",
    messageTitle: "Gateway Timeout",
  },
  // dayplanning.details
  "dayplanning.details.failure.400": {
    message: "Sorry, we were unable to create your itinerary at this time. Please try again and please contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "dayplanning.details.failure.401": {
    message:
      "Sorry, we were unable to create your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "dayplanning.details.failure.402": {
    message: "Sorry, we were unable to create your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "dayplanning.details.failure.403": {
    message: "Sorry, we were unable to create your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "dayplanning.details.failure.404": {
    message: "Sorry, we were unable to create your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "dayplanning.details.failure.500": {
    message: "Sorry, we were unable to create your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "dayplanning.details.failure.501": {
    message: "Sorry, we were unable to create your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "dayplanning.details.failure.502": {
    message: "Sorry, we were unable to create your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "dayplanning.details.failure.503": {
    message: "Sorry, we were unable to create your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "dayplanning.details.failure.504": {
    message: "Sorry, our system is too busy at this time to load your itinerary. Please try again later.",
    messageTitle: "Gateway Timeout",
  },
  // delete.history
  "delete.history.failure.400": {
    message: "Sorry, we were unable to delete your history at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "delete.history.failure.401": {
    message:
      "Sorry, we were unable to delete your history at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "delete.history.failure.402": {
    message: "Sorry, we were unable to delete your history at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "delete.history.failure.403": {
    message: "Sorry, we were unable to delete your history at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "delete.history.failure.404": {
    message: "Sorry, we were unable to delete your history at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "delete.history.failure.500": {
    message: "Sorry, we were unable to delete your history at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "delete.history.failure.501": {
    message: "Sorry, we were unable to delete your history at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "delete.history.failure.502": {
    message: "Sorry, we were unable to delete your history at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "delete.history.failure.503": {
    message: "Sorry, we were unable to delete your history at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "delete.history.failure.504": {
    message: "Sorry, our system is too busy at this time to delete your history. Please try again later.",
    messageTitle: "Gateway Timeout",
  },
  //viewfrequenttrip
  "view.history.failure.400": {
    message: "Sorry, we were unable to load your history at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "view.history.failure.401": {
    message:
      "Sorry, we were unable to load your history at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "view.history.failure.402": {
    message: "Sorry, we were unable to load your history at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "view.history.failure.403": {
    message: "Sorry, we were unable to load your history at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "view.history.failure.404": {
    message: "Sorry, we were unable to load your history at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "view.history.failure.500": {
    message: "Sorry, we were unable to load your history at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "view.history.failure.501": {
    message: "Sorry, we were unable to load your history at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "view.history.failure.502": {
    message: "Sorry, we were unable to load your history at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "view.history.failure.503": {
    message: "Sorry, we were unable to load your history at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "view.history.failure.504": {
    message: "Sorry, our system is too busy at this time to load your history. Please try again later.",
    messageTitle: "Gateway Timeout",
  },
  // itinerary.details
  "itinerary.details.failure.400": {
    message: "Sorry, we were unable to load your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "itinerary.details.failure.401": {
    message:
      "Sorry, we were unable to load your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "itinerary.details.failure.402": {
    message: "Sorry, we were unable to load your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "itinerary.details.failure.403": {
    message: "Sorry, we were unable to load your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "itinerary.details.failure.404": {
    message: "Sorry, we were unable to load your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "itinerary.details.failure.500": {
    message: "Sorry, we were unable to load your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "itinerary.details.failure.501": {
    message: "Sorry, we were unable to load your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "itinerary.details.failure.502": {
    message: "Sorry, we were unable to load your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "itinerary.details.failure.503": {
    message: "Sorry, we were unable to load your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "itinerary.details.failure.504": {
    message: "Sorry, our system is too busy at this time to load your itinerary. Please try again later.",
    messageTitle: "Gateway Timeout",
  },
  //itinerary.list
  "itinerary.list.failure.400": {
    message: "Sorry, we were unable to load your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "itinerary.list.failure.401": {
    message:
      "Sorry, we were unable to load your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "itinerary.list.failure.402": {
    message: "Sorry, we were unable to load your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "itinerary.list.failure.403": {
    message: "Sorry, we were unable to load your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "itinerary.list.failure.404": {
    message: "Sorry, we were unable to load your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "itinerary.list.failure.500": {
    message: "Sorry, we were unable to load your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "itinerary.list.failure.501": {
    message: "Sorry, we were unable to load your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "itinerary.list.failure.502": {
    message: "Sorry, we were unable to load your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "itinerary.list.failure.503": {
    message: "Sorry, we were unable to load your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "itinerary.list.failure.504": {
    message: "Sorry, our system is too busy at this time to load your itinerary. Please try again later.",
    messageTitle: "Gateway Timeout",
  },
  //recommended.list
  "recommended.list.failure.400": {
    message: "Sorry, we were unable to load recommendations at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "recommended.list.failure.401": {
    message:
      "Sorry, we were unable to load recommendations at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "recommended.list.failure.402": {
    message: "Sorry, we were unable to load recommendations at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "recommended.list.failure.403": {
    message: "Sorry, we were unable to load recommendations at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "recommended.list.failure.404": {
    message: "Sorry, we were unable to load recommendations at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "recommended.list.failure.500": {
    message: "Sorry, we were unable to load recommendations at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "recommended.list.failure.501": {
    message: "Sorry, we were unable to load recommendations at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "recommended.list.failure.502": {
    message: "Sorry, we were unable to load recommendations at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "recommended.list.failure.503": {
    message: "Sorry, we were unable to load recommendations at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "recommended.list.failure.504": {
    message: "Sorry, our system is too busy at this time to load recommendations. Please try again later.",
    messageTitle: "Gateway Timeout",
  },
  //weather.details
  "weather.details.failure.400": {
    message: "Sorry, we were unable to load weather details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "weather.details.failure.401": {
    message:
      "Sorry, we were unable to load weather details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "weather.details.failure.402": {
    message: "Sorry, we were unable to load weather details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "weather.details.failure.403": {
    message: "Sorry, we were unable to load weather details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "weather.details.failure.404": {
    message: "Sorry, we were unable to load weather details at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "weather.details.failure.500": {
    message: "Sorry, we were unable to load weather details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "weather.details.failure.501": {
    message: "Sorry, we were unable to load weather details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "weather.details.failure.502": {
    message: "Sorry, we were unable to load weather details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "weather.details.failure.503": {
    message: "Sorry, we were unable to load weather details at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "weather.details.failure.504": {
    message: "Sorry, our system is too busy at this time to load weather details. Please try again later.",
    messageTitle: "Gateway Timeout",
  },
  //saveitinerary.details
  "saveitinerary.details.failure.400": {
    message: "Sorry, we were unable to save your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "saveitinerary.details.failure.401": {
    message:
      "Sorry, we were unable to save your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "saveitinerary.details.failure.402": {
    message: "Sorry, we were unable to save your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "saveitinerary.details.failure.403": {
    message: "Sorry, we were unable to save your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "saveitinerary.details.failure.404": {
    message: "Sorry, we were unable to save your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "saveitinerary.details.failure.500": {
    message: "Sorry, we were unable to save your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "saveitinerary.details.failure.501": {
    message: "Sorry, we were unable to save your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "saveitinerary.details.failure.502": {
    message: "Sorry, we were unable to save your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "saveitinerary.details.failure.503": {
    message: "Sorry, we were unable to save your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "saveitinerary.details.failure.504": {
    message: "Sorry, our system is too busy at this time to save your itinerary. Please try again later.",
    messageTitle: "Gateway Timeout",
  },

  // delete.itinerary
  "delete.itinerary.failure.400": {
    message: "Sorry, we were unable to delete your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Bad Request",
  },
  "delete.itinerary.failure.401": {
    message:
      "Sorry, we were unable to delete your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Unauthorized",
  },
  "delete.itinerary.failure.402": {
    message: "Sorry, we were unable to delete your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Access Required!",
  },
  "delete.itinerary.failure.403": {
    message: "Sorry, we were unable to delete your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Forbidden",
  },
  "delete.itinerary.failure.404": {
    message: "Sorry, we were unable to delete your itinerary at this time. Please try again and contact support if the issue persists.",
    messageTitle: "Not Found!",
  },
  "delete.itinerary.failure.500": {
    message: "Sorry, we were unable to delete your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Internal Server Error",
  },
  "delete.itinerary.failure.501": {
    message: "Sorry, we were unable to delete your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Not Implemented",
  },
  "delete.itinerary.failure.502": {
    message: "Sorry, we were unable to delete your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Bad Gateway",
  },
  "delete.itinerary.failure.503": {
    message: "Sorry, we were unable to delete your itinerary at this time. Please try again and know our team is working to fix this.",
    messageTitle: "Service Unavailable",
  },
  "delete.itinerary.failure.504": {
    message: "Sorry, our system is too busy at this time to delete your itinerary. Please try again later.",
    messageTitle: "Gateway Timeout",
  },
  ApiBreakdown: {
    message: "error",
    messageTitle: "Error",
  },
};

export const TOAST_MESSAGES = {
  ApiBreakdown: {
    type: ToastType.ERROR,
    messageTitle: "OOPS!",
    message: "Something went wrong!",
  },
  ClientSide400: {
    type: ToastType.ERROR,
    messageTitle: "Bad Request",
    message: "The request is invalid or cannot be processed.",
  },
  ClientSide401: {
    type: ToastType.ERROR,
    messageTitle: "Unauthorized",
    message:
      "You are not authorized to access this resource. Please log in and try again.",
  },
  ClientSide402: {
    type: ToastType.ERROR,
    messageTitle: "Access Required!",
    message: "Please get the access to proceed.",
  },

  ClientSide403: {
    type: ToastType.ERROR,
    messageTitle: "Forbidden!",
    message: "You do not have permission to access this resource",
  },
  ClientSide404: {
    type: ToastType.ERROR,
    messageTitle: "Not Found!",
    message: "The requested resource does not exist.",
  },
  ClientSide405: {
    type: ToastType.ERROR,
    messageTitle: "Method Not Allowed",
    message: "The request method is not supported.",
  },
  ServerSide500: {
    type: ToastType.ERROR,
    messageTitle: "Internal Server Error",
    message: "A generic server-side error occurred.",
  },
  ServerSide501: {
    type: ToastType.ERROR,
    messageTitle: "Not Implemented",
    message: "The requested method is not supported.",
  },
  ServerSide502: {
    type: ToastType.ERROR,
    messageTitle: "Bad Gateway",
    message: "The server received an invalid response from another server.",
  },

  ServerSide503: {
    type: ToastType.ERROR,
    messageTitle: "Service Unavailable",
    message: "The server is currently unavailable.",
  },
  ServerSide504: {
    type: ToastType.ERROR,
    messageTitle: "Gateway Timeout",
    message: "The server timed out waiting for a response.",
  },
  BOOKING: {
    Search: {
      FlightSearchSuccess: {
        type: ToastType.SUCCESS,
        messageTitle: "SUCCESS!",
        message: "Flight data fetched successfully",
      },
      NoFlightsFound: {
        messageTitle: "Sorry",
        message:
          "There are no flights available for selected dates and destination. Please try again by changing your dates.",
      },
      NoFlightsFoundScreenMessage: {
        messageTitle: "Sorry",
        message: "No flights found",
      },
      FlightSearchFailure: {
        type: ToastType.ERROR,
        messageTitle: "Sorry",
        message:
          "having trouble getting flight results. Please try again later!",
      },
      HotelSearchSuccess: {
        type: ToastType.SUCCESS,
        messageTitle: "Hotel data fetched successfully",
        message: "Success!",
      },
      NoHotelsFound: {
        messageTitle: "Sorry",
        message:
          "There are no hotels available for selected dates and destination. Please try again by changing your dates.",
      },
      NoHotelsFoundScreenMessage: {
        messageTitle: "Sorry",
        message: "No hotels found",
      },
      HotelSearchFailure: {
        type: ToastType.ERROR,
        messageTitle: "Sorry",
        message:
          "Having trouble getting hotel results. Please try again later!,",
      },
      FlightAvailabilitySuccess: {
        type: ToastType.SUCCESS,
        messageTitle: "SUCCESS!",
        message: "Flight available!",
      },
      FlightAvailabilityFailure: {
        type: ToastType.ERROR,
        messageTitle: "Sorry",
        message:
          "Selected flight is no longer available. Please select another flight or search  again!",
      },
      FlightDetailsFailure: {
        type: ToastType.ERROR,
        messageTitle: "Sorry",
        message:
          "Having trouble getting flight details. Please try again later!",
      },
      HotelAvailabilitySuccess: {
        type: ToastType.SUCCESS,
        messageTitle: "SUCCESS!",
        message: "Hotel room available!",
      },
      HotelAvailabilityFailure: {
        type: ToastType.ERROR,
        messageTitle: "Sorry",
        message:
          "Selected hotel room is no longer available. Please select another room or search  again! ",
      },
      HotelRoomDetailsFailure: {
        type: ToastType.ERROR,
        messageTitle: "Sorry",
        message:
          "Having trouble getting room rates for selected hotel. Please try again later!",
      },
    },
    TravelerInfo: {
      formValidationSuccess: {
        type: ToastType.SUCCESS,
        messageTitle: "SUCCESS!",
        message: "Your data has been updated successfully.",
      },
      formValidationFailure: {
        type: ToastType.ERROR,
        messageTitle: "",
        message:
          "Missing required information! Please enter missing information and try again!",
      },
      invalidDate: {
        type: ToastType.ERROR,
        messageTitle: "",
        message:
          "Invalid Date of Birth. Please enter correct Date and try again!",
      },
      invalidYear: {
        type: ToastType.ERROR,
        messageTitle: "",
        message:
          "Invalid Year for Date of Birth. Please enter correct Year and try again!",
      },
      flyerProgramError: {
        type: ToastType.ERROR,
        messageTitle: "",
        message:
          "Invalid Frequent Flyer Number. Please enter correct information and try again!",
      },
    },
    BOOK: {
      bookingSuccess: {
        type: ToastType.SUCCESS,
        messageTitle: "Booking Complete!",
        message:
          "Your booking has been completed successfully, with the confirmation number below.",
      },
      bookingHotelSuccess: {
        type: ToastType.SUCCESS,
        messageTitle: "Booking Complete!",
        message: "Your hotel booking has been completed successfully",
      },
      bookingFlightSuccess: {
        type: ToastType.SUCCESS,
        messageTitle: "Booking Complete!",
        message: "Your flight booking has been completed successfully",
      },
      bookFailure: {
        type: ToastType.ERROR,
        messageTitle: "OOPS!",
        message: "Sorry, having trouble with booking. Please try again later!",
      },
    },
  },
  PROFILE: {
    profileUpdateSuccess: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Your pofile is successfully updated!",
    },
    profileUpdateFailure: {
      type: ToastType.ERROR,
      messageTitle: "OOPS!",
      message: "Having trouble updating your profile. Please try again later!",
    },
    profileFailure: {
      type: ToastType.ERROR,
      messageTitle: "OOPS!",
      message: "Something went wrong",
    },
    profilePictureUpdateSuccess: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Your photo is updated.",
    },
    profilePictureUpdateFailure: {
      type: ToastType.ERROR,
      messageTitle: "OOPS!",
      message: "Having trouble updating your photo. Please try again later!",
    },
  },
  PREFERENCES: {
    preferencesUpdateSuccess: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Your preferences are successfully updated!",
    },
    preferencesUpdateFailure: {
      type: ToastType.ERROR,
      messageTitle: "OOPS!",
      message:
        "Having trouble updating your preferences. Please try again later!",
    },
    preferenceFailure: {
      type: ToastType.ERROR,
      messageTitle: "OOPS!",
      message: "Something went wrong",
    },
  },
  AUTHENTICATION: {
    verifyEmail: {
      type: ToastType.SUCCESS,
      messageTitle: "",
      message: "Thank you for verifying your email!",
    },

    linkExpired: {
      type: ToastType.ERROR,
      messageTitle: "",
      message: "Password reset link has expired. Try requesting a new one.",
    },

    emailExpire: {
      type: ToastType.SUCCESS,
      messageTitle: "",
      message: "This link is expired, please try with new link.",
    },
    loginSuccess: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Logged in successfully",
    },
    loginFailure: {
      type: ToastType.ERROR,
      messageTitle: "Sorry",
      message:
        "Invalid credentials! Please try again with correct credentials!",
    },
    logoutSuccess: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Logged out successfully",
    },
    logoutError: {
      type: ToastType.ERROR,
      messageTitle: "OOPS!",
      message: "Failed to logout from your account. Please try again later!",
    },
    accountCreationSuccess: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Your account is successfully created!",
    },
    accountCreationFailure: {
      type: ToastType.ERROR,
      messageTitle: "OOPS!",
      message: "Failed to create account. Please try again later!",
    },
    accountCreationInvalidName: {
      type: ToastType.ERROR,
      messageTitle: "",
      message: "Please enter your name",
    },
    accountCreationInvalidEmail: {
      type: ToastType.ERROR,
      messageTitle: "",
      message: "Please enter valid email address",
    },
    accountCreationEmailExist: {
      type: ToastType.ERROR,
      messageTitle: "",
      message: "Email id already exist",
    },
    accountCreationInvalidPassword: {
      type: ToastType.ERROR,
      messageTitle: "",
      message: "Password must be at-least 6 character",
    },
    forgetPasswordInvalidEmail: {
      type: ToastType.ERROR,
      messageTitle: "",
      message: "Please enter valid email address",
    },
    forgetPasswordEmailSentError: {
      type: ToastType.ERROR,
      messageTitle: "OOPS!",
      message: "Unable to send the password link to your email",
    },
    forgetPasswordEmailSentSuccess: {
      type: ToastType.ERROR,
      messageTitle: "SUCCESS!",
      message:
        "Reset password link sent to your email, please check your email inbox!",
    },
  },
  SaveItinerary: {
    inputFieldEnter: {
      type: ToastType.INFO,
      messageTitle: "Please",
      message: "Please type your message before hitting send!",
    },
    inputFieldLoad: {
      type: ToastType.INFO,
      messageTitle: "Please wait!",
      message:
        "Processing your previous request. Either cancel or your previous request to wait it to complete!",
    },
    saveSuccess: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Your itinerarry successfully saved!",
    },
    saveFailure: {
      type: ToastType.ERROR,
      messageTitle: "Sorry",
      message:
        "We are having trouble saving your itinerary. Please try again later!",
    },
    destinationError: {
      type: ToastType.ERROR,
      messageTitle: "Sorry",
      message: "Having trouble with parsing JSON data. Please try again later!",
    },
  },
  ShareItinerary: {
    shareSuccess: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Your itinerary has been shared!",
    },
    shareFailure: {
      type: ToastType.ERROR,
      messageTitle: "Sorry",
      message:
        "Having trouble with sharing your itinerary. Please try again later!",
    },
  },
  EditItinerary: {
    editSuccess: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Your itinerary has been updated!",
    },
    editFailure: {
      type: ToastType.ERROR,
      messageTitle: "Sorry",
      message:
        "Having trouble with updating your itinerary. Please try again later!",
    },
  },
  FAQ: {
    faqFailure: {
      type: ToastType.ERROR,
      messageTitle: "Sorry",
      message: "Having trouble with getting FAQ. Please try again later!",
    },
  },
  TravelerInfoInputFiledNoData: {
    type: ToastType.ERROR,
    messageTitle: "Sorry",
    message: "No data found",
  },
  ModifyingActivity: {
    loading: {
      type: ToastType.LOADING,
      messageTitle: "Loading",
      message: "Saving modified activity...",
    },
    editSuccessWithUserLogin: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Modified activity is saved",
    },
    editSuccessWithoutUserLogin: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Activity is modified!",
    },
    editFailure: {
      type: ToastType.ERROR,
      messageTitle: "Sorry",
      message:
        "Having trouble with updating your itinerary. Please try again later!",
    },
  },
  PostBooking: {
    flightChangeRequest: {
      type: ToastType.SUCCESS,
      messageTitle: "SUBMITTED!",
      message:
        "Your request has been submitted. We will contact you with next steps.",
    },
    flightCancelRequest: {
      type: ToastType.SUCCESS,
      messageTitle: "SUBMITTED!",
      message:
        "Your request has been submitted. We will contact you with next steps.",
    },
    hotelChangeRequest: {
      type: ToastType.SUCCESS,
      messageTitle: "SUBMITTED!",
      message:
        "Your request has been submitted. Hotel change request is in progress",
    },
    hotelChangeRequestFailure: {
      type: ToastType.ERROR,
      messageTitle: "Sorry!",
      message: "Hotel change request failed",
    },
    hotelCancelRequestSuccess: {
      type: ToastType.SUCCESS,
      messageTitle: "SUBMITTED!",
      message:
        "Your request has been submitted. Hotel concellation is in progress.",
    },
    hotelCancelRequestFailure: {
      type: ToastType.ERROR,
      messageTitle: "Sorry!",
      message: "Hotel concellation failed.",
    },
    hotelSearchDateValidationFailure: {
      type: ToastType.ERROR,
      messageTitle: "Sorry!",
      message: "CheckOut dates should be greater than CheckIn dates",
    },
  },
};
