import React, { useContext, useEffect } from "react";
import { StateContext } from "../../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../../context/globalContext/interface";
import NewSingleAirportPicker from "../../../../../component/booking/inputFields/newSingleAirportPicker/newSingleAirportPicker";
import {
  arrowRight,
  arrowRightArrowLeftRegular,
} from "../../../../../constant";
import NewDatePicker from "../../../../../component/booking/inputFields/dateNewPicker/newDatePicker";
import { AirPort } from "../../../../../types";
import styles from "./multiCity.module.scss";
import CustomNewCheckBox from "../../../../../component/reusableComponent/customNewCheckBox/customNewCheckBox";
interface MultiCityInputFieldsProps {
  className?: string;
  handleUpdateTravelData: () => void;
}

const MultiCityInputFields: React.FC<MultiCityInputFieldsProps> = ({
  className,
  handleUpdateTravelData,
}) => {
  const state = useContext(StateContext);
  const {
    bookingDetails,
    setBookingDetails,
    selectedAirports,
    setSelectedAirports,
  }: iGlobalContext = state;

  const handleCheckboxChange = (checked: boolean, airportData: AirPort) => {
    if (checked) {
      setSelectedAirports((prev) => [...prev, airportData]);
    } else {
      setSelectedAirports((prev) =>
        prev.filter(
          (item) =>
            item.departureGoogleAddress !== airportData.departureGoogleAddress
        )
      );
    }
  };

  useEffect(() => {
    setSelectedAirports(
      (prevSelected) =>
        bookingDetails?.airPort?.filter((airport) =>
          prevSelected.some(
            (selected) =>
              selected.departureGoogleAddress === airport.departureGoogleAddress
          )
        ) || []
    );
  }, [bookingDetails.airPort]);

  useEffect(() => {
    if (bookingDetails?.airPort?.length > 0) {
      setSelectedAirports(bookingDetails.airPort);
    }
  }, [bookingDetails.airPort]);

  return (
    <div className={`${className} ${styles.main}`}>
      <div className={styles.airportCard}>
        {bookingDetails?.airPort &&
          bookingDetails?.airPort?.length >= 2 &&
          bookingDetails.airPort.map((i, index) => (
            <div className={styles.content} key={index}>
              <div className={styles.checkBox}>
                <CustomNewCheckBox
                  checked={selectedAirports.some(
                    (item) =>
                      item.departureCityAddress === i.departureCityAddress
                  )}
                  onChange={(checked) => handleCheckboxChange(checked, i)}
                />
              </div>
              <div
                className={`${selectedAirports.some((item) => item.departureGoogleAddress === i.departureGoogleAddress) ? styles.contentBox : styles.disableContentBox}`}>
                <div className={styles.cityInfoCard}>
                  <p className={styles.cityInfo}>
                    {i?.departureCityAddress?.split(",")[0]}
                  </p>
                  <img src={arrowRight} alt="" className={styles.arrowIcon} />
                  <p className={styles.cityInfo}>
                    {i?.arrivalCityAddress?.split(",")[0]}
                  </p>
                </div>
                <div className={styles.newInputCard2}>
                  <div className={styles.card2}>
                    <NewSingleAirportPicker
                      indexNumber={index}
                      label="AIRPORTS"
                      selectedAirportCode={i.departure}
                      googleInputAddress={i.departureGoogleAddress}
                      setGoogleInputAddress={(newAddress) => {
                        const updatedAirports = [...bookingDetails.airPort];
                        updatedAirports[index] = {
                          ...updatedAirports[index],
                          departureGoogleAddress: newAddress,
                        };
                        setBookingDetails({
                          ...bookingDetails,
                          airPort: updatedAirports,
                        });
                      }}
                      selectedAddress={i.departureCityAddress}
                      setSelectedAddress={(newAddress) => {
                        const updatedAirports = [...bookingDetails.airPort];
                        updatedAirports[index] = {
                          ...updatedAirports[index],
                          departureCityAddress: newAddress,
                        };
                        setBookingDetails({
                          ...bookingDetails,
                          airPort: updatedAirports,
                        });
                      }}
                      airportName={i.departureAirportFullName}
                      handleUpdateData={() => {}}
                      value={i.departure}
                      handleAirportChange={(flight) => {
                        const updatedAirports = [...bookingDetails.airPort];
                        updatedAirports[index] = {
                          ...updatedAirports[index],
                          departure: flight.airportCode,
                          departureAirportFullName: flight.airportName,
                        };
                        setBookingDetails({
                          ...bookingDetails,
                          airPort: updatedAirports,
                        });
                      }}
                      clearInputValues={() => {
                        const updatedAirports = [...bookingDetails.airPort];
                        updatedAirports[index] = {
                          ...updatedAirports[index],
                          departure: "",
                          departureAirportFullName: "",
                          departureGoogleAddress: "",
                        };
                        setBookingDetails({
                          ...bookingDetails,
                          airPort: updatedAirports,
                        });
                      }}
                    />
                  </div>
                  <img
                    src={arrowRightArrowLeftRegular}
                    alt="icon"
                    className={styles.rightLeftIcon}
                  />
                  <div className={styles.card2}>
                    <NewSingleAirportPicker
                      indexNumber={index}
                      googleInputAddress={i.arrivalAddress}
                      selectedAirportCode={i.arrival}
                      setGoogleInputAddress={(newAddress) => {
                        const updatedAirports = [...bookingDetails.airPort];
                        updatedAirports[index] = {
                          ...updatedAirports[index],
                          arrivalAddress: newAddress,
                        };
                        setBookingDetails({
                          ...bookingDetails,
                          airPort: updatedAirports,
                        });
                      }}
                      selectedAddress={i.arrivalCityAddress}
                      setSelectedAddress={(newAddress) => {
                        const updatedAirports = [...bookingDetails.airPort];
                        updatedAirports[index] = {
                          ...updatedAirports[index],
                          arrivalCityAddress: newAddress,
                        };
                        setBookingDetails({
                          ...bookingDetails,
                          airPort: updatedAirports,
                        });
                      }}
                      airportName={i.arrivalAirportFullName}
                      handleUpdateData={() => {}}
                      value={i.arrival}
                      handleAirportChange={(flight) => {
                        const updatedAirports = [...bookingDetails.airPort];
                        updatedAirports[index] = {
                          ...updatedAirports[index],
                          arrival: flight.airportCode,
                          arrivalAirportFullName: flight.airportName,
                        };
                        setBookingDetails({
                          ...bookingDetails,
                          airPort: updatedAirports,
                        });
                      }}
                      clearInputValues={() => {
                        const updatedAirports = [...bookingDetails.airPort];
                        updatedAirports[index] = {
                          ...updatedAirports[index],
                          arrival: "",
                          arrivalAirportFullName: "",
                          arrivalAddress: "",
                        };
                        setBookingDetails({
                          ...bookingDetails,
                          airPort: updatedAirports,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.datePicker}>
                  <NewDatePicker
                    label="DEPARTURE"
                    dateInput={i.departureDate}
                    setDateInput={(newDate) => {
                      const updatedAirports = [...bookingDetails.airPort];
                      updatedAirports[index] = {
                        ...updatedAirports[index],
                        departureDate: newDate,
                      };
                      setBookingDetails({
                        ...bookingDetails,
                        airPort: updatedAirports,
                      });
                      // setSelectedAirports(updatedAirports);
                    }}
                    handleUpdateData={() => {}}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MultiCityInputFields;
