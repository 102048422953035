import { Airport } from "../types";

export interface CityAirports {
  city: string;
  airports: Airport[];
}

export interface iAirportResponse {
  statusCode: number;
  message: string;
  data: any;
}

export interface iPreference {
  label: string;
  value: string;
}

export interface iGetHotelRoomsArgs {
  userId: string;
  itineraryId: string;
  hotel: iPostHotel[];
}

export interface iHotelRebookArgs {
  userId: string;
  paymentDetails: iPaymentDetails;
  hotels: iRebookHotel[];
  id: string;
}

export interface iHandleHotelRebookArgs {
  userId: string;
  hotels: iRebookConfirmHotel[];
  id: string;
}

export interface iCancelHotelsArgs {
  userId: string;
  itineraryId: string;
  hotel: iCancelHotel[];
  id: string;
}

export interface iCancelHotel {
  hotelBookId: string;
}

export interface iRebookConfirmHotel {
  prebookId: string;
  sessionId: string;
}

export enum CURRENCY {
  USD = "USD",
}

export interface iRebookHotel {
  hotelBookId: string;
  uniqueId: string;
  sessionId: string;
}

export interface iPaymentDetails {
  successUrl: string;
  cancelUrl: string;
  amount: string;
  currency: string;
  remarks: string;
}

export interface iPostHotel {
  hotelBookId: string;
  checkIn: Date;
  checkOut: Date;
  noOfRooms: number;
}

export interface iHotelSummaryArgs {
  userId: string;
  itineraryID: string;
  transactionId: string[];
}

export interface iExtraServiceDetails {
  seatsPreference: iPreference[];
  mealsPreference: iPreference[];
  specialAssistance: iPreference[];
  frequentFlyerProgram: iPreference[];
}

export interface iFlightAvailabilityData {
  transactionId: string;
  international: boolean;
  extraServiceDetails: iExtraServiceDetails;
}

export interface iFlightAvailabilityResponse {
  statusCode: number;
  message: string;
  data: iFlightAvailabilityData;
}

export interface iStop {
  departureDate: string;
  layover: string;
  airport: string;
  arrivalDate: string;
}

export interface iOutboundFlight {
  stopsCount: number;
  timezone: string;
  totalTime: string;
  transactionId: string;
  flightNumber: string;
  arrivalDate: string;
  route: string;
  airlineImage: string;
  airlineCode: string;
  refundable: boolean;
  departureDate: string;
  stops: iStop[];
  airlineName: string;
  operatingAirlineCode: string;
}

export interface iSector {
  currency: string;
  outbound: iOutboundFlight[];
  price: number;
  uniqueId: string;
}

export interface iPagination {
  offset: number;
  count: number;
  uniqueId?: string;
}

export interface iFlightSearchData {
  sectors: iSector[];
  encryptedKey: string;
  pagination: iPagination;
}

export interface iFlightSearchResponse {
  statusCode: number;
  message: string;
  data: iFlightSearchData;
}

export interface iPriceDetails {
  supplierPrice: string;
  price: string;
  taxesIncluded: string;
  feesIncluded: string;
  noOfNights: number;
}

export interface iTotalPriceDetails {
  roomType: string;
  noOfRooms: number;
  noOfNights: number;
  supplierPrice: string;
  price: string;
  taxesIncluded: string;
  feesIncluded: string;
}

export interface iHotel {
  hotelId: string;
  name: string;
  address: string;
  latitude: number;
  longitude: number;
  country: string;
  city: string;
  zip: string;
  hotelMainImage: string;
  stars: number;
  rating: number;
  reviewCount: number;
  reviewHeading: string;
  amenities: string[];
  refundable: string;
  pricePerRoom: iPriceDetails;
  totalPrice: iTotalPriceDetails;
  currency: string;
}

export interface iCityData {
  city: string;
  checkIn: string;
  checkOut: string;
  hotels: iHotel[];
}

export interface iHotelSearchData {
  status: string;
  pagination: iPagination;
  data: iCityData[];
}

export interface iBookingAirportLoaderTypes {
  arrivalLoader: boolean;
  departureLoader: boolean;
}

export interface iHotelSearchResponse {
  statusCode: number;
  message: string;
  data: iHotelSearchData;
}

export interface BookHotelAndFlightResponse {
  statusCode: number;
  message: string;
}
