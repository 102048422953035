import { PostBookingTypes } from "../../types";

export interface CityInfo {
  name: string;
  latitude: string;
  longitude: string;
  countryCode: string;
}

export const ModifyPostBookingAirportPayload = (
  data: PostBookingTypes[]
): CityInfo[][] => {
  if (!Array.isArray(data)) {
    console.error("Input data must be an array.");
    return [];
  }
  return data?.map((flight) => [
    {
      name: `${flight?.departureCityDetails}, ${flight?.departureCountryName}`,
      latitude: flight?.departureCityLatitude,
      longitude: flight?.departureCityLongitude,
      countryCode: flight?.departureCountryCode,
    },
    {
      name: `${flight?.arrivalCityDetails}, ${flight?.arrivalCountryName}`,
      latitude: flight?.arrivalCityLatitude,
      longitude: flight?.arrivalCityLongitude,
      countryCode: flight?.arrivalCountryCode,
    },
  ]);
};
