import React, { ReactNode } from "react";
import { StateProvider } from "./globalContext/context";
import { TopDestinationContextProvider } from "./topDestinationContext/TopDestinationContext";
import { RecommendedTripContextProvider } from "./recommendedTripContext/recommendedTripContext";
import { HistoryContextProvider } from "./historyContext/historyContext";
import { CurrentTripContextProvider } from "./currentTripContext/currentTripContext";
import { ItineraryContextProvider } from "../routes/itineraryDetails/itineraryDetailsContext/ItineraryDetailsContext";
import { DataStreamingContextProvider } from "./dataStreamingContext/DataStreamingContext";

export default function ContextProvider({ children }: { children: ReactNode }) {
  return (
    <StateProvider>
      <CurrentTripContextProvider>
        <HistoryContextProvider>
          <DataStreamingContextProvider>
            <TopDestinationContextProvider>
              <RecommendedTripContextProvider>
                <ItineraryContextProvider>{children}</ItineraryContextProvider>
              </RecommendedTripContextProvider>
            </TopDestinationContextProvider>
          </DataStreamingContextProvider>
        </HistoryContextProvider>
      </CurrentTripContextProvider>
    </StateProvider>
  );
}
