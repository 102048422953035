import React, { useState } from "react";
import styles from "./imageSlider.module.scss";
import {
  hotelDetailsImg,
  // swipeLeftTr,
  // transparentSwipeRightIcon,
} from "../../../../constant";

interface iProps {
  className?: string;
  imagesArr: any[];
  noPaddSwipeBtn?: boolean;
}

function ImageSlider(props: iProps): JSX.Element {
  const { imagesArr: imageArr = [], className, noPaddSwipeBtn = false } = props;
  const [sliderIndex, setSliderIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  let imagesArr = imageArr.slice(0, 20);

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    const swipeThreshold = 50;
    if (touchStart - touchEnd > swipeThreshold) {
      // Swipe left
      if (sliderIndex < imagesArr.length - 1) {
        setSliderIndex((prev) => prev + 1);
      }
    }
    if (touchEnd - touchStart > swipeThreshold) {
      // Swipe right
      if (sliderIndex > 0) {
        setSliderIndex((prev) => prev - 1);
      }
    }
  };

  return (
    <div
      className={`${className} ${styles.imageContainer}`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {/* {imagesArr.length > 0 && sliderIndex !== 0 && (
        <img
          className={`${styles.rightBtn} ${styles.leftSwipeBtn} ${noPaddSwipeBtn && styles.removeLeftPadd}`}
          src={swipeLeftTr}
          alt="left swipe btn"
          onClick={() => {
            if (sliderIndex > 0) {
              setSliderIndex((prev) => prev - 1);
              return;
            }
            // setSliderIndex(imagesArr.length - 1);
          }}
        />
      )} */}
      {imagesArr.map((eachImg, index) => {
        return (
          <img
            key={index}
            src={eachImg || hotelDetailsImg}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/destinationPlaceholder.png";
              currentTarget.style.objectFit = "cover";
              currentTarget.style.height = "100%";
              currentTarget.style.width = "100%";
            }}
            alt=""
            style={{
              transform: `translateX(${(index - sliderIndex) * 100}%)`,
            }}
            className={`${styles.horizontalImages} ${noPaddSwipeBtn && styles.addExtraPadd} ${className}`}
          />
        );
      })}
      <div className={styles.dotsContainer}>
        {imagesArr.map((_, dotIndex) => (
          <span
            key={dotIndex}
            className={`${styles.dot} ${dotIndex === sliderIndex ? styles.activeDot : ""}`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setSliderIndex(dotIndex);
            }}
          />
        ))}
      </div>
      {/* {imagesArr.length > 0 && sliderIndex !== imagesArr.length - 1 && (
        <img
          className={`${styles.rightBtn} ${noPaddSwipeBtn && styles.removeRightPadd}`}
          src={transparentSwipeRightIcon}
          alt="right btn"
          onClick={(e) => {
            // e.preventDetault();
            e.stopPropagation();
            if (sliderIndex < imagesArr.length - 1) {
              setSliderIndex((prev) => prev + 1);
              return;
            }
            // setSliderIndex(0);
          }}
        />
      )} */}
    </div>
  );
}

export default ImageSlider;
