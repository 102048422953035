import { BookingDetails, TravelJourney } from "../../../types";

export function updateAirportData(
  bookingDetails: BookingDetails,
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>,
  isThisMultiCityTrip: boolean
): void {
  if (!bookingDetails.travelFromDate) {
    return;
  }
  const tripData = isThisMultiCityTrip
    ? bookingDetails.travelJourneyData
    : bookingDetails.travelJourneyData.slice(0, 1);
  const updatedAirPortData = tripData
    .filter(
      (journey, index, self) =>
        index ===
        self?.findIndex((t) => JSON?.stringify(t) === JSON?.stringify(journey))
    )
    .map((journey, index) => {
      return {
        arrivalAddress: journey.arrivalCityAddress ?? "",
        departureGoogleAddress: journey.departureCityAddress ?? "",
        departureCityAddress: journey.departureCityAddress,
        arrivalCityAddress: journey.arrivalCityAddress,
        arrival: bookingDetails.airPort[index]?.arrival || "",
        departure: bookingDetails.airPort[index]?.departure || "",
        fromCity: journey.departureCityAddress,
        destinationCity: journey.arrivalCityAddress,
        departureDate: journey.departureDate,
        returnDate: bookingDetails.travelJourneyData[index]?.returnDate || "",
        departureAirportFullName:
          bookingDetails.airPort[index]?.departureAirportFullName || "",
        arrivalAirportFullName:
          bookingDetails.airPort[index]?.arrivalAirportFullName || "",
        arrivalCityLatitude: bookingDetails.airPort[index]?.arrivalCityLatitude,
        arrivalCityLongitude:
          bookingDetails.airPort[index]?.arrivalCityLongitude,
        departureCityLatitude:
          bookingDetails.airPort[index]?.departureCityLatitude,
        departureCityLongitude:
          bookingDetails.airPort[index]?.departureCityLongitude,
        noOfDays: journey.totalDays || 0,
        newArrivalCitySearch:
          bookingDetails.airPort[index]?.newArrivalCitySearch,
        newDepartureCitySearch:
          bookingDetails.airPort[index]?.newDepartureCitySearch,
        arrivalCityDetails: bookingDetails.airPort[index]?.arrivalCityDetails,
        departureCityDetails:
          bookingDetails.airPort[index]?.departureCityDetails,
        arrivalSelectedLocation: {
          city:
            bookingDetails?.airPort[index]?.arrivalSelectedLocation?.city || "",
          state:
            bookingDetails?.airPort[index]?.arrivalSelectedLocation?.state ||
            "",
          country:
            bookingDetails?.airPort[index]?.arrivalSelectedLocation?.country ||
            "",
          countryCode:
            bookingDetails?.airPort[index]?.arrivalSelectedLocation
              ?.countryCode || "",
          latitude:
            bookingDetails?.airPort[index]?.arrivalSelectedLocation?.latitude ||
            0,
          longitude:
            bookingDetails?.airPort[index]?.arrivalSelectedLocation
              ?.longitude || 0,
        },
        departureSelectedLocation: {
          city:
            bookingDetails?.airPort[index]?.departureSelectedLocation?.city ||
            "",
          state:
            bookingDetails?.airPort[index]?.departureSelectedLocation?.state ||
            "",
          country:
            bookingDetails?.airPort[index]?.departureSelectedLocation
              ?.country || "",
          countryCode:
            bookingDetails?.airPort[index]?.departureSelectedLocation
              ?.countryCode || "",
          latitude:
            bookingDetails?.airPort[index]?.departureSelectedLocation
              ?.latitude || 0,
          longitude:
            bookingDetails?.airPort[index]?.departureSelectedLocation
              ?.longitude || 0,
        },
      };
    });
  setBookingDetails((prevBookingDetails) => ({
    ...prevBookingDetails,
    airPort: updatedAirPortData,
  }));
}

export function updateAirportsData(
  bookingDetails: BookingDetails,
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>,
  isThisMultiCityTrip: boolean,
  travelJourneyData: TravelJourney[]
): void {
  if (!bookingDetails.travelFromDate) {
    return;
  }
  const tripData = isThisMultiCityTrip
    ? travelJourneyData
    : travelJourneyData?.slice(0, 1);
  const updatedAirPortData = tripData
    .filter(
      (journey, index, self) =>
        index ===
        self?.findIndex((t) => JSON?.stringify(t) === JSON?.stringify(journey))
    )
    .map((journey, index) => {
      return {
        arrivalAddress: journey.arrivalCityAddress ?? "",
        departureGoogleAddress: journey.departureCityAddress ?? "",
        departureCityAddress: journey.departureCityAddress,
        arrivalCityAddress: journey.arrivalCityAddress,
        arrival: bookingDetails.airPort[index]?.arrival || "",
        departure: bookingDetails.airPort[index]?.departure || "",
        fromCity: journey.departureCityAddress,
        destinationCity: journey.arrivalCityAddress,
        departureDate: journey.departureDate,
        returnDate: travelJourneyData[index]?.returnDate || "",
        departureAirportFullName:
          bookingDetails.airPort[index]?.departureAirportFullName || "",
        arrivalAirportFullName:
          bookingDetails.airPort[index]?.arrivalAirportFullName || "",
        arrivalCityLatitude: bookingDetails.airPort[index]?.arrivalCityLatitude,
        arrivalCityLongitude:
          bookingDetails.airPort[index]?.arrivalCityLongitude,
        departureCityLatitude:
          bookingDetails.airPort[index]?.departureCityLatitude,
        departureCityLongitude:
          bookingDetails.airPort[index]?.departureCityLongitude,
        noOfDays: journey.totalDays || 0,
        newArrivalCitySearch:
          bookingDetails.airPort[index]?.newArrivalCitySearch,
        newDepartureCitySearch:
          bookingDetails.airPort[index]?.newDepartureCitySearch,
        arrivalCityDetails: bookingDetails.airPort[index]?.arrivalCityDetails,
        departureCityDetails:
          bookingDetails.airPort[index]?.departureCityDetails,
        arrivalSelectedLocation: {
          city:
            bookingDetails?.airPort[index]?.arrivalSelectedLocation?.city || "",
          state:
            bookingDetails?.airPort[index]?.arrivalSelectedLocation?.state ||
            "",
          country:
            bookingDetails?.airPort[index]?.arrivalSelectedLocation?.country ||
            "",
          countryCode:
            bookingDetails?.airPort[index]?.arrivalSelectedLocation
              ?.countryCode || "",
          latitude:
            bookingDetails?.airPort[index]?.arrivalSelectedLocation?.latitude ||
            0,
          longitude:
            bookingDetails?.airPort[index]?.arrivalSelectedLocation
              ?.longitude || 0,
        },
        departureSelectedLocation: {
          city:
            bookingDetails?.airPort[index]?.departureSelectedLocation?.city ||
            "",
          state:
            bookingDetails?.airPort[index]?.departureSelectedLocation?.state ||
            "",
          country:
            bookingDetails?.airPort[index]?.departureSelectedLocation
              ?.country || "",
          countryCode:
            bookingDetails?.airPort[index]?.departureSelectedLocation
              ?.countryCode || "",
          latitude:
            bookingDetails?.airPort[index]?.departureSelectedLocation
              ?.latitude || 0,
          longitude:
            bookingDetails?.airPort[index]?.departureSelectedLocation
              ?.longitude || 0,
        },
      };
    });
  setBookingDetails((prevBookingDetails) => ({
    ...prevBookingDetails,
    airPort: updatedAirPortData,
  }));
}
