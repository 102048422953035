import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  arrowRightArrowLeftRegular,
  backIconWithText,
  expandIcon,
  planeDepartureRegular,
  ROUTES,
} from "../../constant";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import NewSingleAirportPicker from "../../component/booking/inputFields/newSingleAirportPicker/newSingleAirportPicker";
import CabinPicker from "../../component/booking/inputFields/cabinPicker/CabinPicker";
import { useNavigate } from "react-router-dom";
import {
  formatToYYYYMMDD,
  getDayOfWeek,
  getMonthDate,
} from "../../utils/dateUtils";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import { toastMessage } from "../../utils/ToastProvider";
import { TOAST_MESSAGES } from "../../constant/messages/toastMessages";
import { ScreenTypes } from "../../enums";
import NewDatePicker from "../../component/booking/inputFields/dateNewPicker/newDatePicker";
import styles from "./modify.module.scss";
import { changeFlight } from "../../api/booking/postBooking";
import { Button } from "@mui/material";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";

function ModifyFlights(): JSX.Element {
  const state = useContext(StateContext);
  const {
    bookingDetails,
    setBookingDetails,
    userInfo,
    setFlightChange,
    isMobile,
    setScreen,
    itineraryId,
    setModifyFlightStatus,
    setIsFetchingAirports,
    tripOrItinearyId,
  }: iGlobalContext = state;
  const navigate = useNavigate();
  const { handleCustomError } = useCustomErrorHandler();
  const handleCancel = useCallback(() => {
    if (isMobile) {
      navigate(ROUTES.TripDetails);
      setScreen(ScreenTypes.TripDetails);
    }
    setScreen(ScreenTypes.TripDetails);
  }, [navigate]);

  const [expandedCardIndex, setExpandedCardIndex] = useState<number | null>(
    bookingDetails?.postBooking?.length ? 0 : null
  );
  const handleExpandClick = useCallback((index: number) => {
    setExpandedCardIndex((prevIndex) => (prevIndex === index ? null : index));
  }, []);

  const handleFlightModify = useCallback(async () => {
    if (!itineraryId) {
      console.error("Itinerary ID provided.");
      return;
    }

    const segments = bookingDetails.postBooking
      .map((airport) => {
        if (!airport?.departureDate || !airport?.transactionId) {
          console.error("Missing departure date or transaction ID.");
          return null;
        }
        return {
          transactionId: airport.transactionId,
          arrival: airport.arrival,
          departure: airport?.departure,
          pnr: airport?.pnr,
          departureDate: formatToYYYYMMDD(airport?.departureDate),
          cabinClass: bookingDetails.flightClass,
        };
      })
      .filter(Boolean);

    if (segments.length === 0) {
      console.error("No valid segments to modify.");
      return;
    }
    setIsFetchingAirports(true);
    if (!tripOrItinearyId || !userInfo?.id || !itineraryId) {
      setIsFetchingAirports(false);
      return;
    }

    const requestBody = {
      id: tripOrItinearyId,
      userId: userInfo?.id,
      itineraryId: itineraryId,
      tripType: bookingDetails.tripType,
      segments,
    };

    try {
      const flightModify = await changeFlight(requestBody);
      if (flightModify?.statusCode !== 200) {
        handleCustomError(flightModify?.statusCode, "modify.flight");
        toastMessage.error(
          TOAST_MESSAGES.ApiBreakdown.messageTitle,
          TOAST_MESSAGES.ApiBreakdown.message
        );
        return null;
      }
      toastMessage.newSuccess(
        TOAST_MESSAGES.PostBooking.flightChangeRequest.messageTitle,
        TOAST_MESSAGES.PostBooking.flightChangeRequest.message
      );
      setModifyFlightStatus("CHANGE REQUESTED");
      setFlightChange({ flightCancel: false, flightChange: true });
      if (isMobile) {
        navigate(ROUTES.TripDetails);
        setScreen(ScreenTypes.TripDetails);
      }
      setScreen(ScreenTypes.TripDetails);
      console.log(flightModify);
      setIsFetchingAirports(false);
    } catch (error) {
      setIsFetchingAirports(false);
      console.error("Error modifying flight:", error);
    }
  }, [
    itineraryId,
    bookingDetails.postBooking,
    bookingDetails.tripType,
    bookingDetails.flightClass,
    setIsFetchingAirports,
    userInfo?.id,
    setModifyFlightStatus,
    setFlightChange,
    isMobile,
    setScreen,
    handleCustomError,
    navigate,
  ]);

  function handleBackButton() {
    triggerGAEvent(GA_LABEL_ENUMS.trip_details_back_click);
    if (isMobile) {
      setTimeout(() => {
        navigate(ROUTES.TripDetails);
      }, 700);
    }
    setScreen(ScreenTypes.TripDetails);
    setFlightChange({ flightCancel: false, flightChange: false });
  }
  console.log("bookingDetails", bookingDetails.postBooking);

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.buttonContainer}>
          <Button
            className={`${styles.columnAlignButton}`}
            onClick={handleBackButton}>
            <img src={backIconWithText} alt="Close" />
          </Button>
        </div>
        <p className={styles.title}>Change Flights</p>
        <section className={styles.contentCard}>
          <div className={styles.modifyCard}>
            {bookingDetails.postBooking.map((airportData, index) => (
              <div className={styles.subCard} key={index}>
                <div className={styles.flightHeader}>
                  <div className={styles.flightDetails}>
                    <p className={styles.dateInfo}>
                      {getDayOfWeek(airportData.departureDate)}{" "}
                      {getMonthDate(airportData.departureDate)}
                    </p>
                    <p className={styles.flightInfo}>
                      {airportData.fromCity} ({airportData.departure}) -{" "}
                      {airportData.destinationCity} ({airportData.arrival})
                    </p>
                  </div>
                  <div className={styles.accordion}>
                    <img
                      src={planeDepartureRegular}
                      alt=""
                      className={styles.flightIcon}
                    />
                    <button
                      className={styles.expandButton}
                      onClick={() => handleExpandClick(index)}
                      aria-label="Expand">
                      <img
                        src={expandIcon}
                        alt="Expand Icon"
                        className={`${expandedCardIndex === index ? styles.hideExpandIcon : styles.expandIcon}`}
                      />
                    </button>
                  </div>
                </div>
                {expandedCardIndex === index && (
                  <div className={styles.inputFields}>
                    <div className={styles.dateClassCard}>
                      <NewDatePicker
                        dateInput={airportData.departureDate}
                        setDateInput={(newDate) => {
                          setBookingDetails((prevData) => ({
                            ...prevData,
                            postBooking: prevData.postBooking.map((airport) =>
                              airport === airportData
                                ? { ...airport, departureDate: newDate }
                                : airport
                            ),
                          }));
                        }}
                        handleUpdateData={() => {}}
                        label="DATE"
                      />
                      <CabinPicker
                        bookingDetails={bookingDetails}
                        setBookingDetails={setBookingDetails}
                        tripType={bookingDetails.tripType}
                        label="CLASS"
                      />
                    </div>
                    <div className={styles.airportCard}>
                      <div className={styles.newInputCard2} key={index}>
                        <div className={styles.card2}>
                          <NewSingleAirportPicker
                            indexNumber={index}
                            label="AIRPORT"
                            googleInputAddress={`${airportData.departureCityDetails}, ${airportData.departureCountryName}`}
                            selectedAirportCode={airportData.departure}
                            setGoogleInputAddress={(newAddress) => {}}
                            selectedAddress={`${airportData.departureCityDetails}, ${airportData.departureCountryName}`}
                            setSelectedAddress={(newAddress) => {}}
                            airportName={airportData.departureAirportFullName}
                            handleUpdateData={() => {}}
                            value={airportData.departure}
                            handleAirportChange={(flight) => {
                              const updatedAirports = [
                                ...bookingDetails.postBooking,
                              ];
                              updatedAirports[index] = {
                                ...updatedAirports[index],
                                departure: flight.airportCode,
                                departureAirportFullName: flight.airportName,
                              };
                              setBookingDetails({
                                ...bookingDetails,
                                postBooking: updatedAirports,
                              });
                            }}
                            clearInputValues={() => {}}
                            hideSearchInput={true}
                          />
                        </div>
                        <img
                          src={arrowRightArrowLeftRegular}
                          alt="icon"
                          className={styles.rightLeftIcon}
                        />
                        <div className={styles.card2}>
                          <NewSingleAirportPicker
                            indexNumber={index}
                            googleInputAddress={`${airportData.arrivalCityDetails}, ${airportData.arrivalCountryName}`}
                            selectedAirportCode={airportData.arrival}
                            setGoogleInputAddress={(newAddress) => {}}
                            selectedAddress={`${airportData.arrivalCityDetails}, ${airportData.arrivalCountryName}`}
                            setSelectedAddress={(newAddress) => {}}
                            airportName={airportData.arrivalAirportFullName}
                            handleUpdateData={() => {}}
                            value={airportData.arrival}
                            handleAirportChange={(flight) => {
                              const updatedAirports = [
                                ...bookingDetails.airPort,
                              ];
                              updatedAirports[index] = {
                                ...updatedAirports[index],
                                arrival: flight.airportCode,
                                arrivalAirportFullName: flight.airportName,
                              };
                              setBookingDetails({
                                ...bookingDetails,
                                airPort: updatedAirports,
                              });
                            }}
                            clearInputValues={() => {}}
                            hideSearchInput={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className={styles.buttonCard}>
            <button className={styles.cancelBtn} onClick={handleCancel}>
              Cancel
            </button>
            <button className={styles.searchBtn} onClick={handleFlightModify}>
              Submit
            </button>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ModifyFlights;
