import React from "react";
import styles from "./custom.module.scss";

interface CustomNewCheckBoxProps {
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

const CustomNewCheckBox: React.FC<CustomNewCheckBoxProps> = ({
  name,
  checked = false,
  disabled = false,
  onChange,
}) => {
  const handleChange = () => {
    if (onChange) {
      onChange(!checked);
    }
  };

  return (
    <button
      type="button"
      className={styles.inputBox}
      onClick={(e) => !disabled && handleChange()}
      onKeyDown={(e) => !disabled && e.key === "Enter" && handleChange()}
      disabled={disabled}
      aria-label={name}>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        // onChange={handleChange}
        readOnly
        className={styles.customCheckbox}
        disabled={disabled}
      />
      <span className={styles.checkmark}></span>
    </button>
  );
};

export default CustomNewCheckBox;
