import React from "react";
import { AirPort, iHotelInputPayload } from "../../../../../types";
import { arrowRight, planeArrivalRegular } from "../../../../../constant";
import FlightHotelDetails from "../flightHotelDetails/flightHotelDetails";
import styles from "./step.module.scss";

interface StepperProps {
  steps?: AirPort[];
  initialStep: number;
  finalStep: number;
  showAccomodation: boolean;
  cities: iHotelInputPayload[];
}

const Stepper: React.FC<StepperProps> = ({
  steps = [],
  initialStep = 0,
  finalStep = 0,
  showAccomodation,
  cities,
}) => {
  const [showAllSteps, setShowAllSteps] = React.useState(true);

  const handleViewDetails = () => {
    setShowAllSteps((prev) => !prev);
  };

  if (steps.length === 0) {
    return <div>No data found</div>;
  }
  const addFinalTrip = (trips: AirPort[] | undefined | null) => {
    if (!trips || trips.length === 0) {
      return [];
    }
    const newTrips = [...trips];
    const lastTrip = trips[trips.length - 1];
    const finalDestinationCity = lastTrip.newArrivalCitySearch
      ? lastTrip.arrivalCityDetails
      : lastTrip.destinationCity;
    const finalTrip = {
      arrivalAddress: "",
      departureGoogleAddress: "",
      departureCityAddress: "",
      arrivalCityAddress: "",
      arrival: "",
      departure: "",
      fromCity: "",
      destinationCity: finalDestinationCity,
      departureDate: "",
      returnDate: "",
      departureAirportFullName: "",
      arrivalAirportFullName: "",
      arrivalCityLatitude: "",
      arrivalCityLongitude: "",
      departureCityLatitude: "",
      departureCityLongitude: "",
      noOfDays: 0,
      newArrivalCitySearch: false,
      newDepartureCitySearch: false,
      arrivalCityDetails: "",
      departureCityDetails: "",
      arrivalSelectedLocation: {
        city: "",
        state: "",
        country: "",
        latitude: 0,
        longitude: 0,
      },
      departureSelectedLocation: {
        city: "",
        state: "",
        country: "",
        latitude: 0,
        longitude: 0,
      },
    };
    newTrips.push(finalTrip);
    return newTrips;
  };

  const handleStops = (indexNumber: number, indexLength: number): string => {
    let displayText;
    if (indexNumber === 0) {
      displayText = `Flight ${indexNumber + 1}`;
    } else if (indexNumber === addFinalTrip(steps)?.length - 1) {
      displayText = "";
    } else {
      displayText = `Flight ${indexNumber < indexLength ? `${indexNumber + 1}` : indexNumber + 1}`;
    }
    return displayText;
  };

  // function getCityInfo(flights: FlightInfo[] | null | undefined): CityInfo[] {
  //   if (!Array.isArray(flights) || flights.length === 0) {
  //     return [];
  //   }

  //   const cityInfoArray: CityInfo[] = [];

  //   flights.forEach((flight, index) => {
  //     if (!flight) return;
  //     let departureLatitude = flight.departureCityLatitude || "";
  //     let departureLongitude = flight.departureCityLongitude || "";
  //     if ((!departureLatitude || !departureLongitude) && index > 0) {
  //       const prevFlight = flights[index - 1];
  //       departureLatitude = prevFlight?.arrivalCityLatitude || "";
  //       departureLongitude = prevFlight?.arrivalCityLongitude || "";
  //     }
  //     cityInfoArray.push({
  //       name: flight.fromCity || "",
  //       latitude: departureLatitude,
  //       longitude: departureLongitude,
  //     });
  //     cityInfoArray.push({
  //       name: flight.destinationCity || "",
  //       latitude: flight.arrivalCityLatitude || "",
  //       longitude: flight.arrivalCityLongitude || "",
  //     });
  //   });

  //   return cityInfoArray;
  // }

  const hasFulSteps = steps?.some(
    (step) =>
      step.fromCity ||
      step.destinationCity ||
      step.departureDate ||
      step.returnDate
  );

  if (!hasFulSteps) return null;

  return (
    <div className={styles.main}>
      <div className={styles.stepper}>
        {steps &&
          addFinalTrip(steps)?.map((step, index) => {
            // if (index < initialStep || index > finalStep) return null;
            const shouldDisplayStep =
              showAllSteps ||
              index === initialStep ||
              index === finalStep ||
              steps.length <= 2;

            if (!shouldDisplayStep) return null;

            const arrivalSelectedLocation = {
              city: step?.arrivalSelectedLocation?.city,
              state: step?.arrivalSelectedLocation?.state,
              country: step?.arrivalSelectedLocation?.country,
              countryCode: step?.arrivalSelectedLocation?.countryCode,
              latitude: step?.arrivalSelectedLocation?.latitude,
              longitude: step?.arrivalSelectedLocation?.longitude,
            };

            const departureSelectedLocation = {
              city: step?.departureSelectedLocation?.city,
              state: step?.departureSelectedLocation?.state,
              country: step?.departureSelectedLocation?.country,
              countryCode: step?.departureSelectedLocation?.countryCode,
              latitude: step?.departureSelectedLocation?.latitude,
              longitude: step?.departureSelectedLocation?.longitude,
            };

            const finalStop = step?.newArrivalCitySearch
              ? step?.arrivalCityDetails
              : step?.destinationCity;

            return (
              <React.Fragment key={index}>
                <div className={styles.step}>
                  <div className={styles.circle}></div>
                  {step?.fromCity ? (
                    <div className={styles.stepContent}>
                      <p className={styles.stops}>
                        {handleStops(index, addFinalTrip(steps)?.length)}
                      </p>
                      <section className={styles.airportCard}>
                        {step?.fromCity && (
                          <p className={styles.city}>
                            {step?.newDepartureCitySearch
                              ? `${step?.departureSelectedLocation?.city} ${step?.departureSelectedLocation?.state} ${step?.departureSelectedLocation?.country}`
                              : step?.fromCity}
                          </p>
                        )}
                        <img
                          src={arrowRight}
                          alt=""
                          className={styles.arrowIcon}
                        />
                        {step?.destinationCity !== undefined && (
                          <p className={styles.city}>
                            {step?.newArrivalCitySearch
                              ? `${step?.arrivalSelectedLocation?.city} ${step?.arrivalSelectedLocation?.state} ${step?.arrivalSelectedLocation?.country}`
                              : step?.destinationCity}
                          </p>
                        )}
                      </section>
                      {showAllSteps && (
                        <div className={styles.card}>
                          <div>
                            <FlightHotelDetails
                              returnDate={step?.returnDate}
                              departureDate={step?.departureDate}
                              arrival={step?.arrival}
                              departure={step?.departure}
                              fromCity={step?.fromCity}
                              destinationCity={step?.destinationCity}
                              showAccomodation={showAccomodation}
                              indexNumber={index}
                              arrivalCityLatitude={step?.arrivalCityLatitude}
                              arrivalCityLongitude={step?.arrivalCityLongitude}
                              departureCityLatitude={
                                step?.departureCityLatitude
                              }
                              departureCityLongitude={
                                step?.departureCityLongitude
                              }
                              arrivalAirportFullName={
                                step?.arrivalAirportFullName
                              }
                              departureAirportFullName={
                                step?.departureAirportFullName
                              }
                              hotelStayCount={step?.noOfDays}
                              newDepartureCitySearch={
                                step?.newDepartureCitySearch
                              }
                              newArrivalCitySearch={step?.newArrivalCitySearch}
                              arrivalCityDetails={step?.arrivalCityDetails}
                              departureCityDetails={step?.departureCityDetails}
                              arrivalSelectedLocation={arrivalSelectedLocation}
                              departureSelectedLocation={
                                departureSelectedLocation
                              }
                              cities={cities}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className={styles.lastStep}>
                      <img
                        src={planeArrivalRegular}
                        alt=""
                        className={styles.arrivalImg}
                      />

                      {step?.destinationCity && (
                        <p className={styles.destination}>
                          {step?.destinationCity}
                        </p>
                      )}
                    </div>
                  )}
                  {index < addFinalTrip(steps).length - 1 && (
                    <div className={styles.line} />
                  )}
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default Stepper;
