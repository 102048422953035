import React, { useContext, useEffect } from "react";
import styles from "./cost.module.scss";
import { newBlueCheckIcon } from "../../../../../constant";
import Button from "../../../../button/button";
import { StateContext } from "../../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../../context/globalContext/interface";

interface FlightCostProps {
  className?: string;
  selectedFlightId: string;
  uniqueId: string;
  isFlightAvailable: boolean;
  flightCost: number;
  expandedFlightId: string | null;
  handleSelectFlight: () => void;
  viewFlightDetails: boolean;
  handleViewFlightDetails: (id: string) => void;
}

const FlightCost: React.FC<FlightCostProps> = ({
  className,
  selectedFlightId,
  uniqueId,
  isFlightAvailable,
  flightCost,
  expandedFlightId,
  handleSelectFlight,
  viewFlightDetails,
  handleViewFlightDetails,
}) => {
  const state = useContext(StateContext);
  const { setEnableContinueBtn }: iGlobalContext = state;
  useEffect(() => {
    setEnableContinueBtn(selectedFlightId === uniqueId && isFlightAvailable);
  }, [selectedFlightId, uniqueId, isFlightAvailable, setEnableContinueBtn]);
  let content;
  switch (true) {
    case selectedFlightId === uniqueId && isFlightAvailable:
      content = (
        <>
          <img
            src={newBlueCheckIcon}
            alt="icon"
            className={styles.checkedIcon}
          />
          <p className={styles.cost}>${flightCost}</p>
          <p className={styles.tax}>incl taxes & fees</p>
        </>
      );
      break;
    case expandedFlightId === uniqueId &&
      selectedFlightId !== expandedFlightId &&
      viewFlightDetails:
      content = (
        <>
          <p className={styles.cost}>${flightCost}</p>
          <p className={styles.tax1}>incl taxes & fees</p>
          <Button size="sm" onClick={handleSelectFlight}>
            Select
          </Button>
        </>
      );
      break;
    default:
      content = (
        <>
          <Button size="sm" onClick={() => handleViewFlightDetails(uniqueId)}>
            ${flightCost}
          </Button>
          <p className={styles.tax}>incl taxes & fees</p>
        </>
      );
  }

  return (
    <div className={`${className} ${styles.main}`}>
      <section className={styles.flightCostCard}>{content}</section>
    </div>
  );
};

export default FlightCost;
