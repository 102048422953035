import React from "react";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import BookingCounter from "./bookingCounter/BookingCounter";
import CustomPopover from "../../../reusableComponent/customPopver/CustomPopover";
import styles from "./travelerPopover.module.scss";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../constant/google-analytics/GAEvents";
import { toastMessage } from "../../../../utils/ToastProvider";
interface TravelerPopoverProps {
  showRoom: boolean;
  popoverTrigger: ReactNode;
  adultsCount: number;
  childrenCount: number;
  seniorsCount: number;
  setAdultsCount: Dispatch<SetStateAction<number>>;
  setChildrenCount: Dispatch<SetStateAction<number>>;
  setSeniorsCount: Dispatch<SetStateAction<number>>;
  numberOfRoomCount?: number;
  setNumberOfRoomCount: Dispatch<SetStateAction<number>>;
  isPopoverOpen: boolean;
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showOnlyRoom?: boolean;
  showInstrcutions?: boolean;
}

export default function TravelerPopoverProps({
  showRoom,
  popoverTrigger,
  adultsCount,
  childrenCount,
  seniorsCount,
  setAdultsCount,
  setChildrenCount,
  setSeniorsCount,
  numberOfRoomCount = 0,
  setNumberOfRoomCount,
  isPopoverOpen,
  setIsPopoverOpen,
  showOnlyRoom,
  showInstrcutions,
}: TravelerPopoverProps) {
  const [count, setCount] = useState({
    adultCount: 0,
    childCount: 0,
    seniorCount: 0,
    roomCount: 0,
  });

  const validateBookingDetails = (
    adultsCount: number,
    childrenCount: number,
    seniorsCount: number,
    numberOfRoomCount: number,
    showRoom: boolean
  ): string | null => {
    const totalPeople = adultsCount + childrenCount + seniorsCount;
    // Validation rules with appropriate error messages
    if (showRoom) {
      if (numberOfRoomCount <= 0) {
        return "Room count must be greater than zero.";
      }
      if (totalPeople <= 0 && showRoom) {
        return "At least one person must be booked.";
      }

      if (totalPeople > numberOfRoomCount * 4 && showRoom) {
        return "Cannot book more than 4 people per room.";
      }
    } else {
      if (totalPeople <= 0) {
        return "At least one person must be booked.";
      }
    }

    if (adultsCount < 0 || childrenCount < 0 || seniorsCount < 0) {
      return "The count for adults, children, or seniors cannot be negative.";
    }

    return null;
  };

  const handleUpdateData = () => {
    triggerGAEvent(GA_LABEL_ENUMS.booking_travelers_date_apply_click);

    const validationMessage = validateBookingDetails(
      count.adultCount,
      count.childCount,
      count.seniorCount,
      count.roomCount,
      (showRoom = showRoom)
    );

    if (validationMessage) {
      toastMessage.error(validationMessage);
      return;
    }
    setAdultsCount(count.adultCount);
    setChildrenCount(count.childCount);
    setSeniorsCount(count.seniorCount);
    setNumberOfRoomCount(count.roomCount);
  };

  useEffect(() => {
    if (isPopoverOpen) {
      setCount({
        adultCount: adultsCount,
        childCount: childrenCount,
        seniorCount: seniorsCount,
        roomCount: numberOfRoomCount,
      });
    }
  }, [
    adultsCount,
    childrenCount,
    seniorsCount,
    numberOfRoomCount,
    isPopoverOpen,
  ]);

  return (
    <CustomPopover
      popoverTrigger={popoverTrigger}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      showInstrcutions={showInstrcutions}
      popoverContent={
        <div className={styles.main}>
          {showOnlyRoom ? (
            <div className={styles.onlyRoomCard}>
              <h1 className={styles.roomHeading}>Rooms</h1>
              <div
                className={styles.counterContainer}
                onClick={() => {
                  triggerGAEvent(
                    GA_LABEL_ENUMS.booking_travelers_add_remove_room_click
                  );
                }}>
                <BookingCounter
                  heading1="Number of Rooms"
                  heading2="Max 4 rooms"
                  count={count}
                  setCount={setCount}
                  roomType={true}
                  category="room"
                />
              </div>
            </div>
          ) : (
            <div className={styles.mainContainer}>
              <h1 className={styles.heading}>Travelers</h1>
              <div
                className={styles.counterContainer}
                onClick={() => {
                  triggerGAEvent(
                    GA_LABEL_ENUMS.booking_travelers_add_remove_traveler_click
                  );
                }}>
                <BookingCounter
                  heading1="Adults"
                  heading2="18 - 64 years"
                  count={count}
                  setCount={setCount}
                  roomType={false}
                  category="adult"
                />
                <BookingCounter
                  heading1="Children"
                  heading2="2 - 18 years"
                  count={count}
                  setCount={setCount}
                  roomType={false}
                  category="child"
                />
                <BookingCounter
                  heading1="Seniors"
                  heading2="65+ years"
                  count={count}
                  setCount={setCount}
                  roomType={false}
                  category="senior"
                />
              </div>
              {showRoom && (
                <React.Fragment>
                  <h1 className={styles.heading}>Rooms</h1>
                  <div
                    className={styles.counterContainer}
                    onClick={() => {
                      triggerGAEvent(
                        GA_LABEL_ENUMS.booking_travelers_add_remove_room_click
                      );
                    }}>
                    <BookingCounter
                      heading1="Number of Rooms"
                      heading2="Max 4 rooms"
                      count={count}
                      setCount={setCount}
                      roomType={true}
                      category="room"
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      }
      handleApplyButton={handleUpdateData}
    />
  );
}
