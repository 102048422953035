import { useNavigate } from "react-router-dom";
import styles from "./flight.module.scss";
import WeatherFlightDelayHeader from "../../component/weatherFlightDelayHeader/weatherFlightDelayHeader";
import SelectedOptions from "../../component/booking/selectedOptions/selectedOptions";

import { FlightDelayList } from "../../constant/dummyData";
import FlightListing from "../../component/booking/flightBooking/flightListing/flightListing";
import { Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ROUTES, updatedSeeMoreIcon } from "../../constant";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";

function FlightDelay(): JSX.Element {
  const navigate = useNavigate();
  let bgImage =
    "https://zenvoya-uploads-dev-test.s3.amazonaws.com/profile/flightimage.jpg";
  const [selectedOptions] = useState({
    flights: true,
    hotels: false,
  });
  const [visibleList, setVisibleList] = useState({
    flight: 2,
  });
  const handleShowMoreFlights = () => {
    triggerGAEvent(GA_LABEL_ENUMS.flight_delay_show_more_click);
    setVisibleList((prevVisibleList) => ({
      ...prevVisibleList,
      flight: prevVisibleList.flight + 2,
    }));
  };

  useEffect(() => {
    triggerGAEvent(GA_LABEL_ENUMS.flight_delay_page_load);
  }, []);

  const globalContext = useContext(StateContext);
  const { isMobile = false, setScreen }: iGlobalContext = globalContext || {};
  return (
    <div className={styles.main}>
      <WeatherFlightDelayHeader
        bgImage={bgImage}
        title={"Flight Delays"}
        description="Your flight BA817 from Barcelona to London has been delayed by 72 minutes. New departure time is 7:34PM on AUG 18."
        advisoryType="flight"
        bgColor="red"
        handleBackButton={() => {
          triggerGAEvent(GA_LABEL_ENUMS.flight_delay_back_click);
          if (isMobile) navigate("/today");
          if (!isMobile) setScreen("");
        }}
      />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.titleCard}>
            <p className={styles.title}>Suggested Changes to Your Plans</p>
            <p className={styles.desc}>Tap below to accept suggested changes</p>
          </div>
          {selectedOptions.flights && (
            <section className={styles.list}>
              <section className={styles.selected}>
                <SelectedOptions
                  selectedOptions={selectedOptions}
                  flightData={"Flights"}
                  showPrices={false}
                />
              </section>
              <section className={styles.listCard}>
                {FlightDelayList.slice(0, visibleList.flight).map(
                  (i, index) => (
                    <div key={index}>
                      <FlightListing
                        startTime={i.startTime}
                        endTime={i.endTime}
                        flightName={i.flightName}
                        stopsInfo={i.stops}
                        stops={i.stopsInfo}
                        stopTime={i.stopTime}
                        airports={i.airports}
                        flightCost={i.flightCost}
                        flightClass={i.flightClass}
                        tourPackage={false}
                        buttonName="select"
                        flightImage={i.flightImage}
                        detailsButton={"Details"}
                        onDetailsBtnClick={() =>
                          triggerGAEvent(
                            GA_LABEL_ENUMS.flight_delay_details_click
                          )
                        }
                        handleBook={() => {
                          triggerGAEvent(
                            GA_LABEL_ENUMS.flight_delay_select_click
                          );
                          navigate(ROUTES.FlightSelect);
                        }}
                        handleSelect={() => {}}
                      />
                    </div>
                  )
                )}
                {visibleList.flight < FlightDelayList.length && (
                  <div className={styles.showCard}>
                    <Button
                      className={styles.showMoreBtn}
                      endIcon={
                        <img
                          src={updatedSeeMoreIcon}
                          alt="btn"
                          className={styles.showMoreIcon}
                        />
                      }
                      onClick={handleShowMoreFlights}
                    >
                      Show More
                    </Button>
                  </div>
                )}
              </section>
            </section>
          )}
        </div>
      </div>
    </div>
  );
}

export default FlightDelay;
