import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useRef,
  useEffect,
} from "react";
import {
  iDataStreamingContext,
  iStreamingResponse,
} from "./dataStreamingContextInterface";
import { DATA_STREAMING_KEY, LLM_RESPONSE_ERROR } from "../../constant/common";
import { iGlobalContext } from "../globalContext/interface";
import { StateContext } from "../globalContext/context";
import { useNavigate } from "react-router-dom";
import {
  CHAT_DATA,
  DESTINATION_LIST_DATA,
  DESTINATION_LIST_DATA_GRID,
  SELECTED_CHAT_DATA,
  TEMP_TRIP_DETAILS_DATA,
} from "../../constant/dummyData";
import { ROUTES } from "../../constant";
import { v4 } from "uuid";
import { getItineraryImageURL } from "../../api/getItineraryImageURL";
import { iChatData, iCityDetailsResult, iTripDetails } from "../../types";
import { ScreenTypes } from "../../enums";
import {
  getItineraryPriceCityList,
  scrollToBottom,
  updateChatDataWithPrice,
} from "../../utils";
import { iTopDestinationContext } from "../topDestinationContext/topDestinationContextInterface";
import { useTopDestinationContext } from "../topDestinationContext/TopDestinationContext";
import { socket, SOCKET_EVENTS } from "../../utils/socket";
import { getItineraryPriceAPI } from "../../api/itinerary/getItineraryPrice";

const DataStreamingContext = createContext({} as iDataStreamingContext);
export const DataStreamingContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const globalContext = useContext(StateContext);
  const {
    chatInputText,
    setChatData,
    chatData,
    setSelectedChatData,
    isMobile,
    setScreen,
    setFirstScreen,
    scrollContainerRef,
    setOpenLoaderOverlay,
  }: iGlobalContext = globalContext;

  const navigate = useNavigate();
  const chatAbortController = new AbortController();
  const chatAbortControllerRef = useRef<AbortController | null>(null);
  const [disableInput, setDisableInput] = useState<boolean>(false);
  const [streamingInProgressFlag, setStreamingInProgressFlag] = useState(false);
  const [callPriceAPIFlag, setCallPriceAPIFlag] = useState({
    false: false,
    payload: { cities: [] },
  });

  useEffect(() => {
    async function callPriceDetails() {
      if (!callPriceAPIFlag.false) return;
      const priceResponse = await getItineraryPriceAPI(
        callPriceAPIFlag.payload
      );
      setSelectedChatData((prev: any) => ({
        ...prev,
        estimatedPrice: priceResponse?.data?.totalPrice ?? "0",
      }));
    }
    callPriceDetails();
  }, [callPriceAPIFlag]);

  function extractCityNames(data: any) {
    const cityNames = data.map((item: any) => item?.city_name ?? " ");
    let result =
      cityNames.length > 1
        ? cityNames.slice(0, -1).join(", ") + " and " + cityNames.slice(-1)
        : cityNames[0];
    return result;
  }

  let initialReturnType = "";
  async function handleConversationStreamEvents(value: any): Promise<void> {
    try {
      setStreamingInProgressFlag(true);
      const parseValue: iStreamingResponse = JSON.parse(value);
      // if (!parseValue) {
      //   return;
      // }
      // scrollToBottom(scrollContainerRef);

      console.log(parseValue, "CONNECTION STREAM EVENT", initialReturnType);

      if (
        parseValue?.intent === DATA_STREAMING_KEY.INTENT.RETURN_TYPE &&
        parseValue?.return_type === DATA_STREAMING_KEY.REUTRN_TYPE.FOLLOW_UP
      ) {
        console.log("FOLLOW UP EVENT START", parseValue);
        socket.on(SOCKET_EVENTS.followup_stream, handleFollowUpStreamEvents);
      } else if (
        parseValue?.intent === DATA_STREAMING_KEY.INTENT.RETURN_TYPE &&
        parseValue?.return_type === DATA_STREAMING_KEY.REUTRN_TYPE.DESTINATIONS
      ) {
        const destList = {
          destination_name: "",
          destination_city: "",
          destination_country: "",
          destination_description: "",
          approximate_trip_cost: "",
          averagePackagePriceForExperiencing: "",
          description: "",
          id: "",
          checkFlag: false,
          locations: [],
          imageURL: "",
          checkedFlag: false,
        };
        setChatData((prev: any) => [
          ...prev.slice(0, -1),
          {
            ...CHAT_DATA,
            destinationList: CHAT_DATA.destinationList.map(
              (ele: any) => destList
            ),
            preText: "",
            id: v4(),
          },
        ]);
      }

      if (parseValue?.return_type) {
        initialReturnType = parseValue?.return_type;
        if (chatAbortController.signal.aborted) return;
        setSelectedChatData(SELECTED_CHAT_DATA);
      }
      /**
       * For Followup question
       */
      if (
        (parseValue?.intent === DATA_STREAMING_KEY.INTENT.RETURN_TYPE &&
          parseValue?.return_type ===
            DATA_STREAMING_KEY.REUTRN_TYPE.FOLLOW_UP) ||
        initialReturnType === "" ||
        initialReturnType === DATA_STREAMING_KEY.REUTRN_TYPE.FOLLOW_UP
      ) {
        console.log("FOLLOW UP END 1");
        if (isMobile) navigate(ROUTES.TopDestination);
        else {
          setFirstScreen(ScreenTypes.topDestination);
          setScreen("");
        }

        switch (parseValue?.intent) {
          case DATA_STREAMING_KEY.INTENT.FOLLOW_UP: {
            console.log("FOLLOW UP END 2");
            // Use a callback to ensure state updates correctly
            if (chatAbortController.signal.aborted) return;
            setChatData((prevChatData: any) => [
              ...prevChatData.slice(0, -1),
              {
                id: v4(),
                message: parseValue?.statement,
                // prompt: response?.data?.messages,
                prompt: [],
                isUserMessage: false,
                isLoading: false,
                destinationList: [],
                singleSelectedDestination: false,
              },
            ]);
            break;
          }
          default: {
            break;
          }
        }
      } else if (
        /**
         * For Destination list
         */
        (parseValue?.intent === DATA_STREAMING_KEY.INTENT.RETURN_TYPE &&
          parseValue?.return_type ===
            DATA_STREAMING_KEY.REUTRN_TYPE.DESTINATIONS) ||
        initialReturnType === "" ||
        initialReturnType === DATA_STREAMING_KEY.REUTRN_TYPE.DESTINATIONS
      ) {
        const destList = {
          destination_name: "",
          destination_city: "",
          destination_country: "",
          destination_description: "",
          approximate_trip_cost: "",
          averagePackagePriceForExperiencing: "",
          description: "",
          id: "",
          checkFlag: false,
          locations: [],
          imageURL: "",
          checkedFlag: false,
        };
        if (isMobile) navigate(ROUTES.TopDestination);
        else setFirstScreen(ScreenTypes.topDestination);
        switch (parseValue?.intent) {
          case DATA_STREAMING_KEY.INTENT.FOLLOW_UP: {
            if (chatAbortController.signal.aborted) return;
            setChatData((prev: any) => [
              ...prev.slice(0, -1),
              {
                ...CHAT_DATA,
                destinationList: CHAT_DATA.destinationList.map(
                  (ele: any) => destList
                ),
                preText: parseValue?.statement,
                id: v4(),
              },
            ]);
            break;
          }
          case DATA_STREAMING_KEY.INTENT.PRE_TEXT: {
            if (chatAbortController.signal.aborted) return;
            setChatData((prev: any) => [
              ...prev.slice(0, -1),
              {
                ...CHAT_DATA,
                destinationList: CHAT_DATA.destinationList.map(
                  (ele: any) => destList
                ),
                preText: parseValue?.statement,
                id: v4(),
              },
            ]);
            break;
          }
          case DATA_STREAMING_KEY.INTENT.DESTINATION: {
            const imageResponse: any = await getItineraryImageURL(
              parseValue?.destination_city || ""
            );
            if (chatAbortController.signal.aborted) return;
            setChatData((prev: any) => {
              let lastChatData = prev[prev.length - 1];
              // prev.splice(-1)[0];
              let tempDestinationList = lastChatData.destinationList;
              for (let i = 0; i < tempDestinationList.length; i++) {
                if (!tempDestinationList[i].destination_name) {
                  tempDestinationList[i] = {
                    ...parseValue,
                    checkedFlag: false,
                    checkFlag: false,
                    id: v4(),
                    imageURL: imageResponse?.data?.image,
                  };
                  break;
                }
              }
              return [
                ...prev.slice(0, -1),
                {
                  id: v4(),
                  ...lastChatData,
                  destinationList: tempDestinationList,
                },
              ];
            });
            break;
          }
          case DATA_STREAMING_KEY.INTENT.POST_TEXT: {
            if (chatAbortController.signal.aborted) return;
            setChatData((prev: any) => [
              ...prev.slice(0, -1),
              {
                ...prev.splice(-1)[0],
                postText: parseValue?.statement,
              },
            ]);
            break;
          }
          default: {
            break;
          }
        }
        // setChatData((prev: iChatData[]) => [...prev]);
      } else if (
        /**
         * For Itinerary details
         */
        (parseValue?.intent === DATA_STREAMING_KEY.INTENT.RETURN_TYPE &&
          parseValue?.return_type ===
            DATA_STREAMING_KEY.REUTRN_TYPE.ITINERARY) ||
        initialReturnType === "" ||
        initialReturnType === DATA_STREAMING_KEY.REUTRN_TYPE.ITINERARY
      ) {
        // if (isMobile) navigate(ROUTES.ItineraryDetails);
        // else {
        //   setScreen(ScreenTypes.ItineraryInfo);
        //   setFirstScreen(ScreenTypes.topDestination);
        // }
        switch (parseValue?.intent) {
          case DATA_STREAMING_KEY.INTENT.TRIP_PREFERENCE: {
            if (isMobile) navigate(ROUTES.ItineraryDetails);
            else {
              setScreen(ScreenTypes.ItineraryInfo);
              setFirstScreen(ScreenTypes.topDestination);
            }
            setOpenLoaderOverlay(false);
            setSelectedChatData((prev: any) => ({
              ...prev,
              noOfDays: parseValue?.number_of_travel_days,
              number_of_days: parseValue?.number_of_travel_days,
              numberOfdays: parseValue?.number_of_travel_days,
              ...parseValue,
              from_latitude: parseValue?.departure?.city?.latitude,
              from_longitude: parseValue?.departure?.city?.longitude,
              start_date: parseValue?.departure?.date,
              trip_type: parseValue?.departure?.flights?.trip_type,
              from:
                parseValue?.departure?.city?.name +
                ", " +
                parseValue?.departure?.city?.state +
                ", " +
                parseValue?.departure?.city?.country,
              fromCity:
                parseValue?.departure?.city?.name +
                ", " +
                parseValue?.departure?.city?.state +
                ", " +
                parseValue?.departure?.city?.country,
              flight_cabin_class: parseValue?.departure?.flights?.cabin_class,
              number_of_stops: parseValue?.departure?.flights?.number_of_stops,
              flight_refundable: parseValue?.departure?.flights?.refundable,
              flight_day_part: parseValue?.departure?.flights?.time_of_day,
              departure_airport:
                parseValue?.departure?.flights?.departure_airports,
              arrival_airport: parseValue?.departure?.flights?.arrival_airports,
              airlines: parseValue?.departure?.flights?.airlines,

              number_of_rooms: parseValue?.departure?.hotels?.number_of_rooms,
              hotel_refundable: parseValue?.departure?.hotels?.refundable,
              hotel_star_ratings: parseValue?.departure?.hotels?.star_ratings,
              hotels: parseValue?.departure?.hotels?.hotels_brands,
            }));
            break;
          }
          case DATA_STREAMING_KEY.INTENT.ITINERARY_SUMMARY: {
            if (isMobile) navigate(ROUTES.ItineraryDetails);
            else {
              setScreen(ScreenTypes.ItineraryInfo);
              setFirstScreen(ScreenTypes.topDestination);
            }
            setOpenLoaderOverlay(false);
            console.log("ITINEREYA", parseValue?.description);
            // const numberOfDays = parseValue?.number_of_days || 0;

            const destinationList: any = await Promise.all(
              parseValue?.destinations?.map(async (ele: any) => {
                const cityName = ele?.city_name;
                const noOfDays = ele?.no_of_day?.length || 0;
                const cityCountry = ele?.city_country;
                // const [cityName, cityCountry] = ele?.split(",");
                const imageResponse: any = await getItineraryImageURL(
                  cityName || ""
                );
                let tempTripDetails: iTripDetails[] = [];
                for (let i = 0; i < noOfDays; i++) {
                  tempTripDetails.push({
                    ...TEMP_TRIP_DETAILS_DATA,
                    day: "Day " + ele?.no_of_day[i],
                  });
                }
                return {
                  ...DESTINATION_LIST_DATA,
                  city_name: cityName,
                  city_country_code: ele?.country_code,
                  city_country: cityCountry,
                  imageURL: imageResponse?.data?.image,
                  tripDetails: tempTripDetails.map(
                    (eleTripDetails: iTripDetails) => ({
                      ...eleTripDetails,
                      city_name: cityName,
                    })
                  ),
                };
              })
            );
            if (chatAbortController.signal.aborted) return;
            const pricePayload: any = {
              cities: getItineraryPriceCityList(destinationList),
            };
            // const priceResponse = await getItineraryPriceAPI(pricePayload);
            setCallPriceAPIFlag({
              false: true,
              payload: pricePayload ?? { cities: [] },
            });
            setSelectedChatData((prev: any) => ({
              ...prev,
              singleCity: parseValue?.destinations?.length === 1 ? true : false,
              singleSelectedDestination: true,
              complete_itinerary_description: parseValue?.description,
              number_of_days: parseValue?.number_of_days,
              noOfDays: parseValue?.number_of_days,
              numberOfdays: parseValue?.number_of_days,
              destinationList,
              itinerary_version: Number(parseValue?.version).toFixed(0),
              complete_itinerary_flights_cost: "$" + parseValue?.flights_cost,
              complete_itinerary_hotels_cost: "$" + parseValue?.hotels_cost,
              tripName: extractCityNames(parseValue?.destinations),
              // estimatedPrice: priceResponse?.data?.totalPrice ?? "0",
            }));
            // updateChatDataWithPrice(
            //   setSelectedChatData,
            //   setChatData,
            //   destinationList,
            // );
            break;
          }
          case DATA_STREAMING_KEY.INTENT.PRE_TEXT: {
            if (chatAbortController.signal.aborted) return;
            setSelectedChatData((prev: iChatData) => ({
              ...prev,
              preText: parseValue?.statement,
            }));
            break;
          }
          case DATA_STREAMING_KEY.INTENT.DAILY_PLAN: {
            if (chatAbortController.signal.aborted) return;
            setSelectedChatData((prev: iChatData) => ({
              ...prev,
              destinationList: prev.destinationList.map(
                (eleDestinationList: iCityDetailsResult) => ({
                  ...eleDestinationList,
                  tripDetails: eleDestinationList.tripDetails.map(
                    (eleTripDetails: iTripDetails) => {
                      if (
                        Number(parseValue?.day) ===
                        Number(eleTripDetails?.day.split(" ")[1])
                      ) {
                        const temp_itinerary_by_time_of_the_day =
                          parseValue?.activities?.map((eleActivities: any) => ({
                            ...eleActivities,
                            id: v4(),
                            description: eleActivities?.description,
                            time_of_the_day:
                              eleActivities?.time ||
                              eleActivities?.time_of_the_day,
                            activity_type: eleActivities?.type,
                          }));
                        return {
                          ...eleTripDetails,
                          day_itinerary_description: parseValue?.description,
                          itinerary_by_time_of_the_day_data:
                            temp_itinerary_by_time_of_the_day,
                          itinerary_by_time_of_the_day:
                            temp_itinerary_by_time_of_the_day,
                        };
                      }
                      return eleTripDetails;
                    }
                  ),
                })
              ),
            }));
            break;
          }
          case DATA_STREAMING_KEY.INTENT.POST_TEXT: {
            if (chatAbortController.signal.aborted) return;
            setSelectedChatData((prev: iChatData) => {
              const tempSelectedChatData = {
                ...prev,
                postText: parseValue?.statement,
                id: v4(),
              };
              setChatData((prevChatData) => {
                if (prevChatData.length > 0) {
                  // If it is a ittinery from the chat
                  if (prevChatData[prevChatData.length - 1].isLoading) {
                    return [
                      ...prevChatData.slice(0, -1),
                      {
                        ...tempSelectedChatData,
                      },
                    ];
                  }
                  // else if it is an itineary from destination gird/list
                  else {
                    return prevChatData;
                  }
                }
                return prevChatData;
              });
              return tempSelectedChatData;
            });
            break;
          }
          case DATA_STREAMING_KEY.INTENT.FOLLOW_UP: {
            if (chatAbortController.signal.aborted) return;
            setChatData((prevChatData: any) => {
              if (prevChatData[prevChatData.length - 1].isLoading) {
                return [
                  ...prevChatData.slice(0, -1),
                  {
                    id: v4(),
                    message: parseValue?.statement,
                    // prompt: response?.data?.messages,
                    prompt: [],
                    isUserMessage: false,
                    isLoading: false,
                    destinationList: [],
                    singleSelectedDestination: false,
                  },
                ];
              } else {
                return [
                  ...prevChatData,
                  {
                    id: v4(),
                    message: parseValue?.statement,
                    // prompt: response?.data?.messages,
                    prompt: [],
                    isUserMessage: false,
                    isLoading: false,
                    destinationList: [],
                    singleSelectedDestination: false,
                  },
                ];
              }
            });
            if (isMobile) navigate(ROUTES.TopDestination);
            else {
              setFirstScreen(ScreenTypes.topDestination);
              setScreen("");
            }
            break;
          }
          default: {
            break;
          }
        }
      }
    } catch (error: any) {
      if (chatAbortController.signal.aborted) return;
      setChatData((prevChatData) => {
        if (prevChatData[prevChatData.length - 1].isLoading) {
          return [
            ...prevChatData.slice(0, -1),
            {
              id: v4(),
              message: LLM_RESPONSE_ERROR.jsonError.message,
              isUserMessage: false,
              prompt: [],
              isLoading: false,
              destinationList: [],
              singleSelectedDestination: false,
            },
          ];
        } else {
          return [
            ...prevChatData,
            {
              id: v4(),
              message: LLM_RESPONSE_ERROR.jsonError.message,
              isUserMessage: false,
              prompt: [],
              isLoading: false,
              destinationList: [],
              singleSelectedDestination: false,
            },
          ];
        }
      });

      if (isMobile) navigate(ROUTES.TopDestination);
      else {
        setFirstScreen(ScreenTypes.topDestination);
        setScreen("");
      }
      // setChatData([
      //   {
      //     id: v4(),
      //     message: chatInputText,
      //     isUserMessage: true,
      //     prompt: [],
      //     isLoading: false,
      //     destinationList: [],
      //     singleSelectedDestination: false,
      //   },
      //   {
      //     id: v4(),
      //     message: LLM_RESPONSE_ERROR.jsonError.message,
      //     isUserMessage: false,
      //     prompt: [],
      //     isLoading: false,
      //     destinationList: [],
      //     singleSelectedDestination: false,
      //   },
      // ]);

      // appendErrorMessageTochat(
      //   chatData,
      //   setChatData,
      //   LLM_RESPONSE_ERROR.timeOut.message
      // );
      console.log(value, "PARSING ERROR", error.message);
      scrollToBottom(scrollContainerRef);
      return;
    }
  }

  async function handleShowmoreEvents(value: any): Promise<void> {
    try {
      const parseValue: iStreamingResponse = JSON.parse(value);
      // if (!parseValue) {
      //   return;
      // }
      scrollToBottom(scrollContainerRef);
      if (parseValue?.return_type) {
        initialReturnType = parseValue?.return_type;
        if (chatAbortController.signal.aborted) {
          setChatData((prev: iChatData[]) =>
            prev.map((chatDataEle: iChatData) => {
              if (chatDataEle.isLoading)
                return {
                  ...chatDataEle,
                  isLoading: false,
                };
              else return chatDataEle;
            })
          );
          return;
        }

        setSelectedChatData(SELECTED_CHAT_DATA);
      }

      console.log(
        parseValue,
        "Connected listening events now",
        initialReturnType
      );

      /**
       * For Followup question
       */
      if (
        (parseValue?.intent === DATA_STREAMING_KEY.INTENT.RETURN_TYPE &&
          parseValue?.return_type ===
            DATA_STREAMING_KEY.REUTRN_TYPE.FOLLOW_UP) ||
        initialReturnType === "" ||
        initialReturnType === DATA_STREAMING_KEY.REUTRN_TYPE.FOLLOW_UP
      ) {
        console.log("FOLLOW UP QUESTION");
        if (isMobile) navigate(ROUTES.TopDestination);
        else setFirstScreen(ScreenTypes.topDestination);

        switch (parseValue?.intent) {
          case DATA_STREAMING_KEY.INTENT.FOLLOW_UP: {
            console.log("HERE IT IS");
            // Use a callback to ensure state updates correctly
            if (chatAbortController.signal.aborted) {
              setChatData((prev: iChatData[]) =>
                prev.map((chatDataEle: iChatData) => {
                  if (chatDataEle.isLoading)
                    return {
                      ...chatDataEle,
                      isLoading: false,
                    };
                  else return chatDataEle;
                })
              );
              return;
            }
            setChatData((prev: iChatData[]) =>
              prev.map((chatDataEle: iChatData) => {
                if (chatDataEle.isLoading)
                  return {
                    ...chatDataEle,
                    isLoading: false,
                  };
                else return chatDataEle;
              })
            );
            setChatData((prevChatData: any) => [
              ...prevChatData.slice(0, -1),
              {
                id: v4(),
                message: parseValue?.statement,
                // prompt: response?.data?.messages,
                prompt: [],
                isUserMessage: false,
                isLoading: false,
                destinationList: [],
                singleSelectedDestination: false,
              },
            ]);
            break;
          }
          default: {
            break;
          }
        }
      } else if (
        /**
         * For Destination list
         */
        (parseValue?.intent === DATA_STREAMING_KEY.INTENT.RETURN_TYPE &&
          parseValue?.return_type ===
            DATA_STREAMING_KEY.REUTRN_TYPE.DESTINATIONS) ||
        initialReturnType === "" ||
        initialReturnType === DATA_STREAMING_KEY.REUTRN_TYPE.DESTINATIONS
      ) {
        if (isMobile) navigate(ROUTES.TopDestination);
        else setFirstScreen(ScreenTypes.topDestination);
        switch (parseValue?.intent) {
          case DATA_STREAMING_KEY.INTENT.FOLLOW_UP: {
            if (chatAbortController.signal.aborted) {
              setChatData((prev: iChatData[]) =>
                prev.map((chatDataEle: iChatData) => {
                  if (chatDataEle.isLoading)
                    return {
                      ...chatDataEle,
                      isLoading: false,
                    };
                  else return chatDataEle;
                })
              );
              return;
            }
            // setChatData((prev: any) => [
            //   ...prev.slice(0, -1),
            //   {
            //     ...CHAT_DATA,
            //     preText: parseValue?.statement,
            //   },
            // ]);
            setChatData((prev: iChatData[]) =>
              prev.map((chatDataEle: iChatData) => {
                // if (chatDataEle.id === chatDataArgs.id)
                if (chatDataEle.isLoading) {
                  return {
                    ...chatDataEle,
                    preText: parseValue?.statement,
                  };
                } else return chatDataEle;
              })
            );
            break;
          }
          case DATA_STREAMING_KEY.INTENT.PRE_TEXT: {
            if (chatAbortController.signal.aborted) {
              setChatData((prev: iChatData[]) =>
                prev.map((chatDataEle: iChatData) => {
                  if (chatDataEle.isLoading) {
                    return {
                      ...chatDataEle,
                      isLoading: false,
                    };
                  } else return chatDataEle;
                })
              );
              return;
            }
            // setChatData((prev: any) => [
            //   ...prev.slice(0, -1),
            //   {
            //     ...CHAT_DATA,
            //     preText: parseValue?.statement,
            //   },
            // ]);
            setChatData((prev: iChatData[]) =>
              prev.map((chatDataEle: iChatData) => {
                // if (chatDataEle.id === chatDataArgs.id)
                if (chatDataEle.isLoading) {
                  return {
                    ...chatDataEle,
                    preText: parseValue?.statement,
                  };
                } else return chatDataEle;
              })
            );
            break;
          }
          case DATA_STREAMING_KEY.INTENT.DESTINATION: {
            const imageResponse: any = await getItineraryImageURL(
              parseValue?.destination_city || ""
            );
            if (chatAbortController.signal.aborted) {
              setChatData((prev: iChatData[]) =>
                prev.map((chatDataEle: iChatData) => {
                  if (chatDataEle.isLoading)
                    return {
                      ...chatDataEle,
                      isLoading: false,
                    };
                  else return chatDataEle;
                })
              );
              return;
            }

            // setChatData((prev: iChatData[]) =>
            //   prev.map((chatDataEle: iChatData) => {
            //     // if (chatDataEle.id === chatDataArgs.id)
            //     if (chatDataEle.isLoading) {

            //       return {
            //         ...chatDataEle,
            //         preText: parseValue?.statement,
            //       };
            //     } else return chatDataEle;
            //   })
            // );
            setChatData((prev: any) => {
              let lastChatData = prev[prev.length - 1];
              // prev.splice(-1)[0];
              let tempDestinationList = lastChatData.destinationList;
              for (let i = 0; i < tempDestinationList.length; i++) {
                if (!tempDestinationList[i].destination_name) {
                  tempDestinationList[i] = {
                    ...parseValue,
                    checkedFlag: false,
                    checkFlag: false,
                    id: v4(),
                    imageURL: imageResponse?.data?.image,
                  };
                  break;
                }
              }
              return [
                ...prev.slice(0, -1),
                {
                  id: v4(),
                  ...lastChatData,
                  destinationList: tempDestinationList,
                },
              ];
            });
            break;
          }
          case DATA_STREAMING_KEY.INTENT.POST_TEXT: {
            if (chatAbortController.signal.aborted) {
              setChatData((prev: iChatData[]) =>
                prev.map((chatDataEle: iChatData) => {
                  if (chatDataEle.isLoading)
                    return {
                      ...chatDataEle,
                      isLoading: false,
                    };
                  else return chatDataEle;
                })
              );
              return;
            }
            // setChatData((prev: any) => [
            //   ...prev.slice(0, -1),
            //   {
            //     ...prev.splice(-1)[0],
            //     postText: parseValue?.statement,
            //   },
            // ]);
            setChatData((prev: iChatData[]) =>
              prev.map((chatDataEle: iChatData) => {
                // if (chatDataEle.id === chatDataArgs.id)
                if (chatDataEle.isLoading)
                  return {
                    ...chatDataEle,
                    isLoading: false,
                    postText: parseValue?.statement,
                  };
                else return chatDataEle;
              })
            );
            break;
          }
          default: {
            break;
          }
        }
        // setChatData((prev: iChatData[]) => [...prev]);
      }
    } catch (error: any) {
      if (chatAbortController.signal.aborted) return;
      setChatData((prev: iChatData[]) =>
        prev.map((chatDataEle: iChatData) => {
          // if (chatDataEle.id === chatDataArgs.id)
          if (chatDataEle.isLoading)
            return {
              ...chatDataEle,
              isLoading: false,
            };
          else return chatDataEle;
        })
      );

      if (isMobile) navigate(ROUTES.TopDestination);
      else {
        setFirstScreen(ScreenTypes.topDestination);
        setScreen("");
      }
      console.log(value, "PARSING ERROR", error.message);
      scrollToBottom(scrollContainerRef);
      return;
    }
  }

  let isStatementStart = false;

  function checkForStatementStart(str: string) {
    const match = str.match(/"statement":\s*"([^"]*)"/); // Match the string after "statement":
    return match ? match[1] : null; // Return the captured group if found, otherwise null
  }

  async function handleFollowUpStreamEvents(value: any): Promise<void> {
    try {
      console.log("DATA", value);
      // const formattedValue = checkForStatementStart(value)
      // let appendValue = ""
      // console.log(isStatementStart)
      // if (formattedValue) {
      //   isStatementStart = true
      //   appendValue = formattedValue
      // } else if (isStatementStart) {
      //   appendValue = value
      // }
      setChatData((prevChatData: any) => [
        ...prevChatData.slice(0, -1),
        {
          id: v4(),
          message: prevChatData[prevChatData.length - 1].message + value,
          // prompt: response?.data?.messages,
          prompt: [],
          isUserMessage: false,
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
      ]);
      scrollToBottom(scrollContainerRef);
    } catch (error: any) {}
  }

  const value = {
    handleConversationStreamEvents,
    handleShowmoreEvents,
    handleFollowUpStreamEvents,
    disableInput,
    setDisableInput,
    chatAbortControllerRef,
    chatAbortController,
    streamingInProgressFlag,
    setStreamingInProgressFlag,
  };
  return (
    <DataStreamingContext.Provider value={value}>
      {children}
    </DataStreamingContext.Provider>
  );
};
export const useDataStreamingContext = () => useContext(DataStreamingContext);
