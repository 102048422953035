import { useState } from "react";
import { BookingDetails } from "../../../../types";
import TravelerPopover from "../../bookingPopover/travelerPopover/TravelerPopover";
import styles from "./traveler.module.scss";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../constant/google-analytics/GAEvents";
import { expandIcon } from "../../../../constant";

interface NewTravelersProps {
  className?: string;
  showRoomAndTravellers: boolean;
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
  showOnlyRoom: boolean;
  showInstrcutions: boolean;
}

type CountType =
  | "adultsCount"
  | "childrenCount"
  | "seniorsCount"
  | "numberOfRoomCount";

const NewTravelers: React.FC<NewTravelersProps> = ({
  className,
  bookingDetails,
  setBookingDetails,
  showRoomAndTravellers: showRoom,
  showOnlyRoom,
  showInstrcutions,
}): JSX.Element => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const setCount = (
    type: CountType
  ): React.Dispatch<React.SetStateAction<number>> => {
    return (value) => {
      setBookingDetails((prevDetails) => ({
        ...prevDetails,
        [type]: typeof value === "function" ? value(prevDetails[type]) : value,
        update: true,
      }));
    };
  };

  const setAdultsCount = setCount("adultsCount");
  const setChildrenCount = setCount("childrenCount");
  const setSeniorsCount = setCount("seniorsCount");
  const setNumberOfRoomCount = setCount("numberOfRoomCount");

  const checkCounts = (bookingDetails: BookingDetails) => {
    const { adultsCount, childrenCount, seniorsCount, numberOfRoomCount } =
      bookingDetails;

    const baseCondition =
      adultsCount >= 1 || childrenCount > 1 || seniorsCount >= 1;

    if (showRoom) {
      if (numberOfRoomCount > 1) {
        return adultsCount >= 1 || seniorsCount >= 1;
      }
      return baseCondition && numberOfRoomCount >= 1;
    }

    return baseCondition;
  };

  const checkData = checkCounts(bookingDetails);

  const calculateTotalTravelers = (
    adult: number,
    child: number,
    senior: number
  ): number => {
    return adult + child + senior;
  };

  const totalTravelers = calculateTotalTravelers(
    bookingDetails.adultsCount,
    bookingDetails.childrenCount,
    bookingDetails.seniorsCount
  );

  return (
    <div className={`${className} ${styles.main}`}>
      <TravelerPopover
        adultsCount={bookingDetails.adultsCount}
        setAdultsCount={setAdultsCount}
        childrenCount={bookingDetails.childrenCount}
        setChildrenCount={setChildrenCount}
        seniorsCount={bookingDetails.seniorsCount}
        setSeniorsCount={setSeniorsCount}
        numberOfRoomCount={bookingDetails.numberOfRoomCount}
        setNumberOfRoomCount={setNumberOfRoomCount}
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        showOnlyRoom={showOnlyRoom}
        showInstrcutions={showInstrcutions}
        popoverTrigger={
          <div className={styles.container}>
            <p className={styles.label}>
              {showOnlyRoom ? "ROOM" : "Travelers"}
            </p>
            <div
              className={`${isPopoverOpen ? styles.activeTravelBtn : styles.travelBtn}`}>
              {showOnlyRoom ? (
                <div className={styles.roomCard}>
                  {bookingDetails.numberOfRoomCount > 0 && (
                    <div className={styles.onlyRoomBtn}>
                      <div className={styles.roomBtn}>
                        <span className={styles.roomCount}>
                          {bookingDetails.numberOfRoomCount}
                        </span>
                        <span className={styles.roomLabel}>
                          {`${
                            bookingDetails.numberOfRoomCount === 1
                              ? "ROOM"
                              : "ROOMS"
                          }`}
                        </span>
                      </div>
                      <img
                        src={expandIcon}
                        alt="expand"
                        className={styles.expandIcon}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className={styles.travelersCard}>
                  <div className={styles.travelerBtn}>
                    <span className={styles.totalTravelers}>
                      {totalTravelers}
                    </span>{" "}
                    <span className={styles.travelerLabel}>
                      {totalTravelers > 1 ? "TRAVELERS" : "TRAVELER"}
                    </span>
                  </div>
                </div>
              )}
              {showRoom && (
                <div className={styles.roomBox}>
                  <div className={styles.roomCard}>
                    <p className={styles.room}>
                      {bookingDetails.numberOfRoomCount > 0
                        ? bookingDetails.numberOfRoomCount
                        : ""}
                    </p>
                    <p className={styles.room}>
                      {bookingDetails.numberOfRoomCount > 1 ? "ROOMS" : "ROOM"}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        }
        showRoom={showRoom}
      />
    </div>
  );
};

export default NewTravelers;
