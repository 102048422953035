import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./modifyHotelsSuccess.module.scss";
import { bookedSuccesIcon, ROUTES } from "../../constant";
import { Button } from "@mui/material";
import { iGlobalContext } from "../../context/globalContext/interface";
import { StateContext } from "../../context/globalContext/context";
import { ScreenTypes } from "../../enums";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { iCurrentTripContext } from "../../context/currentTripContext/currentTripContextInterface";
import { useCurrentTripContext } from "../../context/currentTripContext/currentTripContext";
import Navbar from "../../component/desktopLandingPage/navbar/navbar";
import { TRIP_DETAILS_TABS } from "../../component/myTripsPage/myTripsConstants";
import { handleHotelRebookAPI } from "../../api/booking/postBooking";
import { toastMessage } from "../../utils/ToastProvider";
import { TOAST_MESSAGES } from "../../constant/messages/toastMessages";
import NewLoader from "../../component/reusableComponent/newLoader/NewLoader";

export default function ModifyHotelsSuccess() {
  const navigate = useNavigate();
  const state = useContext(StateContext);
  const {
    selectedChatData,
    // userInfo,
    setTripOrItinearyId,
    bookedtransIds,
    setFirstScreen,
    setScreen,
    setMyTripActiveTab,
    setIsUserNavigatingFromPaymentGateway,
    userInfo,
    setIsUserJustBookedTrip,
    isMyTripsTheFirstSelectedScreen,
    setActiveTripDetailsTab,
  }: iGlobalContext = state;
  const { getCurrentTrip }: iCurrentTripContext = useCurrentTripContext();
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const { hotels = [], itineraryID } = bookedtransIds || {};
  const [isLoading, setIsLoading] = useState(false);

  const [isBookingFailed, setIsBookingFailed] = useState(false);

  /**
   * Form data
   */

  const handleViewItinerary = useCallback(() => {
    // window.history.replaceState(null, '', ROUTES.TripDetails);
    setActiveTripDetailsTab(TRIP_DETAILS_TABS.DAILY_PLANS);
    setIsUserJustBookedTrip(true);
    // window.history.pushState({}, "", ROUTES.MyTrips);

    getCurrentTrip(userInfo?.id);
    // setBookedTransIds(null);

    setTripOrItinearyId(selectedChatData?.id);
    if (isMobile) {
      // window.history.deleteAll();
      window.history.pushState({}, "", ROUTES.MyTrips);
      navigate(ROUTES.TripDetails);
    } else {
      setIsUserNavigatingFromPaymentGateway(true);
      // window.history.pushState(null, "", "/");
      // window.history.replaceState(null, "", "/");
      navigate(ROUTES.Home, { replace: true });
      if (isMyTripsTheFirstSelectedScreen) setFirstScreen(ScreenTypes.MyTrips);
      if (!isMyTripsTheFirstSelectedScreen)
        setFirstScreen(ScreenTypes.topDestination);
      setScreen(ScreenTypes.TripDetails);
    }
    // if (isMobile) navigate(ROUTES.MyTrips);
    // else {
    //   setFirstScreen(ScreenTypes.MyTrips);
    //   setScreen("");
    // }
    toast.dismiss();
    setMyTripActiveTab("UPCOMING");
  }, [
    getCurrentTrip,
    isMobile,
    isMyTripsTheFirstSelectedScreen,
    navigate,
    selectedChatData?.id,
    setActiveTripDetailsTab,
    setFirstScreen,
    setIsUserJustBookedTrip,
    setIsUserNavigatingFromPaymentGateway,
    setMyTripActiveTab,
    setScreen,
    setTripOrItinearyId,
    userInfo?.id,
  ]);

  const confirmRebookingAPI = useCallback(async () => {
    if (hotels.length > 0) {
      setIsBookingFailed(false);
      if (!selectedChatData?.id) return;
      const response = await handleHotelRebookAPI({
        userId: userInfo?.id,
        hotels: hotels.map((item: any) => ({
          prebookId: item?.prebookId,
          sessionId: item?.sessionId,
        })),
        id: selectedChatData?.id,
      });
      if (response.statusCode === 200) {
        console.log(response);
        // toastMessage.success(
        //   TOAST_MESSAGES.PostBooking.hotelChangeRequest.messageTitle,
        //   TOAST_MESSAGES.PostBooking.hotelChangeRequest.message
        // );
      } else {
        setIsBookingFailed(true);
      }
    }
  }, [hotels, selectedChatData?.id, userInfo?.id]);

  useEffect(() => {
    confirmRebookingAPI();
  }, [confirmRebookingAPI]);

  if (isBookingFailed || !itineraryID)
    return (
      <NewLoader
        isOpen
        onClose={() => {
          navigate(ROUTES.Home);
        }}
        text={TOAST_MESSAGES.BOOKING.BOOK.bookFailure.message}
        hideLoader
        loadFullScreen
      />
    );

  return (
    <>
      {!isMobile && <Navbar />}
      <div className={styles.successContainer}>
        {isLoading && hotels?.length > 0 && (
          <NewLoader
            isOpen={isLoading}
            onClose={() => setIsLoading(false)}
            text="Loading.."
            loadFullScreen
          />
        )}
        {!!itineraryID && (
          <div className={styles.mainContainer}>
            <div className={styles.booking}>
              <img src={bookedSuccesIcon} alt="booked" />
              <h1>Done!</h1>
            </div>
            <p className={styles.informationText}>
              Your reservation has been modified successfully.
            </p>
            <h1 className={styles.confirmationNumber}>{itineraryID}</h1>
            <Button className={styles.button} onClick={handleViewItinerary}>
              View Itinerary
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
