// export function updateDaywiseTripDate(itinerary: any, startDate: string) {
//   if (!itinerary) return null;
//   return {
//     ...itinerary,
//     city_wise_itinerary: itinerary?.city_wise_itinerary?.map((cityELE: any) =>
//       cityELE?.city_itinerary?.map((ele: any) => {
//         const match = ele?.day.match(/\d+/); // Matches any digit(s) in the string
//         let count = match ? parseInt(match[0], 10) - 1 : 0;
//         return {
//           ...ele,
//           date: getYYYYMMDD(
//             String(
//               new Date(
//                 new Date(startDate).setDate(
//                   new Date(startDate).getUTCDate() + count
//                 )
//               )
//             )
//           ),
//         };
//       })
//     ),
//   };
// }

// export function updateDaywiseTripDateForMetaData(
//   destinationList: any,
//   startDate: string
// ) {
//   if (!destinationList || destinationList?.length === 0) return null;
//   return destinationList?.map((destEle: any) => ({
//     ...destEle,
//     tripDetails: destEle?.tripDetails?.map((ele: any) => {
//       const match = ele?.day.match(/\d+/); // Matches any digit(s) in the string
//       let count = match ? parseInt(match[0], 10) - 1 : 0;
//       return {
//         ...ele,
//         date: getYYYYMMDD(
//           String(
//             new Date(
//               new Date(startDate).setDate(new Date(startDate).getUTCDate() + count)
//             )
//           )
//         ),
//       };
//     }),
//   }));
// }

// function getYYYYMMDD(dateStr: string) {
//   return new Date(dateStr).toLocaleDateString("en-CA");
// }

type Itinerary = {
  city_wise_itinerary?: CityItinerary[];
};

type CityItinerary = {
  city_itinerary?: DayItinerary[];
};

type DayItinerary = {
  day: string;
  date?: string;
};

type Destination = {
  tripDetails?: DayItinerary[];
};

const DAY_REGEX = /\d+/;

export function updateDaywiseTripDate(
  itinerary: Itinerary | null,
  startDate: string
): Itinerary | null {
  if (!itinerary) return null;

  const parsedStartDate = new Date(startDate);

  return {
    ...itinerary,
    city_wise_itinerary: itinerary.city_wise_itinerary?.map(
      (cityItinerary) => ({
        ...cityItinerary,
        city_itinerary: cityItinerary.city_itinerary?.map((dayItinerary) =>
          updateDayItineraryDate(dayItinerary, parsedStartDate)
        ),
      })
    ),
  };
}

export const roundValue = (value: number) => Math.round(value);

export const floorValue = (value: number) => Math.floor(value);

export const calculateNights = (checkIn: string, checkOut: string): number => {
  const checkInDate = new Date(checkIn);
  const checkOutDate = new Date(checkOut);
  const timeDifference = checkOutDate.getTime() - checkInDate.getTime();
  const nights = timeDifference / (1000 * 3600 * 24);
  return nights;
};

export function updateDaywiseTripDateForMetaData(
  destinationList: Destination[] | null,
  startDate: string
): Destination[] | null {
  if (!destinationList || destinationList.length === 0) return null;

  const parsedStartDate = new Date(startDate);

  return destinationList.map((destEle) => ({
    ...destEle,
    tripDetails: destEle.tripDetails?.map((dayItinerary) =>
      updateDayItineraryDate(dayItinerary, parsedStartDate)
    ),
  }));
}

function updateDayItineraryDate(
  dayItinerary: DayItinerary,
  startDate: Date
): DayItinerary {
  const match = dayItinerary.day.match(DAY_REGEX);
  const count = match ? parseInt(match[0], 10) - 1 : 0;
  // const updatedDate = new Date(startDate);
  // updatedDate.setDate(startDate.getUTCDate() + count);
  const updatedDate = new Date(startDate);
  updatedDate.setDate(updatedDate.getDate() + count);

  return {
    ...dayItinerary,
    date: getYYYYMMDD(updatedDate),
  };
}

function getYYYYMMDD(date: Date): string {
  if (!date || isNaN(date.getTime())) return "";
  return date.toISOString().split("T")[0];
}

function calculateAge(dobStr: string): number {
  // Parse the date of birth string into a Date object
  const dob = new Date(dobStr);
  const today = new Date();

  // Calculate the age
  let age = today.getFullYear() - dob.getFullYear();
  const monthDifference = today.getMonth() - dob.getMonth();

  // Adjust age if the birthday hasn't occurred yet this year
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < dob.getDate())
  ) {
    age--;
  }

  return age;
}

export enum PAXTYPE {
  ADULT = "ADT",
  CHILD = "CHD",
  INFANT = "INF",
}

export function getPaxTypeFunc(dateOfBirth: string): string {
  const age = calculateAge(dateOfBirth);
  if (age < 12) return PAXTYPE.CHILD;
  return PAXTYPE.ADULT;
}
