import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
  useRef,
} from "react";
import { localStorageName, ScreenTypes } from "../../enums";
import { ROUTES, SENDING_CHAT_MESSAGE } from "../../constant";
import {
  checkIfThereIsDestination,
  getItineraryPriceCityList,
  scrollToBottom,
  updateChatDataWithPrice,
} from "../../utils";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { useNavigate } from "react-router-dom";
import { getFrequentlyViewTripsApi } from "../../api/getFrequentlyViewTrips";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { iGlobalContext } from "../globalContext/interface";
import { StateContext } from "../globalContext/context";
import ReactGA from "react-ga4";
import SpeechRecognition from "react-speech-recognition";
import { v4 } from "uuid";
import { iRecommendedTripContext } from "./recommendedTripContextInterface";
import { iHistoryContext } from "../historyContext/interface";
import { useHistoryContext } from "../historyContext/historyContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { getItinerariesListAPI } from "../../api/getSaveItinerariesList";
import { iChatData } from "../../types";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import { GetItinerariesListResponse } from "../../interface";
import { chatStreaming } from "../../api/streaming/chatStreaming";
import { iChatStreamingPayload } from "../../interface/common";
import { socket, SOCKET_EVENTS } from "../../utils/socket";
import { SELECTED_CHAT_DATA } from "../../constant/dummyData";
import { iDataStreamingContext } from "../dataStreamingContext/dataStreamingContextInterface";
import { useDataStreamingContext } from "../dataStreamingContext/DataStreamingContext";
import {
  ENVIRONMENT_TYPE,
  INVALID_JSON_PROMPT,
  LLM_RESPONSE_ERROR,
} from "../../constant/common";
import { getItineraryPriceAPI } from "../../api/itinerary/getItineraryPrice";
import { getItineraryImageURL } from "../../api/getItineraryImageURL";
import { chatInteractionAPI } from "../../api/chatInteractionAPI";
import { appendErrorMessageTochat } from "../../utils/appendErrorMessageToChat";
import { environment, STREAMING_ENABLE } from "../../constant/environment";
import { TOAST_MESSAGES } from "../../constant/messages/toastMessages";

const RecommendedTripContext = createContext({} as iRecommendedTripContext);

export const RecommendedTripContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const context = useContext(StateContext);
  const {
    socketId,
    chatInputText,
    setChatInputText,
    setShowMoreFlag,
    setOpenOverlayScreen,
    setChatData,
    setSharedItineraryId,
    setIsItinerarySavedFlag,
    setenableMultiSelect,
    setScreen,
    setFirstScreen,
    openDetailsSliderFlag,
    setSelectedChatData,
    isMobile,
    chatData,
    userInfo,
    appConfig,
  }: iGlobalContext = context;
  const { setCallSaveHistoryAPIFlag }: iHistoryContext = useHistoryContext();
  const {
    handleConversationStreamEvents,
    disableInput,
    setDisableInput,
    chatAbortController,
    chatAbortControllerRef,
    handleFollowUpStreamEvents,
    setStreamingInProgressFlag,
  }: iDataStreamingContext = useDataStreamingContext();

  const { handleCustomError } = useCustomErrorHandler();
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [temFreqViewTrips, setFreqViewTrips] = useSessionStorage(
    localStorageName.TriptroveFrequentlyViewTrips,
    [] as any
  );
  const [index, setIndex] = useState({
    first: 0,
    last: 3,
  });
  useEffect(() => {
    setIndex({
      first: 0,
      last: temFreqViewTrips.length - 1,
    });
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 5320,
      });
    }
  }, [temFreqViewTrips]);

  const [frequentlyViewTrips, setFrequentlyViewTrips] = useState([] as any[]);
  async function getFrequentlyViewTrips() {
    const response = await getFrequentlyViewTripsApi();
    if (response && response?.statusCode === 200) {
      setFreqViewTrips(response?.data);
      setFrequentlyViewTrips([
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
      ]);
    } else {
    }
  }

  const [urlParamQuestion, setURLParamQuestion] = useState("");
  /**
   * Side effect to listen if user has added the question in the URL
   */
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const data = urlParams.get("prompt");
    if (data) {
      const formattedData = decodeURIComponent(data)
        .replace(/\+/g, " ")
        .replace(/=/g, "")
        .replace(/\?/g, "");
      setURLParamQuestion(formattedData);
      setChatInputText(formattedData);
    }
  }, []);

  useEffect(() => {
    if (urlParamQuestion) {
      setChatInputText(urlParamQuestion);
      if (chatInputText) {
        setChatData([
          {
            id: v4(),
            message: urlParamQuestion,
            isUserMessage: true,
            prompt: [],
            isLoading: false,
            destinationList: [],
            singleSelectedDestination: false,
          },
        ]);
        handleCreateItinerary();
      }
    }
  }, [urlParamQuestion]);

  const navigate = useNavigate();
  function extractStatement(str: string) {
    const match = str.match(/"statement":\s*"([^"]*)"/); // Match statement and handle newlines manually
    return match ? match[1].replace(/\\n/g, "\n") : null; // Replace escaped newlines with actual newlines
  }

  async function handleCreateItineraryStreaming() {
    scrollToBottom(scrollContainerRef);
    setenableMultiSelect(false);
    if (chatInputText === "") {
      toastMessage.info("Please type your message!");
      return;
    }
    setOpenOverlayScreen(true);
    setDisableInput(true);
    SpeechRecognition.stopListening();
    setShowMoreFlag(false);
    chatAbortControllerRef.current = chatAbortController;
    setSelectedChatData(SELECTED_CHAT_DATA);
    setChatData([
      {
        id: v4(),
        message: chatInputText,
        isUserMessage: true,
        prompt: [],
        isLoading: false,
        destinationList: [],
        singleSelectedDestination: false,
      },
      {
        id: v4(),
        message: "",
        isUserMessage: false,
        prompt: [],
        isLoading: true,
        destinationList: [],
        singleSelectedDestination: false,
      },
    ]);

    const payload: iChatStreamingPayload = {
      userId: userInfo?.id || "",
      userInput: chatInputText,
      messages: [],
      socketId: socketId || "",
      iteneryInput: null,
      showMore: false,
    };
    socket.on(
      SOCKET_EVENTS.conversation_stream,
      handleConversationStreamEvents
    );
    let response = await chatStreaming(payload, chatAbortController.signal);
    setStreamingInProgressFlag(false);
    if (!response && chatAbortController.signal.aborted) {
      socket.off(
        SOCKET_EVENTS.conversation_stream,
        handleConversationStreamEvents
      );
      socket.off(SOCKET_EVENTS.followup_stream, handleFollowUpStreamEvents);
      setChatData((prev: iChatData[]) => {
        const length = prev.length;
        if (prev.length > chatData.length)
          return [...prev.splice(0, length - 2)];
        return prev;
      });
      return;
    }

    if (response?.data?.result) {
      socket.off(
        SOCKET_EVENTS.conversation_stream,
        handleConversationStreamEvents
      );
      socket.off(SOCKET_EVENTS.followup_stream, handleFollowUpStreamEvents);
      // let shouldFetchPrice = false;
      // let tempDestinationList: any = [];
      setChatData((prevChatData) => {
        if (prevChatData.length > 0) {
          const message = prevChatData[prevChatData.length - 1].message;
          const extractedMessage = extractStatement(message);

          let lastChatData: iChatData = {
            ...prevChatData[prevChatData.length - 1], // Copy the last element
            prompt: response?.data?.messages,
            isLoading: false,
            message: extractedMessage
              ? extractedMessage
              : prevChatData[prevChatData.length - 1].message,
            results: response?.data?.result,
          };
          if (
            lastChatData?.message === "" &&
            lastChatData?.destinationList?.length === 0
          ) {
            if (isMobile) navigate(ROUTES.TopDestination);
            else setFirstScreen(ScreenTypes.topDestination);
            lastChatData = {
              id: v4(),
              message: LLM_RESPONSE_ERROR.timeOut.message,
              isUserMessage: false,
              prompt: [],
              isLoading: false,
              destinationList: [],
              singleSelectedDestination: false,
            };
          }
          // if (lastChatData?.destinationList?.length) {
          //   shouldFetchPrice = true;
          // }
          setSelectedChatData(lastChatData);
          // tempDestinationList = lastChatData?.destinationList ?? [];
          return [
            ...prevChatData.slice(0, -1), // All elements except the last
            lastChatData,
          ];
        }
        return prevChatData; // If empty, return as is
      });

      // if (shouldFetchPrice) {
      //   await updateChatDataWithPrice(
      //     setSelectedChatData,
      //     setChatData,
      //     tempDestinationList
      //   );
      // }
    }

    ReactGA.event({
      category: SENDING_CHAT_MESSAGE.CATEGORY,
      action: SENDING_CHAT_MESSAGE.ACTION,
      label: ` ${SENDING_CHAT_MESSAGE.LABEL}  ${chatInputText}`,
    });
    setOpenOverlayScreen(false);
    setDisableInput(false);
    setCallSaveHistoryAPIFlag({
      flag: true,
      method: "POST",
    });
    setChatInputText("");
    scrollToBottom(scrollContainerRef);
  }

  async function handleCreateItinerary() {
    scrollToBottom(scrollContainerRef);
    setenableMultiSelect(false);
    if (chatInputText === "") {
      toastMessage.info("Please type your message!");
      return;
    }
    setOpenOverlayScreen(true);
    setDisableInput(true);
    SpeechRecognition.stopListening();
    setShowMoreFlag(false);
    const abortController = new AbortController();
    chatAbortControllerRef.current = abortController;
    let response = await chatInteractionAPI(
      userInfo?.id || "",
      chatInputText,
      [],
      abortController.signal,
      null,
      appConfig
    );
    scrollToBottom(scrollContainerRef);
    if (!response && abortController.signal.aborted) {
      setChatData((prev: iChatData[]) => {
        const length = prev.length;
        if (prev.length > chatData.length)
          return [...prev.splice(0, length - 2)];
        return prev;
      });
      return;
    }

    /**
     * Recalling the API if it fails
     */
    if (
      checkIfThereIsDestination(response) === LLM_RESPONSE_ERROR.jsonError.error
    ) {
      response = await chatInteractionAPI(
        userInfo?.id || "",
        chatInputText + INVALID_JSON_PROMPT,
        [],
        abortController.signal,
        null,
        appConfig
      );
    }
    if (
      response === null ||
      (!response && !abortController.signal.aborted) ||
      !response?.data?.result
    ) {
      setChatData([
        {
          id: v4(),
          message: chatInputText,
          isUserMessage: true,
          prompt: [],
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
      ]);
      appendErrorMessageTochat(
        chatData,
        setChatData,
        LLM_RESPONSE_ERROR.timeOut.message
      );

      setOpenOverlayScreen(false);
      setDisableInput(false);
      setScreen("");
      setCallSaveHistoryAPIFlag({
        flag: true,
        method: "POST",
      });
      if (isMobile) navigate(ROUTES.TopDestination);
      else setFirstScreen(ScreenTypes.topDestination);
      setChatInputText("");
      return;
      return;
    }

    setOpenOverlayScreen(false);
    response = checkIfThereIsDestination(response);
    if (response === LLM_RESPONSE_ERROR.jsonError.error) {
      setDisableInput(false);
      if (environment !== ENVIRONMENT_TYPE.PRODUCTION) {
        toastMessage.error(
          TOAST_MESSAGES.SaveItinerary.destinationError.messageTitle,
          TOAST_MESSAGES.SaveItinerary.destinationError.message
        );
      }
      setChatData([
        {
          id: v4(),
          message: chatInputText,
          isUserMessage: true,
          prompt: [],
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
        {
          id: v4(),
          message: LLM_RESPONSE_ERROR.jsonError.message,
          isUserMessage: false,
          prompt: [],
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
      ]);

      setOpenOverlayScreen(false);
      setDisableInput(false);
      setScreen("");
      setCallSaveHistoryAPIFlag({
        flag: true,
        method: "POST",
      });
      if (isMobile) navigate(ROUTES.TopDestination);
      else setFirstScreen(ScreenTypes.topDestination);
      setChatInputText("");
      return;
    }
    const citiesList = response?.data?.cities;
    if (citiesList?.length > 0) {
      const tempResponse = citiesList.map((ele: any) => ({
        ...ele,
        id: v4(),
        checkFlag: false,
        locations: [],
      }));
      setChatData([
        {
          id: v4(),
          message: chatInputText,
          isUserMessage: true,
          prompt: [],
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
        {
          ...response?.data,
          results: response?.data?.results,
          cities: [],
          city_wise_itinerary: [],
          result: "",
          messages: [],
          fromCity: response?.data?.from,
          noOfDays: response?.data?.numberOfdays,
          id: v4(),
          message: "",
          preText: response?.data?.preText,
          postText: response?.data?.postText,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          singleSelectedDestination: response?.data?.singleCity,
          destinationList: tempResponse,
          itinerary_version: "1",
          complete_itinerary_description:
            response?.data?.complete_itinerary_description,
        },
      ]);
      const currentItineraryList = tempResponse;
      for (let i = 0; i < currentItineraryList.length; i++) {
        const cityName =
          currentItineraryList[i]?.destination_city ||
          currentItineraryList[i]?.city_name ||
          currentItineraryList[i]?.destination_city ||
          "";
        let imageRespone: any = await getItineraryImageURL(cityName);
        let count = 0;
        while (!imageRespone || !imageRespone?.data?.image) {
          // Limiting the number of API call to 10 times incase of 409 error
          count++;
          if (count === 3) break;
          // re calling the API
          imageRespone = await getItineraryImageURL(cityName);
        }
        if (imageRespone?.statusCode === 200) {
          currentItineraryList[i].imageURL = imageRespone.data.image;
        } else {
          currentItineraryList[i].imageURL = "https://error.error"
        }
      }
    } else {
      setChatData([
        {
          id: v4(),
          message: chatInputText,
          isUserMessage: true,
          prompt: [],
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
        {
          id: v4(),
          message: response?.data?.result,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
      ]);
    }
    ReactGA.event({
      category: SENDING_CHAT_MESSAGE.CATEGORY,
      action: SENDING_CHAT_MESSAGE.ACTION,
      label: ` ${SENDING_CHAT_MESSAGE.LABEL}  ${chatInputText}`,
    });

    setOpenOverlayScreen(false);
    setDisableInput(false);
    setScreen("");
    setCallSaveHistoryAPIFlag({
      flag: true,
      method: "POST",
    });
    if (isMobile) navigate(ROUTES.TopDestination);
    else setFirstScreen(ScreenTypes.topDestination);
    setChatInputText("");
    scrollToBottom(scrollContainerRef);
  }

  const [, setSelectedRecommendedTripId] = useState("");

  function handleViewItineraryDetails(data: any) {
    setSelectedChatData(
      typeof data?.metaData === "string"
        ? JSON.parse(data?.metaData)[0].selectedChatData
        : data?.metaData.selectedChatData
    );
    setSelectedRecommendedTripId(data.id);
    setSharedItineraryId("");
    setIsItinerarySavedFlag(false);
    triggerGAEvent(
      GA_LABEL_ENUMS.Clicks_Recommended_Trips_card,
      data?.tripName
    );
    if (isMobile) navigate(ROUTES.RecommendedTripDetails);
    setScreen(ScreenTypes.SavedItinerary);
  }

  useEffect(() => {
    if (openDetailsSliderFlag === false) setSelectedRecommendedTripId("");
  }, [openDetailsSliderFlag]);

  const handleCancelStreaming = () => {
    if (chatAbortControllerRef.current) {
      chatAbortControllerRef.current.abort();
      setOpenOverlayScreen(false);
      setChatInputText("");
      setDisableInput(false);
    }
  };

  const handleCancel = () => {
    if (chatAbortControllerRef.current) {
      chatAbortControllerRef.current.abort();
      setOpenOverlayScreen(false);
      setChatInputText("");
      setDisableInput(false);
    }
  };
  function handleScrollLeft() {
    if (index.first > 0 && sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -190,
        behavior: "smooth",
      });
      setIndex({
        first: index.first - 1,
        last:
          (index.last - 1 + temFreqViewTrips.length) % temFreqViewTrips.length,
      });
    }
  }

  function handleScrollRight() {
    setFrequentlyViewTrips([
      ...frequentlyViewTrips,
      temFreqViewTrips[index.first],
    ]);
    setIndex({
      first: (index.first + 1) % temFreqViewTrips.length,
      last: (index.last + 1) % temFreqViewTrips.length,
    });
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 190,
        behavior: "smooth",
      });
    }
  }

  /**
   * Upcoming trip
   */
  const [upcomingTripArr, setUpcomingTripArr] = useLocalStorage(
    "_trip_trove_upcoming_trip_arr",
    []
  );

  async function getUpcomingTrip() {
    try {
      const response: GetItinerariesListResponse | any =
        await getItinerariesListAPI(userInfo?.id, "upcoming", 0, 10);

      if (response?.statusCode === 200) {
        setUpcomingTripArr(response?.data || []);
      } else {
        handleCustomError(response?.statusCode, "itinerary.list");
        return null;
      }
    } catch (error) {
      console.error("Error fetching upcoming trips:", error);
    }
  }

  useEffect(() => {
    getUpcomingTrip();
  }, [userInfo]);

  const value = {
    scrollContainerRef,
    frequentlyViewTrips,
    index,
    handleScrollLeft,
    sliderRef,
    handleViewItineraryDetails,
    handleScrollRight,
    disableInput,
    handleCreateItinerary: STREAMING_ENABLE
      ? handleCreateItineraryStreaming
      : handleCreateItinerary,
    handleCancel: STREAMING_ENABLE ? handleCancelStreaming : handleCancel,
    setDisableInput,
    upcomingTripArr,
    setUpcomingTripArr,
  };
  return (
    <RecommendedTripContext.Provider value={value}>
      {children}
    </RecommendedTripContext.Provider>
  );
};
export const useRecommendedTripContext = () =>
  useContext(RecommendedTripContext);
