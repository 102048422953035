import React, { ChangeEvent } from "react";
import styles from "./newCheck.module.scss";

interface CustomCheckboxProps {
  label: string;
  name: string;
  checkBoxClassName?: string;
  labelClassName?: string;
  handleCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedOptions: { [key: string]: boolean };
  travelerForm?: boolean;
  onClick?: (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void;
  disable?: boolean;
}

const BookingNewCheckBox: React.FC<CustomCheckboxProps> = ({
  label,
  name,
  handleCheckboxChange,
  onClick,
  selectedOptions,
  travelerForm,
  checkBoxClassName = "",
  labelClassName = "",
  disable = false,
}) => {
  return (
    <label
      className={styles.customCheckboxContainer}
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) onClick(e);
      }}>
      <section className={styles.inputBox}>
        <input
          type="checkbox"
          name={name}
          // onClick={onClick}
          checked={selectedOptions?.[name] || false}
          onChange={handleCheckboxChange}
          className={`${checkBoxClassName} ${styles.customCheckbox}`}
          disabled={disable}
        />
        <span
          className={`${disable ? styles.disableCheck : styles.checkmark}`}></span>
      </section>
      {travelerForm ? (
        <p
          className={`${labelClassName} ${styles.label} ${selectedOptions[name] ? styles.selected : ""}`}>
          {label}
        </p>
      ) : (
        <p className={styles.label}>{label}</p>
      )}
    </label>
  );
};

export default BookingNewCheckBox;
