import { SELECTED_CHAT_DATA_DUMMY } from "../../component/tripDetailsPage/activitiesTabs/activitiesConstant";

export {};
export const BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT = {
  Flights: "Flights",
  Hotels: "Hotels",
  Review: "Review",
  Travelers: "Travelers",
  Payment: "Payment",
};

export const INITIAL_BREADCRUMBS_LIST = [
  {
    id: 1,
    value: BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT.Flights,
    checked: true,
    activeCrumbs: true,
    heading: "Search Flights & Hotels",
    price: "$895",
    showSearchFilter: true,
    data: {},
  },
  {
    id: 2,
    value: BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT.Hotels,
    checked: false,
    activeCrumbs: false,
    heading: "Search Flights & Hotels",
    price: "$895",
    showSearchFilter: true,
    data: {},
  },
  // {
  //   id: 3,
  //   value: "Review",
  //   checked: false,
  //   activeCrumbs: false,
  //   heading: "Review Itinerary",
  //   price: "$1,297",
  //   showSearchFilter: false,
  //   data: SELECTED_CHAT_DATA_DUMMY,
  // },
  {
    id: 4,
    value: BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT.Travelers,
    checked: false,
    activeCrumbs: false,
    heading: "Traveler Information",
    price: "$1,297",
    showSearchFilter: false,
    data: {},
  },
  // {
  //   id: 5,
  //   value: "Payment",
  //   checked: false,
  //   activeCrumbs: false,
  //   heading: "Payment",
  //   price: "",
  //   showSearchFilter: false,
  //   data: {
  //     flight: {
  //       heading: "Flights",
  //       list: [
  //         {
  //           key: "3 Travelers",
  //           value: "$840.00"
  //         },
  //         {
  //           key: "Taxes +  Fees",
  //           value: "$75.00"
  //         },
  //         {
  //           key: "Total",
  //           value: "$925.00"
  //         },
  //       ]
  //     },
  //     hotel: {
  //       heading: "Hotels",
  //       list: [
  //         {
  //           key: "2 Rooms",
  //           value: "$642.00"
  //         },
  //         {
  //           key: "Taxes +  Fees",
  //           value: "$80.00"
  //         },
  //         {
  //           key: "Total",
  //           value: "$722.00"
  //         },
  //       ]
  //     },
  //   },
  // },
];