import React, { useContext } from "react";
import NewLoader from "../newLoader/NewLoader";
import {
  bedFontLight,
  failureOvalIcon,
  planeEnginesLight,
} from "../../../constant";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { ScreenTypes } from "../../../enums";
import BookingPreferencesModal from "../../booking/bookingPreferencesModal/bookingPreferencesModal";
import styles from "./bookingModals.module.scss";
import CancelHotelsModal from "../../../routes/modifyHotelReservation/components/cancel-hotels-modal/cancelHotelsModal";
import RoomDetailsModal from "../../hotelDetails/components/roomDetailsModal/roomDetailsModal";
import CancellationPolicyModal from "../../booking/hotelBooking/cancellation-policy-modal/cancellationPolicyModal";

function BookingModals(): JSX.Element {
  const globalContext = useContext(StateContext);
  const {
    showCancelHotelsModal = false,
    setShowCancelHotelsModal,
    isFetchingFlights = false,
    isFetchingHotels = false,
    noFlightFound = false,
    // flightData,
    // hotelData,
    setIsFetchingFlights,
    setIsFetchingHotels,
    selectedFlightOptions,
    isCheckingFlight = false,
    setIsCheckingFlight,
    checkFlightDetails = false,
    setCheckFlightDetails,
    isFetchingHotelDetails,
    setIsFetchingHotelDetails,
    isMobile,
    setScreen,
    setIsFetchingAirports,
    isFetchingAirports,
    setAirPorts,
    shouldUserNavigateBacktoTripDetailsPage,
    showBookingPreferences,
    setShowBookingPreferences,
    setNoFlightFound,
    // setShouldUserNavigateBackTotripDetailsPage,
    setShowRoomDetails,
    showRoomDetails,
    roomDetailsData,
    isCanclPolModalOpen,
    setIsCancPolModalOpen,
    setCancellationData,
  }: iGlobalContext = globalContext || {};
  return (
    <div>
      {" "}
      <BookingPreferencesModal
        isOpen={showBookingPreferences}
        onClose={() => {
          setShowBookingPreferences(false);
        }}
      />
      {selectedFlightOptions.flights && selectedFlightOptions.hotels && (
        <NewLoader
          loaderIcon1={planeEnginesLight}
          loaderIcon2={bedFontLight}
          text={"Searching for flights and hotels"}
          isOpen={isFetchingFlights || isFetchingHotels}
          onClose={() => {
            setIsFetchingFlights(false);
            setIsFetchingHotels(false);
          }}
          loadFullScreen
        />
      )}
      <NewLoader
        failureIcon={failureOvalIcon}
        text="No flights available for your dates"
        subText="Please try another date"
        isOpen={noFlightFound}
        onClose={() => {
          setNoFlightFound(false);
        }}
        cancelButtonClassName={styles.searchBtnClass}
        // bottomBtnText="Search Again"
        hideLoader
        loadFullScreen
      />
      {isFetchingAirports && (
        <NewLoader
          text="Please wait"
          // className={styles.loaderMain}
          // cancelButtonClassName={styles.cancelBtn}
          isOpen={true}
          onClose={() => {
            setIsFetchingAirports(false);
            setAirPorts([]);
          }}
          loadFullScreen
        />
      )}
      {isFetchingHotelDetails && (
        <NewLoader
          loaderIcon1={bedFontLight}
          // loaderIcon2={bedFontLight}
          text="Fetching hotel details"
          isOpen={isFetchingHotelDetails}
          onClose={() => {
            setIsFetchingHotelDetails(false);
          }}
          loadFullScreen
        />
      )}
      {selectedFlightOptions.hotels && !selectedFlightOptions.flights && (
        <NewLoader
          loaderIcon1={bedFontLight}
          // loaderIcon2={bedFontLight}
          text="Searching for hotels"
          isOpen={isFetchingHotels}
          onClose={() => {
            setIsFetchingHotels(false);
          }}
          loadFullScreen
        />
      )}
      {selectedFlightOptions.flights && !selectedFlightOptions.hotels && (
        <NewLoader
          loaderIcon1={planeEnginesLight}
          // loaderIcon2={bedFontLight}
          text="Searching for flights"
          isOpen={isFetchingFlights}
          onClose={() => {
            setIsFetchingFlights(false);
          }}
          loadFullScreen
        />
      )}
      <CancelHotelsModal
        isOpen={showCancelHotelsModal}
        onClose={() => setShowCancelHotelsModal(false)}
      />
      <NewLoader
        loaderIcon1={planeEnginesLight}
        // loaderIcon2={bedFontLight}
        text="Checking flight availability"
        isOpen={isCheckingFlight}
        onClose={() => {
          setIsCheckingFlight(false);
        }}
        loadFullScreen
      />
      <NewLoader
        loaderIcon1={planeEnginesLight}
        // loaderIcon2={bedFontLight}
        text="Checking flight details"
        isOpen={checkFlightDetails}
        onClose={() => {
          setCheckFlightDetails(false);
        }}
        loadFullScreen
      />
      <RoomDetailsModal
        isOpen={showRoomDetails}
        roomDetailsData={roomDetailsData}
        onClose={() => {
          setShowRoomDetails(false);
        }}
      />
      <CancellationPolicyModal
        isOpen={isCanclPolModalOpen}
        onClose={() => {
          setIsCancPolModalOpen(false);
          setCancellationData(null);
        }}
      />
    </div>
  );
}

export default BookingModals;
