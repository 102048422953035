import { Button } from "@mui/material";
import styles from "./flight.module.scss";
import SelectedInfo from "../selectedInfo/selectedInfo";
import { StateContext } from "../../../context/globalContext/context";
import { useCallback, useContext } from "react";
import { iGlobalContext } from "../../../context/globalContext/interface";

interface FlightSelectButtonsProps {
  readonly onContinue: () => void;
  readonly selectFlightHotel: () => void;
  readonly selectedFlightId: string | null;
  readonly selectedHotelId: number | null;
  readonly flight: boolean;
  readonly hotel: boolean;
}

function FlightSelectButtons({
  onContinue,
  selectFlightHotel,
  selectedFlightId,
  selectedHotelId,
  flight,
  hotel,
}: FlightSelectButtonsProps): JSX.Element {
  const state = useContext(StateContext);
  const { selectedHotelRoomArr, bookingDetails }: iGlobalContext = state;

  const areAllRoomsSelected = selectedHotelRoomArr.every((eachHotel) => {
    if (!eachHotel?.selectedHotelRoom || !eachHotel?.selectedHotelRoom) {
      return false;
    }
    if (
      !eachHotel?.selectedHotelRoom?.preBookId ||
      !eachHotel?.selectedHotelRoom?.sessionId
    ) {
      return false;
    }
    return true;
  });

  const handleFlightHotel = useCallback(
    () =>
      bookingDetails.isFlightAvailable &&
      areAllRoomsSelected &&
      selectedFlightId,
    [areAllRoomsSelected, bookingDetails.isFlightAvailable, selectedFlightId]
  );

  const isFlightHotelAvailable = handleFlightHotel();

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.container}>
          {hotel && !flight && (
            <Button
              disabled={!areAllRoomsSelected}
              className={areAllRoomsSelected ? styles.continue : styles.select}
              onClick={() => {
                if (areAllRoomsSelected) {
                  onContinue();
                }
              }}>
              Continue
            </Button>
          )}
          {flight && !hotel && (
            <Button
              disabled={!isFlightHotelAvailable}
              className={
                isFlightHotelAvailable ? styles.continue : styles.select
              }
              onClick={() => {
                if (isFlightHotelAvailable) {
                  onContinue();
                }
              }}>
              Continue
            </Button>
          )}

          {flight && hotel && (
            <Button
              disabled={!handleFlightHotel()}
              className={handleFlightHotel() ? styles.continue : styles.select}
              onClick={() => {
                if (handleFlightHotel()) {
                  onContinue();
                }
              }}>
              Continue
            </Button>
          )}
          {selectedFlightId || selectedHotelId ? null : (
            <div className={styles.selectInfo}>
              <SelectedInfo flight={flight} hotel={hotel} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FlightSelectButtons;
