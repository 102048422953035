import React from "react";
import { Button, TextField } from "@mui/material";
import Budget from "../../component/budget/budget";
import { BudgetTypes, ButtonSizes, ButtonVariants } from "../../enums";
import Experience from "../../component/previewPreference/experience/experience";
import Leave from "../../component/previewPreference/leave/leave";
import Accommodations from "../../component/previewPreference/accomodations/accomodations";
import FoodPreferences from "../../component/previewPreference/foodPreferences/foodPreferences";
import { useCallback, useEffect, useRef, useState } from "react";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import { LocationTypes, UpdateStateTypes } from "../../types";
import Loader from "../../component/reusableComponent/loader/Loader";
import EditButton from "../../component/editBtn/editBtn";
import { useNavigate } from "react-router-dom";
import { newCloseIcon, PathIcon, ROUTES } from "../../constant";
// import ReactGA from "react-ga4";
import styles from "./preview.module.scss";
import DiscardChanges from "../../component/dialog/discardChanges/discardChanges";
import AutocompleteAddress from "../../component/googleMapInputFields/autoCompleteAddress/autoCompleteAddress";
import { getCategory } from "../../api/getCategory";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { APP_CONFIG } from "../../constant/common";
import { getConfigMessage } from "../../utils";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
import {
  addUserPreferencesApi,
  getPreviewPreferenceApi,
  updateUserPreferencesApi,
} from "../../api/preference";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import { TOAST_MESSAGES } from "../../constant/messages/toastMessages";
import {
  AddUserPreferencesResponse,
  iGetPreviewPreferenceResponse,
  iUpdatePreferenceResponse,
} from "../../interface";

function PreviewPreference(): JSX.Element {
  const state = React.useContext(StateContext);
  const {
    userInfo,
    setLoginCreateAccountState,
    loginCreateAccountState,
    isMobile,
    appConfig,
    setUserInfo,
  }: iGlobalContext = state;
  const navigate = useNavigate();
  const firstSectionRef = useRef<HTMLDivElement | null>(null);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [update, setUpdate] = useState<UpdateStateTypes>({
    city: false,
    climate: false,
    population: false,
    exp: false,
    duration: false,
  });
  const [city, setCity] = useState("");
  const [isFormUpdated, setIsFormUpdated] = useState<boolean>(false);
  const [budget, setBudget] = useState<BudgetTypes>(BudgetTypes.Economy);
  const [scrollValue, setScrollValue] = useState<number>(3);
  const [prefId, setPrefId] = useState<string>("");
  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);
  const [population, setPopulation] = useState<string[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string[]>(["Anytime"]);
  const [selectedAccomodation, setSelectedAccomodation] = useState<string[]>(
    []
  );
  const { handleCustomError } = useCustomErrorHandler();
  const [selectedFood, setSelectedFood] = useState<string[]>([]);
  const [climate, setClimate] = useState<string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<LocationTypes>({
    city: "",
    state: "",
    country: "",
  });
  const [aboutMe, setAboutMe] = useState<string>("");
  const updateSuccessMessage =
    appConfig.find((item) => item?.key === APP_CONFIG.TOAST_PREFERENCE_UPDATE)
      ?.value ?? "Your preferences are updated!";
  const addSuccessMessage =
    appConfig.find((item) => item?.key === APP_CONFIG.TOAST_PREFERENCE_ADD)
      ?.value ?? "Your preferences are added!";
  const handleScroll = () => {
    firstSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleUpdate = () => {
    triggerGAEvent(GA_LABEL_ENUMS.preview_preferences_edit_click);
    setUpdate((prevUpdate) => ({ ...prevUpdate, city: !prevUpdate.city }));
  };

  const handleUpdateUserData = useCallback((userData: any) => {
    if (userData) {
      setAboutMe(userData?.aboutMe);
      const { city, state, country } = userData.homeCity || {};
      setCity([city, state, country].filter(Boolean).join(" "));
      setSelectedLocation({
        city: city ?? "",
        state: state ?? "",
        country: country ?? "",
      });
      setSelectedMonth(userData.travelMonth);
      setClimate(userData.climate ? userData.climate.flat() : []);
      setPopulation(userData.population ? userData.population.flat() : []);
      setSelectedAccomodation(
        userData.accommodations ? userData.accommodations.flat() : []
      );
      setSelectedFood(
        userData.foodPreferences ? userData.foodPreferences.flat() : []
      );
      setBudget(userData.budget);
      setPrefId(userData.id ?? "");

      setScrollValue(userData.duration ?? "");
      setSelectedActivities(
        userData.experiences ? userData.experiences.flat() : []
      );
      setShowLoader(false);
    }
  }, []);

  const handleEditAllFields = useCallback(() => {
    setUpdate({
      city: false,
      climate: false,
      duration: false,
      exp: false,
      population: false,
    });
  }, []);

  const getApiData = useCallback(async () => {
    setShowLoader(true);
    try {
      const response: iGetPreviewPreferenceResponse =
        await getPreviewPreferenceApi(userInfo?.id);
      const { statusCode, data } = response;
      handleCustomError(statusCode, "preference.details");
      if (data) {
        handleUpdateUserData(data);
        handleEditAllFields();
        setUserInfo((prev: any) => ({
          ...prev,
          preferences: data,
        }));
      }

      if (data && data.homeCity.city !== "") {
        setUpdate((prevData) => ({
          ...prevData,
          city: true,
        }));
      }
    } catch (error) {
      console.error(error);
      // toastMessage.error(
      //   getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      // );
    } finally {
      setShowLoader(false);
    }
  }, [userInfo?.id, handleUpdateUserData, handleEditAllFields, appConfig]);

  const getCategoryData = useCallback(async () => {
    const storedCategory = window.localStorage.getItem("_all_category");
    if (storedCategory) {
      await getApiData();
    } else {
      try {
        const response = await getCategory(null);
        if (response?.statusCode !== 200) {
          handleCustomError(response?.statusCode, "category.details");
          return null;
        }
        if (response && response?.data) {
          window.localStorage.setItem(
            "_all_category",
            JSON.stringify(response?.data)
          );
          await getApiData();
        } else {
          console.error("Failed to fetch the data");
        }
      } catch (error) {
        console.error(error);
        toastMessage.error(
          "OOPS!",
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
      }
    }
  }, [appConfig, getApiData]);

  useEffect(() => {
    getCategoryData();
  }, [getCategoryData]);

  const handleAddUserPreferences = async () => {
    if (!city) {
      toastMessage.error("", "Please enter your home city");
      return;
    }

    const payload = {
      userId: userInfo?.id,
      homeCity: selectedLocation,
      climate,
      population,
      experiences: selectedActivities,
      travelMonth: selectedMonth,
      accommodations: selectedAccomodation,
      foodPreferences: selectedFood,
      budget,
      duration: scrollValue.toString(),
      aboutMe: aboutMe,
    };

    try {
      // const response = await axios.post(
      //   `${ENDPOINT}/app/v1/users/preferences`,
      //   payload
      // );
      const response: AddUserPreferencesResponse =
        await addUserPreferencesApi(payload);
      if (response?.statusCode !== 200) {
        toastMessage.error(
          TOAST_MESSAGES.PREFERENCES.preferencesUpdateFailure.messageTitle,
          TOAST_MESSAGES.PREFERENCES.preferencesUpdateFailure.message
        );
        handleCustomError(response?.statusCode, "preference.update");
        return null;
      }
      if (response && response.statusCode === 200) {
        triggerGAEvent(GA_LABEL_ENUMS.preview_preferences_save_changes);
        handleScroll();
        getApiData();
        setIsFormUpdated(false);
        setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
        // ReactGA.event({
        //   category: SELECTED_PREFERENCES.CATEGORY,
        //   action: SELECTED_PREFERENCES.ACTION,
        //   label: ` ${SELECTED_PREFERENCES.LABEL}  ${payload}`,
        // });
        toastMessage.success("SUCCESS!", addSuccessMessage);
        setTimeout(() => {
          navigate(ROUTES.Home);
        }, 10);
      } else {
        toastMessage.error(
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
      }
    } catch (error) {
      console.error("Error:", error);
      toastMessage.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
    }
  };

  const handleUpdateUserPreference = async () => {
    if (!city) {
      toastMessage.error("", "Please enter city");
      return;
    }

    const payload = {
      id: prefId,
      userId: userInfo?.id,
      homeCity: selectedLocation,
      climate,
      population,
      experiences: selectedActivities,
      travelMonth: selectedMonth,
      accommodations: selectedAccomodation,
      foodPreferences: selectedFood,
      budget,
      duration: scrollValue.toString(),
      aboutMe: aboutMe,
    };

    try {
      // const response = await axios.put(
      //   `${ENDPOINT}/app/v1/users/preferences`,
      //   payload
      // );
      const response: iUpdatePreferenceResponse =
        await updateUserPreferencesApi(payload);

      if (response?.statusCode !== 200) {
        toastMessage.error(
          TOAST_MESSAGES.PREFERENCES.preferencesUpdateFailure.messageTitle,
          TOAST_MESSAGES.PREFERENCES.preferencesUpdateFailure.message
        );
        handleCustomError(response?.statusCode, "preference.update");
        return null;
      }
      if (response && response?.statusCode === 200) {
        triggerGAEvent(GA_LABEL_ENUMS.preview_preferences_save_changes);
        getApiData();
        handleScroll();
        setIsFormUpdated(false);
        // setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
        // ReactGA.event({
        //   category: SELECTED_PREFERENCES.CATEGORY,
        //   action: SELECTED_PREFERENCES.ACTION,
        //   label: ` ${SELECTED_PREFERENCES.LABEL}  ${payload}`,
        // });
        toastMessage.success("SUCCESS!", updateSuccessMessage);
        setTimeout(() => {
          navigate(ROUTES.Home);
        }, 10);
      } else {
        toastMessage.error(
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
      }
    } catch (error) {
      toastMessage.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
      console.error("Error:", error);
    }
  };

  // useEffect(() => {
  //   getApiData();
  // }, []);

  const handleBack = () => {
    if (isMobile) {
      // if (checkIfThereIsChatContext(chatData)) navigate(ROUTES.TopDestination);
      // else navigate("/");
      navigate("/");
      setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
    } else {
      setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
    }
  };

  useEffect(() => {
    triggerGAEvent(GA_LABEL_ENUMS.preview_preference_load);
  }, []);

  const handleClickOpen = () => {
    triggerGAEvent(GA_LABEL_ENUMS.preview_preference_close_click);
    isFormUpdated ? setOpen(true) : handleBack();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDiscard = () => {
    if (isFormUpdated) {
      getApiData();
      setOpen(false);
      setIsFormUpdated(false);
      // if (chatData?.length > 0) navigate(ROUTES.TopDestination);
      // else navigate("/");
      navigate("/");
      setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
    }
  };

  const handlePlaceSelected = () => {
    setIsFormUpdated(true);
  };

  return (
    <div className={styles.main}>
      {showLoader ? (
        <section className={styles.loader}>
          <Loader />
        </section>
      ) : (
        <div className={styles.container}>
          <DiscardChanges
            open={open}
            onClose={handleClose}
            handleDiscard={handleDiscard}
          />
          <section className={styles.titleCard} ref={firstSectionRef}>
            <div className={styles.titleSubCard}>
              <div className={styles.closeCard} onClick={handleClickOpen}>
                <img src={newCloseIcon} alt="" className={styles.closeIcon} />
                <span className={styles.closeText}>Close</span>
              </div>
              <span className={styles.edit}>
                {prefId === "" ? "Add Preferences" : "Edit Preferences"}
              </span>
            </div>
          </section>

          <section className={styles.cityCard}>
            <div className={styles.cityBox}>
              <div className={styles.cityDiv}>
                <span className={styles.myCity}>My home city: </span>
                {update.city ? (
                  <AutocompleteAddress
                    onPlaceSelected={handlePlaceSelected}
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                    setAddress={setCity}
                    address={city}
                    setIsFormUpdated={setIsFormUpdated}
                  />
                ) : (
                  <span className={styles.city}>
                    {[
                      selectedLocation.city,
                      selectedLocation.state,
                      selectedLocation.country,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </span>
                )}
              </div>
              {!update.city && (
                <div className={styles.editBox}>
                  <EditButton onClick={handleUpdate} />
                </div>
              )}
            </div>
          </section>

          <section className={styles.budget}>
            <Budget
              setBudget={setBudget}
              budget={budget}
              setIsFormUpdated={setIsFormUpdated}
            />
          </section>

          <section className={styles.exp}>
            <Leave
              update={update}
              setUpdate={setUpdate}
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
              setScrollValue={setScrollValue}
              scrollValue={scrollValue}
              setIsFormUpdated={setIsFormUpdated}
            />
          </section>

          <div className={styles.dividerCard}>
            <div className={styles.line}></div>
          </div>

          <section className={styles.accomodation}>
            <FoodPreferences
              selectedFood={selectedFood}
              setSelectedFood={setSelectedFood}
              setIsFormUpdated={setIsFormUpdated}
            />
          </section>

          <div className={styles.dividerCard}>
            <div className={styles.line}></div>
          </div>

          <section className={styles.accomodation}>
            <Accommodations
              selectedAccomodation={selectedAccomodation}
              setSelectedAccomodation={setSelectedAccomodation}
              setIsFormUpdated={setIsFormUpdated}
            />
          </section>

          <div className={styles.dividerCard}>
            <div className={styles.line}></div>
          </div>

          <section className={styles.accomodation}>
            <Experience
              selectedActivities={selectedActivities}
              setSelectedActivities={setSelectedActivities}
              setIsFormUpdated={setIsFormUpdated}
            />
          </section>
          <div className={styles.dividerCard}>
            <div className={styles.line}></div>
          </div>
          <section className={styles.accomodation}>
            <span className={styles.aboutMe}>About Me</span>
            <TextField
              id="textarea"
              placeholder="Add any other preferences here"
              multiline
              fullWidth
              value={aboutMe}
              onChange={(e) => setAboutMe(e.target.value)}
              variant="outlined"
              sx={{
                mt: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#A4D1F0 !important",
                  },
                  "&:hover fieldset": {
                    borderColor: "#A4D1F0 !important",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#A4D1F0 !important",
                  },
                },
              }}
            />
          </section>
          <div className={styles.dividerCard}>
            <div className={styles.line}></div>
          </div>

          <section className={styles.saveCard}>
            <Button
              variant={ButtonVariants.Contained}
              size={ButtonSizes.Small}
              className={styles.skipBtn}
              onClick={handleClickOpen}>
              Cancel
            </Button>
            <Button
              variant={ButtonVariants.Contained}
              size={ButtonSizes.Small}
              className={styles.saveBtn}
              onClick={
                prefId === ""
                  ? handleAddUserPreferences
                  : handleUpdateUserPreference
              }>
              Save Changes
            </Button>
          </section>
        </div>
      )}
    </div>
  );
}

export default PreviewPreference;
