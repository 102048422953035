import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { toastMessage } from "../../../../utils/ToastProvider";
import RangePicker from "../../../../component/booking/inputFields/rangePicker/rangePicker";
import InputDatePicker from "../../../../component/booking/inputFields/inputDatePicker/inputDatePicker";
import NewTravelers from "../../../../component/booking/inputFields/newTravelers/newTravelers";
import Button from "../../../../component/button/button";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../constant/google-analytics/GAEvents";
import NewSingleAirportPicker from "../../../../component/booking/inputFields/newSingleAirportPicker/newSingleAirportPicker";
import { simplifyFlights } from "../../../../utils/booking/flight/simplifyFlights";
import { handleFlightTime } from "../../../../utils/booking/handleFlightTime";
import { iFlightSearchResponse } from "../../../../interface";
import { searchFlights } from "../../../../api/booking/booking";
import { arrowRight, arrowRightArrowLeftRegular } from "../../../../constant";
import { saveBookInputFields } from "../../../../api/booking/saveBookedTransactionDetailsAPI";
import { iHistoryContext } from "../../../../context/historyContext/interface";
import { useHistoryContext } from "../../../../context/historyContext/historyContext";
import MultiCityInputFields from "./multiCItyInputFields/multiCityInputFields";
import useCustomErrorHandler from "../../../../hooks/useCustomErrorHandler";
import styles from "./input.module.scss";
import { BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT } from "../../../../constant/bookingInProgressConstant";
import RouteAndCabinInput from "../../../../component/booking/inputFields/routeAndCabinInput/routeAndCabinInput";
interface InputFieldsProps {
  handleUpdateData: () => void;
  searchHotels?: () => void;
  setHideDetails: Dispatch<SetStateAction<boolean>>;
}

const InputFields: React.FC<InputFieldsProps> = ({
  handleUpdateData,
  searchHotels,
  setHideDetails,
}) => {
  const state = useContext(StateContext);
  const {
    bookingDetails,
    setBookingDetails,
    selectedFlightOptions,
    setSelectedFlightOptions,
    setShowBookingPreferences,
    showBookingPreferences,
    setFlightData,
    setShowMore,
    setIsFetchingFlights,
    userInfo,
    setNoFlightFound,
    selectedChatData,
    setHotelData,
    flightData,
    hotelData,
    searchOptions,
    setSearchOptions,
    selectedAirports,
    activeBreadCrumbs,
  }: iGlobalContext = state;
  const [enableBtn, setEnableBtn] = useState<boolean>(true);
  const [checkBackButton, setCheckBackButton] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { setCallSaveHistoryAPIFlag }: iHistoryContext = useHistoryContext();
  const saveBookingInfoRef = useRef(bookingDetails);
  const { handleCustomError } = useCustomErrorHandler();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    triggerGAEvent(GA_LABEL_ENUMS.booking_flight_hotel_checkbox_click);

    const { name, checked } = event.target;

    setSelectedFlightOptions((prevState) => {
      // Ensure at least one option is always selected
      if (!checked && Object.values(prevState).filter(Boolean).length === 1) {
        return prevState;
      }

      return {
        ...prevState,
        [name]: checked,
      };
    });
  };

  let fetchFlightController: any = useRef(null);

  //Save Itinerary

  const handleSaveBookingInfo = useCallback(async (): Promise<boolean> => {
    const bookFilter = bookingDetails;
    const userId = userInfo?.id;
    const status = "draft";
    const metaData: any = {
      selectedChatData: {
        ...selectedChatData,
        prompt: [],
        postText: "",
        preText: "",
      },
    };

    try {
      setIsModified(true);
      const response = await saveBookInputFields(
        selectedChatData.id,
        userId,
        metaData,
        bookFilter,
        status
      );
      setCallSaveHistoryAPIFlag({
        flag: true,
        method: "PUT",
      });
      console.log("Save successful:", response);
      return true;
    } catch (error) {
      console.error("Save failed:", error);
      return false;
    }
  }, [bookingDetails, selectedChatData, userInfo]);

  useEffect(() => {
    const prevBookingDetails = saveBookingInfoRef.current;
    if (
      !isSaving &&
      JSON.stringify(prevBookingDetails) !== JSON.stringify(bookingDetails)
    ) {
      setIsSaving(true);
      handleSaveBookingInfo()
        .then(() => {
          setIsSaving(false);
        })
        .catch((error) => {
          console.error("Error saving booking info:", error);
          setIsSaving(false);
        });
    }

    saveBookingInfoRef.current = bookingDetails;
  }, [bookingDetails, handleSaveBookingInfo, isSaving]);

  const handleFlightSearch = async (count: number) => {
    setHideDetails(true);
    const flights = bookingDetails.isThisMultiCityTrip
      ? simplifyFlights(selectedAirports, bookingDetails)
      : simplifyFlights(bookingDetails?.airPort, bookingDetails);
    if (flights?.some((flight) => !flight.arrival || !flight.departure)) {
      toastMessage.error(
        "",
        "Please select both arrival and departure airports"
      );
      return;
    }

    // Check for same arrival and departure airports
    if (flights?.some((flight) => flight.arrival === flight.departure)) {
      toastMessage.error(
        "",
        "Arrival and departure airports cannot be the same"
      );
      return;
    }

    // loading state
    const flightTime = handleFlightTime(bookingDetails.travelJourneyData);
    // payload
    const requestBody = {
      userId: userInfo?.id,
      pagination: { offset: count, count: 4 },
      adults: bookingDetails.adultsCount,
      children: bookingDetails.childrenCount,
      infants: 0,
      seniors: bookingDetails.seniorsCount,
      tripType: bookingDetails.tripType,
      cabin: bookingDetails.flightClass,
      currency: "USD",
      segments: flights,
      filters: {
        airlineInclude:
          bookingDetails.flightPreferences?.airlines?.join(", ") || null,
        refundable: bookingDetails?.flightPreferences?.refundable ?? null,
        daypart: flightTime || null,
        stopsCount: bookingDetails?.flightPreferences?.connections || null,
      },
    };
    try {
      if (count === 0) setIsFetchingFlights(true);

      // Create an AbortController for this request
      fetchFlightController.current = new AbortController();
      const FlightData: iFlightSearchResponse = await searchFlights(
        requestBody,
        fetchFlightController.current
      );
      // Handle errors based on status code
      if (FlightData?.statusCode !== 200) {
        handleCustomError(FlightData?.statusCode, "flight.search");
        setIsFetchingFlights(false);
        return null;
      }

      // Handle empty sectors
      const newSectors = FlightData?.data?.sectors || [];
      if (newSectors.length === 0) {
        setNoFlightFound(true);
        setIsFetchingFlights(false);

        return;
      }

      // Update flight data and booking details
      setFlightData((prevData: any) => {
        const prevSectors = prevData?.sectors || [];
        if (newSectors.length < 4) {
          setBookingDetails((prevData) => ({
            ...prevData,
            noMoreFlights: true,
          }));
        }
        return {
          ...prevData,
          sectors: [...prevSectors, ...newSectors],
          encryptedKey: FlightData?.data?.encryptedKey,
          pagination: FlightData?.data?.pagination,
        };
      });

      if (count === 0) setIsFetchingFlights(false);

      // Update visibility for pagination
      setShowMore((prevShowMore) => ({
        ...prevShowMore,
        visibleList: {
          ...prevShowMore.visibleList,
          flight: count,
        },
      }));
    } catch (err) {
      if (err instanceof Error) {
        if (
          err?.name === "AbortError" ||
          err.message.includes("signal is aborted")
        ) {
          console.log("Flight search request was canceled by the user.");
          return;
        }
      } else {
        console.error("An unexpected error occurred:", err);
      }

      // Reset state on failure
      if (count === 0) setIsFetchingFlights(false);

      setFlightData(null);
    } finally {
      console.log("error");
    }
  };

  const handleFlightSearchClick = () => {
    setFlightData(null);
    setSearchOptions({
      flight: true,
      hotel: false,
    });
    setBookingDetails((prevDetails) => {
      return {
        ...prevDetails,
        noMoreFlights: false,
        visibleList: {
          ...prevDetails.visibleList,
          flight: 0,
        },
      };
    });
    handleFlightSearch(0);
  };

  const handleFlightInputValidation = () => {
    const {
      airPort,
      adultsCount,
      childrenCount,
      seniorsCount,
      travelFromDate,
    } = bookingDetails || {};
    setBookingDetails((prevData) => ({
      ...prevData,
      selectedFlightId: "",
    }));
    const checkDeparture = airPort?.some((flight) => flight.departure);
    const checkArrival = airPort?.some((flight) => flight.arrival);
    if (travelFromDate === "") {
      toastMessage.error("", "Please select a travel date.");
      setEnableBtn(true);
      return;
    }
    if (!checkDeparture) {
      toastMessage.error("", "Please select a departure airport.");
      setEnableBtn(true);
      return;
    }
    if (!checkArrival) {
      toastMessage.error("", "Please select a arrival airport.");
      setEnableBtn(true);
      return;
    }

    const totalAdults = adultsCount + seniorsCount;

    if (totalAdults === 0) {
      toastMessage.error("", "At least 1 adult or senior is required.");
      setEnableBtn(true);
      return;
    }

    if (childrenCount > 0 && totalAdults === 0) {
      toastMessage.error(
        "",
        "A child must be accompanied by at least 1 adult or senior."
      );
      setEnableBtn(true);
      return;
    }

    handleFlightSearchClick();
  };

  //reset api data

  const bookingRefData = {
    adultsCount: bookingDetails?.adultsCount,
    childrenCount: bookingDetails?.childrenCount,
    seniorsCount: bookingDetails?.seniorsCount,
    numberOfRoomCount: bookingDetails?.numberOfRoomCount,
    travelFromDate: bookingDetails?.travelFromDate,
    travelToDate: bookingDetails.travelToDate || "",
    flightClass: bookingDetails.flightClass,
    tripType: bookingDetails.tripType,
    filters: bookingDetails?.filters.starRating.map((starRating) => ({
      starRating: starRating,
    })),
    // Flight Preferences
    flightConnections: bookingDetails?.flightPreferences?.connections || 0,
    flightDepartureTime:
      bookingDetails?.flightPreferences?.departureTime.map(
        (departureTime) => departureTime
      ) || [],
    refundableFlights: bookingDetails?.flightPreferences?.refundable || false,
    preferredAirlines: bookingDetails?.flightPreferences?.airlines || [],

    // Hotel Preferences
    refundableHotels: bookingDetails?.hotelPreferences?.refundable || false,
    hotelChains: bookingDetails?.hotelPreferences?.hotelChains || [],
    hotels: bookingDetails.hotels.map((city) => ({
      city: city || "",
    })),
    airPort: bookingDetails?.airPort.map(({ arrival, departure }) => ({
      arrival: arrival || "",
      departure: departure || "",
    })),
  };
  const filteredBookingDetails = {
    adultsCount: bookingDetails.adultsCount,
    childrenCount: bookingDetails.childrenCount,
    seniorsCount: bookingDetails.seniorsCount,
    numberOfRoomCount: bookingDetails.numberOfRoomCount,
    travelFromDate: bookingDetails.travelFromDate,
    travelToDate: bookingDetails.travelToDate,
    flightClass: bookingDetails.flightClass,
    tripType: bookingDetails.tripType,
    filters: bookingDetails?.filters?.starRating?.map((starRating) => ({
      starRating: starRating,
    })),
    // Flight Preferences
    flightConnections: bookingDetails?.flightPreferences?.connections || 0,
    flightDepartureTime:
      bookingDetails?.flightPreferences?.departureTime.map(
        (departureTime) => departureTime
      ) || [],
    refundableFlights: bookingDetails?.flightPreferences?.refundable || false,
    preferredAirlines: bookingDetails?.flightPreferences?.airlines || [],

    // Hotel Preferences
    refundableHotels: bookingDetails?.hotelPreferences?.refundable || false,
    hotelChains: bookingDetails?.hotelPreferences?.hotelChains || [],
    hotels: bookingDetails.hotels.map((city) => ({
      city,
    })),
    airPort: bookingDetails.airPort.map(({ arrival, departure }) => ({
      arrival,
      departure,
    })),
  };

  const prevBookingDetailsRef = useRef(
    bookingDetails ? filteredBookingDetails : null
  );
  const handleBookingDetailsChange = async () => {
    if (checkBackButton) return;
    const { ...currentDetailsWithoutVisibleList } = bookingRefData || {};
    const { ...prevDetailsWithoutVisibleList } =
      prevBookingDetailsRef.current || {};

    if (
      isModified &&
      JSON.stringify(currentDetailsWithoutVisibleList) !==
        JSON.stringify(prevDetailsWithoutVisibleList)
    ) {
      // await setFlightData(null);
      // await setHotelData(null);
      setIsModified(false);
      setBookingDetails((prevData) => ({
        ...prevData,
        isDataModified: {
          ...prevData.isDataModified,
          flight: true,
          hotel: true,
        },
      }));
    }
  };

  useEffect(() => {
    if (flightData === null && hotelData === null) {
      return;
    }
    handleBookingDetailsChange();
  }, [bookingDetails, isModified]);

  useEffect(() => {
    const {
      airPort,
      adultsCount,
      seniorsCount,
      childrenCount,
      travelFromDate,
    } = bookingDetails || {};

    // Check if airPort is empty or has missing fields
    const isFieldMissing =
      !airPort?.length ||
      airPort.some((flight) => {
        return !flight.departure || !flight.arrival;
      });

    // Check if travelFromDate is missing or empty
    const isTravelDateMissing = !travelFromDate;

    const shouldDisableButton =
      isFieldMissing ||
      isTravelDateMissing ||
      (childrenCount === 1 && adultsCount < 1 && seniorsCount < 1) ||
      (adultsCount < 1 && seniorsCount < 1) ||
      (bookingDetails.isThisMultiCityTrip && selectedAirports.length === 0);
    setEnableBtn(shouldDisableButton);
  }, [bookingDetails, selectedAirports]);

  const handleSearch = useCallback(async () => {
    try {
      await handleUpdateData();

      if (searchHotels) {
        searchHotels();
        setSearchOptions((prevData) => ({
          ...prevData,
          hotel: true,
        }));
      } else {
        console.warn("searchHotels is undefined.");
      }
    } catch (error) {
      console.error("An error occurred during the search process:", error);
    }
  }, [handleUpdateData, searchHotels]);

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.searchContainer}>
          <div className={styles.travelersCard}>
            <NewTravelers
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              showRoomAndTravellers={false}
              showOnlyRoom={false}
              showInstrcutions={true}
            />

            <RouteAndCabinInput
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              tripType={bookingDetails.tripType}
            />
          </div>
          {!bookingDetails.isThisMultiCityTrip && (
            <div className={styles.calendarCard}>
              <RangePicker
                startDate={bookingDetails.travelFromDate}
                endDate={bookingDetails.travelToDate}
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                handleUpdateData={() => {
                  handleUpdateData();
                }}
                label="DEPARTURE"
              />
              <InputDatePicker
                label="RETURN"
                startDate={bookingDetails.travelToDate}
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                handleUpdateData={() => {}}
              />
            </div>
          )}
          {bookingDetails.isThisMultiCityTrip ? (
            <MultiCityInputFields
              handleUpdateTravelData={() => {
                handleUpdateData();
              }}
            />
          ) : (
            <div className={styles.airportCard}>
              {bookingDetails?.airPort &&
                bookingDetails?.airPort?.length >= 1 &&
                bookingDetails.airPort.map((i, index) => (
                  <div className={styles.newInputCard2} key={index}>
                    <div className={styles.cityInfoCard}>
                      <p className={styles.cityInfo}>
                        {i?.departureCityAddress?.split(",")[0]}
                      </p>
                      <img
                        src={arrowRight}
                        alt=""
                        className={styles.arrowIcon}
                      />
                      <p className={styles.cityInfo}>
                        {i?.arrivalCityAddress?.split(",")[0]}
                      </p>
                    </div>
                    <div className={styles.card2}>
                      <NewSingleAirportPicker
                        indexNumber={index}
                        label="AIRPORT"
                        googleInputAddress={i.departureGoogleAddress}
                        selectedAirportCode={i.departure}
                        setGoogleInputAddress={(newAddress) => {
                          const updatedAirports = [...bookingDetails.airPort];
                          updatedAirports[index] = {
                            ...updatedAirports[index],
                            departureGoogleAddress: newAddress,
                          };
                          setBookingDetails({
                            ...bookingDetails,
                            airPort: updatedAirports,
                          });
                        }}
                        selectedAddress={i.departureCityAddress}
                        setSelectedAddress={(newAddress) => {
                          const updatedAirports = [...bookingDetails.airPort];
                          updatedAirports[index] = {
                            ...updatedAirports[index],
                            departureCityAddress: newAddress,
                          };
                          setBookingDetails({
                            ...bookingDetails,
                            airPort: updatedAirports,
                          });
                        }}
                        airportName={i.departureAirportFullName}
                        handleUpdateData={() => {}}
                        value={i.departure}
                        handleAirportChange={(flight) => {
                          const updatedAirports = [...bookingDetails.airPort];
                          updatedAirports[index] = {
                            ...updatedAirports[index],
                            departure: flight.airportCode,
                            departureAirportFullName: flight.airportName,
                          };
                          setBookingDetails({
                            ...bookingDetails,
                            airPort: updatedAirports,
                          });
                        }}
                        clearInputValues={() => {
                          const updatedAirports = [...bookingDetails.airPort];
                          updatedAirports[index] = {
                            ...updatedAirports[index],
                            departure: "",
                            departureAirportFullName: "",
                            departureGoogleAddress: "",
                          };
                          setBookingDetails({
                            ...bookingDetails,
                            airPort: updatedAirports,
                          });
                        }}
                      />

                      <img
                        src={arrowRightArrowLeftRegular}
                        alt="icon"
                        className={styles.rightLeftIcon}
                      />
                      <div className={styles.card2}>
                        <NewSingleAirportPicker
                          indexNumber={index}
                          googleInputAddress={i.arrivalAddress}
                          selectedAirportCode={i.arrival}
                          setGoogleInputAddress={(newAddress) => {
                            const updatedAirports = [...bookingDetails.airPort];
                            updatedAirports[index] = {
                              ...updatedAirports[index],
                              arrivalAddress: newAddress,
                            };
                            setBookingDetails({
                              ...bookingDetails,
                              airPort: updatedAirports,
                            });
                          }}
                          selectedAddress={i.arrivalCityAddress}
                          setSelectedAddress={(newAddress) => {
                            const updatedAirports = [...bookingDetails.airPort];
                            updatedAirports[index] = {
                              ...updatedAirports[index],
                              arrivalCityAddress: newAddress,
                            };
                            setBookingDetails({
                              ...bookingDetails,
                              airPort: updatedAirports,
                            });
                          }}
                          airportName={i.arrivalAirportFullName}
                          handleUpdateData={() => {}}
                          value={i.arrival}
                          handleAirportChange={(flight) => {
                            const updatedAirports = [...bookingDetails.airPort];
                            updatedAirports[index] = {
                              ...updatedAirports[index],
                              arrival: flight.airportCode,
                              arrivalAirportFullName: flight.airportName,
                            };
                            setBookingDetails({
                              ...bookingDetails,
                              airPort: updatedAirports,
                            });
                          }}
                          clearInputValues={() => {
                            const updatedAirports = [...bookingDetails.airPort];
                            updatedAirports[index] = {
                              ...updatedAirports[index],
                              arrival: "",
                              arrivalAirportFullName: "",
                              arrivalAddress: "",
                            };
                            setBookingDetails({
                              ...bookingDetails,
                              airPort: updatedAirports,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
          <div className={styles.searchBtn}>
            <Button
              size="md"
              onClick={() => {
                // selectedFlightOptions.flights &&
                // searchOptions.flight

                if (
                  activeBreadCrumbs?.value ===
                  BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT.Flights
                )
                  handleFlightInputValidation();
                else if (
                  activeBreadCrumbs?.value ===
                  BOOKING_IN_PROGRESS_BREADCRUMBS_TEXT.Hotels
                )
                  handleSearch();
                // if (searchHotels) searchHotels();
                // searchFlights()
              }}
              disabled={enableBtn}>
              Search
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputFields;
