import {
  getMonthDateUTC,
  getTwelveHourTimeFormat,
} from "../../../utils/dateUtils";
import NewFlightListing from "../newFlightListingCard/newFlightListingCard";

interface FlightProps {
  flightData: any;
}

const FlightListingComponent = ({ flightData }: FlightProps): JSX.Element => {
  if (flightData?.sectors[0]?.outbound?.length > 1)
    flightData = {
      ...flightData,
      sectors: flightData?.sectors.map((sector: any) => ({
        ...sector,
        inbound: [sector.outbound[1]],
        outbound: [sector.outbound[0]],
      })),
    };
  return (
    <div>
      {flightData?.sectors?.map((flight: any, index: number) =>
        flight.outbound?.map((outboundFlight: any, outboundIndex: number) => {
          const inboundFlight = flight?.inbound?.[outboundIndex];
          if (!outboundFlight) return null;
          const {
            airlineImage: outboundAirlineImage,
            arrivalDate: outboundArrivalDate,
            stopsCount: outboundStopsCount,
            departureDate: outboundDepartureDate,
            totalTime: outboundTotalTime,
            route: outboundRoute,
          } = outboundFlight;

          const inboundFlightDetails = inboundFlight
            ? {
                airlineImage: inboundFlight.airlineImage,
                arrivalDate: inboundFlight.arrivalDate,
                stopsCount: inboundFlight.stopsCount,
                departureDate: inboundFlight.departureDate,
                totalTime: inboundFlight.totalTime,
                route: inboundFlight.route,
              }
            : null;

          return (
            <NewFlightListing
              key={`${index}-${outboundIndex}`}
              outboundFlightAirlineImage={outboundAirlineImage}
              outboundFlightDepartureTime={getTwelveHourTimeFormat(
                outboundDepartureDate
              )}
              outboundFlightDepartureDate={getMonthDateUTC(
                outboundDepartureDate
              )}
              outboundFlightArrivalDate={getMonthDateUTC(outboundArrivalDate)}
              outboundFlightArrivalTime={getTwelveHourTimeFormat(
                outboundArrivalDate
              )}
              inboundFlightDepartureTime={
                inboundFlightDetails
                  ? getTwelveHourTimeFormat(inboundFlightDetails.departureDate)
                  : ""
              }
              inboundFlightDepartureDate={
                inboundFlightDetails
                  ? getMonthDateUTC(inboundFlightDetails.departureDate)
                  : ""
              }
              inboundFlightArrivalDate={
                inboundFlightDetails
                  ? getMonthDateUTC(inboundFlightDetails.arrivalDate)
                  : ""
              }
              inboundFlightArrivalTime={
                inboundFlightDetails
                  ? getTwelveHourTimeFormat(inboundFlightDetails.arrivalDate)
                  : ""
              }
              outboundFlightStopsCount={outboundStopsCount}
              outboundFlightTotalTime={outboundTotalTime}
              outboundFlightRoute={outboundRoute}
              inboundFlightAirlineImage={
                inboundFlightDetails?.airlineImage || null
              }
              inboundFlightStopsCount={inboundFlightDetails?.stopsCount || null}
              inboundFlightTotalTime={inboundFlightDetails?.totalTime || null}
              inboundFlightRoute={inboundFlightDetails?.route || null}
              flightCost={Math?.round(flight?.price)}
              encryptedKey={flightData?.encryptedKey}
              uniqueId={flight?.uniqueId}
            />
          );
        })
      )}
    </div>
  );
};

export default FlightListingComponent;
