import React from "react";
import { Stack, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../../context/globalContext/context";
import { DialogSelected } from "../../constant/common";
import { iGlobalContext } from "../../context/globalContext/interface";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { TOAST_MESSAGES } from "../../constant/messages/toastMessages";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
import { logout } from "../../api/authentication";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import { Logoutresponse } from "../../interface";
import Cookies from "js-cookie";
import { environment } from "../../constant/environment";

interface iProps {
  closeDrawer: () => void;
}

export default function Login({ closeDrawer }: iProps): JSX.Element {
  const navigate = useNavigate();
  // Context
  const state = React.useContext(StateContext);
  const {
    setNav,
    setMenuOpenState,
    setLoginCreateAccountState,
    setSignInClick,
    setStartingValue,
    setDestValue,
    setClimate,
    setPopulation,
    setExperience,
    setAccomodations,
    setFoodPreferences,
    setMonths,
    setSelectedDestFlag,
    setTripForNDays,
    setIsLoggedIn,
    setBudget,
    setScreen,
    setFirstScreen,
    userInfo,
    setUserInfo,
  }: iGlobalContext = state;
  const { handleCustomError } = useCustomErrorHandler();
  // Sign in
  function handleSignIn() {
    setSignInClick(true);
    setMenuOpenState(false);
    setLoginCreateAccountState({ flag: true, page: DialogSelected.LOGIN });
    closeDrawer();
  }

  function resetAppState() {
    setScreen("");
    setFirstScreen("");
    window.localStorage.removeItem("JWT");

    const resetUserInfo = {
      id: "",
      name: "",
      email: "",
      phone: "",
      profile_pic: "",
      loggedIn: false,
      role: 0,
    };
    [setUserInfo].forEach((setFunc) => setFunc(resetUserInfo));
    setIsLoggedIn(false);

    setNav("LANDING_PAGE");
    setSelectedDestFlag(false);
    setStartingValue({ value: "", flag: false });
    setDestValue({ value: "", flag: false });

    resetStateValues([
      setClimate,
      setPopulation,
      setExperience,
      setFoodPreferences,
      setAccomodations,
      setMonths,
      setBudget,
      setTripForNDays,
    ]);

    closeDrawer();
  }

  function resetStateValues(
    setters: React.Dispatch<React.SetStateAction<any[]>>[]
  ) {
    setters.forEach((setter) => setter([]));
  }

  function clearStorage(keys: string[]) {
    keys.forEach((key) => {
      window.localStorage.removeItem(key);
      window.sessionStorage.removeItem(key);
    });
  }

  function postLogoutMessage() {
    const iframe = document.querySelector(
      "#app_domain"
    ) as HTMLIFrameElement | null;
    const wind = iframe?.contentWindow;
    wind?.postMessage(JSON.stringify({}), "*");

    if (window && window.parent) {
      const message = {
        action: "logout",
        data: {},
      };
      // window.parent.postMessage(JSON.stringify(message), "*");
      window.parent.postMessage(JSON.stringify(message));
      console.log(message);
    }
  }

  async function handleLogoutClick() {
    try {
      // const response: Logoutresponse = await logout(userInfo?.id);
      // if (response?.statusCode !== 200) {
      //   handleCustomError(response?.statusCode, "user.logout");
      //   return null;
      // }
      // if (response?.statusCode !== 200) {
      //   toastMessage.error("OOPS", "Logout failed");
      // }
      // Remove cookies in this function as well
      Cookies.remove(`${environment}userData`, {
        path: "/",
        domain: ".zenvoya.ai",
      });

      toastMessage.success(
        TOAST_MESSAGES.AUTHENTICATION.logoutSuccess.messageTitle,
        TOAST_MESSAGES.AUTHENTICATION.logoutSuccess.message
      );

      // Clear state and storage
      resetAppState();
      clearStorage([
        "JWT",
        "_starting",
        "_destination",
        "_extra_meta",
        "_international",
        "_select_dest_flag",
        "shared_itinerary_id",
        "_itinerary_details_from_list",
        "_itinerary_details",
        "_shared_itinerary_id",
        "_sliderValue",
        // "_trip_trove_selected_chat_data",
      ]);

      // Navigate and trigger post-logout actions
      navigate("/");
      triggerGAEvent(GA_LABEL_ENUMS.Logout);
      postLogoutMessage();
    } catch (error) {
      console.error("Error during logout:", error);
      toastMessage.error("Logout failed. Please try again.");
    }
  }

  return (
    <Stack
      zIndex={999}
      bgcolor="transparent"
      sx={{ borderRadius: "4px" }}
      spacing={2}
    >
      {userInfo?.loggedIn === true ? (
        <Button
          onClickCapture={handleLogoutClick}
          size="small"
          sx={{
            mb: { sm: 4, md: 0 },
            bgcolor: "#BF150D",
            color: "#ffff",
            borderRadius: "46.5px",
            boxShadow: "none",
            textTransform: "none",
            fontFamily: "Lato",
            pt: 0.5,
            pb: 0.5,
            pl: { xs: 2, sm: 2, md: 2 },
            pr: { xs: 2, sm: 2, md: 2 },
            "&:hover": {
              bgcolor: "#BF150D",
              color: "#ffff",
            },
          }}
          variant="text"
        >
          Log out
        </Button>
      ) : (
        <Button
          variant="contained"
          size="small"
          sx={{
            mb: { xs: "80px", sm: 4, md: 0 },
            bgcolor: "#BF150D",
            color: "#fff",
            borderRadius: "46.5px",
            boxShadow: "none",
            textTransform: "none",
            fontFamily: "Lato",
            pt: 0.5,
            pb: 0.5,
            pl: { xs: 2, sm: 2, md: 2 },
            pr: { xs: 2, sm: 2, md: 2 },
            "&:hover": {
              bgcolor: "#BF150D",
              color: "#fff",
            },
            width: "max-content",
            alignSelf: "center",
          }}
          onClickCapture={() => {
            triggerGAEvent(GA_LABEL_ENUMS.Login_in_click);
            handleSignIn();
          }}
        >
          Login
        </Button>
      )}
    </Stack>
  );
}
