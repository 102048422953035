import { fetchApi } from "../../utils/apiFunction";
import { endPoints } from "../../constant/api_constants/url";
import { API_METHODS } from "../../enums";
import {
  iCancelHotelsArgs,
  iGetHotelRoomsArgs,
  iHandleHotelRebookArgs,
  iHotelRebookArgs,
  iHotelSummaryArgs,
} from "../../interface";
import { Dispatch, ReactNode, SetStateAction } from "react";

export const changeFlight = async (requestBody: any) => {
  return await fetchApi(
    endPoints.flightModify,
    API_METHODS.POST,
    requestBody,
    null
  );
};

export const cancelFlight = async (requestBody: any) => {
  return await fetchApi(
    endPoints.flightCancel,
    API_METHODS.POST,
    requestBody,
    null
  );
};

export const getPostHotelsSummaryAPI = async (
  requestBody: iHotelSummaryArgs
) => {
  return await fetchApi(
    endPoints.hotelSummary,
    API_METHODS.POST,
    requestBody,
    null
  );
};

export const getHotelRoomsAPI = async (requestBody: iGetHotelRoomsArgs) => {
  return await fetchApi(
    endPoints.postBookingHotelRooms,
    API_METHODS.POST,
    requestBody,
    null
  );
  // const result = new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve({
  //       status: 200,
  //       message: "Hotel room pricing request processed successfully.",
  //       data: {
  //         status: "success",
  //         data: [
  //           {
  //             hotelBookId: "3bRgMaK7G",
  //             pagination: {
  //               offset: 0,
  //               count: 5,
  //             },
  //             rooms: [
  //               {
  //                 roomName: "Queen Room with Balcony",
  //                 roomId: 156687915,
  //                 roomDimensions: {
  //                   size: 22,
  //                   unit: "m2",
  //                 },
  //                 maxAdults: 2,
  //                 maxChildren: 1,
  //                 maxOccupancy: 2,
  //                 roomTypeId: 156687915,
  //                 bedInformation: [
  //                   {
  //                     quantity: 1,
  //                     bedType: "Large bed(s) (King size)",
  //                   },
  //                 ],
  //                 roomAmenities: [
  //                   "Shower",
  //                   "Air conditioning",
  //                   "Balcony",
  //                   "Bathrobe",
  //                   "Free toiletries",
  //                   "Private bathroom",
  //                   "Wireless Internet",
  //                   "Flat-screen TV",
  //                   "Coffee machine",
  //                   "Linen",
  //                   "Cots",
  //                 ],
  //                 roomImages: [
  //                   {
  //                     url: "https://static.cupid.travel/rooms-large-pictures/247231904.jpg",
  //                     description: "",
  //                   },
  //                   {
  //                     url: "https://static.cupid.travel/rooms-large-pictures/247231957.jpg",
  //                     description: "",
  //                   },
  //                   {
  //                     url: "https://static.cupid.travel/rooms-large-pictures/247231926.jpg",
  //                     description: "",
  //                   },
  //                 ],
  //                 roomPrices: [
  //                   {
  //                     uniqueId:
  //                       "GE5ESNBSIZKVEQ2OJRFEURSPKRFVUR2OIRKVGVCLKVEVERCVKNLUWRKJGVDVKVKVGJEESRK2KZEVCMSPJRFEYRKPK5FFGS2SI5KTIU2TLBFFMS2WKVJEGQSKLJFEMR2SGJNEOSSLIUZFIU2DJNHEOVJSK5JUKSKGJBDEKVKTJBGEMTSGJFKEWTSMJJGFKT2WJJKEWUSEKU2FOU2VJE2U2VCJKZBU4SSWJJDE6VCLJZDU4Q2FK5KEWVKJKJCFKU2XJNCUUVSHKZCVMMSIJFKVUVKPKIZE6S2KJNCU6V2TKVEVER2WKVJVGWKKIJCEITKSINCEURSOIZDVEMSCJRFEGRKXKRFUGS2OIRKVGV2TLFGEMR2WIVKUWSCKIVNEMSKRJNHEWSSKKVHU4SSXJE2UMVCHKVNFQSSOJRCVKV2DLJFVURSWJFKUGU2KJZGEKT2VKNCUOUSEKVGVCS2VJFDESVCHJZBUQSSVLJKUSUJSKBEVCMSFJ5KVGSCJGVDVMQ2NKNKUWQSJKZJVMQ2QJJLE4RSNKNBUER2SINDEOVBSINGEERCWJNLUWVKKIZDVKSKUINFUOVSFKVGVIMSKJRFEYRKPKZFFISKSI5KTEU2TKREVMR2WKVLEGRCKKJDEMTKSGJLEOTSDIUZFIS2LJNHESRSFKNJUMSSWJNDESTSDJBFEMTKVJFJEWT2KJJEVKT2TJMZESUSDKUZFKU2TJE2UCVSTKNBVUSS2JNCESUZSJJGEMS2FJNKFGS2LKJEUMQ2XJNKUSNKIIVKVOQ2IJFDE2VKHKIZFMS2OJFCUWVCTJNEVMR2WJFKVGRKJGVCVMU2SINDEUWSGIU2FEMSCJRFEEVCFKRFVGS2OINKUGV2TIVEU4SCWIVKUWSCJIY3EIQ2OIJKEQQKYIREU2VBUI5EVSRCFJZFE4R2BLJBTETKSKNCUCWSEINHVEUKHIE2UIQKNIQ2EWSSIKF6DKNJWGYYTE7BSPRWHAOBXMU2DI7BRFV6FKU2EPRKVG7BSGAZDKLJQGIWTEM34GIYDENJNGAZC2MRXPR6DMLRQGA",
  //                     roomOnly: true,
  //                     refundable: true,
  //                     rateType: "Refundable",
  //                     cancellationPolicy:
  //                       "If cancelled before Feb 22, 2025 09:00 PM, the cancellation fee is 317.21 USD.",
  //                     supplierPrice: "1438.42",
  //                     tax: "185.98",
  //                     suggestedSellingPrice: "1495.96",
  //                     sellingPrice: "1495.96",
  //                     amountDue: 0.0,
  //                     // "toSave": "1438.42_185.98_0.00_57.54",
  //                     taxIncluded: "yes",
  //                     feesIncluded: false,
  //                     currency: "USD",
  //                     included: {
  //                       description: "Tax",
  //                       amount: 185.98,
  //                     },
  //                     excluded: {
  //                       description: "Mandatory Tax, Resort Fee",
  //                       amount: 206.4,
  //                     },
  //                   },
  //                 ],
  //               },
  //               {
  //                 roomName: "King With Balcony",
  //                 roomId: 156687905,
  //                 roomDimensions: {
  //                   size: 24,
  //                   unit: "m2",
  //                 },
  //                 maxAdults: 2,
  //                 maxChildren: 1,
  //                 maxOccupancy: 2,
  //                 roomTypeId: 156687905,
  //                 bedInformation: [
  //                   {
  //                     quantity: 1,
  //                     bedType: "Extra-large double bed(s) (Super-king size)",
  //                   },
  //                 ],
  //                 roomAmenities: [
  //                   "Shower",
  //                   "Air conditioning",
  //                   "Balcony",
  //                   "Bathrobe",
  //                   "Free toiletries",
  //                   "Private bathroom",
  //                   "Wireless Internet",
  //                   "Flat-screen TV",
  //                   "Coffee machine",
  //                   "Linen",
  //                   "Cots",
  //                 ],
  //                 roomImages: [
  //                   {
  //                     url: "https://static.cupid.travel/rooms-large-pictures/hd/247231976.jpg",
  //                     description: "",
  //                   },
  //                   {
  //                     url: "https://static.cupid.travel/rooms-large-pictures/hd/247231993.jpg",
  //                     description: "",
  //                   },
  //                   {
  //                     url: "https://static.cupid.travel/rooms-large-pictures/247231904.jpg",
  //                     description: "",
  //                   },
  //                   {
  //                     url: "https://static.cupid.travel/rooms-large-pictures/247231957.jpg",
  //                     description: "",
  //                   },
  //                   {
  //                     url: "https://static.cupid.travel/rooms-large-pictures/247231926.jpg",
  //                     description: "",
  //                   },
  //                 ],
  //                 roomPrices: [
  //                   {
  //                     uniqueId:
  //                       "GE5ESNKOIZCVEQ2OJI2UMRSPKRFVMR2OINCTMVCLKNFUMRCUJFGTEVKKKZEFKVKXINEEWRK2KVEVKS2OJNFESVKPK5FTESKSI5KTEU2TLBFFMR2UI5JEGSCKLJFUKT2SGIZEWTSLIU3FIMSNI5JEIVKLKVJVKSKWI5KUKVJSJBFVMTKVJFJEWTSLJJFUMQKVJMZESUSBKUZFKU2XJE2UCVSVKJBUMSSZLJKESVCLK5GEERCEJVLFGTCLKJEUMRKTGJLUSNBTKVDVMU2MJJNEEVKRK5FU4SSOIJCU6UKTINGEETKVGJLVGWCJGVBVISKTINNEUWSCKUZFISZSJFFEYRKPJYZEGS2KIRKESV2TKVFE4SCVIVLUWSKJKUZEKSKUGJHUUSSKIVHVKS22JE2UYVSJJVJVESSNGJDEKVSDJRFFURSGJ5JDEWSMIZFUKS2TJMZEWUSEKVFVMMSVJJHEQRKVKYZEQTCGJVLESUSKLBEU4SSVKNLVGSKKIJGVKMSVKNJESNKFKRCVMQ2CJJLEURSHKIZEUTCKINCUOVCLINFUMSKGINLVGWKMIZFVMRKVKNEEURK2IZEVEWSYJFFEURKPKVFVUSKSIVKTEUKTLJEVMSCFKVJEUV2KIVNEMR2TJMZEUQSCKVHVIS2TJNDEIVKTJVJVIR2KI5KUKVJSI5FVMTSFJFJEWSSJJJFFKT2SJJLESUSBKUZFCTBUI5CTERCRJ5BE6R2NLJEFSTKSKFDUSMSTGJGUEU2GKVNEIRKJIJJUORJVIRAU2QRSI5AVSSCZKVJVA7BVGU3DMMJSPQZHY3DQHA3WKNBUPQYS27CVKNCHYVKTPQZDAMRVFUYDELJSGN6DEMBSGUWTAMRNGI3XY7BWFYYDA",
  //                     roomOnly: true,
  //                     refundable: true,
  //                     rateType: "Refundable",
  //                     cancellationPolicy:
  //                       "If cancelled before Feb 22, 2025 09:00 PM, the cancellation fee is 329.68 USD.",
  //                     supplierPrice: "1488.32",
  //                     tax: "192.06",
  //                     suggestedSellingPrice: "1547.85",
  //                     sellingPrice: "1547.85",
  //                     amountDue: 347.85,
  //                     // "toSave": "1488.32_192.06_0.00_59.53",
  //                     taxIncluded: "yes",
  //                     feesIncluded: false,
  //                     currency: "USD",
  //                     included: {
  //                       description: "Tax",
  //                       amount: 192.06,
  //                     },
  //                     excluded: {
  //                       description: "Mandatory Tax, Resort Fee",
  //                       amount: 206.4,
  //                     },
  //                   },
  //                 ],
  //               },
  //             ],
  //             sessionId: "b8B2uFGOR0ZS6DNP",
  //           },
  //         ],
  //       },
  //     });
  //   }, 2000);
  // });
  // return await result;
};

export const hotelRebookAPI = async (requestBody: iHotelRebookArgs) => {
  return await fetchApi(
    endPoints.hotelRebook,
    API_METHODS.POST,
    requestBody,
    null
  );
};

export const handleHotelRebookAPI = async (
  requestBody: iHandleHotelRebookArgs
) => {
  return await fetchApi(
    endPoints.hotelConfirmRebook,
    API_METHODS.POST,
    requestBody,
    null
  );
};

export const cancelHotelsAPI = async (requestBody: iCancelHotelsArgs) => {
  return await fetchApi(
    endPoints.cancelHotels,
    API_METHODS.POST,
    requestBody,
    null
  );
};

export interface PostBookingAirportProps {
  googleInputAddress: string;
  setGoogleInputAddress: (address: string) => void;
  setSelectedAddress: (address: string) => void;
  selectedAddress: string;
  popoverTrigger: ReactNode;
  isPopoverOpen: boolean;
  setIsPopoverOpen: Dispatch<SetStateAction<boolean>>;
  handleApplyButton: () => void;
  label: string;
  value: string;
  handleAirportChange: (flight: {
    airportCode: string;
    airportName: string;
  }) => void;
  clearInputValues: () => void;
  hideSearchInput?: boolean;
  indexNumber: number;
}
