import { useCallback, useContext, useEffect, useRef, useState } from "react";
import hotelRoom from "../../../../assets/hotelRoom.png";
import HotelRating from "../hotelRating/hotelRating";
// import { Amenity } from "../../../../types";
// import { expandIcon, starSharpSolid } from "../../../../constant";
import styles from "./hotel.module.scss";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import { HOTEL_CARD_TYPES } from "../../../../enums";
import {
  bedFontLight,
  expandIcon,
  newBlueCheckIcon,
  searchGrayIcon,
} from "../../../../constant";
import TravelerPopover from "../../bookingPopover/travelerPopover/TravelerPopover";
import RoomWithImages from "../../../hotelDetails/components/selectRoomModal/components/RoomWithImages";
// import RoomDetailsModal from "../../../hotelDetails/components/roomDetailsModal/roomDetailsModal";
import NewDatePicker from "../../inputFields/dateNewPicker/newDatePicker";
import { checkHotelRoomAvailable } from "../../../../api/hotelDetailsAPI";
import NewLoader from "../../../reusableComponent/newLoader/NewLoader";
import { toastMessage } from "../../../../utils/ToastProvider";
import { TOAST_MESSAGES } from "../../../../constant/messages/toastMessages";
import HotelDetails from "../../../hotelDetails";
import { roundValue } from "../../../../utils";
import AppButton from "../../../button/button";
import BookedHotelDetails from "../../../hotelDetails/bookedHotelDetails";

interface HotelCardProps {
  readonly star: number;
  readonly hotelName: string;
  readonly hotelLocation: string;
  readonly hotelRating?: number;
  readonly reviewCount?: number;
  readonly Amenity?: string[];
  readonly hotelList: boolean;
  readonly hotelMainImage?: string;
  readonly reviewHeading?: string;
  readonly handleViewDetails?: () => void;
  handleHotelSelect?: () => void;
  readonly city?: string;
  readonly hotelDetails?: any;
  readonly hotelCardType?: HOTEL_CARD_TYPES;
  setIsThisHotelExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
  isThisHotelExpanded?: boolean;
  // isAccordClosed?: React.MutableRefObject<boolean>;
}

function HotelCard({
  star,
  hotelName,
  hotelLocation,
  hotelRating,
  reviewCount,
  Amenity,
  hotelList,
  hotelMainImage,
  reviewHeading,
  handleViewDetails,
  city,
  hotelDetails,
  hotelCardType = HOTEL_CARD_TYPES.ADDRESSS,
  handleHotelSelect,
  setIsThisHotelExpanded,
  isThisHotelExpanded,
  // isAccordClosed,
}: HotelCardProps): JSX.Element {
  // const [showReviewsModal, setShowReviewsModal] = useState(false);
  const globalContext = useContext(StateContext);
  const {
    setShowReviews,
    setManageHotelsArr,
    selectedHotelRoomArr = [],
    setRoomDetailsData,
    setShowRoomDetails,
  }: iGlobalContext = globalContext;
  const [isRoomCountPopoverOpen, setIsRoomCountPopoverOpen] = useState(false);
  const [checkInBookingDetails, setCheckInBookingDetails] = useState<any>({});
  const [checkOutBookingDetails, setCheckOutBookingDetails] = useState<any>({});
  // const [roomDetailsData, setRoomDetailsData] = useState<any>(null);
  // const [showRoomDetails, setShowRoomDetails] = useState(false);
  const [isLoading] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [showHotelRoomDetails, setShowHotelRoomDetails] = useState(false);

  useEffect(() => {
    setCheckInBookingDetails(hotelDetails?.checkIn);
    setCheckOutBookingDetails(hotelDetails?.checkOut);
  }, []);

  // useEffect(() => {
  //   console.log(isAccordClosed?.current, "isAccordClosed");
  //   // if (!hideShowRoomDetails) setShowHotelRoomDetails(false);
  // }, [isAccordClosed]);

  const handleReviewClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setShowReviews(true);
    },
    [setShowReviews]
  );

  const handleShowHotelRoomDetails = useCallback(() => {
    if (
      hotelCardType === HOTEL_CARD_TYPES.ADDRESSS ||
      hotelCardType === HOTEL_CARD_TYPES.BOOKING
    ) {
      setShowHotelRoomDetails(!showHotelRoomDetails);
      // console.log(isThisHotelExpanded, "value");
      setIsThisHotelExpanded &&
        setIsThisHotelExpanded(() => {
          return isThisHotelExpanded ? false : true;
        });
    }
  }, [
    hotelCardType,
    isThisHotelExpanded,
    setIsThisHotelExpanded,
    showHotelRoomDetails,
  ]);

  // useEffect(() => {
  //   // console.log(checkInBookingDetails, hotelDetails, "checkInBookingDetails");
  //   setCheckInBookingDetails(hotelDetails?.checkIn);
  //   setCheckOutBookingDetails(hotelDetails?.checkOut);
  // }, [hotelDetails?.checkIn, hotelDetails?.checkOut]);

  const checkingAvailabilityController: any = useRef(null);

  const checkPostHotelAvailability = useCallback(
    async (hotelUniqueId: string, sessionId: string, amountDue: number) => {
      checkingAvailabilityController.current = new AbortController();
      const response = await checkHotelRoomAvailable(
        {
          hotels: [{ uniqueId: hotelUniqueId, sessionId }],
        },
        checkingAvailabilityController.current,
        setIsChecking
      );
      if (response) {
        console.log(response);
        setManageHotelsArr((prev) =>
          prev.map((item: any) =>
            item.hotelBookId === hotelDetails.hotelBookId
              ? {
                  ...item,
                  selectedHotelIds: {
                    prebookId: response[0].prebookId,
                    uniqueId: hotelUniqueId,
                    sessionId,
                    hotelBookId: hotelDetails.hotelBookId,
                    amountDue,
                  },
                }
              : item
          )
        );
        toastMessage.success(
          TOAST_MESSAGES.BOOKING.Search.HotelAvailabilitySuccess.messageTitle,
          TOAST_MESSAGES.BOOKING.Search.HotelAvailabilitySuccess.message
        );
      } else {
        toastMessage.error(
          TOAST_MESSAGES.BOOKING.Search.HotelAvailabilityFailure.messageTitle,
          TOAST_MESSAGES.BOOKING.Search.HotelAvailabilityFailure.message
        );
      }
    },
    [hotelDetails.hotelBookId, setManageHotelsArr]
  );

  const { totalPrice } = hotelDetails || {};
  const {
    price: hotelPrice,
    taxesIncluded = false,
    feesIncluded = false,
  } = totalPrice || {};

  const { selectedHotelRoom } =
    selectedHotelRoomArr?.find(
      (eachHotel: any) =>
        eachHotel?.checkIn === hotelDetails?.checkIn &&
        eachHotel?.checkOut === hotelDetails?.checkOut &&
        eachHotel?.city === hotelDetails?.city
    ) || {};

  const isThisHotelSelected =
    hotelDetails?.hotelId === selectedHotelRoom?.selectedHotelId &&
    hotelDetails?.checkIn === selectedHotelRoom?.checkIn &&
    hotelDetails?.checkOut === selectedHotelRoom?.checkOut;

  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  const renderRoomWithImages = useCallback(() => {
    return hotelDetails?.rooms?.map((room: any, idx: any) => (
      <RoomWithImages
        key={idx}
        checkPostHotelAvailability={checkPostHotelAvailability}
        isChecking={isChecking}
        room={room}
        selectedHotelRoom={selectedHotelRoom}
        sessionId={hotelDetails?.sessionId}
        setRoomDetailsData={setRoomDetailsData}
        setShowRoomDetails={setShowRoomDetails}
        hotelCardType={hotelCardType}
        hotelDetails={hotelDetails}
      />
    ));
  }, [
    checkPostHotelAvailability,
    hotelCardType,
    hotelDetails,
    isChecking,
    selectedHotelRoom,
    setRoomDetailsData,
    setShowRoomDetails,
  ]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 } // Trigger when 10% of element is visible
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (!isVisible && hotelCardType !== HOTEL_CARD_TYPES.BOOKING) {
      setShowHotelRoomDetails(false);
      if (setIsThisHotelExpanded) setIsThisHotelExpanded(false);
    }
  }, [isVisible]);

  return (
    <>
      {isChecking && (
        <NewLoader
          loaderIcon1={bedFontLight}
          // loaderIcon2={bedFontLight}
          text="Checking room availability"
          isOpen={isChecking}
          onClose={() => {
            if (checkingAvailabilityController?.current) {
              checkingAvailabilityController.current?.abort();
            }
          }}
          loadFullScreen
        />
      )}
      <div
        className={`${styles.main} ${(hotelCardType === HOTEL_CARD_TYPES.ADDRESSS || hotelCardType === HOTEL_CARD_TYPES.REFUND) && styles.hotelCardBorder} ${showHotelRoomDetails && hotelCardType === HOTEL_CARD_TYPES.ADDRESSS && styles.expandedBorder} ${hotelCardType === HOTEL_CARD_TYPES.SEARCH && styles.searchTypeCls}`}
        onClick={handleShowHotelRoomDetails}
        ref={cardRef}
      >
        <div className={styles.container}>
          <div
            className={`${styles.firstCard} ${(hotelCardType === HOTEL_CARD_TYPES.ADDRESSS || hotelCardType === HOTEL_CARD_TYPES.BOOKING) && styles.addressTypeCard}`}
            // onClick={handleShowHotelRoomDetails}
          >
            <section className={styles.imgBox}>
              <img
                src={hotelMainImage ?? hotelRoom}
                alt="Hotel"
                className={`${hotelList ? styles.hotelImg : styles.packageImg}`}
              />
              <div className={styles.hotelAndStarContainer}>
                <p className={styles.hotelNameText}>{hotelName}</p>
                {!!star && (
                  <p className={styles.ratingsContainer}>{star} star hotel</p>
                )}
              </div>
            </section>
          </div>

          {(hotelCardType === HOTEL_CARD_TYPES.ADDRESSS ||
            hotelCardType === HOTEL_CARD_TYPES.REFUND ||
            hotelCardType === HOTEL_CARD_TYPES.BOOKING) && (
            <section
              className={`${styles.contentCard} ${showHotelRoomDetails && styles.tripRightPadding} ${hotelCardType === HOTEL_CARD_TYPES.BOOKING && styles.gapStyles}`}
              // onClick={handleShowHotelRoomDetails}
            >
              <div
                className={`${styles.reviewsContainer} ${!reviewCount && !hotelRating && styles.noReviews} ${isThisHotelSelected && styles.selectedHotelNoReviews} ${showHotelRoomDetails && styles.showHotelRoomReviewDetails} ${hotelCardType === HOTEL_CARD_TYPES.ADDRESSS && styles.addressRoomNoReviews}`}
              >
                {reviewCount !== undefined && hotelRating !== undefined && (
                  <HotelRating
                    hotelRating={hotelRating}
                    onReviewClick={handleReviewClick}
                    reviewCount={reviewCount}
                    reviewHeading={reviewHeading}
                  />
                )}
                {/* <span className={styles.star}>{star} star property</span> */}
              </div>
              {hotelCardType === HOTEL_CARD_TYPES.BOOKING && (
                <div className={styles.distanceAndPriceContainer}>
                  <div
                    className={`${styles.bookingContainer} ${showHotelRoomDetails && styles.showHotelRoomDetailsStyles}`}
                  >
                    <p
                      className={`${styles.distance} ${showHotelRoomDetails && styles.selectDistanceState}`}
                    >
                      {hotelDetails?.distance} mi from center
                    </p>
                    <p
                      className={`${styles.refundText} ${showHotelRoomDetails && styles.boldText}`}
                    >
                      *&nbsp;&nbsp;{hotelDetails?.refundable}
                    </p>
                  </div>
                  {isThisHotelSelected ? (
                    <div
                      className={`${styles.selectedHotelPriceContainer} ${showHotelRoomDetails && styles.expandedSelectedRoomDetails}`}
                    >
                      <img
                        src={newBlueCheckIcon}
                        alt="check icon"
                        className={styles.circleCheckIcon}
                      />
                      <div className={styles.selectedPriceContainer}>
                        <p className={styles.hotelPrice}>
                          {selectedHotelRoom?.price
                            ? `$${roundValue(selectedHotelRoom?.price)}`
                            : ""}
                        </p>
                        <p className={styles.taxAndFeesContainer}>
                          {selectedHotelRoom?.taxIncluded === "yes" &&
                            selectedHotelRoom?.feesIncluded &&
                            "incl taxes & fees"}
                          {selectedHotelRoom?.taxIncluded === "yes" &&
                            !selectedHotelRoom?.feesIncluded &&
                            "incl taxes & excl fees"}
                          {selectedHotelRoom?.taxIncluded !== "yes" &&
                            selectedHotelRoom?.feesIncluded &&
                            "excl taxes & incl fees"}
                          {selectedHotelRoom?.taxIncluded !== "yes" &&
                            !selectedHotelRoom?.feesIncluded &&
                            "excl taxes & fees"}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`${styles.priceContainer} ${showHotelRoomDetails && styles.tripRightMargin}`}
                    >
                      <p className={styles.hotelPrice}>
                        {hotelPrice ? `$${roundValue(hotelPrice)}` : ""}
                      </p>
                      <p className={styles.taxAndFeesContainer}>
                        {taxesIncluded === "yes" &&
                          feesIncluded === "yes" &&
                          "incl taxes & fees"}
                        {taxesIncluded === "yes" &&
                          feesIncluded !== "yes" &&
                          "incl taxes & excl fees"}
                        {taxesIncluded !== "yes" &&
                          feesIncluded === "yes" &&
                          "excl taxes & incl fees"}
                        {taxesIncluded !== "yes" &&
                          feesIncluded !== "yes" &&
                          "excl taxes & fees"}
                      </p>
                      {showHotelRoomDetails && (
                        <AppButton
                          className={styles.selectBtn}
                          onClick={handleHotelSelect}
                        >
                          Select rooms
                        </AppButton>
                      )}
                    </div>
                  )}
                </div>
              )}
              {hotelCardType === HOTEL_CARD_TYPES.ADDRESSS && (
                <div className={styles.divider} />
              )}
              {hotelCardType === HOTEL_CARD_TYPES.ADDRESSS && (
                <div className={styles.hotelDetailCard}>
                  <div className={styles.hotelNameCard}>
                    {/* <p className={styles.hotelName}>{hotelName}</p> */}
                    <p className={styles.location}>
                      {hotelLocation}
                      {city && `, ${city}`}
                    </p>

                    {hotelDetails?.hotelBookId && (
                      <p className={styles.confirmationTicket}>
                        Confirmation: {hotelDetails?.hotelBookId}
                      </p>
                    )}
                  </div>
                  {/* {!!hotelDetails?.distance && ( */}
                  {/* // <div className={styles.addCard}> */}
                  {/* <p className={styles.address}>{hotelLocation}</p> */}
                  {/* <p className={styles.distance}>
                  {hotelDetails?.distance} mi from Center
                </p> */}
                  {/* </div> */}
                  {/* )} */}
                </div>
              )}
              {hotelCardType === HOTEL_CARD_TYPES.REFUND && (
                <p className={styles.refundTextTwo}>
                  {hotelDetails?.roomInfo?.priceInfo?.isRefundable
                    ? "* Refundable"
                    : "* Non-Refundable"}
                </p>
              )}

              {/* <div className={styles.amenitiesCard}>
          {Amenity &&
            Amenity.map((i, index) => (
              <p className={styles.amenities} key={index}>
                {i}
                {index !== Amenity.length - 1 &&
                  "\u00A0\u00A0" + " | " + "\u00A0\u00A0"}
              </p>
            ))}
        </div> */}
            </section>
          )}
          {hotelCardType === HOTEL_CARD_TYPES.BOOKING &&
            isThisHotelSelected &&
            selectedHotelRoom?.roomName && (
              <p
                className={`${styles.selectedHotelRoomName} ${showHotelRoomDetails && styles.expandedHotelRoomName}`}
                onClick={handleHotelSelect}
              >
                {selectedHotelRoom?.roomName}{" "}
                {selectedHotelRoom?.bedInformation
                  ? `| ${selectedHotelRoom?.bedInformation}`
                  : ""}
              </p>
            )}
          {hotelCardType === HOTEL_CARD_TYPES.ADDRESSS &&
            showHotelRoomDetails && (
              <HotelDetails
                hotelDetails={{
                  ...hotelDetails?.hotelInformation,
                  hotelImages: hotelDetails?.roomInfo?.roomImages,
                  amenities: hotelDetails?.roomInfo?.roomAmenities,
                  hotelAndRoomInfo: hotelDetails,
                }}
              />
            )}
          {/* <div
            className={`${styles.bookingHotelDetailsContainer} ${showHotelRoomDetails && styles.revealHotelDetails}`}
          > */}
          {hotelCardType === HOTEL_CARD_TYPES.BOOKING &&
            showHotelRoomDetails && (
              <BookedHotelDetails
                hotelId={hotelDetails?.hotelId}
                isOpen={showHotelRoomDetails}
              />
            )}
          {/* </div> */}

          {hotelCardType === HOTEL_CARD_TYPES.SEARCH && (
            <div className={styles.hotelSearchContainer}>
              <div className={styles.searchIconContainer}>
                <img
                  src={searchGrayIcon}
                  alt="search icon"
                  className={styles.searchIcon}
                />
                <p>Search Criteria</p>
              </div>
              <div className={styles.criteriaContainer}>
                <div className={styles.roomsContainer}>
                  <p className={styles.label}>Rooms</p>
                  <TravelerPopover
                    showOnlyRoom
                    showInstrcutions
                    adultsCount={1}
                    setAdultsCount={() => {}}
                    childrenCount={0}
                    setChildrenCount={() => {}}
                    seniorsCount={0}
                    setSeniorsCount={() => {}}
                    numberOfRoomCount={hotelDetails?.roomInfo?.noOfRooms}
                    setNumberOfRoomCount={(value: any) => {
                      setManageHotelsArr((prev) =>
                        prev.map((item: any) =>
                          item.hotelBookId === hotelDetails.hotelBookId
                            ? {
                                ...item,
                                roomInfo: {
                                  ...item?.roomInfo,
                                  noOfRooms: value,
                                },
                              }
                            : item
                        )
                      );
                    }}
                    isPopoverOpen={isRoomCountPopoverOpen}
                    setIsPopoverOpen={setIsRoomCountPopoverOpen}
                    popoverTrigger={
                      <div
                        className={`${isRoomCountPopoverOpen ? styles.activeTravelBtn : styles.travelBtn}`}
                      >
                        {hotelDetails?.roomInfo?.noOfRooms > 0 && (
                          <p>
                            &nbsp;&nbsp;
                            <span>
                              {hotelDetails?.roomInfo?.noOfRooms}
                            </span>{" "}
                            {hotelDetails?.roomInfo?.noOfRooms > 1
                              ? "ROOMS"
                              : "ROOM"}
                          </p>
                        )}
                        <img
                          src={expandIcon}
                          alt="expand"
                          className={`${styles.arrowIcon} ${isRoomCountPopoverOpen && styles.openPopOverStyles}`}
                        />
                      </div>
                    }
                    showRoom
                  />
                </div>
                <div className={styles.roomsContainer}>
                  <NewDatePicker
                    label="check in"
                    actualDate={hotelDetails?.checkIn}
                    dateInput={checkInBookingDetails}
                    setDateInput={setCheckInBookingDetails}
                    handleUpdateData={() => {
                      setManageHotelsArr((prev) =>
                        prev.map((item: any) =>
                          item.hotelBookId === hotelDetails.hotelBookId
                            ? { ...item, checkIn: checkInBookingDetails }
                            : item
                        )
                      );
                    }}
                  />
                </div>
                <div className={styles.roomsContainer}>
                  <NewDatePicker
                    label="check out"
                    actualDate={hotelDetails?.checkOut}
                    dateInput={checkOutBookingDetails}
                    setDateInput={setCheckOutBookingDetails}
                    handleUpdateData={() => {
                      setManageHotelsArr((prev) =>
                        prev.map((item: any) =>
                          item.hotelBookId === hotelDetails.hotelBookId
                            ? { ...item, checkOut: checkOutBookingDetails }
                            : item
                        )
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {hotelCardType === HOTEL_CARD_TYPES.ROOM && (
            <div className={styles.roomDetailsContainer}>
              {renderRoomWithImages()}
              {Array.isArray(hotelDetails?.rooms) &&
                hotelDetails?.rooms?.length === 0 &&
                !isLoading && (
                  <>
                    <div className={styles.showCard}>No Rooms found</div>
                    {/* )} */}
                  </>
                )}
              {/* <RoomDetailsModal
              isOpen={showRoomDetails}
              roomDetailsData={roomDetailsData}
              onClose={() => {
                setShowRoomDetails(false);
              }}
            /> */}
            </div>
          )}
        </div>
        {/* <section className={styles.secondCard}>
        {reviewCount !== undefined && hotelRating !== undefined && (
          <HotelRating
            hotelRating={hotelRating}
            onReviewClick={() => setShowReviews(true)}
            reviewCount={reviewCount}
            reviewHeading={reviewHeading}
          />
        )}

        <div className={styles.viewBtn} onClick={handleViewDetails}>
          <p className={styles.viewTitle}>VIEW DETAILS</p>{" "}
          <img src={expandIcon} alt="" className={styles.expIcon} />
        </div>
      </section> */}
        {/* <ReviewsModal
        isOpen={showReviewsModal}
        onClose={() => setShowReviewsModal(false)}
      /> */}
      </div>
    </>
  );
}

export default HotelCard;
