import axios from "axios";
import { ENDPOINT } from "../../constant/environment";
import { iChatStreamingPayload } from "../../interface/common";

export async function chatStreaming(payload: iChatStreamingPayload, signal: any) {
  const URL = `${ENDPOINT}/app/v1/itineraries/stream`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    signal,
    // timeout: 1000,
  };

  try {
    const response = await axios.post(URL, payload, config);
    return response?.data;
  } catch (error: any) {
    return null;
  }
}


export async function modifyItineraryStreaming(payload: iChatStreamingPayload, signal: any) {
  const URL = `${ENDPOINT}/app/v1/itineraries/stream`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    signal,
    // timeout: 1000,
  };

  try {
    const response = await axios.post(URL, payload, config);
    return response?.data;
  } catch (error: any) {
    return null;
  }
}
