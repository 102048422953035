import React, { useContext, useEffect } from "react";
import NextBreadcrumbBtn from "../component/nextBreadcrumbBtn/NextBreadcrumbBtn";
import FlightListingComponent from "../../../component/booking/flightListingComponent/FlightListingComponent";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { StateContext } from "../../../context/globalContext/context";

export default function FlightCrumbsDetails({
  handleContinue,
}: Readonly<{
  handleContinue: any;
}>) {
  const state = useContext(StateContext);
  const {
    flightData,
    enableContinueBtn,
    flightAvailabilityData,
  }: iGlobalContext = state;
  const isContinueHide = !flightData;
  return (
    <div>
      <FlightListingComponent flightData={flightData} />
      {!isContinueHide && (
        <NextBreadcrumbBtn
          handleNext={() => {
            handleContinue();
          }}
          disabled={!flightAvailabilityData}
          //  disabled={!enableContinueBtn}
        />
      )}
    </div>
  );
}
