import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./tripDetailsPage.module.scss";
import TabsContainer from "../reusableComponent/tabsContainer/tabsContainer";
import DailyTabs from "./dailyTabs/DailyTabs";
import FlightsTabs from "./flightsTabs/FlightsTabs";
import HotelsTabs from "./hotelsTabs/HotelsTabs";
import {
  backIconWithText,
  ROUTES,
  bookIconWithText,
  shareIconWithText,
} from "../../constant";
import { Button, IconButton } from "@mui/material";
import TripDetailsHeader from "./tripDetailsHeader/TripDetailsHeader";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import { getItineraryDetailsAPI } from "../../api/getItineraryDetails";
import { getBookedFlightDetailsAPI } from "../../api/booking/getBookedFlightDetailsAPI";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
import { TRIP_DETAILS_TABS } from "./tripDetailsPageConstant";
import { getBookedFlightAndHotelSummaryAPI } from "../../api/booking/getBookedFlightAndHotelSummary";
import { toastMessage } from "../../utils/ToastProvider";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import NewLoader from "../reusableComponent/newLoader/NewLoader";
import { getBookedTravelerInfoDetails } from "../../api/booking/getBookedTravelerInfoDetails";
import { getBookedPaymentDetails } from "../../api/booking/getBookedPaymentDetails";
import { getConfigMessage } from "../../utils";
import { APP_CONFIG } from "../../constant/common";
import { iHotelSummaryArgs, ItineraryDetailsResponse } from "../../interface";
import toast from "react-hot-toast";
import { handleTripType } from "../../utils/booking/tripTypeHandler";
// import { ScreenTypes } from "../../enums";
import { getPostHotelsSummaryAPI } from "../../api/booking/postBooking";
import { RWebShare } from "react-web-share";
import { APP_URL } from "../../constant/environment";
import { ScreenTypes } from "../../enums";

export default function TripDetailsPage() {
  const globalContext = useContext(StateContext);
  const {
    prevRoute,
    setScreen,
    isMobile,
    flightChange,
    tripOrItinearyId,
    setTripOrItinearyId,
    setPrevRoute,
    selectedChatData,
    setSelectedChatData,
    activeTripDetailsTab,
    setActiveTripDetailsTab,
    setIsUserNavigatingFromPaymentGateway,
    appConfig,
    userInfo,
    setManageHotelsArr,
    setBookingDetails,
    setFlightChange,
    setItineraryId,
    setMyTripsItineraryId,
    fetchItineraryDetails,
    setFetchItineraryDetails,
    setModifyFlightStatus,
    setFirstScreen,
  }: iGlobalContext = globalContext;

  const [tabs, setTabs] = useState(["ITINERARY", "FLIGHTS", "HOTELS"]);

  const [flightSummary, setFlightSummary] = useState(null);
  const [hotelSummary, setHotelSummary] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { handleCustomError } = useCustomErrorHandler();
  const [tripDetails, setTripDetails] = useState({
    data: null,
    flightSummary: null,
    dayWiseItinerary: null,
    hotelSummary: null,
  } as any);

  const [paymentDetails, setPaymentDetails] = useState({} as any);

  useEffect(() => {
    triggerGAEvent(GA_LABEL_ENUMS.trip_details_page_load);
    return () => {
      setPrevRoute("/");
    };
  }, []);

  useEffect(() => {
    setIsUserNavigatingFromPaymentGateway(false);
    let tabs = ["ITINERARY"];
    if (flightSummary) tabs.push("FLIGHTS");
    if (hotelSummary) tabs.push("HOTELS");
    setTabs(tabs);
  }, [isLoading, tripDetails, flightSummary, hotelSummary]);

  useEffect(() => {
    // if (activeTripDetailsTab !== TRIP_DETAILS_TABS.HOTELS)
    setManageHotelsArr([]);
  }, [activeTripDetailsTab, setManageHotelsArr]);

  const navigate = useNavigate();
  const renderTripCard = useCallback(() => {
    switch (activeTripDetailsTab) {
      case TRIP_DETAILS_TABS.DAILY_PLANS: {
        return (
          <DailyTabs
            startDate={tripDetails?.data?.startDate}
            status={tripDetails?.data?.status || "draft"}
            flightSummary={flightSummary}
          />
        );
      }
      // case TRIP_DETAILS_TABS.ACTIVITIES: {
      //   return (
      //     <ActivitiesTabs
      //       // activitiesData={
      //       //   JSON.parse(tripDetails?.data?.metaData)[0]?.selectedChatData
      //       // }
      //       // activitiesData={selectedChatData}
      //       startDate={tripDetails?.data?.startDate}
      //       status={tripDetails?.data?.status || "draft"}
      //       flightSummary={flightSummary}
      //     />
      //   );
      // }
      case TRIP_DETAILS_TABS.FLIGHTS: {
        return (
          <FlightsTabs
            flightDetails={flightSummary}
            travellersInfo={tripDetails?.passengerDetails}
            flightPayment={paymentDetails?.flights}
            cardDetails={paymentDetails?.payment_info}
          />
        );
      }
      case TRIP_DETAILS_TABS.HOTELS: {
        return (
          <HotelsTabs
            hotelSummary={hotelSummary}
            travellersInfo={tripDetails?.passengerDetails}
            hotelPayment={paymentDetails?.hotels}
            cardDetails={paymentDetails?.payment_info}
          />
        );
      }
      default:
        return <></>;
    }
  }, [
    activeTripDetailsTab,
    flightSummary,
    hotelSummary,
    tripDetails?.data,
    tripDetails?.passengerDetails,
    paymentDetails?.flights,
    paymentDetails?.payment_info,
    paymentDetails?.hotels,
  ]);

  function handleBackButton() {
    triggerGAEvent(GA_LABEL_ENUMS.trip_details_back_click);
    if (isMobile) {
      setTimeout(() => {
        // window.history.back();
        navigate(ROUTES.MyTrips);
      }, 700);
    }
    setScreen("");
    setFlightChange({ flightCancel: false, flightChange: false });
  }

  function handleShare() {
    triggerGAEvent(GA_LABEL_ENUMS.trip_details_share_click);
  }

  /**
   * Getting the trip details
   */
  const getItineraryDetails = useCallback(async () => {
    setIsLoading(true);
    setManageHotelsArr([]);
    const itineraryDetailsResponse: ItineraryDetailsResponse =
      await getItineraryDetailsAPI(tripOrItinearyId);
    if (itineraryDetailsResponse?.statusCode !== 200) {
      handleCustomError(
        itineraryDetailsResponse?.statusCode,
        "itinerary.details"
      );
      return;
    }
    setFetchItineraryDetails(false);
    setBookingDetails((prevData) => ({
      ...prevData,
      isThisMultiCityTrip:
        handleTripType(itineraryDetailsResponse?.data?.trip_type) === 3,
    }));
    setTripDetails((prev: any) => ({
      ...prev,
      data: itineraryDetailsResponse?.data,
    }));
    if (itineraryDetailsResponse?.data)
      setSelectedChatData(
        typeof itineraryDetailsResponse?.data?.metaData === "string"
          ? JSON.parse(itineraryDetailsResponse?.data?.metaData)[0]
              ?.selectedChatData
          : itineraryDetailsResponse?.data?.metaData?.selectedChatData
      );

    // Getting Flight and hotel summary
    const itineraryId = itineraryDetailsResponse?.data?.itineraryId ?? "";
    setItineraryId(itineraryId);
    if (itineraryId) setMyTripsItineraryId(itineraryId);
    const summaryPayload = {
      userId: userInfo?.id || 1234,
      itineraryID: itineraryId,
    };

    const summaryResponse =
      await getBookedFlightAndHotelSummaryAPI(summaryPayload);

    if (summaryResponse?.statusCode !== 200) {
      toastMessage.error("OOPS!", "Flight and hotel data not available!");
      setIsLoading(false);
      return;
    }
    setTripDetails((prev: any) => ({
      ...prev,
      // hotelSummary: summaryResponse?.data?.hotel?.bookingInfo,
      // flightSummary: summaryResponse?.data?.flight,
      itineraryData: summaryResponse?.data?.itineraryData,
    }));

    // Getting flight Details
    const transactionIds =
      summaryResponse?.data?.flight?.map((item: any) => item?.transactionId) ||
      [];
    const flightPayload = {
      userId: userInfo?.id || 1234,
      itineraryId: itineraryId,
      transactionId: transactionIds,
    };
    const flightDetailsResponse =
      await getBookedFlightDetailsAPI(flightPayload);

    if (flightDetailsResponse?.statusCode === 200) {
      const modifyFlightStatus = flightDetailsResponse?.data?.data?.map(
        (item: any) => item?.postFlowStatus
      );
      if (modifyFlightStatus?.length)
        setModifyFlightStatus(modifyFlightStatus[0]);
      setTripDetails((prev: any) => ({
        ...prev,
        flightSummary: flightDetailsResponse?.data?.data,
      }));
    }

    // Getting flight Details
    const hotelTransactionIds =
      summaryResponse?.data?.hotel?.bookingInfo?.map(
        (item: any) => item?.transactionId
      ) || [];

    const hotelPayload: iHotelSummaryArgs = {
      itineraryID: itineraryId,
      userId: userInfo?.id || "1234",
      transactionId: hotelTransactionIds,
    };
    const hotelDetailsResponse = await getPostHotelsSummaryAPI(hotelPayload);
    if (hotelDetailsResponse?.statusCode === 200) {
      setTripDetails((prev: any) => ({
        ...prev,
        hotelSummary: hotelDetailsResponse?.data?.hotel?.bookingInfo,
      }));
    } else {
      setTripDetails((prev: any) => ({
        ...prev,
        hotelSummary: null,
      }));
    }

    // Getting Traveler info details
    const travelerDetailsResponse = await getBookedTravelerInfoDetails({
      itineraryId: itineraryId,
    });
    if (travelerDetailsResponse?.statusCode === 200) {
      setTripDetails((prev: any) => ({
        ...prev,
        passengerDetails: travelerDetailsResponse?.data?.passengerDetails,
      }));
    }

    // Getting Payment Details
    const paymentDetailsResponse = await getBookedPaymentDetails({
      itineraryId: itineraryId,
    });
    if (paymentDetailsResponse?.statusCode === 200) {
      if (
        JSON.stringify(paymentDetailsResponse) === "{}" ||
        (JSON.stringify(paymentDetailsResponse?.data?.flights) === "{}" &&
          paymentDetailsResponse?.data?.flights?.length === 0)
      ) {
        toastMessage.error(
          "OOPS!",
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
      }
      setPaymentDetails(paymentDetailsResponse?.data);
    }
    console.log(isSharedTrip, "SHARED");
    setIsLoading(false);
  }, [
    appConfig,
    handleCustomError,
    setBookingDetails,
    setFetchItineraryDetails,
    setItineraryId,
    setManageHotelsArr,
    setModifyFlightStatus,
    setMyTripsItineraryId,
    setSelectedChatData,
    tripOrItinearyId,
    userInfo?.id,
  ]);

  useEffect(() => {
    getItineraryDetails();
  }, [tripOrItinearyId, flightChange.flightCancel]);

  useEffect(() => {
    if (fetchItineraryDetails) {
      getItineraryDetails();
    }
  }, [fetchItineraryDetails]);

  useEffect(() => {
    setMyTripsItineraryId("");
  }, []);

  useEffect(() => {
    if (tripDetails?.data?.metaData) {
      let tempList: any = [];
      const list =
        typeof tripDetails?.data?.metaData === "string"
          ? JSON.parse(tripDetails?.data?.metaData)[0]?.selectedChatData
              ?.destinationList
          : tripDetails?.data?.metaData?.selectedChatData?.destinationList;
      for (let i = 0; i < list?.length; i++) {
        tempList = [...tempList, ...(list[i]?.tripDetails ?? [])];
      }
      setFlightSummary(
        tripDetails?.flightSummary?.length > 0
          ? tripDetails?.flightSummary
          : null
      );
      setHotelSummary(
        tripDetails?.hotelSummary?.length > 0 ? tripDetails?.hotelSummary : null
      );
    }
  }, [tripDetails]);

  /**
   * Side effect to listen if it is a shared trip and has the itinerary id append to the URL
   */
  const [isSharedTrip, setIsSharedTrip] = useState(false);
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const data = urlParams.get("id");
    if (data) {
      const formattedData = decodeURIComponent(data)
        .replace(/\+/g, " ")
        .replace(/=/g, "")
        .replace(/\?/g, "");
      setTripOrItinearyId(formattedData);
      setIsSharedTrip(true);
      setActiveTripDetailsTab(TRIP_DETAILS_TABS.DAILY_PLANS);
    }
  }, []);

  useEffect(() => {
    if (isSharedTrip) {
      setScreen(ScreenTypes.TripDetails);
      setFirstScreen("");
      if (!isMobile) navigate(ROUTES.Home);
    }
  }, [isSharedTrip]);

  if (
    JSON.stringify(tripDetails) === "{}" ||
    JSON.stringify(paymentDetails) === "{}" ||
    (JSON.stringify(paymentDetails?.flights) === "{}" &&
      paymentDetails?.flights?.length === 0) ||
    isLoading
  )
    return (
      <NewLoader
        text="Please wait"
        // className={styles.loaderMain}
        // cancelButtonClassName={styles.cancelBtn}
        isOpen={true}
        onClose={() => {
          toast.dismiss();
          if (isMobile) navigate(prevRoute);
          else setScreen("");
        }}
      />
    );

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <div
          className={styles.buttonContainer}
          // style={{ marginBottom: isSharedTrip ? "0px" : "-10px" }}
        >
          <Button
            className={`${styles.columnAlignButton}`}
            onClick={handleBackButton}>
            <img src={backIconWithText} alt="Close" />
          </Button>

          <div className={styles.rightBtnContainer}>
            {!isSharedTrip && (
              <div className={styles.shareButtonContainer}>
                <RWebShare
                  data={{
                    url: `${APP_URL}trip-details/?id=${tripOrItinearyId}`,
                    title: "Zenvoya",
                  }}
                  onClick={handleShare}>
                  <IconButton
                    className={`${styles.rowAlignButton}`}
                    onClick={handleShare}>
                    <img
                      className={styles.iconButton}
                      src={shareIconWithText}
                      alt="share"
                    />
                  </IconButton>
                </RWebShare>
              </div>
            )}
            {/* <IconButton
              className={`${styles.rowAlignButton}`}
              onClick={() => {}}>
              <img
                className={styles.iconButton}
                src={bookIconWithText}
                alt="share"
              />
            </IconButton> */}
          </div>
        </div>
        <TripDetailsHeader
          travellersInfo={tripDetails?.itineraryData}
          tripName={
            tripDetails?.data?.tripName ||
            selectedChatData?.destinationList[0]?.city_name
          }
          itineraryNumber={tripDetails?.itineraryData?.itineraryId}
          startDate={tripDetails?.data?.startDate}
          endDate={tripDetails?.data?.endDate}
          bookedDate={tripDetails?.itineraryData?.bookedOn}
          handleShare={handleShare}
          tripOrItinearyId={tripOrItinearyId}
          isSharedTrip={isSharedTrip}
        />
      </div>
      <TabsContainer
        className={styles.tabsClass}
        activeTab={activeTripDetailsTab}
        setActiveTab={setActiveTripDetailsTab}
        tabsArr={tabs}
      />
      {renderTripCard()}
    </div>
  );
}
