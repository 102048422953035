import React from "react";
import ArrivalOnDifferentDate from "../../../../reusableComponent/arrivalOnDifferentDate/ArrivalOnDifferentDate";
import NewFlightDetails from "../../../newFlightDetails/newFlightDetails";
import FlightDetails from "../../../../tripDetailsPage/flightsTabs/flightDetails/flightDetail";
import styles from "./step.module.scss";
interface StepperProps {
  steps?: any[];
  stops?: any[];

  flightImage?: any;
}

const FlightDetailStepper: React.FC<StepperProps> = ({
  flightImage = "",
  steps = [],
  stops = [],
}) => {
  const showAllSteps = true;

  function isLayover(booleanData: any) {
    return booleanData;
  }

  function addHHMM(time: string) {
    if (typeof time !== "string" || time.split(":").length < 2) return "";
    const splitTime = time.split(":");
    return splitTime[0] + "h " + splitTime[1] + "m";
  }

  function checkIfArrivalIsNextDate(timestamp1: string, timestamp2: string) {
    if (!timestamp1 || !timestamp2) return false;
    const date1 = timestamp1.split("-");
    const date2 = timestamp2.split("-");
    return !(
      date1[0] === date2[0] &&
      date1[1] === date2[1] &&
      date1[2].substring(0, 2) === date2[2].substring(0, 2)
    );
  }

  if (steps.length === 0) {
    return <div>No data found</div>;
  }

  return (
    <div className={styles.main}>
      <div className={styles.stepper}>
        {steps.map((step: any, index: number) => {
          return (
            <React.Fragment key={index}>
              {(showAllSteps || index === 0) && (
                <div className={styles.step}>
                  <div className={styles.circle}></div>
                  <div className={styles.stepContent}>
                    <div className={styles.arrivalDateContainer}>
                      <FlightDetails
                        airportCode={step?.depAirportCode}
                        airportName={step?.depAirportName}
                        date={step?.departureDate}
                        flightType={"departure"}
                      />
                    </div>
                    {checkIfArrivalIsNextDate(
                      step?.departureDate ?? "",
                      step?.departureDate
                    ) && (
                      <div className={styles.arrivalOnDiff}>
                        <ArrivalOnDifferentDate variant="danger" />
                      </div>
                    )}

                    {showAllSteps && (
                      <div className={styles.card}>
                        <div className={styles.subCard}>
                          <div className={styles.airlineImgCard}>
                            <img
                              src={step?.marketingAirlineImage}
                              alt="flight"
                              className={styles.airlineImg}
                            />
                          </div>

                          <NewFlightDetails
                            title={step?.marketingAirlineName}
                            subTitle={`${step?.operatingAirline}${step?.flightNumber}`}
                            description={""}
                            imgUrl={""}
                            hide={true}
                            flightName={step?.marketingAirlineName}
                            flightNumber={step?.flightNumber}
                            marketingAirline={step?.marketingAirline}
                            duration={`${addHHMM(step?.flyDuration) || ""}`}
                            manufactureName={step?.equipmentType ?? ""}
                            operatorName={step?.operatingAirlineName}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className={`${step.layOver ? styles.dashLine : styles.line}`}></div>
                  <div className={`${step.layOver ? styles.square : null}`} />
                </div>
              )}

              {/* Arrival */}
              {(showAllSteps || index !== 0 || steps?.length === 1) && (
                <div className={styles.step}>
                  <div className={styles.circle}></div>
                  <div className={styles.stepContent}>
                    <div className={styles.arrivalDateContainer}>
                      <FlightDetails
                        airportCode={step?.arrAirportCode}
                        airportName={step?.arrAirportName}
                        date={step?.arrivalDate}
                        flightType={"arrival"}
                      />
                    </div>
                    {checkIfArrivalIsNextDate(
                      step?.departureDate,
                      step?.arrivalDate
                    ) && (
                      <div className={styles.arrivalOnDiff}>
                        {" "}
                        <ArrivalOnDifferentDate />
                      </div>
                    )}
                    {showAllSteps && stops[index]?.layover && (
                      <div className={styles.card}>
                        <div className={styles.subCard}>
                          {isLayover(steps?.length - 1 !== index) && (
                            <NewFlightDetails
                              title={"Layover"}
                              subTitle={""}
                              description={addHHMM(stops[index]?.layover) || ""}
                              imgUrl={""}
                              layoverCityName={stops[index]?.cityName}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {index < steps.length - 1 && (
                    <div
                      className={`${isLayover(steps?.length - 1 !== index) ? styles.dashLine : styles.line}`}>
                      <div
                        className={`${isLayover(steps?.length - 1 !== index) && styles.square}`}
                      />
                    </div>
                  )}
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default FlightDetailStepper;
